import { motion } from "framer-motion";
import { Fragment } from "react";

const SectionTitle = ({ title }) => {
  const words = title.split(" ");
  
  const transition = { duration: 1, ease: [.25,.1,.25,1] };
  const variants = {
    hidden: { 
      filter: "blur(5px)", // Reduced blur for better mobile performance
      transform: "translateY(30px)",
      opacity: 0 
    },
    visible: { 
      filter: "blur(0)", 
      transform: "translateY(0)", 
      opacity: 1 
    },
  };

  return (
    <motion.div
      style={{ perspective: "1000px" }}
      initial="hidden"
      whileInView="visible"
      viewport={{ 
        once: true, 
        margin: "-50px" // Adjusted margin for better mobile viewing
      }}
      transition={{ staggerChildren: 0.03 }} // Slightly faster animation
    >
      <h1 className="font-medium text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center">
        {words.map((word, index) => (
          <Fragment key={index}>
            <motion.span 
              className="inline-block"
              transition={transition}
              variants={variants}
            >
              {word}
            </motion.span>
            {index < words.length - 1 && ' '}
          </Fragment>
        ))}
      </h1>
    </motion.div>
  );
};

export default SectionTitle;






// import { motion } from "framer-motion";
// import { Fragment } from "react";

// const SectionTitle = ({ title }) => {
//   const words = title.split(" ");
  
//   const transition = { duration: 1, ease: [.25,.1,.25,1] };
//   const variants = {
//     hidden: { 
//       filter: "blur(10px)", 
//       transform: "translateY(50px)",
//       opacity: 0 
//     },
//     visible: { 
//       filter: "blur(0)", 
//       transform: "translateY(0)", 
//       opacity: 1 
//     },
//   };

//   return (
//     <motion.div
//       style={{ perspective: "1000px" }}
//       initial="hidden"
//       whileInView="visible"
//       viewport={{ once: true, margin: "-100px" }}
//       transition={{ staggerChildren: 0.04 }}
//     >
//       <h2 className="font-medium text-5xl text-center">
//         {words.map((word, index) => (
//           <Fragment key={index}>
//             <motion.span 
//               className="inline-block"
//               transition={transition}
//               variants={variants}
//             >
//               {word}
//             </motion.span>
//             {index < words.length - 1 && ' '}
//           </Fragment>
//         ))}
//       </h2>
//     </motion.div>
//   );
// };

// export default SectionTitle;