import AnimatedSparkle from "./SVG/AnimatedSparkle";

const AILoadingAnimation = ({ width, height, text }) => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <AnimatedSparkle width={width} height={height} />
      <p className="text-2xl text-blue-600 font-medium mt-12">{text}</p>
    </div>
  )

};
export default AILoadingAnimation;