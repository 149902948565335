import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faGear } from "@fortawesome/free-solid-svg-icons";
import FlashcardSettingsModal from "./FlashcardSettingsModal";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const FlashcardProgressBar = ({ index, numberOfQuestions, flashcards, uuid }) => {
  const [progress, setProgress] = useState(0);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const navigate = useNavigate();

  sessionStorage.setItem("loadNavbar", "false");


  useEffect(() => {
    index === 0 && setProgress(0);
    index > 0 && setProgress((index / numberOfQuestions) * 100);
  }, [index, numberOfQuestions]);

  const handleQuitModalButtonClicked= (event) => {
    event.preventDefault();
    event.stopPropagation();
    sessionStorage.setItem("loadNavbar", null);
    return navigate(`/flashcard/preview/${uuid}`);
  }

  const handleSettingsModalButtonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowSettingsModal(true)
  }


  return (
    <div className="py-6">
      <FlashcardSettingsModal showModal={showSettingsModal} setShowModal={setShowSettingsModal} flashcards={flashcards} askMode={"write"} />
      <div className="flex justify-between items-center">
        <div className="w-3/12 h-7 flex items-center justify-center pl-20">
          <motion.button 
            onClick={(event) => handleQuitModalButtonClicked(event)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ fontSize: "30px" }}
              className="text-gray-500 hover:text-gray-600 dark:hover:text-gray-500 dark:text-gray-300"
            />
          </motion.button>
        </div>
        <div className="w-6/12 h-7">
          <div className="w-full bg-gray-200 rounded-full h-5 md:h-7 mb-6 dark:bg-gray-800 overflow-hidden">
            <div
              className="bg-green-400 h-5 md:h-7 dark:bg-green-500 rounded-full"
              style={{
                width: `${progress}%`,
                transition: "width 0.6s ease-out"
              }} 
            />
          </div>
        </div>
        <div className="w-3/12 h-7 flex items-center justify-center pr-20">
          <motion.button 
            onClick={(event) => handleSettingsModalButtonClicked(event)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FontAwesomeIcon icon={faGear} style={{fontSize:"22.5px"}} className="text-gray-500 hover:text-gray-600 dark:hover:text-gray-500 dark:text-gray-300" />
          </motion.button>
        </div>
      </div>
    </div>
  );
};
export default FlashcardProgressBar;
