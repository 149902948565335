import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useTransform,
  wrap,
  animate,
} from 'framer-motion';
import ReviewCard from './ReviewCard';
import MobileReviewCard from './MobileReviewCard';
import person1 from '../../Assets/images/headshots/person1.webp';
import person2 from '../../Assets/images/headshots/person2.webp';
import person3 from '../../Assets/images/headshots/person3.webp';
import person4 from '../../Assets/images/headshots/person4.webp';
import person5 from '../../Assets/images/headshots/person5.webp';
import person6 from '../../Assets/images/headshots/person6.webp';
import person7 from '../../Assets/images/headshots/person7.webp';
import person8 from '../../Assets/images/headshots/person8.webp';
import person9 from '../../Assets/images/headshots/person9.webp';
import SectionTitle from './SectionTitle';


const TestimonialsSection = () => {

  const testimonials = useMemo(() => [
    {
        id: 1,
        review: 'This app is amazing! Generating flashcards from text and images has made studying so much easier, and the AI feedback is super helpful for improving my understanding.',
        name: 'Hiroshi Tanaka',
        reviewerImage: person1, // Asia male
    },
    {
        id: 2,
        review: 'I love the personalized prep exams and detailed feedback. It’s like having my own tutor, helping me focus on what really matters for my exams.',
        name: 'Ashley Johnson',
        reviewerImage: person2, // USA female
    },
    {
        id: 3,
        review: 'Fantastic tool! The AI flashcards and feedback on my work make studying so much less stressful and way more productive.',
        name: 'Arjun Sharma',
        reviewerImage: person4, // Indian male
    },
    {
        id: 4,
        review: 'The grammar exercises and mistake explanations have been a huge help. I finally understand where I go wrong, making my practice much more effective.',
        name: 'Ethan Miller',
        reviewerImage: person3, // USA male
    },

    {
        id: 5,
        review: 'This app has changed how I study. The feedback on prep exams and personalized exercises are spot-on, helping me feel more prepared for tests.',
        name: 'Jessica Wilson',
        reviewerImage: person5, // USA female
    },
    {
        id: 6,
        review: 'Exceeded my expectations! From flashcards to personalized feedback, this app has everything I need to stay on top of my studies.',
        name: 'Matthew Anderson',
        reviewerImage: person6, // USA male
    },
    {
        id: 7,
        review: 'A must-have! The grammar exercises are tailored to my needs, and the explanations make it easy to learn from my mistakes.',
        name: 'Priya Patel',
        reviewerImage: person7, // Indian woman
    },
    {
        id: 8,
        review: 'Amazing features! The personalized feedback and practice exams have made studying so much more effective and stress-free.',
        name: 'Kwame Okoro',
        reviewerImage: person8, // African male
    },
    {
        id: 9,
        review: 'This app saves me so much time! Flashcards, prep exams, and grammar exercises—all with helpful feedback to guide my learning.',
        name: 'Minho Park',
        reviewerImage: person9, // Asian male
    },
  ], []);


  const carouselRef = useRef(null);
  const baseX = useMotionValue(0);
  const [isHovering, setIsHovering] = useState(false);
  const [carouselWidth, setCarouselWidth] = useState(0);

  const baseSpeed = 70;
  const slowSpeed = 20;

  const speed = useMotionValue(baseSpeed);

  useEffect(() => {
    if (carouselRef.current) {
      const totalWidth = carouselRef.current.scrollWidth;
      setCarouselWidth(totalWidth / 2);
    }
  }, [testimonials]);

  useEffect(() => {
    const controls = animate(speed, isHovering ? slowSpeed : baseSpeed, {
      type: 'spring',
      damping: 50,
      stiffness: 150,
      mass: 0.5,
    });

    return controls.stop;
  }, [isHovering, speed, baseSpeed, slowSpeed]);

  useAnimationFrame((_t, delta) => {
    const moveBy = (speed.get() * delta) / 1000;
    baseX.set(baseX.get() - moveBy);
  });

  const x = useTransform(baseX, (v) => wrap(-carouselWidth, 0, v));

  const shadowStyleLeft = {
    background: 'linear-gradient(to right, rgba(246, 247, 251, 1) 15%, transparent 70%)',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '10%',
    height: '100%',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 1,
    zIndex: 10,
  };

  const shadowStyleRight = {
    background: 'linear-gradient(to left, rgba(246, 247, 251, 1) 15%, transparent 70%)',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '10%',
    height: '100%',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 1,
    zIndex: 10,
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="mt-20 md:mt-24 mb-8 md:mb-20 px-4 sm:px-8 lg:px-16"
    >
      <SectionTitle title="What Our Users Say" />

      {/* Mobile static version */}
      <motion.div 
        className="lg:hidden mt-6"
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
      >
        {testimonials.slice(0,3).map((testimonial, index) => (
          <motion.div
            className='flex flex-col items-center justify-center'
            key={index}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <MobileReviewCard
              review={testimonial.review}
              reviewerName={testimonial.name}
              reviewerImage={testimonial.reviewerImage}
            />
          </motion.div>
        ))}
      </motion.div>



      {/* Desktop */}
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
        className="relative overflow-hidden w-full mt-6 hidden lg:block"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div style={shadowStyleLeft} />

        <motion.div
          className="flex flex-nowrap gap-20"
          style={{ x }}
          ref={carouselRef}
        >
          {[...testimonials, ...testimonials].map((testimonial, index) => (
            <div key={index} className="flex-shrink-0 w-72 sm:w-80 lg:w-96">
              <ReviewCard
                review={testimonial.review}
                reviewerName={testimonial.name}
                reviewerImage={testimonial.reviewerImage}
              />
            </div>
          ))}
        </motion.div>

        <div style={shadowStyleRight} />
      </motion.div>
    </motion.div>
  );
};

export default TestimonialsSection;