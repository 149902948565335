import api from "./../api";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { Notify } from "../providers/NotificationProvider";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
import { motion, AnimatePresence } from "framer-motion";

const AllFlashcards = () => {
  const [loading, setLoading] = useState(true);
  const [flashcardDrafts, setFlashcardDrafts] = useState([]);
  const [flashcardData, setFlashcardData] = useState([]);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [deleteDraftUuid, setDeleteDraftUuid] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    const urls = ["/api/flashcards/flashcard_set_title_uuid/", "/api/flashcards/get_all_flashcard_drafts/"];

    Promise.all(urls.map((url) => api.get(url)))
    .then((responses) => {
      setFlashcardData(responses[0].data);
      setFlashcardDrafts(responses[1].data);
      console.log("flashcardDrafts: ", responses[1].data);
      setLoading(false);
    })
    .catch((error) => {
      
      console.error(error);
    });
  }, [reloadTrigger]);



  const handleDeleteDraft = () => {
    api.delete(`/api/flashcards/delete_flashcard_draft/${deleteDraftUuid}/`)
    .then((response) => {
      console.log(response.data);
      Notify({message: "Draft deleted successfully!", type: "success"});
      setReloadTrigger(!reloadTrigger);
    })
    .catch((error) => {
      console.error(error);
    });
  };



  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };



  const boxVariants = {
    hidden: { y: 50, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        type: "easeOut",
        duration: 0.4
      }
    }
  };

  const deleteButtonVariants = {
    hidden: { x: 20, opacity: 0 },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  const progressBarVariants = {
    hidden: { width: 0 },
    show: width => ({
      width: `${width}%`,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    })
  };

  const allCards = [
    ...flashcardDrafts.map(draft => ({ ...draft, type: 'draft' })),
    ...flashcardData.map(set => ({ ...set, type: 'set' }))
  ];


  if (loading) {
    return null;
  }

  return (
    <div style={{ padding: "0 13vw", minHeight: `${ window.innerHeight - 77 }px` }} className="min-h-screen bg-light dark:bg-dark">


      <ConfirmDeletionModal showModal={showConfirmDeletionModal} setShowModal={setShowConfirmDeletionModal} handleDelete={handleDeleteDraft} header={"Delete Draft?"} text={"Are you sure you want to delete the draft? This action cannot be undone."} buttonLabel={"Delete"} />


      <motion.div 
        className="py-10"
        initial="hidden"
        animate="show"
        variants={containerVariants}
      >

        <motion.div 
          className="w-full flex justify-between items-center mb-10"
          initial="hidden"
          animate="show"
          variants={{
            hidden: { y: -20, opacity: 0 },
            show: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: "easeOut",
                when: "beforeChildren"
              }
            }
          }}
        >
          <h1 className="text-3xl font-semibold mb-0 flex items-center dark:text-dark">
            Flashcard Sets
          </h1>
          <motion.button
            className="text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-white flex items-center px-5 py-1.5 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-600"
            onClick={() => navigate("/flashcard/input")}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="text-2xl mr-2"
            />
            <span className="text-xl font-medium">Create</span>
          </motion.button>
        </motion.div>

        <div className="grid gird-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
          {allCards.map((item, index) => (
            <motion.div 
              key={item.uuid}
              variants={boxVariants}
            >
              <motion.div 
                layout
                className="bg-white dark:bg-gray-800 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 rounded-xl"
                style={{ height: item.type === 'draft' ? 192 : 'auto' }}
              >
                {item.type === 'draft' ? (
                  <div className="p-6 flex flex-col items-start group hover:text-inherit" onClick={() => navigate(`/flashcard/input/${item.uuid}`)}>
                    <div className="flex justify-start space-x-2">
                      <p className="font-semibold text-lg mb-4 dark:text-dark">
                        {item.title || "Untitled"}
                      </p>
                      <span className="text-blue-600 dark:text-blue-500 font-semibold text-lg">(Draft)</span>
                    </div>
                    <p className="rounded-full bg-gray-200 dark:bg-gray-700 dark:text-dark px-3 py-1 text-sm font-medium">
                      Terms: {item.words.length}
                    </p>
                    <div className="w-full flex justify-end">
                      <button 
                        className="flex justify-end items-center font-medium text-red-500 hover:text-red-700 gap-2.5" 
                        style={{ marginTop: 40 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteDraftUuid(item.uuid);
                          setShowConfirmDeletionModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        <p>Delete Draft</p>
                      </button>
                    </div>
                  </div>
                ) : (
                  <Link to={"/flashcard/preview/" + item.uuid} className="group hover:text-inherit border-gray-300 rounded-xl p-6 flex flex-col items-start">
                    <div className="font-semibold text-lg mb-4 dark:text-dark">{item.title}</div>
                    <div className="rounded-full bg-gray-200 dark:bg-gray-700 px-3 py-1 text-sm font-medium dark:text-dark">
                      Terms: {item.number_of_flashcards}
                    </div>
                    <label className="w-full font-semibold mb-2 mt-6 text-sm dark:text-dark">{item.performance}% learned</label>
                    <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded-full w-full">

                      <motion.div
                        className="bg-green-400 dark:bg-green-500 h-5 rounded-full"
                        initial="hidden"
                        animate="show"
                        variants={progressBarVariants}
                        custom={item.performance}
                      />
                    </div>
                  </Link>
                )}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </motion.div>

    </div>
  );
};
export default AllFlashcards;
