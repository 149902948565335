import { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import '../../styles/FlashcardCarousel.css'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import useDarkMode from '../../hooks/useDarkMode';

const FlashcardCarousel = ({ flashcards }) => {
  const [flippedCardIndex, setFlippedCardIndex] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);  
  const [displayedCards, setDisplayedCards] = useState([]);
  const sliderRef = useRef(null);

  const isDarkMode = useDarkMode()

  useEffect(() => {
    const validFlashcards = flashcards.filter(card => card.word.trim() !== "" && card.definition.trim() !== "");
    setDisplayedCards(validFlashcards);
  }, [flashcards]);



  const toggleFlip = (index) => {
    if (index === currentSlide) {

      setFlippedCardIndex(flippedCardIndex === index ? null : index); 
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,        
    slidesToScroll: 1,
    centerMode: true,      
    centerPadding: '60px',
    adaptiveHeight: true,
    arrows: false,
    afterChange: (current) => {
      setCurrentSlide(current);
      setFlippedCardIndex(null); // Reset the flipped state when slide changes
    },       
  };

  return (
    <div className="mb-12">
      <Slider ref={sliderRef} {...settings}>
        {displayedCards.map((card, index) => (
          <div
            key={card.uuid}
            className="perspective-container max-w-[800px] mx-auto h-[450px] py-5 px-6"
          >

            <div
              onClick={() => toggleFlip(index)}
              className={`card relative ${
                flippedCardIndex === index
                  ? 'flipped shadow-flipped dark:shadow-none'
                  : 'shadow-normal dark:shadow-none'
              } bg-white dark:bg-gray-800 rounded-2xl border-2 border-gray-300 h-full`}
            >
              <div className="absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black dark:text-dark select-none card-face card-front">
                {card.word}
              </div>
              <div className="absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black dark:text-dark select-none card-face card-back">
                {card.definition}
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {displayedCards.length > 1 && (
        <div className="flex justify-center gap-28">
          <div className='bg-white dark:bg-gray-800 rounded-full'>
            <button 
              className='hover:bg-gray-200 dark:hover:bg-gray-700 p-2.5 shadow-lg dark:shadow-none rounded-full focus:outline-none transition duration-200 ease-in-out transform hover:scale-105 active:scale-95 active:shadow-inner'
              onClick={() => sliderRef.current.slickPrev()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke={isDarkMode ? "white" : "black"}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
          </div>
          <div className='bg-white dark:bg-gray-800 rounded-full'>
            <button 
              className='hover:bg-gray-200 dark:hover:bg-gray-700 p-2.5 shadow-lg dark:shadow-none rounded-full focus:outline-none transition duration-200 ease-in-out transform hover:scale-105 active:scale-95 active:shadow-inner'
              onClick={() => sliderRef.current.slickNext()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke={isDarkMode ? "white" : "black"}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default FlashcardCarousel;
