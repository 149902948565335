import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";

const GetStartedButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();


  const buttonVariants = {
    rest: {
      backgroundColor: "#FFFFFF",
      paddingLeft: "0.5rem",
      paddingRight: "1rem",
      backgroundImage: "", 

    },
    hover: {
      backgroundColor: "#1d4ed8",
      paddingLeft: "1rem",
      paddingRight: "0.5rem",
      backgroundImage: "linear-gradient(to right, #1d4ed8, #3b82f6)", 

    },
  };

  const iconVariants = {
    rest: {
      backgroundColor: "#1d4ed8",
      color: "#FFFFFF",
      boxShadow: "0px 4px 6px rgba(29, 78, 216, 0.5)",
    },
    hover: {
      backgroundColor: "#FFFFFF",
      color: "#1d4ed8",
      boxShadow: "rgba(4, 3, 18, 0.38) 0px 4px 10px 0px",
    },
  };

  const textVariants = {
    rest: { color: "#000000" },
    hover: { color: "#FFFFFF" },
  };

  return (
    <motion.button
      className={`flex items-center justify-center py-2 rounded-full overflow-hidden gap-2.5 shadow-color-lg ${isHovered ? "shadow-blue-500/50" : "shadow-gray-300"}`}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileTap={{ scale: 0.95 }}
      layout
      variants={buttonVariants}
      animate={isHovered ? "hover" : "rest"}
      transition={{
        backgroundColor: { duration: 0 },
        paddingLeft: { duration: 0.4, ease: "easeOut" },
        paddingRight: { duration: 0.4, ease: "easeOut" },
        layout: { duration: 0.4, ease: "easeOut" },
      }}
      onClick={() => navigate("/register")}
    >
      <motion.div
        layout
        style={{ order: isHovered ? 2 : 1 }}
        className="flex-shrink-0"
      >
        <motion.div
          className="relative w-7 md:w-8 h-7 md:h-8 rounded-full flex items-center justify-center"
          variants={iconVariants}
          animate={isHovered ? "hover" : "rest"}
          transition={{
            backgroundColor: { duration: 0.4, ease: "easeOut" },
            color: { duration: 0.4, ease: "easeOut" },
            boxShadow: { duration: 0.4, ease: "easeOut" },
            layout: { duration: 0.4, ease: "easeOut" },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >             
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2.5}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </motion.div>
      </motion.div>

      <motion.span
        layout
        style={{ order: isHovered ? 1 : 2 }}
        className="text-lg md:text-xl font-medium"
        variants={textVariants}
        animate={isHovered ? "hover" : "rest"}
        transition={{
          color: { duration: 0.4, ease: "easeOut" },
          layout: { duration: 0.4, ease: "easeOut" },
        }}
      >
        Start for Free
      </motion.span>
    </motion.button>
  );
};

export default GetStartedButton;
