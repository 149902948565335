import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import LoadingSpinner from "../components/General/LoadingSpinner";
import api from "../api";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import { motion, AnimatePresence } from "framer-motion";



const FlashcardRecap = () => {
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);
  const [allCorrect, setAllCorrect] = useState(false);
  const [flashcards, setFlashcards] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { uuid, type } = useParams();


  const askTermsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.definition,
      answer: flashcard.user_answer,
      correctIncorrect: flashcard.correct_incorrect[flashcard.correct_incorrect.length - 1],
      correction: flashcard.correction,
      point: flashcard.points
    }));
  }
  const askDefinitionsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.word,
      answer: flashcard.user_answer,
      correctIncorrect: flashcard.correct_incorrect[flashcard.correct_incorrect.length - 1],
      correction: flashcard.correction,
      point: flashcard.points
    }));
  }

  useEffect(() => {
    setLoading(true);
    const urls = [`/api/flashcards/write_mode_settings/${uuid}/`, `/api/flashcards/saved_write_mode_flashcards/${uuid}/`];

    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      const settingsResponse = responses[0].data;
      const flashcardsResponse = responses[1].data;

      console.log("this is the flashcard data: ", responses[1].data)

      let flashcardData = [];
      if (settingsResponse.ask_definition) {
        flashcardData = askDefinitionsFormatter(flashcardsResponse);
      }
      else {
        flashcardData = askTermsFormatter(flashcardsResponse);
      }
      setAllCorrect(flashcardData.every(item => { return item.correctIncorrect === true; }));
      setPoints(flashcardData.reduce((acc, item) => acc + item.point, 0));
      setMaxPoints(flashcardData.length);
      setFlashcards(flashcardData);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Failed to fetch data:", error);
    });
  }, []);






  const handleRepeatButtonClicked = () => {
    if (allCorrect) {
      navigate(`/flashcard/preview/${uuid}`);
    }
    else {
      if (type === "starred" || type === "starred-incorrect") {
        navigate(`/flashcard/mode/write/${uuid}/starred-incorrect`);
      } else if (type === "all" || type === "incorrect") {
        navigate(`/flashcard/mode/write/${uuid}/incorrect`);
      }
    }
  }







  const progressBarVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6
      }
    }
  };

  const cardVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.9
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeOut",
        duration: 0.6
      }
    }
  };


  if (loading) {
    return (
      <LoadingSpinner />
    );
  }
  return (
    <div className="px-6 bg-light dark:bg-dark min-h-screen">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={progressBarVariants}
      >
        <PointsBarComponent points={points} maxPoints={maxPoints} handleExitButtonClicked={() => navigate(`/flashcard/preview/${uuid}`)} handleRepeatButtonClicked={handleRepeatButtonClicked} />
      </motion.div>



      <AnimatePresence>
        {flashcards.map((flashcard, index) => (
          <motion.div 
            className="flex justify-center items-center" 
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
            variants={cardVariants}
          >
            <div 
              className={`max-w-[735px] w-full bg-white dark:bg-gray-800 rounded-2xl mb-8 p-8 dark:shadow-none shadow-color-lg ${flashcard.correctIncorrect ? "shadow-green-300" : "shadow-red-300"}`}
            >
              <p className="text-xl mb-6 dark:text-dark">{flashcard.question}</p>
              {flashcard.correctIncorrect ? (
                <AnswerInputFieldCorrectCorrection userAnswer={flashcard.answer} showHeader={false} isRecapPage={true} />
              ) : (
                <AnswerInputFieldIncorrectCorrection userAnswer={flashcard.answer} correction={flashcard.correction} correctionHeader={"Correct answer"} isRecapPage={true} />
              )}

            </div>
          </motion.div>
        ))}
      </AnimatePresence> 

      <motion.div 
        className="flex justify-center pb-20 mt-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <button
          className="max-w-[735px] w-full px-6 py-3 rounded-lg text-xl font-medium transition shadow-lg dark:shadow-none text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"
          onClick={handleRepeatButtonClicked}
        >
          {allCorrect ? "Repeat All Terms" : "Repeat Wrong Terms"}
        </button>
      </motion.div>
    </div>

  );
};
export default FlashcardRecap;
