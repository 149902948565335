import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RecentQuizList from "../components/Home/RecentQuizList";
import CountDownTimer from "../components/Home/CountDownTimer";
import CircularProgress from "../components/Home/CircularProgress";
import NoSetField from "../components/Home/NoSetField";
import { motion } from "framer-motion";


const HomePageAuth = () => {
  const [loading, setLoading] = useState(true);
  const [examData, setExamData] = useState([]);
  const [flashcardData, setFlashcardData] = useState([]);
  const [grammarData, setGrammarData] = useState([]);
  const [lastReset, setLastReset] = useState(null);
  const [maxTokens, setMaxTokens] = useState(null);
  const [currentTokens, setCurrentTokens] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [padding, setPadding] = useState("0px");

  const navigate = useNavigate(); 

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/api/latest_uuid_titles_summary/10/",
      "/api/latest_grammar_uuid_titles/10/",
      "/api/flashcards/latest_flashcard_title_uuid/10/",
      "/api/flashcards/get_all_flashcard_drafts/",
      "/api/user-tokens/",
      "/api/payment/subscriptions/"
    ]

    Promise.all(urls.map(url => api.get(url)))
      .then(responses => {
        console.log("exam data: ", responses[0].data);
        setExamData(responses[0].data);
        setGrammarData(responses[1].data);
        const originalFlashcardData = responses[2].data;
        const flashcardDrafts = responses[3].data.map(draft => ({
          ...draft,
          type: "draft" 
        }));
  
        const combinedFlashcardData = [...flashcardDrafts, ...originalFlashcardData];
        setFlashcardData(combinedFlashcardData);

        setLastReset(responses[4].data.last_reset);
        console.log("subscription plan: ", responses[5].data);
        setSubscriptionPlan(responses[5].data.subscription_tier);

        if (responses[5].data.subscription_tier === "max") {
          setMaxTokens(1);
          setCurrentTokens(1);
        } else {
          setMaxTokens(responses[4].data.max_tokens);
          setCurrentTokens(responses[4].data.tokens);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });

  }, []);

  
  useEffect(() => {
    const handleResize = () => {
        const width = window.innerWidth; 
        const dynamicPadding = Math.max(0, 0.13 * width - 15) + 'px';
        setPadding(dynamicPadding);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []); 







  return !loading && (
    <div className="min-h-screen bg-light dark:bg-dark">
      <div className="pt-2 pb-20">

        <div className="w-full flex justify-between items-center mt-8 mb-3.5" style={{padding: "0 13vw"}}>
          <h1 className="text-3xl font-semibold mb-0 flex items-center dark:text-dark">
            Recent Exams
          </h1>
          {examData.length !== 0 && (
            <motion.button
              className="group text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-white flex items-center px-5 py-1.5 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-600"
              onClick={() => navigate("/exam/input")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-2xl mr-2"
              />
              <span className="text-xl font-medium">New Exam</span>
            </motion.button>
          )}

        </div>
        
        <div style={{padding: `0 ${padding}`}}>
          {examData.length === 0 ? (
            <NoSetField type={"exam"} header={"Create your first exam"} description={"Enter your summary and get a personal exam based on the your summary"} buttonText={"Create free exam"} />
          ) : (
            <RecentQuizList questionType={"exam"} data={examData} setData={setExamData} />
          )}
        </div>




        <div className="w-full flex justify-between items-center mt-10 mb-3.5" style={{padding: "0 13vw"}}>
          <h1 className="text-3xl font-semibold mb-0 flex items-center dark:text-dark">
            Recent Flashcard Sets
          </h1>
          {flashcardData.length !== 0 && (
            <motion.button
              className="group text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-white flex items-center px-5 py-1.5 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-600"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate("/flashcard/input")}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-2xl mr-2"
              />
              <span className="text-xl font-medium">New Flashcard Set</span>
            </motion.button>
          )}

        </div>
        <div style={{padding: `0 ${padding}`}}>
          {flashcardData.length === 0 ? (
            <NoSetField type={"flashcard"} header={"Create your first flashcard set"} description={"Enter your terms and definitions and get a flashcard set"} buttonText={"Create free flashcard set"} />
          ) : (
            <RecentQuizList questionType={"flashcards"} data={flashcardData} setData={setFlashcardData} />
          )}
        </div>

        <div className="w-full flex justify-between items-center mt-10 mb-3.5" style={{padding: "0 13vw"}}>
          <h1 className="text-3xl font-semibold mb-0 flex items-center dark:text-dark">
            Recent Grammar Exercises
          </h1>
          {grammarData.length !== 0 && (
            <motion.button
              className="group text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-white flex items-center px-5 py-1.5 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-600"
              onClick={() => navigate("/grammar/input")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-2xl mr-2"
              />
              <span className="text-xl font-medium">New Exercises</span>
            </motion.button>
          )}
        </div>
        <div style={{padding: `0 ${padding}`}}>
          {grammarData.length === 0 ? (
            <NoSetField type={"grammar"} header={"Create your first grammar set"} description={"Enter your grammar questions and answers and get a grammar set"} buttonText={"Create free grammar set"} />
          ) : (
            <RecentQuizList questionType={"grammar"} data={grammarData} setData={setGrammarData} />
          )}
         
        </div>

        <div style={{padding: "0 13vw"}}>
          <h2 className="text-3xl font-semibold mb-6 mt-10 dark:text-dark">Token usage</h2>
          <div className=" flex-row justify-start bg-white dark:bg-gray-800 p-4 md:p-6 lg:p-8 w-full rounded-xl shadow-lg dark:shadow-none">

            <div className="mt-6 mb-12">
              <CircularProgress maxTokens={maxTokens} currentTokens={currentTokens} subscriptionTier={subscriptionPlan} />
            </div>
            {subscriptionPlan !== "max" && (
              <>
                <CountDownTimer lastReset={lastReset} />
                <div className="flex items-center justify-center w-full mt-8">
                  <button 
                    className="text-xl bg-gradient-to-t from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 font-semibold w-1/3 py-2.5 rounded-xl text-white"
                    onClick={() => navigate("/pricing")}
                  >
                    Try 7 Days for Free
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

      </div>
    </div>
  )
};
export default HomePageAuth;