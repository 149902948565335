import axios from "axios";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/General/LoadingSpinner";
import { isIOS } from "react-device-detect";

const ConfirmEmailRedirect = () => {
  const { key } = useParams(); 
  const navigate = useNavigate();

  useEffect(() => {
    axios.post("/api/auth/account-confirm-email/", { key: key })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

        if (isIOS) {
          redirectToApp();
        } else {
          navigate("/login");
        }
      });
  }, [key, navigate]); 

  const redirectToApp = () => {
    const appScheme = 'studybuddyapp://login/';
    const timeout = 1000;

    let timer = setTimeout(() => {
      navigate("/login");
    }, timeout);

    window.location = appScheme;

    setTimeout(() => {
      clearTimeout(timer);
    }, timeout);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <LoadingSpinner />
    </div>
  );
};

export default ConfirmEmailRedirect;
