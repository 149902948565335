import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import TitleInput from "../components/InputComponents/TitleInput";
import GrammarInputComponent from "../components/Grammar/GrammarInputComponent";
import api from "./../api";
import LimitModal from "../components/Modals/LimitModal";
import AILoadingAnimation from "../components/AILoadingAnimation";
import { motion } from "framer-motion";
import GenerateButton from "../components/Buttons/GenerateButton";

const GrammarInput = () => {
  const [language, setLanguage] = useState("");
  const [grammarTopic, setGrammarTopic] = useState("");
  const [exampleExercise, setExampleExercise] = useState("");
  const [numberOfExercises, setNumberOfExercises] = useState(1);
  const [title, setTitle] = useState("");
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [difficulty, setDifficulty] = useState("medium");
  const [submitted, setSubmitted] = useState(false);
  const [generating, setGenerating] = useState(false);

  const [titleError, setTitleError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [grammarTopicError, setGrammarTopicError] = useState(false);
  const [exampleExerciseError, setExampleExerciseError] = useState(false);
  const [numberOfExercisesError, setNumberOfExercisesError] = useState(false);


  const titleRef = useRef(null);
  const languageRef = useRef(null);
  const grammarTopicRef = useRef(null);
  const exampleExerciseRef = useRef(null);
  const numberOfExercisesRef = useRef(null);


  const navigate = useNavigate();



  const handleSubmit = () => {

    if (title === "") {
      titleRef.current.focus();
      setTitleError(true);
      return;
    }
    if (language === "") {
      languageRef.current.focus();
      setLanguageError(true);
      return;
    }
    if (grammarTopic === "") {
      grammarTopicRef.current.focus();
      setGrammarTopicError(true);
      return;
    }
    if (exampleExercise === "") {
      exampleExerciseRef.current.focus();
      setExampleExerciseError(true);
      return;
    }
    if (numberOfExercises === "") {
      numberOfExercisesRef.current.focus();
      setNumberOfExercisesError(true);
      return;
    }

    if (submitIsValid()) {
      setGenerating(true);
      const data = {
        language: language,
        topic: grammarTopic,
        example: exampleExercise,
        number_of_exercises: numberOfExercises,
        title: title,
        difficulty: difficulty,
      };
      api.post("/api/grammar_input/", data)
        .then((response) => {
          if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
            setGenerating(false);
            setShowLimitModal(true);
            return;
          } else {
            return navigate(`/grammar/questions/${response.data.uuid}`);
          }
        })
        .catch((error) => {
          setGenerating(false);
          console.error(error);
        });
    }
  };



  const submitIsValid = () => {
    return (
      title !== "" &&
      language !== "" &&
      grammarTopic !== "" &&
      exampleExercise !== "" &&
      numberOfExercises !== "" 
    );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    }
  };






  if (generating) {
    return (
      <div 
        className='fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-light dark:bg-dark' style={{ zIndex: 9999 }}>
        <AILoadingAnimation width={"450"} height={"450"} text={"Generating Grammar Exercises..."} />
      </div>
    );
  }


  return (
    <>
      <LimitModal limitReason={"tokens"} showModal={showLimitModal} setShowModal={setShowLimitModal} />
      <motion.div 
        className="bg-light dark:bg-dark"
        style={{ padding: "0 13vw", height: `${ window.innerHeight - 77 }px` }}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >

        <motion.div variants={itemVariants}>
          <TitleInput
            value={title}
            setValue={setTitle}
            titleRef={titleRef}
            error={titleError}
            setError={setTitleError}
          />
        </motion.div>

        <motion.div variants={itemVariants} className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
          <GrammarInputComponent
            title={"Language:"}
            value={language}
            setValue={setLanguage}
            placeholder={"Language"}
            inputRef={languageRef}
            error={languageError}
            setError={setLanguageError}
          />
          <GrammarInputComponent
            title={"Grammar topic:"}
            value={grammarTopic}
            setValue={setGrammarTopic}
            placeholder={"Grammar topic"}
            inputRef={grammarTopicRef}
            error={grammarTopicError}
            setError={setGrammarTopicError}
          />
        </motion.div>

        <motion.div variants={itemVariants} className="my-6 flex flex-col justify-center">
          <GrammarInputComponent
            title={"Example Exercise:"}
            value={exampleExercise}
            setValue={setExampleExercise}
            placeholder={"Example Exercise"}
            inputRef={exampleExerciseRef}
            error={exampleExerciseError}
            setError={setExampleExerciseError}
          />
        </motion.div>

        <motion.div variants={itemVariants} className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
          <div>
            <h2 className="mb-2 font-semibold text-2xl dark:text-dark">Number of exercises:</h2>
            <input
              type="number"
              className="w-full border-b-2 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500 rounded-lg p-2 font-medium text-lg outline-none shadow-lg dark:shadow-none dark:bg-gray-800 dark:text-dark"
              onChange={(event) => setNumberOfExercises(event.target.value)}
              value={numberOfExercises}
              max={25}
              min={1}
            />
            <p className="text-gray-600 mt-1.5 font-medium dark:text-gray-400">Max: 25</p>
          </div>
          <div>
            <h2 className="mb-2 font-semibold text-2xl dark:text-dark">Difficulty:</h2>
            <select
              className="w-full border-b-2 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500 rounded-lg p-2.5 font-medium text-lg outline-none shadow-lg dark:shadow-none dark:bg-gray-800 dark:text-dark focus:outline-none"
              onChange={(event) => setDifficulty(event.target.value)}
              value={difficulty}
            >
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </div>
        </motion.div>


        <motion.div variants={itemVariants} className="flex justify-center py-12">
          {/* <button
            className={`${!submitIsValid() === true ? "text-gray-500" : "text-white"} bg-blue-600 border-2 border-blue-600 py-2.5 px-10 rounded-full font-medium text-lg transition-colors duration-300 ease-in-out
                      hover:bg-blue-700 hover:border-blue-600
                      active:bg-blue-800 active:border-blue-700 active:shadow-inner
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                      shadow-color-lg shadow-blue-400
                      disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
            onClick={handleSubmit}
            disabled={!submitIsValid()}
          >
            Generate grammar exercises
          </button> */}
          <GenerateButton onClick={handleSubmit} label={"Generate grammar exercises"} />
        </motion.div>
      </motion.div>    
    </>

  );
};

export default GrammarInput;
