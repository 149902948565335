import api from "./../api";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Notify } from "../providers/NotificationProvider";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
import { motion, AnimatePresence } from "framer-motion";


const AllPage = () => {
  const [loading, setLoading] = useState(true);
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const [data, setData] = useState([]);
  const [deleteSetUuid, setDeleteSetUuid] = useState(null); 
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);


  const { type } = useParams();

  const navigate = useNavigate();

  const handleRepeatButtonClicked = (index) => {
    const url = type === "grammar" ? "/api/delete_grammar_fields/" : "/api/delete_summary_fields/";
    const redirectUrl = type === "grammar" ? "/grammar/questions/" : "/exam/questions/";
    api.put(`${url}${data[index].uuid}/`,{})
      .then(() => {
        console.log("Deleted fields");
        navigate(redirectUrl + data[index].uuid);
      })
      .catch((error) => {
        console.log("Error while deleting fields:", error);
      });
  };

  useEffect(() => {
    setSelectedBoxIndex(null);
    setLoading(true);
    const url = type === "grammar" ? "/api/grammar_title_uuid/" : "/api/summary_title_uuid/";
    api.get(url)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [type]);

  const handleBoxClick = (index) => {
    if (data[index].has_started === true) {
      navigate((type === "grammar" ? "/grammar/questions/" : "/exam/questions/") + data[index].uuid);
    }
    else {
      setSelectedBoxIndex(index === selectedBoxIndex ? null : index);
    }
  };

  const handleDeleteSet = () => {
    api.delete((type === "grammar" ? "/api/grammar/delete/" : "/api/summary/delete/") + deleteSetUuid + "/")
    .then((response) => {
      console.log(response.data);
      setSelectedBoxIndex(null);
      const updatedData = data.filter(item => item.uuid !== deleteSetUuid);
      setData(updatedData);
      Notify({message: "Set deleted successfully!", type: "success"});
    })
    .catch((error) => {
      console.error(error);
    });
    
    console.log((type === "grammar" ? "/api/grammar/delete/" : "/api/summary/delete/") + deleteSetUuid + "/");
  }




  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      }
    }
  };


  const boxVariants = {
    hidden: { y: 50, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        type: "easeOut",
        duration: 0.4
      }
    }
  };

  const deleteButtonVariants = {
    hidden: { x: 20, opacity: 0 },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  const progressBarVariants = {
    hidden: { width: 0 },
    show: width => ({
      width: `${width}%`,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    })
  };


  if (loading) {
    return null;
  }

  return (
    <div style={{ padding: "0 13vw", minHeight: `${ window.innerHeight - 77 }px`}} className="bg-light dark:bg-dark">
      <motion.div
        initial="hidden"
        animate="show"
        variants={containerVariants}
        className="pb-20"

      >
        <ConfirmDeletionModal 
          showModal={showConfirmDeletionModal} 
          setShowModal={setShowConfirmDeletionModal} 
          handleDelete={handleDeleteSet} 
          header={"Delete Set"} 
          text={"Are you sure you want to delete the set? This action cannot be undone."} buttonLabel={"Delete"} 
        />
        
        <motion.div 
          className="w-full flex justify-between items-center py-10"
          initial="hidden"
          animate="show"
          variants={{
            hidden: { y: -20, opacity: 0 },
            show: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: "easeOut",
                when: "beforeChildren"
              }
            }
          }}
        >
          <h1 className="text-3xl font-semibold mb-0 flex items-center dark:text-dark">
            {type === "grammar" ? "Grammar Exercises" : "Exams"}
          </h1>
          <motion.button
            className="text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-white flex items-center px-5 py-1.5 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-600"
            onClick={() => navigate(type === "grammar" ? "/grammar/input" : "/exam/input")}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FontAwesomeIcon icon={faPlus} className="text-2xl mr-2" />
            <span className="text-xl font-medium">Create</span>
          </motion.button>
        </motion.div>

        <div className="grid gird-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
          {data.map((set, index) => (
            <motion.div 
              key={index}
              variants={boxVariants}
            >
              <motion.div
                className={`bg-white dark:bg-gray-800 shadow-lg dark:shadow-none hover:border-blue-600 transition duration-300 hover:scale-105 ease-in-out rounded-xl p-6 flex flex-col items-start`}
                onClick={() => handleBoxClick(index)}
                layout
              >
                <motion.div className='w-full flex flex-row justify-between items-center mb-4' layout>
                  <h3 className="font-semibold text-xl items-center dark:text-dark">{set.title}</h3>
                  <AnimatePresence>
                    {selectedBoxIndex === index && (
                      <motion.button 
                        className="text-red-500 hover:text-red-600 text-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteSetUuid(set.uuid);
                          setShowConfirmDeletionModal(true);
                        }}
                        variants={deleteButtonVariants}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        whileHover={{ scale: 1.1 }}
                      >
                        <span className='mr-2 font-semibold'>Delete</span>
                        <FontAwesomeIcon icon={faTrash} />
                      </motion.button>
                    )}
                  </AnimatePresence>
                </motion.div>

                <div className="rounded-full bg-gray-200 dark:bg-gray-700 dark:text-dark px-3 py-1 text-sm font-medium">
                  Questions: {set.number_of_questions}
                </div>

                <label className="w-full font-semibold mb-2 mt-6 text-sm dark:text-dark">
                  {set.performance}% learned
                </label>
                <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded-full w-full overflow-hidden">
                  <motion.div
                    className="bg-green-400 dark:bg-green-500 h-5 rounded-full"
                    initial="hidden"
                    animate="show"
                    variants={progressBarVariants}
                    custom={set.performance}
                  />
                </div>

                <AnimatePresence>
                  {selectedBoxIndex === index && (
                    <motion.div 
                      layout
                      initial={{ opacity: 0, height: 0, marginTop: 0 }}
                      animate={{
                        opacity: 1,
                        height: "auto",
                        marginTop: "24px",
                      }}
                      exit={{ opacity: 0, height: 0, marginTop: 0 }}
                      transition={{
                        layout: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
                        duration: 0.3,
                        ease: [0.4, 0, 0.2, 1],
                      }}
                      className="flex justify-between gap-4 w-full"
                      // initial={{ opacity: 0, y: -20 }}
                      // animate={{ opacity: 1, y: 0 }}
                      // exit={{ opacity: 0, y: -20 }}
                      // transition={{ duration: 0.3 }}
                    >
                      <motion.button
                        className="flex-grow p-2.5 rounded-lg text-white bg-blue-600 hover:bg-blue-700 text-center font-medium w-1/2"
                        onClick={() => handleRepeatButtonClicked(index)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Repeat {type === "grammar" ? "exercises" : "exam"}
                      </motion.button>
                      <motion.button
                        className="flex-grow p-2.5 rounded-lg text-white bg-blue-600 hover:bg-blue-700 text-center font-medium w-1/2"
                        onClick={() => navigate((type === "grammar" ? "/grammar/recap/" : "/exam/recap/") + set.uuid)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Show recap
                      </motion.button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default AllPage;
