import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const EmailInputField = ({ email, setEmail, emailError, emailErrorMessage, placeholder }) => (
  <>
    <div className="relative">
      <FontAwesomeIcon
        icon={faEnvelope}
        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
      />
      {console.log("email Error: ", emailError)}
      <input
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="email"
        id="email"
        className={`bg-gray-50 ${
          emailError ? "border-2 border-red-500" : "border border-gray-300"
        } text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
        placeholder={placeholder ? placeholder : "Email"}

      />
    </div>

    {emailErrorMessage && (
      <span className="text-red-500 text-sm mt-1 block">
        {emailErrorMessage}
      </span>
    )}
  </>
);
export default EmailInputField;