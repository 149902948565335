import { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

const FlashcardInputGuest = () => {
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);

  const titleInputRef = useRef(null);

  // The user will need to first use the ai feature, so he will need to upload an image text or a document

  // Then the user is able to choose how many questions he wants to generate

  // OPTION: could also directly redirect the user to a flashcard preview like page
  // After that the user will get redirected to the edit page where he will be able to make edits to the set and it will save those edits automatically
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };
  
  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20 
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div 
      className="bg-light dark:bg-dark mx-auto px-4 max-w-[1200px]"
      initial="hidden"
      animate="show"
      variants={containerVariants}
    >
      
      <motion.div variants={itemVariants} className="flex flex-col justify-start w-full bg-white rounded-lg shadow-lg">
            <input
              autoFocus={true}
              id="title"
              ref={titleInputRef}
              type="text"
              className={`rounded-md text-xl font-medium border-b-2 ${
                titleError ? "shadow-color-lg dark:shadow-none shadow-red-400 border-red-500" : "focus:border-gray-400 dark:border-gray-700 dark:focus:border-gray-500 "
              } py-2.5 px-4 focus:outline-none outline-none dark:bg-gray-800 dark:text-dark`}
              placeholder="Enter a title"
              onChange={(event) => setTitle(event.target.value)}
              value={title}
            />
          </motion.div>
          {titleError && <p className="text-red-500 text-sm font-semibold mt-2">Title is required</p>}

    </motion.div>
  );
};
export default FlashcardInputGuest;