import { useEffect, useState } from "react";

const InputFieldComponent = ({
  value,
  setValue,
  maxWidth,
  placeholder,
  submitted,
  handleSubmit
}) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (submitted && value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  }, [submitted, value]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className={`mx-auto flex w-full max-w-[${maxWidth}] flex-col items-center`}>
        <div className="flex w-full flex-col items-start">
          <input
            id="title"
            autoFocus
            type="text"
            className={`m-1.5 p-2.5 w-full rounded-md border-b-2  ${error ? "border-red-500 shadow-lg dark:shadow-none shadow-red-400" : "border-gray-200 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500"} p-2.5 focus:outline-none shadow-lg dark:shadow-none dark:bg-gray-800 dark:text-white`}
            placeholder={placeholder}
            onChange={(event) => setValue(event.target.value)}
            value={value}
            onKeyDown={handleKeyDown}
          />
          {error && <p className="mt-2 text-red-500 text-sm">Field is required</p>}
        </div>
      </div>
    </div>
  );
};

export default InputFieldComponent;
