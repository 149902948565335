import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faTimes, faRepeat } from "@fortawesome/free-solid-svg-icons";
import { motion } from 'framer-motion';

const PointsBarComponent = ({ points, maxPoints, handleRepeatButtonClicked, handleExitButtonClicked }) => (
  <div className="max-w-[735px] mx-auto">
    <div className="flex items-center justify-between pt-6 mb-4">
      <motion.button className="w-6" onClick={handleExitButtonClicked} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-600 dark:hover:text-gray-500 dark:text-gray-300 text-3xl" />
      </motion.button>
      <span className="text-xl font-semibold text-zinc-700 dark:text-gray-300">
        Points: {points}/{maxPoints} - {Math.round((points / maxPoints) * 100)}%
      </span>
      <motion.button className="w-6" onClick={handleRepeatButtonClicked} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <FontAwesomeIcon icon={faRepeat} className="text-gray-500 hover:text-gray-600 dark:hover:text-gray-500 dark:text-gray-300 text-2xl" />
      </motion.button>
    </div>
    <div className={`flex-grow h-7 rounded-full overflow-hidden mb-10 ${points !== maxPoints && "bg-red-400 dark:bg-red-500"} `}>
      <div
        style={{ width: `${(points / maxPoints) * 100}%` }}
        className={`h-7 rounded-full ${points !== 0 && "bg-green-400 dark:bg-green-500"}`}
      />
    </div>
  </div>
);


export default PointsBarComponent;
