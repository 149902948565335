import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faStar, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import api from "./../api";
import { motion, AnimatePresence } from "framer-motion"; 
import FlashcardInputFieldMinimal from "../components/DefinitionQuestionComponents/FlashcardTextInputFieldMinimal";
import { v4 as uuidv4 } from "uuid";

const FlashcardEditField = ({
  flashcards,
  setFlashcards,
  flashcardSetUuid,
  flashcardLimit,
  setShowLimitModal
}) => {
  const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [addFieldActive, setAddFieldActive] = useState(false);
  const [editFlashcardIndex, setEditFlashcardIndex] = useState(null);
  const [editingFlashcard, setEditingFlashcard] = useState({});
  const [flashcardErrorIndex, setFlashcardErrorIndex] = useState(null);

  const [wordFocusIndex, setWordFocusIndex] = useState(null);
  const [definitionFocusIndex, setDefinitionFocusIndex] = useState(null);

  const wordInputRefs = useRef({});
  const definitionInputRefs = useRef({});





  useEffect(() => {
    if (wordFocusIndex !== null) {
      const ref = wordInputRefs.current[wordFocusIndex];
      if (ref && ref.focus) {
        ref.focus();
      }
      setWordFocusIndex(null);
    } else if (definitionFocusIndex !== null) {
      const ref = definitionInputRefs.current[definitionFocusIndex];
      if (ref && ref.focus) {
        ref.focus();
      }
      setDefinitionFocusIndex(null);
    }
  }, [wordFocusIndex, definitionFocusIndex]);



  useEffect(() => {
    if (flashcards.length === flashcardLimit) {
      setShowMaxFieldsMessage(true);
      setDisableAddButton(true);
    } else {
      setShowMaxFieldsMessage(false);
      setDisableAddButton(false);
    }
  }, [flashcardSetUuid]);



  const handleEditFlashcards = (index) => {
    if (editFlashcardIndex !== null) { 
      if (addFieldActive) {
        return null;
      } else {
        handleCancel();  
      }
    } else {
      setDisableAddButton(true);
      setEditFlashcardIndex(index);
      setEditingFlashcard(flashcards[index]);
      setWordFocusIndex(index);
    }
  }



  const handleSaveEditedFlashcards = () => {
    if (editingFlashcard.word.trim() === "" || editingFlashcard.definition.trim() === "") {
      setFlashcardErrorIndex(editFlashcardIndex);
      setDisableAddButton(true);
    } else {
      if (addFieldActive===false) {
        const newFlashcards = [...flashcards];
        newFlashcards[editFlashcardIndex] = editingFlashcard;
        setFlashcards(newFlashcards);

        const data = {
          flashcard: editingFlashcard,
          flashcard_set_uuid: flashcardSetUuid
        }

        api.put("/api/flashcards/update_flashcard/", data)
        .then((response) => {
          console.log("this is the response from the backend", response.data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
        setFlashcardErrorIndex(null);
        setEditFlashcardIndex(null);
        setEditingFlashcard({});
        setAddFieldActive(false)
        setDisableAddButton(false)
      }
      else {
        const data = {
          flashcard_set_uuid: flashcardSetUuid,
          flashcard: {
            word: editingFlashcard.word,
            definition: editingFlashcard.definition,
            position: flashcards.length,
            uuid: editingFlashcard.uuid
          }
        }
        console.log("data: ", data);


        api.post("/api/flashcards/add_flashcard/", data)
        .then((response) => {
          const newFlashcard = response.data;
          setFlashcards((prevFlashcards) => [...prevFlashcards.slice(0, prevFlashcards.length - 1),newFlashcard]);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

        setFlashcardErrorIndex(null);
        setEditFlashcardIndex(null);
        setEditingFlashcard({});
        setAddFieldActive(false)
        setDisableAddButton(false)
      }
    }
  };



  // const handleSaveEditedFlashcards = () => {
  //   if (editingFlashcard.word.trim() === "" || editingFlashcard.definition.trim() === "") {
  //     setFlashcardErrorIndex(editFlashcardIndex);
  //     setDisableAddButton(true);
  //   } else {
  //     if (!addFieldActive) {
  //       const newFlashcards = [...flashcards];
  //       newFlashcards[editFlashcardIndex] = editingFlashcard;
  //       setFlashcards(newFlashcards);

  //       const data = {
  //         flashcard: editingFlashcard,
  //         flashcard_set_uuid: flashcardSetUuid
  //       }

  //       api.put("/api/flashcards/update_flashcard/", data)
  //       .then((response) => {
  //         console.log("this is the response from the backend", response.data);
  //       })
  //       .catch((error) => {
  //         console.error("There was an error!", error);
  //       });
  //       setFlashcardErrorIndex(null);
  //       setEditFlashcardIndex(null);
  //       setEditingFlashcard({});
  //       setAddFieldActive(false)
  //       setDisableAddButton(false)
  //     } else {
  //       const data = {
  //         flashcard_set_uuid: flashcardSetUuid,
  //         flashcard: {
  //           word: editingFlashcard.word,
  //           definition: editingFlashcard.definition,
  //           position: flashcards.length,
  //         },
  //       };
  
  //       api.post("/api/flashcards/add_flashcard/", data)
  //         .then((response) => {
  //           const newFlashcard = response.data;
  //           setFlashcards((prevFlashcards) =>
  //             prevFlashcards.map((f) =>
  //               f.uuid === editingFlashcard.uuid ? newFlashcard : f
  //             )
  //           );
  //         })
  //         .catch((error) => {
  //           console.error("There was an error!", error);
  //         });
  
  //       setFlashcardErrorIndex(null);
  //       setEditFlashcardIndex(null);
  //       setEditingFlashcard({});
  //       setAddFieldActive(false);
  //       setDisableAddButton(false);
  //     }
  //   }
  // };
  


  const handleAddFlashcard = () => {
    console.log("add flashcard function got called: ")
    if (flashcards.length === flashcardLimit) {
      setShowLimitModal(true);
      setShowMaxFieldsMessage(true);
      setDisableAddButton(true);
      return;
    }
    console.log("flashcards: ", flashcards);
    const addedUuid = uuidv4();
    setFlashcards([...flashcards, { word: "", definition: "", uuid: addedUuid }]);
    setEditFlashcardIndex(flashcards.length);
    setAddFieldActive(true);
    setEditingFlashcard({uuid: addedUuid});
    setWordFocusIndex(flashcards.length);
  }


  // const handleAddFlashcard = () => {
  //   if (flashcards.length === flashcardLimit) {
  //     setShowLimitModal(true);
  //     setShowMaxFieldsMessage(true);
  //     setDisableAddButton(true);
  //     return;
  //   }
  
  //   const tempUuid = uuidv4();
  //   const newFlashcard = { uuid: tempUuid, temp: true, word: "", definition: "" };
  
  //   setFlashcards([...flashcards, newFlashcard]);
  //   setEditFlashcardIndex(flashcards.length);
  //   setAddFieldActive(true);
  //   setEditingFlashcard(newFlashcard);
  //   setWordFocusIndex(flashcards.length);
  // };
  


  // const handleCancel = () => {
  //   if (addFieldActive) {
  //     setFlashcards((prevFlashcards) =>
  //       prevFlashcards.filter((f) => f.uuid !== editingFlashcard.uuid)
  //     );
  //     setEditFlashcardIndex(null);
  //     setEditingFlashcard({});
  //     setFlashcardErrorIndex(null);
  //     setDisableAddButton(false);
  //     setAddFieldActive(false);
  //   } else {
  //     setEditFlashcardIndex(null);
  //     setEditingFlashcard({});
  //     setFlashcardErrorIndex(null);
  //     setDisableAddButton(false);
  //   }
  // };




  const handleCancel = () => {
    if (addFieldActive){
      setFlashcards((prevFlashcards) => prevFlashcards.slice(0, prevFlashcards.length - 1));
      setEditFlashcardIndex(null);
      setEditingFlashcard({});
      setFlashcardErrorIndex(null);
      setDisableAddButton(false);
      setAddFieldActive(false);
    }
    else {
      setEditFlashcardIndex(null);
      setEditingFlashcard({});
      setFlashcardErrorIndex(null);
      setDisableAddButton(false);
    }
  }




  const toggleStar = (index) => {
    console.log("this is the flashcarddata from the toggled star", flashcards[index]);
    setFlashcards((prevFlashcards) => {
      const newFlashcards = [...prevFlashcards];
      newFlashcards[index] = {
        ...newFlashcards[index],
        starred: !newFlashcards[index].starred,
      };
      return newFlashcards;
    }
    );
    api.put("/api/flashcards/update_starred/", {starred: !flashcards[index].starred, uuid: flashcards[index].uuid})
      .then((response) => {
        console.log("this is the response from the backend", response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  

  const handleDeleteFlashcard = (indexToRemove) => {
    console.log("this is the index that should get removed", indexToRemove)
    if (flashcards.length > 1) {
      api.delete("/api/flashcards/delete_flashcard/"+flashcards[indexToRemove].uuid+"/"+flashcardSetUuid+"/")
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        const newFlashcards = [...flashcards];
        newFlashcards.splice(indexToRemove, 1);
        setFlashcards(newFlashcards);
        setEditFlashcardIndex(null);
        setEditingFlashcard({});
        if (showMaxFieldsMessage) {
          setShowMaxFieldsMessage(false);
        }
        setDisableAddButton(false);
      }
      )
      .catch((error) => {
        console.error("There was an error!", error);
      }
      );
    }
  }


  

  const cardVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.9
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeOut",
        duration: 0.6
      }
    }
  };


  const buttonVariants = {
    hidden: {
      y: 100,
      opacity: 0,
      scale: 0.8
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        duration: 0.8,
        bounce: 0.35,
        delay: 0.2
      }
    }
  };

  const iconVariants = {
    initial: {
      opacity: 0,
      scale: 0.6,
      x: 20
    },
    animate: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        duration: 0.2,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      scale: 0.6,
      x: -20,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  };






  return (
    <div>
        <motion.div
          layout={"position"}
          className="flex flex-col gap-6 pb-24"
        >

          <AnimatePresence>
            {flashcards.map((flashcard, index) => (
              <motion.div
                key={flashcard.uuid}
                onClick={() => handleEditFlashcards(index)}
                className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 cursor-pointer"
                layout={"position"}
                initial="hidden"
                whileInView="visible"
                viewport={{ 
                  once: true,
                  margin: "0px 0px -75px 0px",
                  amount: 0.3
                }}
                variants={cardVariants}
              >
                <div className="flex flex-col">
                  <motion.div  className="flex flex-row justify-between items-center">
                    <label className="mb-2 font-medium text-lg text-neutral-800 dark:text-dark">
                      {index + 1}
                    </label>
                    {editFlashcardIndex !== index ? (
                      <motion.div 
                        className="flex gap-6"
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={iconVariants}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleStar(index);
                          }}
                          className="outline-none"
                        >
                          <FontAwesomeIcon
                            icon={flashcard.starred ? faStar : farStar}
                            className={
                              flashcard.starred
                                ? "text-yellow-400"
                                : "text-gray-500 dark:text-gray-400"
                            }
                            style={{ fontSize: "19px" }}
                          />
                        </button>

                        <FontAwesomeIcon
                          icon={faPen}
                          className="text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-blue-600"
                          style={{ fontSize: "18px" }}
                        />
                      </motion.div>
                    ) : (
                      !addFieldActive &&
                      flashcards.length > 1 && (
                        <motion.button
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          variants={iconVariants}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteFlashcard(index);
                          }}
                          className="outline-none"
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-red-500"
                            style={{ fontSize: "17px" }}
                          />
                        </motion.button>
                      )
                    )}
                  </motion.div>

                  {editFlashcardIndex !== index ? (
                    <div className="flex flex-col md:flex-row justify-between gap-6 mt-2">
                      <p className="text-lg w-full border-b-2 border-white dark:border-gray-600 p-2.5 dark:text-dark rounded-lg">{flashcard.word}</p>
                      <div className="h-0.5 visible md:hidden" />
                      <div className="h-0.5 visible md:hidden rounded-lg bg-gray-200" />
                      <div className="h-0.5 visible md:hidden" />
                      <p className="text-lg w-full border-b-2 border-white dark:border-gray-600 p-2.5 dark:text-dark rounded-lg">{flashcard.definition}</p>
                    </div>
                  ) : (
                    <div className="flex flex-col md:flex-row justify-between gap-6 mt-2">
                      <div
                        className="w-full md:w-1/2"
                      >
                        <FlashcardInputFieldMinimal
                          ref={(ref) =>
                            (wordInputRefs.current[index] = ref)
                          }
                          value={editingFlashcard.word}
                          error={
                            flashcardErrorIndex === index &&
                            editingFlashcard.word.trim() === ""
                          }
                          onChange={(e) =>
                            setEditingFlashcard({
                              ...editingFlashcard,
                              word: e.target.value,
                            })
                          }
                          placeholder="Enter a term"
                        />
                      </div>

                      <div className="h-1.5 visible md:hidden" />

                      <div
                        className="w-full md:w-1/2"
                      >
                        <FlashcardInputFieldMinimal
                          ref={(ref) =>
                            (definitionInputRefs.current[index] = ref)
                          }
                          value={editingFlashcard.definition}
                          error={
                            flashcardErrorIndex === index &&
                            editingFlashcard.definition.trim() === ""
                          }
                          onChange={(e) =>
                            setEditingFlashcard({
                              ...editingFlashcard,
                              definition: e.target.value,
                            })
                          }
                          placeholder="Enter a definition"
                        />
                      </div>
                    </div>
                  )}

                  <AnimatePresence>
                    {editFlashcardIndex === index && (
                      <motion.div
                        layout
                        className="flex gap-6 w-full"
                        initial={{ opacity: 0, height: 0, marginTop: 0 }}
                        animate={{
                          opacity: 1,
                          height: "auto",
                          marginTop: "24px",
                        }}
                        exit={{ opacity: 0, height: 0, marginTop: 0 }}
                        transition={{
                          layout: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
                          duration: 0.3,
                          ease: [0.4, 0, 0.2, 1],
                        }}
                      >

                        <motion.button
                          className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-700 dark:hover:border-gray-600 w-full hover:border-gray-400 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition dark:text-dark font-medium"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCancel();
                          }}
                        >
                          Cancel
                        </motion.button>
                        <motion.button
                          className="px-6 py-2 bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 hover:from-blue-700 hover:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 text-white rounded-lg transition font-medium w-full" 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSaveEditedFlashcards();
                          }}
                        >
                          {addFieldActive ? "Save new word" : "Save Changes"}
                        </motion.button>
                      </motion.div>

                    )}
                  </AnimatePresence>

                </div>
              </motion.div>
            ))}

          {showMaxFieldsMessage && (
            <motion.div
              key="max-fields-message"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
              className="text-center mt-6 flex justify-center"
            >
              <p className="text-lg font-semibold text-red-500 inline">
                You've reached the maximum field limit ({flashcardLimit}).
              </p>
              <a
                href="/pricing"
                className="text-lg font-medium text-blue-600 hover:text-blue-700 dark:dark:text-blue-500 dark:hover:text-blue-600 underline-offset-1 underline"
              >
                Upgrade
              </a>
            </motion.div>
          )}

          <motion.div
            key="add-button"
            layout
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-50px" }}
            variants={buttonVariants}
          >
            {/* <motion.button
              className={`${
                disableAddButton === true ? "text-gray-500" : "text-white"
              } mt-8 bg-blue-600 border-2 border-blue-600 py-2.5 px-8 w-full rounded-lg font-medium text-lg transition-colors duration-300 ease-in-out hover:bg-blue-700 hover:border-blue-600
              active:bg-blue-800 active:border-blue-700 active:shadow-inner
              focus:outline-none shadow-color-lg shadow-blue-400
              disabled:bg-gray-200 disabled:border-gray-200 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500 `}
              onClick={handleAddFlashcard}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={disableAddButton || addFieldActive}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-4" />
              Add Card
            </motion.button> */}

            <motion.button
              className={`mt-8 px-6 py-3 w-full rounded-lg text-xl font-semibold transition shadow-lg dark:shadow-none ${disableAddButton || addFieldActive ? "cursor-not-allowed dark:bg-gray-800 dark:text-gray-500 shadow-gray-300 bg-gray-200 text-gray-500" : "text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"}`}  
              onClick={handleAddFlashcard}
              whileHover={{ scale: disableAddButton || addFieldActive ? 1.05 : 1 }}
              whileTap={{ scale: disableAddButton || addFieldActive ? 0.95 : 1 }}
              disabled={disableAddButton || addFieldActive}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-4" />
              Add Card
            </motion.button>
          </motion.div>
          </AnimatePresence>

        </motion.div>


    </div>
  );

};

export default FlashcardEditField;