const BrandLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-name="Layer 1"
    viewBox="0 0 24 15.65"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={3.03}
        x2={20.41}
        y1={16.84}
        y2={-0.53}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#54aee2" />
        <stop offset={1} stopColor="#0073E9" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={-1.46}
        x2={15.91}
        y1={12.35}
        y2={-5.03}
      />
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={0.71}
        x2={18.09}
        y1={14.52}
        y2={-2.85}
      />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={7.04}
        x2={24.41}
        y1={20.85}
        y2={3.47}
      />
    </defs>
    <path
      d="M17.26 6.62S20.91 5.17 24 1.93c0 0-5.76 1.76-10.08 0 .07.04 2.94 1.85 8.34.73l-.38.45S15.02 4.62 10.57-.01c0 0-.54 3.13-4.57 5.83a.936.936 0 0 1-.52 1.08c.05.66.05 1.52.01 2.42 1.03.02 2.14-.04 3.23-.25 0 0 .45 1.91-1.15 3.69 0 0 4.98-.47 6.14-5.17 0 0 .09 1.19-.23 1.84 0 0 2.51 1.45 6.19.07 0 0-2.82-.75-3.1-3.83 0 0 .38.81.69.94Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <path
      d="M4.53 6.81s-.05-.04-.08-.06C3.22 7.39 1.75 7.98 0 8.46c0 0 1.72.57 4.06.79.27-1.15.42-2.07.47-2.44Z"
      style={{
        fill: "url(#b)",
      }}
    />
    <path
      d="M5.18 6.71a.65.65 0 0 0 .57-.65V6a.652.652 0 1 0-1.02.6c.04.02.07.05.11.06 0 0-.14 1.1-.49 2.61-.42 1.79-1.14 4.18-2.3 6.04l.43.19s1.17-2.35 1.32-3.31c0 0-.13 2.24-.48 3.22l1.41.09s.38-3.38.48-6.18c.04-1.01.04-1.94-.03-2.61Z"
      style={{
        fill: "url(#c)",
      }}
    />
    <path
      d="M13.77 14.5c-1.55 0-2.45-.96-2.45-.96.16.61 1.04 2.19 2.78 2.11 3.46-.35 2.61-3.59 2.61-3.59-.23 1.06-1.39 2.44-2.94 2.44Z"
      style={{
        fill: "url(#d)",
      }}
    />
  </svg>
)
export default BrandLogo;
