// BenefitsSection.jsx
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import StudentOnBeanbag from "../../Assets/student_studying_on_beanbag.webp";
import StudentHoldingTrophy from "../../Assets/student_holding_trophy.webp";
import Productivity from "../../Assets/images/productivity_3d_icon.webp";

const BenefitsSection = () => {
  // Custom hook to determine if the screen is small
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Function to check screen width
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1024); // Tailwind's 'lg' breakpoint is 1024px
    };

    // Initial check
    checkScreenSize();

    // Add event listener
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Variants for container animation (used on large screens)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  // Variants for individual card animation
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Additional variants for individual animations on small screens
  const cardVariantsSmallScreen = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Array of card data to map through
  const cards = [
    {
      img: Productivity,
      alt: "Fast time 3d icon",
      text: "Save Time, Achieve More",
      bgGradient: "from-green-400 to-green-500",
      shadowColor: "shadow-green-500",
    },
    {
      img: StudentHoldingTrophy,
      alt: "Student Holding Trophy",
      text: "Rise to the Top of Your Class",
      bgGradient: "from-yellow-400 to-yellow-500",
      shadowColor: "shadow-yellow-500",
    },
    {
      img: StudentOnBeanbag,
      alt: "Student on Beanbag",
      text: "Ace Exams Stress-Free",
      bgGradient: "from-blue-400 to-blue-500",
      shadowColor: "shadow-blue-500",
    },
  ];

  return (
    <div className="mt-16">
      {isSmallScreen ? (
        // Layout for small screens: Each card animates individually on scroll into view
        <div className="flex flex-col lg:flex-row justify-between items-center gap-4">
          {cards.map((card, index) => (
            <motion.div
              key={index}
              className={`mt-6 max-w-lg lg:max-w-none bg-gradient-to-r ${card.bgGradient} px-16 pt-4 pb-6 lg:px-12 lg:pb-8 lg:pt-2 rounded-2xl shadow-color-xl ${card.shadowColor}/50 text-white w-full lg:w-1/3 flex flex-col items-center justify-center`}
              variants={cardVariantsSmallScreen}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
            >
              <img
                src={card.img}
                alt={card.alt}
                className="h-52 lg:h-64 mb-6 lg:mb-10"
              />
              <h1 className="font-semibold text-2xl lg:text-4xl text-center">
                {card.text}
              </h1>
            </motion.div>
          ))}
        </div>
      ) : (
        <motion.div
          className="flex flex-col lg:flex-row justify-between items-center gap-6"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          {cards.map((card, index) => (
            <motion.div
              key={index}
              className={`mt-6 max-w-lg lg:max-w-none bg-gradient-to-r ${card.bgGradient} px-16 pt-4 pb-6 lg:px-12 lg:pb-8 lg:pt-2 rounded-2xl shadow-color-xl ${card.shadowColor}/50 text-white w-full lg:w-1/3 flex flex-col items-center justify-center`}
              variants={cardVariants}
            >
              <img
                src={card.img}
                alt={card.alt}
                className="h-52 lg:h-64 mb-6 lg:mb-10"
              />
              <h1 className="font-semibold text-2xl lg:text-4xl text-center">
                {card.text}
              </h1>
            </motion.div>
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default BenefitsSection;






// import { motion } from "framer-motion";
// import StudentOnBeanbag from "../../Assets/student_studying_on_beanbag.webp";
// import StudentHoldingTrophy from "../../Assets/student_holding_trophy.webp";
// import Productivity from "../../Assets/images/productivity_3d_icon.webp"

// const BenefitsSection = () => {
//   // Things to add:
//   // have 3 ppanels with 3 difrent light colors
//   // top should be a image of something that resembles the feature/benefit
//   // under that there should be a short text/title that describes the feature/benefit

//   // Benefits:
//   // 1. Save time
//   // 2. Get better grades
//   // 3. Reduce anxiety


//   // Icons:
//   // Save Time: Clock, https://www.freepik.com/premium-vector/round-clock-with-arrow-time-keeping-measurement-time-time-management-deadline-working-hours-concept-3d-vector-icon-cartoon-minimal-style_25891548.htm

//   // best save time 3d icons: https://iconscout.com/3d-illustrations/fast-clock


//   // Get Better Grades: A+ Grade, https://www.freepik.com/premium-psd/psd-grade-report-3d-icon_43185693.htm
//   // Could also use this one for the better grade: https://iconscout.com/3d-illustration/good-score-10871241


//   //FIXME: This is the final icon for the fast time: https://stock.adobe.com/de/images/3d-minimal-fast-service-icon-urgent-work-quick-time-delivery-icon-stopwatch-with-a-speed-line-3d-illustration/567842639?asset_id=567842639
  
//   //TODO: change the colors switch the fast time color to yellow and the get top class to green


//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3, // Delay between each card animation
//       },
//     },
//   };

//   const cardVariants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 1,
//       },
//     },
//   };

//   return (
//     <div className="mt-32">
//       <motion.div 
//         className="flex flex-row justify-between gap-4"
//         variants={containerVariants}
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true }}
//       >
//         <motion.div
//           className="bg-gradient-to-r from-green-400 to-green-500 px-12 pb-12 pt-2 rounded-2xl shadow-color-xl shadow-green-500/50 text-white w-1/3 flex flex-col items-center justify-center"
//           variants={cardVariants}
//         >
//           <img src={Productivity} alt="Fast time 3d icon" className="h-64 mb-10" />
//           <p className="font-semibold text-4xl text-center">Save Time, Achieve More</p>
//         </motion.div>

//         <motion.div
//           className="bg-gradient-to-r from-yellow-400 to-yellow-500 px-12 pb-12 pt-2 rounded-2xl shadow-color-xl shadow-yellow-500/50 text-white w-1/3 flex flex-col items-center justify-center"
//           variants={cardVariants}
//         >
//           <img src={StudentHoldingTrophy} alt="Student Holding Trophy" className="h-64 mb-10" />
//           <p className="font-semibold text-4xl text-center">Rise to the Top of Your Class</p>
//         </motion.div>

//         <motion.div
//           className="bg-gradient-to-r from-blue-400 to-blue-500 px-12 pb-12 pt-2 rounded-2xl shadow-color-xl shadow-blue-500/50 text-white w-1/3 flex flex-col items-center justify-center"
//           variants={cardVariants}
//         >
//           <img src={StudentOnBeanbag} alt="Student on Beanbag" className="h-64 mb-10" />
//           <p className="font-semibold text-4xl text-center">
//           Ace Exams Stress-Free</p>
//         </motion.div>
//       </motion.div>
//     </div>
//   );
// };

// export default BenefitsSection;