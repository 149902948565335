import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import api from "../api";
import BrandLogo from "../components/SVG/BrandLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import EmailInputField from "../components/Authentication/EmailInputField";
import PasswordInputField from "../components/Authentication/PasswordInputField";
import GoogleLoginButton from "../components/Authentication/GoogleLoginButton";
import AppleLoginButton from "../components/Authentication/AppleLoginButton";
import { Notify } from "../providers/NotificationProvider";
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet-async';

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [nonFieldError, setNonFieldError] = useState("");

  const navigate = useNavigate();

  const fetchSubscriptionData = () => {
    api.get("/api/payment/subscriptions/")
      .then((response) => {
        const subscriptionTier = response.data.subscription_tier;
        localStorage.setItem("subscriptionTier", subscriptionTier);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error);
      });
  };

  const handleButtonClicked = (event) => {
    event.preventDefault();
    const data = {
      email: email,
      password: password,
    };

    axios.post("/api/auth/login/", data)
      .then((response) => {
        console.log("This is the response from login:", JSON.stringify(response.data, null, 2));
        Cookies.set("accessToken", response.data.access, {
          expires: 2,
          secure: true,
          sameSite: "strict",
        });
        Cookies.set("refreshToken", response.data.refresh, {
          expires: 90,
          secure: true,
          sameSite: "strict",
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "sign_in_success",
          userEmail: response.data.user.email,
          userId: response.data.user.pk,
        });

        fetchSubscriptionData();
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.data) {
          if (error.response.data.non_field_errors) {
            Notify({ message: error.response.data.non_field_errors, type: "error" })
            setNonFieldError(error.response.data.non_field_errors);
            setEmailError(true);
            setPasswordError(true);
          }
          if (error.response.data.email) {
            Notify({ message: error.response.data.email, type: "error" })
            setEmailError(true);
            setEmailErrorMessage(error.response.data.email);
          }
          if (error.response.data.password) {
            Notify({ message: error.response.data.password, type: "error" })
            setPasswordError(true);
            setPasswordErrorMessage(error.response.data.password);
          }
        }
      });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  



  return (
    <>
      <Helmet>
        <title>Study Buddy AI | Login</title>
        <link rel="canonical" href="https://studybuddyai.app/login" />
      </Helmet>

      <motion.section 
        className="min-h-screen bg-light dark:bg-dark" 
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 relative">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="absolute left-6 top-6 dark:text-dark cursor-pointer"
            onClick={() => navigate("/")}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="h-6 w-6" />
          </motion.button>
          <motion.div variants={itemVariants} className="mb-8 flex flex-row items-center">
            <BrandLogo className="w-16" />
            <span className="text-4xl font-bold ml-3 tracking-wide dark:text-dark">Study Buddy</span>
          </motion.div>


          <motion.div variants={itemVariants} className="w-full bg-white rounded-xl shadow-lg sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div className="text-center">
                <h1 className="font-bold text-gray-900 dark:text-dark" style={{ fontSize: "28px", lineHeight: "35px" }}>
                  Welcome Back!
                </h1>
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                  Please sign in to continue to your account
                </p>
              </div>

              <form onSubmit={handleButtonClicked}>
                <EmailInputField 
                  email={email}
                  setEmail={setEmail}
                  emailError={emailError}
                  emailErrorMessage={emailErrorMessage}
                />
                <div className="mt-6">
                  <PasswordInputField 
                    password={password}
                    setPassword={setPassword}
                    passwordError={passwordError}
                    passwordErrorMessage={passwordErrorMessage}
                    placeholder="Password"
                  />
                </div>
                

                <div className="flex items-center justify-start mt-2.5">
                  <Link
                    to="/password/reset"
                    className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 hover:text-blue-700 dark:hover:text-blue-600"
                  >
                    Forgot password?
                  </Link>
                </div>

                {nonFieldError && (
                  <div className="text-red-500 text-sm font-bold mt-4">
                    {nonFieldError}
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full text-white transition duration-300 ease-in-out transform
                  bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  style={{ marginTop: 40 }}
                >
                  Sign in
                </button>

                <div className="flex items-center my-6">
                  <hr className="flex-grow border-gray-300 dark:border-gray-500" />
                  <span className="mx-2 text-gray-500 dark:text-gray-300">or</span>
                  <hr className="flex-grow border-gray-300 dark:border-gray-500" />
                </div>

                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0"> 
                  <AppleLoginButton />
                  <GoogleLoginButton />
                </div>

                <p className="text-center text-gray-500 dark:text-gray-300 mt-6">
                  Don't have an account yet?{" "}
                  <Link
                    to="/register"
                    className="font-medium text-blue-600 hover:underline hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-600"
                  >
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
          </motion.div>
        </div>
      </motion.section>
    
    </>
  );
};

export default LoginPage;
