import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import api from "./../api";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import LoadingSpinner from "../components/General/LoadingSpinner";
import { motion, AnimatePresence } from "framer-motion";


const GrammarRecap = () => {
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [correctIncorrectList, setCorrectIncorrectList] = useState([]);
  const [corrections, setCorrections] = useState([]);
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);
  const [pointsList, setPointsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { uuid } = useParams();




  useEffect(() => {
    setLoading(true);
    api.get("/api/grammar_questions/" + uuid + "/")
      .then((response) => {
        setQuestionList(response.data.questions);
        setAnswerList(response.data.user_answers);
        setCorrections(response.data.corrections);
        setPointsList(response.data.points_list);
        setCorrectIncorrectList(response.data.correct_incorrect);
        setMaxPoints(response.data.questions.length);
        setPoints(response.data.points_list.reduce((a, b) => a + b, 0));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);




  // const { data, error } = useAxiosGet("/api/grammar_questions/" + uuid + "/");
  // useEffect(() => {
  //   if (data) {
  //     console.log(data);
  //     setQuestionList(data.questions);
  //     setAnswerList(data.user_answers);
  //     setCorrections(data.corrections);
  //     setPointsList(data.points_list);
  //     setCorrectIncorrectList(data.correct_incorrect);
  //     setMaxPoints(data.questions.length);
  //     setPoints(data.points_list.reduce((a, b) => a + b, 0));
  //     setLoading(false);
  //   }
  //   if (error) {
  //     console.error(error);
  //   }
  // }, [data, error]);



  const handleRestartButtonClicked = () => {
    api.put(`/api/delete_grammar_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      navigate("/grammar/questions/"+ uuid);
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
    });
  };

  const progressBarVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6
      }
    }
  };

  const cardVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.9
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeOut",
        duration: 0.6
      }
    }
  };





  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="px-6 min-h-screen bg-light dark:bg-dark">

      <motion.div 
        initial="hidden"
        animate="visible"
        variants={progressBarVariants}
      >
        <PointsBarComponent points={points} maxPoints={maxPoints} handleExitButtonClicked={() => navigate("/")} handleRepeatButtonClicked={handleRestartButtonClicked} />
      </motion.div>

      <AnimatePresence>
        {questionList.map((question, index) => (
          <motion.div 
            className="flex justify-center items-center" 
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
            variants={cardVariants}
          >
            <div className={`max-w-[735px] w-full bg-white dark:bg-gray-800 rounded-2xl mb-8 p-8 dark:shadow-none shadow-color-lg ${correctIncorrectList[index] ? "shadow-green-300" : "shadow-red-300"}`}>
              <p className="text-xl mb-3 dark:text-dark">{question}</p>
              <p className="text-lg text-neutral-700 mb-6 font-medium dark:text-gray-400">Points: {pointsList[index]}</p>
              {correctIncorrectList[index] ? (
                <AnswerInputFieldCorrectCorrection
                  userAnswer={answerList[index]}
                  showHeader={true}
                  isRecapPage={true}
                />
              ) : (
                <AnswerInputFieldIncorrectCorrection
                  userAnswer={answerList[index]}
                  correction={corrections[index]}
                  correctionHeader={"Feedback"}
                  isRecapPage={true}
                />
              )}

            </div>
          </motion.div>
        ))}
      </AnimatePresence>


      <motion.div 
        className="flex justify-center mt-12 pb-24"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <button
          className="max-w-[735px] w-full px-6 py-3 rounded-lg text-xl font-medium transition shadow-lg dark:shadow-none text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"
          onClick={handleRestartButtonClicked}
          >
            Repeat Exercises
        </button>
      </motion.div>
    </div>
  );
};
export default GrammarRecap;

