import { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';


const questionData = [
  {
    question: 'What is the sugar found in DNA?',
    answer: 'Deoxyribose',
    feedback: '',
  },
  {
    question: 'How many strands does DNA have?',
    answer: '3',
    feedback: 'Incorrect. The correct answer is 2.',
  },
  {
    question: 'What is the shape of DNA called?',
    answer: 'Double helix',
    feedback: '',
  },
  {
    question: 'Which base pairs with cytosine in DNA?',
    answer: 'Guanine',
    feedback: '',
  },
  {
    question: 'What is the process of copying DNA called?',
    answer: 'Translation',
    feedback: 'Incorrect. The correct answer is replication.',
  },
];



const FlashcardWriteFeaturePreview = () => {
  const containerRef = useRef(null);
  const prevIsInViewRef = useRef(false);
  const [isInView, setIsInView] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);

  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [answerBoxColor, setAnswerBoxColor] = useState('gray');
  const [animatedAnswer, setAnimatedAnswer] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [animatedFeedback, setAnimatedFeedback] = useState('');
  const [hasStartedAnimations, setHasStartedAnimations] = useState(false);
  


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
        if (entry.isIntersecting && !hasStartedAnimations) {
          setHasStartedAnimations(true);
        }
      },
      { threshold: 0.8 }
    );
  
    if (containerRef.current) observer.observe(containerRef.current);
  
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [hasStartedAnimations]);

  // Helper function to wait for a specified duration
  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Helper function to animate typing
  const animateTyping = (fullText, setTextFn, interval = 100) => {
    return new Promise((resolve) => {
      let index = 0;
      let current = '';
      const typingInterval = setInterval(() => {
        current += fullText.charAt(index);
        setTextFn(current);
        index++;
        if (index >= fullText.length) {
          clearInterval(typingInterval);
          resolve();
        }
      }, interval);
    });
  };

  const resetForNewQuestion = useCallback(() => {
    setShowPlaceholder(true);
    setAnswerBoxColor('gray');
    setAnimatedAnswer('');
    setShowFeedback(false);
    setAnimatedFeedback('');
  }, []);

  const runAnimationForQuestion = useCallback(async () => {
    if (!isInView || !hasStartedAnimations) return;

    const currentQuestion = questionData[questionIndex];
    const questionAnimationTime = currentQuestion.question.length * 30;
    
    await wait(questionAnimationTime);

    // Show placeholder
    setShowPlaceholder(true);
    await wait(1500);

    // Hide placeholder and show active state
    setShowPlaceholder(false);
    setAnswerBoxColor('active');
    await wait(100);

    // Animate answer typing
    await animateTyping(currentQuestion.answer, setAnimatedAnswer, 100);

    // After typing answer, wait a second before checking feedback
    await wait(1000);

    const feedbackText = currentQuestion.feedback;
    if (feedbackText) {
      // Incorrect answer scenario
      setAnswerBoxColor('red');
      setShowFeedback(true);
      await animateTyping(feedbackText, setAnimatedFeedback, 50);
    } else {
      setAnswerBoxColor('green');
    }



  }, [questionIndex, isInView, hasStartedAnimations]);






  // Orchestrate the animation sequence
  useEffect(() => {
    let isCancelled = false;

    const runSequence = async () => {
      if (!isInView || !hasStartedAnimations || isCancelled) return;


      resetForNewQuestion();

      await runAnimationForQuestion();
      if (isCancelled) return;

      await wait(1000);
      if (isCancelled) return;

      if (isInView && hasStartedAnimations && !isCancelled) {
        setQuestionIndex((prev) => (prev + 1) % questionData.length);
      }
    };

    runSequence();

    return () => {
      isCancelled = true;
    };

  }, [questionIndex, isInView, hasStartedAnimations, runAnimationForQuestion, resetForNewQuestion]);


  useEffect(() => {
    if (isInView && !prevIsInViewRef.current) {
      setHasStartedAnimations(true);
    } else if (!isInView && prevIsInViewRef.current) {
      setHasStartedAnimations(false);
      resetForNewQuestion();
    }

    prevIsInViewRef.current = isInView;
  }, [isInView, resetForNewQuestion]);


  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };


  const feedbackVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
      },
    },
  };

  const fadeVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.5, 
        ease: 'easeOut',
        delay: 0.2
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.5, ease: 'easeIn' },
    },
  };
  

  const colorClasses = {
    gray: {
      border: 'border-gray-200',
      shadow: 'shadow-lg',
    },
    active: {
      border: 'border-gray-400',
      shadow: 'shadow-lg',
    },
    green: {
      border: 'border-green-500',
      shadow: 'shadow-color-lg shadow-green-300/80 bg-green-50',
    },
    red: {
      border: 'border-red-500',
      shadow: 'shadow-color-lg shadow-red-300/80 bg-red-50',
    },
  };


  return (
    <div ref={containerRef} className="flex flex-col items-center justify-start pb-16">
      <AnimatePresence mode="wait">
        <motion.div
          key={questionIndex}
          variants={fadeVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="flex flex-col items-center justify-center w-full"
        >
          <motion.p
            className="text-xl lg:text-2xl font-medium"
            variants={containerVariants}
            initial="hidden"
            animate={hasStartedAnimations ? "visible" : "hidden"}
          >
            {questionData[questionIndex].question.split('').map((char, index) => (
              <motion.span key={index} variants={childVariants}>
                {char}
              </motion.span>
            ))}
          </motion.p>


          <div className="mx-auto flex flex-col items-center justify-center mt-6">
            <motion.div
              layout
              layoutId="answer-box"
              transition={{ 
                duration: 0.5,
                ease: "easeInOut"
              }}
              variants={fadeVariants}
              animate={hasStartedAnimations ? "visible" : "hidden"}
              className={`min-w-[300px] max-w-[375px] min-h-[46px] lg:min-h-[50px] w-full rounded-md border-b-2 transition-colors duration-300 ${colorClasses[answerBoxColor].border} p-2.5 focus:outline-none ${colorClasses[answerBoxColor].shadow}`}
            >
              {showPlaceholder && (
                <span className="text-sm text-gray-400">Enter your answer...</span>
              )}
              {!showPlaceholder && (
                <motion.p
                  className="text-base lg:text-lg text-black"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                >
                  {animatedAnswer.split('').map((char, index) => (
                    <motion.span key={index} variants={childVariants}>
                      {char}
                    </motion.span>
                  ))}
                </motion.p>
              )}
            </motion.div>
            
            <div className='h-[46px] lg:h-[50px] mt-4'>
              {showFeedback && (
                <motion.div
                  layout
                  variants={feedbackVariants}
                  transition={{
                    layout: { duration: 0.5, ease: "easeInOut" },
                    opacity: { duration: 0.3, ease: "easeInOut" }
                  }}
                  initial="hidden"
                  animate="visible"
                  className="mt-4 min-w-[300px] max-w-[375px] w-full p-2.5 rounded-md border-b-2 transition-colors duration-300 border-green-500 shadow-color-lg bg-green-50 shadow-green-300/80"
                  style={{ minHeight: '50px' }}
                >
                  <motion.p
                    className="text-base lg:text-lg text-black"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                  >
                    {animatedFeedback.split('').map((char, index) => (
                      <motion.span key={index} variants={childVariants}>
                        {char}
                      </motion.span>
                    ))}
                  </motion.p>
                </motion.div>
              )}

            </div>

          </div>

        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default FlashcardWriteFeaturePreview;
