import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const FlashcardInputField = forwardRef(({
  value,
  error,
  onChange,
  placeholder,
}, ref) => {
  const textareaRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    },
  }));

  const handleScroll = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      if (textarea.scrollHeight > textarea.clientHeight) {
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  };

  useEffect(() => {
    handleScroll();
  }, [value]);

  const onChangeHandler = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className='w-full'>
      <TextareaAutosize
        ref={textareaRef}
        minRows={1}
        maxRows={5}
        placeholder={placeholder}
        onClick={(e) => e.stopPropagation()}
        className={`p-2.5 border ${error ? 'border-red-500' : 'border-gray-200 dark:border-gray-700 dark:focus:border-gray-500 focus:border-gray-400'} rounded-lg w-full bg-gray-50 dark:bg-gray-700 outline-none resize-none dark:text-white`}
        value={value}
        onChange={onChangeHandler}
        style={{
          lineHeight: '24px',
          overflowY: 'hidden',
        }}
        onHeightChange={() => {
          const textarea = textareaRef.current;
          if (textarea) {
            if (textarea.scrollHeight > textarea.clientHeight) {
              textarea.style.overflowY = 'scroll';
              textarea.scrollTop = textarea.scrollHeight;
            } else {
              textarea.style.overflowY = 'hidden';
            }
          }
        }}
      />
      {error && (
        <p className="text-red-500 text-sm font-semibold mt-2">
          Field is required
        </p>
      )}
    </div>
  );
});

export default FlashcardInputField;
