import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faImage, faTrash } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import Modal from './Modal';
import CloudUploadIcon from '../SVG/CloudUploadIcon';
import AILoadingAnimation from '../AILoadingAnimation';
import { motion } from 'framer-motion';
import ModalGenerateButton from '../Buttons/ModalGenerateButton';

const UploadImageModal = ({ showModal, setShowModal, processData, loading, setLoading, setLimitReason, setShowLimitModal, saveFlashcards, uuid, fetchData }) => {
  const [dragOver, setDragOver] = useState(false);
  const [fileUploadErrors, setFileUploadErrors] = useState("");
  const [files, setFiles] = useState([]);


  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      processFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const processFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    if (files.length + newFiles.length > 3) {
      setFileUploadErrors("You can upload a maximum of 3 images.");
      return;
    }
    setFileUploadErrors("");
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };
  

  const uploadFiles = () => {
    if (files.length === 0) {
      setFileUploadErrors("You need to upload at least one image.")
      return;
    }
    setLoading(true);
    const formData = new FormData();
    files.forEach(file => {
      formData.append('image', file);
    });
    if (saveFlashcards) {
      api.post(`/api/flashcards/generate_flashcards_and_save/${uuid}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        const enoughImages = response.data.enough_images;
        const limitReached = Boolean(response.data.limit_reached);

        if (!enoughImages && enoughImages !== undefined) {
          setLimitReason("tokens");
          setShowModal(false);
          setShowLimitModal(true);
          setLoading(false);
          return;
        } else if (limitReached) {
          setLimitReason("flashcards");
          setShowLimitModal(true);
          setLoading(false);
          fetchData();
          setShowModal(false);
          setLoading(false);
        } else {
          fetchData();
          setShowModal(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    } else {
      api.post("/api/flashcards/generate_flashcards/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        console.log("Response: ", response.data);
        if (response.data.enough_images === false && response.data.enough_images !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setShowModal(false)
          setLoading(false);
          return;
        } else {
          setFileUploadErrors("");
          processData(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }

  };

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
    return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const onDelete = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };





  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
      <div className="rounded-lg shadow-lg relative w-[768px] bg-white dark:bg-dark">
        {loading ? (
          <div className='h-[494.5px] flex flex-col items-center justify-center dark:bg-dark'>
              <AILoadingAnimation width={"250"} height={"250"} text={"Generating Flashcards..."} />
          </div>
        ) : (
          <div>
            <div className='px-8 pt-8'>
              <div className='flex flex-row justify-between items-center'>
                <h2 className="text-2xl font-bold dark:text-dark">Upload Images</h2>
                <motion.button
                  onClick={() => setShowModal(false)}
                  className="hover:text-gray-400 text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
                  style={{ fontSize: "28px"}}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </motion.button>
              </div>
              <h3 className='pt-2 text-lg text-gray-600 dark:text-gray-400 mb-6'>Please ensure that your images are not bigger than 10MB.</h3>


              <div className={`h-[250px] border-2 rounded-lg ${fileUploadErrors !== "" ? "border-red-500" : "border-blue-600"} ${dragOver ? ' bg-blue-200 dark:bg-gray-700' : 'border-dashed dark:gray-800'} relative`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                <input type="file" id="file-upload" className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
                  onChange={(e) => processFiles(e.target.files)}
                  accept=".png,.jpeg,.jpg"
                  onClick={(event) => { event.target.value = null; }}
                  multiple={true}
                />
                <label htmlFor="file-upload" className="text-center flex flex-col items-center justify-center h-full absolute inset-0">

                  <div className="flex flex-col items-center justify-center">
                    <CloudUploadIcon className="w-14 h-14 transition-colors duration-300 text-blue-600 dark:text-blue-500" />
                    <p className="text-lg mt-4 dark:text-dark">Drag and drop your images here.</p>
                    <div className="flex items-center w-full mt-4">
                      <hr className="flex-grow border border-gray-300 dark:border-gray-500" />
                      <span className="mx-2 text-gray-500 dark:text-gray-300">or</span>
                      <hr className="flex-grow border border-gray-300 dark:border-gray-500" />
                    </div>
                    <div className='bg-white dark:bg-gray-700 rounded-lg border-2 py-1 px-6 mt-6 border-blue-600 dark:border-gray-600 text-blue-600 dark:text-white text-lg font-medium'>
                      Browse images
                    </div>
                  </div>

                </label>
              </div>
              <p className='text-gray-500 dark:text-gray-400 mt-2.5 text-lg'>
                Support only .png, .jpeg, .jpg files. Maximum of 3 images.
              </p>
              <p className='text-lg text-red-500 mt-2'>{fileUploadErrors}</p>
              
              {files.length > 0 && (
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold mt-6 dark:text-dark">Uploaded Images (Limit: 3)</h2>
                  {files.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center border-2 border-gray-200 dark:border-gray-700 bg-gray-800 rounded-xl px-6 py-4 mb-6"
                    >
                      <FontAwesomeIcon
                        icon={faImage}
                        className="text-blue-600 dark:text-blue-500 text-4xl flex-shrink-0"
                      />
                      <div className="ml-6 flex-1">
                        <p className="font-bold text-gray-800 dark:text-gray-200">{file.name}</p>
                        <p className="text-gray-600 dark:text-gray-400 text-sm">
                          {formatFileSize(file.size)}
                        </p>
                      </div>
                      <button
                        onClick={() => onDelete(index)}
                        className="text-red-500 hover:text-red-700 transition-colors"
                      >
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>


            <div className="flex justify-between mt-12 px-8 pb-8">
              <button 
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-700 dark:hover:border-gray-600 w-full max-w-60 hover:border-gray-400 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition dark:text-dark font-medium"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <ModalGenerateButton onClick={uploadFiles} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UploadImageModal;




















//OLD

// import { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes, faImage } from '@fortawesome/free-solid-svg-icons';
// import Lottie from 'react-lottie';
// import animationData from "../../Assets/Lottie/generate-from-file.json";
// import api from '../../api';

// const UploadImpageModal = ({ showModal, setShowModal, processData, loading, setLoading }) => {
//   const [dragOver, setDragOver] = useState(false);
//   const [fileUploadErrors, setFileUploadErrors] = useState("");
//   const [fileSubmitted, setFileSubmitted] = useState(false);
//   const [fileName, setFileName] = useState("");
//   const [file, setFile] = useState(null);
//   const [userCharacterLimit, setUserCharacterLimit] = useState(10000);
//   const [numberOfFlashcards, setNumberOfFlashcards] = useState(1);


//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: 'xMidYMid slice'
//     }
//   };


//   const numberOfFlashcardOptions = [];
//   for (let i = 1; i <= 5; i++) {
//     numberOfFlashcardOptions.push(
//       <option key={i} value={i}>
//         {i}
//       </option>
//     );
//   }

//   for (let i = 10; i <= 50; i += 5) {
//     numberOfFlashcardOptions.push(
//       <option key={i} value={i}>
//         {i}
//       </option>
//     );
//   }


//   useEffect(() => {
//     api.get('api/subscription/' )
//     .then((response) => {
//       console.log(response.data);
//       const plan = response.data.plan;
//       if (plan === "basic") {
//         setUserCharacterLimit(10_000);
//       }
//       if (plan === "max") {
//         setUserCharacterLimit(50_000);
//       }
//     })
//     .catch((error) => {
//       console.error(error);
//     })
//   }, []);




//   const handleDragEnter = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragOver(true);
//   };

//   const handleDragLeave = (e) => {
//       e.preventDefault();
//       e.stopPropagation();
//       setDragOver(false);
//   };

//   const handleDragOver = (e) => {
//       e.preventDefault();
//       e.stopPropagation();
//       setDragOver(true);
//   };

//   const handleDrop = (e) => {
//       e.preventDefault();
//       e.stopPropagation();
//       setDragOver(false);
//       if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
//         processFile(e.dataTransfer.files);
//         e.dataTransfer.clearData();
//       }
//   };

//   const processFile = (file) => {
//     setFileUploadErrors("");
//     setFileSubmitted(true);
//     setFileName(file[0].name);
//     console.log("Image: ", file[0]);
//     setFile(file[0]);

//     //TODO: need to change here because this time it is more than one file
//   };
  

//   const uploadFile = () => {
//     const formData = new FormData();
//     console.log("this is the image: ", file);

//     formData.append('image', file); 
//     formData.append('number_of_flashcards', numberOfFlashcards);

//     api.post("/api/flashcards/generate_flashcards/", formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then((response) => {
//       console.log(response.data);
//     })
//     .catch((error) => {
//       console.error(error);
//     })
//   };




  
//   return showModal && (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//         <div className="bg-gray-100 rounded-lg shadow-lg w-full relative max-w-5xl">
//           {loading ? (
//             <div className='h-[698px] flex flex-col items-center justify-center'>
//               <Lottie 
//                 options={defaultOptions}
//                 height={500}
//                 width={500}
//               />
//               <h1 className='text-xl font-semibold text-purple-500'>Generating flashcards...</h1>
//             </div>
//           ) : (
//             <div>
//               <div className='px-8 pt-8'>
//                 <h2 className="text-2xl font-bold">Upload Images</h2>
//                 <h3 className='mt-2 text-lg'>Please ensure that your images are not bigger than 10MB. //TODO: write this better//</h3>
//                 <h3 className='text-lg mb-6'>Only PNG and JPEG files are allowed.//TODO: it can also just be images from vocabulary books only they will work</h3>
//                 <div className={`h-[300px] border-4 border-dashed rounded-lg ${dragOver ? 'border-blue-600 bg-blue-200' : 'border-blue-400 bg-blue-100'} relative`}
//                     onDragEnter={handleDragEnter}
//                     onDragOver={handleDragOver}
//                     onDragLeave={handleDragLeave}
//                     onDrop={handleDrop}>
//                     {!fileSubmitted && (
//                       <input type="file" id="file-upload" className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
//                         onChange={(e) => processFile(e.target.files)}
//                         accept=".png,.jpeg"
//                         onClick={(event) => { event.target.value = null; }}
//                         multiple={false}
//                       />
//                     )}


//                     <label htmlFor="file-upload" className={`text-center flex flex-col items-center justify-center h-full `}>
//                       {fileSubmitted ? (
//                         <div>
//                           <FontAwesomeIcon icon={faImage} className='text-blue-500 text-8xl' />
//                           <p className='mt-4 mb-8 text-lg text-blue-600 font-medium'>{fileName}</p>
//                           <button className=' bg-blue-200 text-blue-600 rounded-full px-3 py-1.5 font-semibold hover:bg-blue-300 hover:text-blue-700' onClick={() => setFileSubmitted(false)}>
//                             Change Image
//                           </button>
//                         </div>
//                       ) : (
//                         <div>
//                           <p className="text-blue-600 font-medium">Drag and drop your images here or click to select files.</p>
//                           <p className="text-blue-600 font-medium">Only PNG and JPEG files are allowed.</p>
//                         </div>
//                       )}
//                     </label>
//                 </div>
//                 <p className='text-lg font-medium text-red-500 mt-3'>{fileUploadErrors}</p>
//                 <div className='mt-6'>
//                   <h2 className='text-xl font-bold'>Number of Flashcards</h2>
//                   <select 
//                     className='border-2 border-gray-300 rounded px-3 py-2.5 mt-3 w-[205px] font-medium'
//                     value={numberOfFlashcards}
//                     onChange={(e) => setNumberOfFlashcards(e.target.value)}
//                   >
//                     {numberOfFlashcardOptions}
                  
//                   </select>
//                 </div>
//               </div>
//               <div className='border-b-2 border-blue-400 mt-8' />

//               <div className="flex justify-between mt-4 px-8 pb-8">
//                 <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={() => setShowModal(false)}>Cancel</button>
//                 <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={uploadFile}>Upload</button>
//               </div>
//               <button
//                 onClick={() => setShowModal(false)}
//                 className="absolute top-5 right-5 text-2xl hover:text-gray-500 text-gray-800">
//                 <FontAwesomeIcon icon={faTimes} />
//               </button>
//             </div>
//           )}

//         </div>
//     </div>
// );
// };
// export default UploadImpageModal;