import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from 'framer-motion';

const ProgressBarComponent = ({ index, numberOfQuestions }) => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  sessionStorage.setItem("loadNavbar", "false");

  useEffect(() => {
    index === 0 && setProgress(0);
    index > 0 && setProgress((index / numberOfQuestions) * 100);
  }, [index, numberOfQuestions]);


  const handleModalButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    sessionStorage.setItem("loadNavbar", null);
    return navigate("/");
  };

  return (
    <div className="py-6 w-full">
      <div className="flex justify-between items-center">
        <div className="w-1/4 h-7 flex items-center justify-center pl-20">
          <motion.button onClick={handleModalButtonClick} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <FontAwesomeIcon icon={faTimes} className="text-zinc-500 hover:text-zinc-600 dark:hover:text-gray-500 dark:text-gray-300" style={{fontSize: "30px"}}/>
          </motion.button>
        </div>
        <div className="w-2/4 h-7">
          <div className="w-full bg-gray-200 rounded-full h-5 md:h-7 mb-6 dark:bg-gray-800 overflow-hidden">
            <div
              className="bg-green-400 h-5 md:h-7 dark:bg-green-500 rounded-full"
              style={{
                width: `${progress}%`,
                transition: "width 0.6s ease-out"
              }} 
            />
          </div>
        </div>
        <div className="w-1/4 h-7 flex items-center justify-center pr-20">
          <div className="text-xl text-zinc-600 dark:text-gray-300 font-medium">{index}/{numberOfQuestions}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBarComponent;
