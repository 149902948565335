import { useState, useEffect, useRef, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";

const multipleChoiceData = [
  {
    question: 'Where is DNA found in a eukaryotic cell?',
    options: ['Nucleus', 'Cytoplasm', 'Ribosome'],
    answerIndex: 0,
    correctIndex: 0,
  },
  {
    question: 'What is the role of an enzyme?',
    options: ['Break down waste', 'Speed up reactions', 'Store energy'],
    answerIndex: 1,
    correctIndex: 2,
  },
  {
    question: 'What moves during osmosis?',
    options: ['Water', 'Proteins', 'Glucose'],
    answerIndex: 1,
    correctIndex: 1,
  },
  {
    question: 'What is the building block of proteins?',
    options: ['Amino acids', 'Fatty acids', 'Nucleotides'],
    answerIndex: 2,
    correctIndex: 0,
  },
  {
    question: 'Which base pairs with adenine in DNA?',
    options: ['Cytosine', 'Guanine', 'Thymine'],
    answerIndex: 2,
    correctIndex: 2,
  }
];


const ExamFeaturePreview = () => {
  const containerRef = useRef(null);
  const prevIsInViewRef = useRef(false);

  const [isInView, setIsInView] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [hasStartedAnimations, setHasStartedAnimations] = useState(false);



  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
        if (entry.isIntersecting && !hasStartedAnimations) {
          setHasStartedAnimations(true);
        }
      },
      { threshold: 0.8 }
    );
  
    if (containerRef.current) observer.observe(containerRef.current);
  
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [hasStartedAnimations]);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));



  const resetForNewQuestion = useCallback(() => {
    // Reset states for a new question
    setSelectedIndex(null);
    setShowFeedback(false);
  }, []);



  const runAnimationForQuestion = useCallback(async () => {
    if (!isInView || !hasStartedAnimations) return;

    const currentQuestion = multipleChoiceData[questionIndex];
    const questionAnimationTime = currentQuestion.question.length * 50;
    console.log('questionAnimationTime', questionAnimationTime);
    await wait(questionAnimationTime + 1500); 


    setSelectedIndex(currentQuestion.answerIndex);
    await wait(1500);

    setShowFeedback(true);

    await wait(1000);


    // const nextIndex = (questionIndex + 1) % multipleChoiceData.length;
    // setQuestionIndex(nextIndex);
    // resetForNewQuestion();
  }, [questionIndex, isInView, hasStartedAnimations]);



  useEffect(() => {
    let isCancelled = false;

    const runSequence = async () => {
      if (!isInView || !hasStartedAnimations || isCancelled) return;
      


      await runAnimationForQuestion();
      if (isCancelled) return;

      await wait(1500);
      if (isCancelled) return;

      if (isInView && hasStartedAnimations && !isCancelled) {
        setQuestionIndex((prev) => (prev + 1) % multipleChoiceData.length);
        resetForNewQuestion();
      }
    };

    runSequence();

    return () => {
      isCancelled = true;
    };
  }, [questionIndex, runAnimationForQuestion, isInView, hasStartedAnimations, resetForNewQuestion]);

  useEffect(() => {
    if (isInView && !prevIsInViewRef.current) {
      // Component just became visible
      if (!hasStartedAnimations) {
        setHasStartedAnimations(true);
      }
      resetForNewQuestion();
    } else if (!isInView && prevIsInViewRef.current) {
      resetForNewQuestion();
    }
    
    prevIsInViewRef.current = isInView;
  }, [isInView, resetForNewQuestion, hasStartedAnimations]);




  const childVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const fadeVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: 'easeOut' },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.5, ease: 'easeIn' },
    },
  };
  
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.03,
        duration: 0.8,
      },
    },
  };
  
  const optionsContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 1.2,
        staggerChildren: 0.2,
        duration: 0.5,
      },
    },
  };
  
  const optionItemVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  // Determine classes for each option based on current states
  const getOptionClasses = (index) => {
    let baseClasses = "transition-colors duration-500 min-w-[290px] max-w-[375px] w-full rounded-xl shadow-lg p-2 lg:p-2.5 my-2 lg:my-2.5 text-center font-medium text-base lg:text-lg cursor-pointer";

    // Default state
    let borderClass = "border-2 border-gray-200";
    let bgClass = "bg-white hover:bg-gray-50 text-black";

    // If we have selectedIndex, highlight that one in blue (before showing feedback)
    if (selectedIndex === index && !showFeedback) {
      borderClass = "border-2 border-blue-500";
      bgClass = "bg-blue-50 text-black";
    }

    // Once showFeedback is true, show red/green
    if (showFeedback) {
      const { answerIndex, correctIndex } = multipleChoiceData[questionIndex];
      if (index === correctIndex && index === answerIndex) {
        // User was correct
        borderClass = "border-2 border-green-500";
        bgClass = "bg-green-50 text-black";
      } else if (index === correctIndex) {
        // Correct answer
        borderClass = "border-2 border-green-500";
        bgClass = "bg-green-50 text-black";
      } else if (index === answerIndex && answerIndex !== correctIndex) {
        // User's chosen incorrect answer
        borderClass = "border-2 border-red-500";
        bgClass = "bg-red-50 text-black";
      } else {
        // Other non-selected and non-correct options remain default
        borderClass = "border-2 border-gray-200";
        bgClass = "bg-white hover:bg-gray-50 text-black";
      }
    }

    return `${baseClasses} ${borderClass} ${bgClass}`;
  };

  return (
    <div ref={containerRef} className="flex flex-col items-center justify-start">
      <AnimatePresence mode="wait">
        <motion.div
          key={questionIndex}
          variants={fadeVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="flex flex-col items-center justify-start pb-6"
        >
          <motion.p
            className="text-xl lg:text-2xl font-medium"
            variants={containerVariants}
            initial="hidden"
            animate={hasStartedAnimations ? "visible" : "hidden"}
          >
            {multipleChoiceData[questionIndex].question.split('').map((char, index) => (
              <motion.span key={index} variants={childVariants}>
                {char}
              </motion.span>
            ))}
          </motion.p>
          
          {/* Render options */}
          <motion.div 
            className="mx-auto flex flex-col items-center justify-center mt-6"
            variants={optionsContainerVariants}
            initial="hidden"
            animate={hasStartedAnimations ? "visible" : "hidden"}
          >
            {multipleChoiceData[questionIndex].options.map((option, index) => (
              <motion.div
                key={index}
                className={getOptionClasses(index)}
                variants={optionItemVariants}
              >
                {option}
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </AnimatePresence>  
    </div>
  );
};

export default ExamFeaturePreview;
