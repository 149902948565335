import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileLines } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import pdfToText from 'react-pdftotext'
import Modal from './Modal';
import CloudUploadIcon from '../SVG/CloudUploadIcon';
import AILoadingAnimation from '../AILoadingAnimation';
import { motion } from 'framer-motion';
import ModalGenerateButton from '../Buttons/ModalGenerateButton';

const UploadDocumentModal = ({ showModal, setShowModal, processData, loading, setLoading, setLimitReason, setShowLimitModal, subscriptionPlan, saveFlashcards, uuid, fetchData, maxNumberOfFlashcards }) => {
  const [dragOver, setDragOver] = useState(false);
  const [fileUploadErrors, setFileUploadErrors] = useState("");
  const [fileSubmitted, setFileSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [numberOfFlashcards, setNumberOfFlashcards] = useState(5);
  const [showUpgradeButton, setShowUpgradeButton] = useState(false);
  const [fileText, setFileText] = useState("");

  const navigate = useNavigate();

  const userCharacterLimit = subscriptionPlan === "max" ? 50000 : 10000;



  const numberOfFlashcardOptions = Array.from(
    { length: Math.ceil(maxNumberOfFlashcards < 50 ? maxNumberOfFlashcards / 5 : 50 / 5) },
    (_, i) => {
      const value = (i + 1) * 5;
      return {
        label: `${value} Flashcard${value > 1 ? 's' : ''}`,
        value
      };
    }
  );


  // TODO: The pdf upload is not working
  // TODO: the word upload is also not working
  // TODO: Need to check if it works in the exam input page







  const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
  };

  const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
  };

  const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
  };

  const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        processFile(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
  };



  const processFile = (file) => {
    // Info: 5242880 = 5MB
    if (file.size > 5242880) {
      return setFileUploadErrors("File size is too large. Please upload a file less than 5MB.");
    } 
    console.log("File type: ", file[0].type)

    if (file[0].type === "application/pdf") {
      pdfToText(file[0])
      .then((text) => {
        console.log("Uploaded text", text)
        console.log("Uploaded text length", text.length)
        if (text === "") {
          setFileUploadErrors("File is empty. No content could get extracted. Please upload a file with content.");
          return;
        } else if (text.length > userCharacterLimit) {
          setFileUploadErrors(`File is too large, upload a file with less than ${userCharacterLimit.toLocaleString()} characters.`);
          setShowUpgradeButton(true);
          setLimitReason("text");
          setShowLimitModal(true);
        } else {
          setFileText(text);
          setFileUploadErrors("");
          setFileSubmitted(true);
          setShowLimitModal(false);
        }

      })
      .catch((error) => {
        console.error("Failed to extract text from pdf", error)
        setFileUploadErrors("Failed to extract text from pdf. Please upload a file with content.");
        setFileText("");
      });
    } else {
      setFileUploadErrors("");
      setFileSubmitted(true);
      setFile(file[0]);
    }
    setFileName(file[0].name);
  };



  const handlePdfUpload = () => {
    setLoading(true);
    console.log("fileText", fileText);
    console.log("These are the number of flashcards: ", numberOfFlashcards);
    console.log("This is the type of the number of flashcards: ", typeof numberOfFlashcards);
    if (saveFlashcards) {
      const data = {
        "text": fileText,
        "number_of_flashcards": numberOfFlashcards,
        "uuid": uuid
      };
      api.post("/api/flashcards/generate_flashcards_from_text_and_save/", data)
      .then((response) => {
        if (response.data.enough_tokens === false && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setShowModal(false);
          setLoading(false);
        } else {
          fetchData();
          setShowModal(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    } else {
      const data = {
        "text": fileText,
        "number_of_flashcards": numberOfFlashcards
      };
      api.post("/api/flashcards/generate_flashcards_from_text/", data)
      .then((response) => {
        if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setShowModal(false);
          setLoading(false);
          return;
        }
        setFileUploadErrors('');
        processData(response.data);
      })
    }

  }



  const uploadFile = () => {
    console.log("This is the file: ", file);
    console.log("this is the file text: ", fileText);
    if (!file && fileText === "") {
      return setFileUploadErrors('Please select a file.');
    }
    setLoading(true);

    if (fileText !== "") {
      return handlePdfUpload();
    };


  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('number_of_flashcards', numberOfFlashcards);
    console.log("These are the number of flashcards: ", numberOfFlashcards);
    if (saveFlashcards) {
      api.post(`/api/upload_file/flashcards/save/${uuid}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        if (response.data.enough_tokens === false && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setShowModal(false);
          setLoading(false);
        } else {
          fetchData();
          setShowModal(false);
          setLoading(false);
        }
        console.log("This the response from saving the flashcards: ", response.data);
      })
      .catch((error) => {
        console.error("Error saving flashcards:", error);
      });
    } else {
      api.post("/api/upload_file/flashcards/", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        console.log("This is the response data: ", response.data);

        if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setLoading(false);
          return;
        }
        processData(response.data);
        setFileUploadErrors('');
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          console.log(error.response.data);
          const errorMessage = error.response.data.error || 'An error occurred while uploading the file.';
          setFileUploadErrors(errorMessage);
        } else {
          setFileUploadErrors('Error uploading file. Please try again.');
        }
        console.error("Error uploading file:", error);
      });
    }
  };
  

  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
      <div className="rounded-lg shadow-lg w-full relative max-w-3xl bg-white dark:bg-dark" >
        {loading ? (
          <div className='h-[632.5px] flex flex-col items-center justify-center dark:bg-dark'>
            <AILoadingAnimation width={"300"} height={"300"} text={"Generating Flashcards..."} />
          </div>
        ) : (
          <div>
            <div className='px-8 pt-8'>
              <div className='flex flex-row justify-between items-center'>
                <h2 className="text-2xl font-bold dark:text-dark">Upload Document</h2>
                <motion.button
                  onClick={() => setShowModal(false)}
                  className="hover:text-gray-400 text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
                  style={{ fontSize: "28px"}}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </motion.button>
              </div>
              <h3 className="pt-2 text-lg text-gray-600 dark:text-gray-400 mb-6">
                Please make sure your file is under {userCharacterLimit.toLocaleString()} characters and does not exceed 5 MB in size.
              </h3>
              

              <div className={`h-[250px] border-2 rounded-lg ${fileUploadErrors !== "" ? "border-red-500" : "border-blue-600"} ${dragOver ? ' bg-blue-200 dark:bg-gray-700' : 'border-dashed dark:bg-gray-800'} relative`}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}>
                  {!fileSubmitted && (
                    <input type="file" id="file-upload" className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
                      onChange={(e) => processFile(e.target.files)}
                      accept=".pdf,.docx"
                      onClick={(event) => { event.target.value = null; }}
                      multiple={false}
                    />
                  )}


                  <label htmlFor="file-upload" className="text-center flex flex-col items-center justify-center h-full">
                    {fileSubmitted ? (
                      <div>
                        <FontAwesomeIcon icon={faFileLines} className='text-blue-500 text-7xl' />
                        <p className='mt-6 mb-6 text-lg text-blue-600 dark:text-blue-500 font-medium'>{fileName}</p>
                        <button 
                          className='bg-blue-200 dark:bg-blue-600 text-blue-600 dark:text-white rounded-full px-4 py-1.5 font-semibold hover:bg-blue-300 dark:hover:bg-blue-500 hover:text-blue-700 dark:hover:text-white' 
                          onClick={() => setFileSubmitted(false)}
                        >
                          Change File
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <CloudUploadIcon className="w-14 h-14 transition-colors duration-300 text-blue-600 dark:text-blue-500" />
                        <p className="text-lg mt-4 dark:text-dark">Drag and drop your files here.</p>
                        <div className="flex items-center w-full my-4">
                          <hr className="flex-grow border border-gray-300 dark:border-gray-500" />
                          <span className="mx-2 text-gray-500 dark:text-gray-300">or</span>
                          <hr className="flex-grow border border-gray-300 dark:border-gray-500" />
                        </div>
                        <div className='bg-white dark:bg-gray-700 rounded-lg border-2 py-1 px-4 border-blue-600 dark:border-gray-600 text-blue-600 dark:text-white text-lg font-medium'>
                          Browse files
                        </div>
                      </div>
                    )}
                  </label>
              </div>
              <p className='text-gray-500 dark:text-gray-400 mt-2.5 text-lg'>
                Support only .pdf and .docs files.
              </p>
              {(fileUploadErrors || showUpgradeButton) && (
                <div className="flex flex-row items-center mt-2">
                  <p className='text-lg font-medium text-red-500 mr-1'>{fileUploadErrors}</p>
                  {showUpgradeButton && <button className="text-lg font-medium text-blue-600 hover:text-blue-700 dark:dark:text-blue-500 dark:hover:text-blue-600 underline-offset-1 underline" onClick={() => navigate("/pricing")} >Upgrade to Study Buddy MAX!</button>}
                </div>
              )}

              <div className='mt-6'>
                <h2 className="text-xl font-semibold dark:text-dark">Number of Flashcards</h2>
                <select 
                  className='border border-gray-200 dark:border-gray-700 rounded-lg p-2.5 mt-4 w-[205px] shadow-lg dark:shadow-none focus:border-gray-400 dark:focus:border-gray-500 focus:outline-none dark:bg-gray-800 dark:text-dark'
                  value={numberOfFlashcards}
                  onChange={(e) => setNumberOfFlashcards(e.target.value)}
                >
                  {numberOfFlashcardOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className='flex justify-between mt-6 px-8 pb-8'>
              <button 
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-700 dark:hover:border-gray-600 w-full max-w-60 hover:border-gray-400 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition dark:text-dark font-medium"
                onClick={() => setShowModal(false)}
              >
                Cancel  
              </button>
              <ModalGenerateButton onClick={uploadFile} />
            </div>
          </div>
        )}

      </div>
    </Modal>
  );
};

export default UploadDocumentModal;
