import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosGet from "../hooks/useAxiosGet";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import OptionsCorrectionComponent from "../components/OtherQuestionComponents/OptionsCorrectionComponent";
import api from "../api";
import LoadingSpinner from "../components/General/LoadingSpinner";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import { motion, AnimatePresence } from "framer-motion";

const ExamRecap = () => {
  const [userAnswers, setUserAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [corrections, setCorrections] = useState([]);
  const [correctIncorrect, setCorrectIncorrect] = useState([]);
  const [pointsList, setPointsList] = useState([]);
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);


  const { uuid } = useParams();
  const navigate = useNavigate();



  const getMaxPointsForQuestionType = (type) => {
    if (type === "multipleChoice") {
      return 1;
    } else if (type === "trueOrFalse") {
      return 1;
    } else if (type === "fillInTheBlank") {
      return 2;
    } else if (type === "shortAnswer") {
      return 5;
    } else if (type === "essay") {
      return 10;
    }
  };

  const { data, loading, error } = useAxiosGet("/api/summary/" + uuid + "/");

  useEffect(() => {
    if (data) {
      setUserAnswers(eval(data.user_answers));
      setCorrections(eval(data.corrections));
      setCorrectIncorrect(eval(data.correct_incorrect));
      let totalPointsCalc = 0;
      eval(data.questions).forEach((question) => {
        totalPointsCalc += getMaxPointsForQuestionType(question.type);
      });
      setMaxPoints(totalPointsCalc);
      
      setPointsList(eval(data.points_list));
      setPoints(eval(data.points_list).reduce((a, b) => a + b, 0));
      setQuestions(eval(data.questions));
      console.log("these are the quesitons", questions);
      console.log(data);
    }
    if (loading) {
      console.log("Loading");
    }
    if (error) {
      console.log(error);
    }
  }, [data, loading, error]);



  const getChoiceIndex = (choices, userAnswer) => {
    console.log("this is the user answer", userAnswer);
    console.log("choices", choices.indexOf(userAnswer));
    return choices.indexOf(userAnswer);
  };


  const handleRepeatButtonClicked = () => {
    api.put(`/api/delete_summary_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      navigate("/exam/questions/"+uuid);
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
    });
  };

  const progressBarVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6
      }
    }
  };

  const cardVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.9
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeOut",
        duration: 0.6
      }
    }
  };




  if (loading || questions.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen dark:bg-dark">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="px-6 min-h-screen bg-light dark:bg-dark">

      <motion.div
        initial="hidden"
        animate="visible"
        variants={progressBarVariants}
      >
        <PointsBarComponent points={points} maxPoints={maxPoints} handleExitButtonClicked={() => navigate("/")} handleRepeatButtonClicked={handleRepeatButtonClicked} />
      </motion.div>


      <AnimatePresence>
        {questions.map((question, index) => (
          <motion.div 
            className="flex justify-center items-center" 
            key={index}
            initial="hidden"
            whileInView="visible" 
            viewport={{ once: true, margin: "-100px" }}
            variants={cardVariants}
          >
            <div 
              className={`max-w-[735px] w-full bg-white dark:bg-gray-800 rounded-2xl mb-8 p-8 shadow-color-lg dark:shadow-none ${correctIncorrect[index] ? "shadow-green-300" : "shadow-red-300"}`}
            >
              <p className="text-xl mb-3 dark:text-dark">{question.question}</p>
              <p className="text-lg text-neutral-700 dark:text-gray-400 mb-6 font-medium">Points: {pointsList[index]}</p>
              {question.type === "multipleChoice" && (
                <div>
                  {correctIncorrect[index] ? (
                    <div>
                      <OptionsCorrectionComponent
                        optionsList={question.choices}
                        correctIndex={getChoiceIndex(
                          question.choices,
                          userAnswers[index]
                        )}
                        isRecapPage={true}
                      />
                    </div>
                  ) : (
                    <div>
                      <OptionsCorrectionComponent
                        optionsList={question.choices}
                        correctIndex={corrections[index]}
                        incorrectIndex={getChoiceIndex(
                          question.choices,
                          userAnswers[index]
                        )}
                        isRecapPage={true}
                      />
                    </div>
                  )}
                </div>
              )}
              {question.type === "trueOrFalse" && (
                <div>
                  {correctIncorrect[index] ? (
                    <div>
                      <OptionsCorrectionComponent
                        optionsList={["True", "False"]}
                        correctIndex={getChoiceIndex(
                          ["True", "False"],
                          userAnswers[index]
                        )}
                        isRecapPage={true}
                      />
                    </div>
                  ) : (
                    <div>
                      <OptionsCorrectionComponent
                        optionsList={["True", "False"]}
                        correctIndex={corrections[index]}
                        incorrectIndex={getChoiceIndex(
                          ["True", "False"],
                          userAnswers[index]
                        )}
                        isRecapPage={true}
                      />
                    </div>
                  )}
                </div>
              )}
              {question.type === "fillInTheBlank" && (
                <>
                  {correctIncorrect[index] ? (
                    <AnswerInputFieldCorrectCorrection
                      userAnswer={userAnswers[index]}
                      showHeader={true}
                      isRecapPage={true}
                    />
                  ) : (
                    <AnswerInputFieldIncorrectCorrection
                      correction={corrections[index]}
                      userAnswer={userAnswers[index]}
                      correctionHeader={"This would be a word that fits in the blank"}
                      isRecapPage={true}
                    />
                  )}
                </>
              )}

              {question.type === "shortAnswer" && (
                <>
                  {correctIncorrect[index] ? (
                    <AnswerInputFieldCorrectCorrection
                      userAnswer={userAnswers[index]}
                      showHeader={true}
                      isRecapPage={true}
                    />
                  ) : (
                    <AnswerInputFieldIncorrectCorrection
                      correction={corrections[index]}
                      userAnswer={userAnswers[index]}
                      correctionHeader={"This would be the correct answer"}
                      isRecapPage={true}
                    />
                  )}
                </>
              )}
              {question.type === "essay" && (
                <>
                  {correctIncorrect[index] ? (
                    <AnswerInputFieldCorrectCorrection
                      userAnswer={userAnswers[index]}
                      showHeader={true}
                      isRecapPage={true}
                    />
                  ) : (
                    <AnswerInputFieldIncorrectCorrection
                      correction={corrections[index]}
                      userAnswer={userAnswers[index]}
                      correctionHeader={"Feedback on your essay"}
                      isRecapPage={true}
                    />
                  )}
                </>
              )}
            </div>
          </motion.div>  
        ))}
      </AnimatePresence>  

      <motion.div 
        className="flex justify-center mt-12 pb-24"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <button
          className="max-w-[735px] w-full px-6 py-3 rounded-lg text-xl font-medium transition shadow-lg dark:shadow-none text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"
          onClick={handleRepeatButtonClicked}
          >
            Repeat Exam
        </button>
      </motion.div>

    </div>
  );
};
export default ExamRecap;