import { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import LoadingSpinner from '../components/General/LoadingSpinner';


const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); 

  const verifyToken = () => {
    const token = Cookies.get("accessToken");
    if (!token && !Cookies.get("refreshToken")) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    } else if (!token) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    } else if (!Cookies.get("refreshToken")) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    } else {
      setIsAuthenticated(true);
    }
    setLoading(false);
  };


  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading }}>
      {loading ? <LoadingSpinner /> : children} 
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
