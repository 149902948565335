import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faFileLines,
  faImage,
  faChevronLeft,
  faSave,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../components/General/LoadingSpinner";
import LimitModal from "../components/Modals/LimitModal";
import { ReactComponent as TextIcon } from "../Assets/SVG/TextIcon.svg";
import UploadDocumentModal from "../components/Modals/UploadDocumentModal";
import UploadTextModal from "../components/Modals/UploadTextModal";
import UploadImageModal from "../components/Modals/UploadImageModal";
import { motion, AnimatePresence  } from "framer-motion"; 
import { v4 as uuidv4 } from "uuid";
import FlashcardInputField from "../components/DefinitionQuestionComponents/FlashcardInputField";
import debounce from 'lodash/debounce';
import { Notify } from "../providers/NotificationProvider";
import AnimatedSparkle from "../components/SVG/AnimatedSparkle";
import UploadButton from "../components/Buttons/UploadButton";


const FlashcardEdit = () => {
  const [flashcards, setFlashcards] = useState([]);
  const [title, setTitle] = useState("");
  const [flashcardLimit, setFlashcardLimit] = useState(100);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [showUploadTextModal, setShowUploadTextModal] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [limitReason, setLimitReason] = useState("");
  const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false)
  const [errors, setErrors] = useState({
    definitions: [],
    title: false,
    words: [],
  });
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [generatingFlashcards, setGeneratingFlashcards] = useState(false);
  const [changes,  setChanges] = useState({
    added: [],
    updated: [],
    titleChange: null
  })


  const [isSticky, setIsSticky] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  

  const [wordFocusIndex, setWordFocusIndex] = useState(null);
  const [definitionFocusIndex, setDefinitionFocusIndex] = useState(null);

  const wordInputRefs = useRef({});
  const definitionInputRefs = useRef({});
  const titleInputRef = useRef(null);
  const isStickyRef = useRef(isSticky);
  const changesRef = useRef(changes);
  const saveAbortController = useRef(null);

  const isSavingRef = useRef(false)
  const isDeletingRef = useRef(false)

  const navigate = useNavigate();
  const { uuid } = useParams();
  

  useEffect(() => {
    changesRef.current = changes;
  }, [changes]);
  



  useEffect(() => {
    isStickyRef.current = isSticky;
  }, [isSticky]);

  const fetchData = async () => {
    setLoading(true);
    const urls = [
      `/api/flashcards/all/${uuid}/`,
      `/api/flashcards/flashcard_set/${uuid}/`,
      `/api/subscription/`,
    ];

    try {
      const responses = await Promise.all(urls.map((url) => api.get(url)));
      
      const flashcardResponse = Array.from(responses[0].data);
      const flashcardSetResponse = responses[1].data;
      const subscriptionResponse = responses[2].data;

      const updatedFlashcards = flashcardResponse.map((flashcard) => ({
        ...flashcard,
        uuid: flashcard.uuid || uuidv4(),
      }));

      setFlashcards(updatedFlashcards);
      setTitle(flashcardSetResponse.title);

      let cardLimit = 100;
      if (subscriptionResponse.plan === 'max') {
        cardLimit = 5000;
        setSubscriptionPlan('max');
      } else {
        cardLimit = 100;
        setSubscriptionPlan('basic');
      }
      setFlashcardLimit(cardLimit);
      if (updatedFlashcards.length === cardLimit) {
        setShowMaxFieldsMessage(true);
        setDisableAddButton(true);
      } else {
        setShowMaxFieldsMessage(false);
        setDisableAddButton(false);
      }
      setErrors({
        definitions: [],
        title: false,
        words: [],
      })
      

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [uuid]);




  useEffect(() => {
    if (wordFocusIndex !== null) {
      const ref = wordInputRefs.current[wordFocusIndex];
      if (ref && ref.focus) {
        ref.focus();
      }
      setWordFocusIndex(null);
    } else if (definitionFocusIndex !== null) {
      const ref = definitionInputRefs.current[definitionFocusIndex];
      if (ref && ref.focus) {
        ref.focus();
      }
      setDefinitionFocusIndex(null);
    }
  }, [wordFocusIndex, definitionFocusIndex]);
  





  const handleInputChange = (index, field, value) => {
    const updatedFlashcards = [...flashcards];
    updatedFlashcards[index] = {
      ...updatedFlashcards[index],
      [field]: value,
    };
    setFlashcards(updatedFlashcards);
  
    const updatedFlashcard = updatedFlashcards[index];

    setChanges((prevChanges) => {
      const isNewlyAdded = prevChanges.added.find(fc => fc.uuid === updatedFlashcard.uuid);
      // If it is a newly added flashcard, we need to update the added array
      if (isNewlyAdded) {
        const updatedAdded = prevChanges.added.map(fc =>
          fc.uuid === updatedFlashcard.uuid ? updatedFlashcard : fc
        );
        return { ...prevChanges, added: updatedAdded };
      } else {
        const alreadyUpdated = prevChanges.updated.find(fc => fc.uuid === updatedFlashcard.uuid);
        if (alreadyUpdated) {
          return {
            ...prevChanges,
            updated: prevChanges.updated.map(fc =>
              fc.uuid === updatedFlashcard.uuid ? updatedFlashcard : fc
            ),
          };
        } else {
          return {
            ...prevChanges,
            updated: [...prevChanges.updated, updatedFlashcard],
          };
        }
      }
    });
  };



  const handleDeleteField = async (indexToRemove) => {
    if (flashcards.length <= 1) {
      return ;
    }
    if (isSavingRef.current || isDeletingRef.current) {
      Notify({ message: "Please wait until the current operation is complete.", type: "error" });
      return;
    }

    if (saveAbortController.current) {
      saveAbortController.current.abort();
    }

    const flashcardToDelete = flashcards[indexToRemove];
    const isNewlyAdded = changes.added.find(fc => fc.uuid === flashcardToDelete.uuid);

    if (isNewlyAdded) {
      setChanges(prevChanges => ({
        ...prevChanges,
        added: prevChanges.added.filter(fc => fc.uuid !== flashcardToDelete.uuid),
      }));
      const newFlashcards = [...flashcards];
      newFlashcards.splice(indexToRemove, 1);
      setFlashcards(newFlashcards);
      if (showMaxFieldsMessage) {
        setDisableAddButton(false);
        setShowMaxFieldsMessage(false);
      }
    } else {
      try {
        isDeletingRef.current = true;
        const response = await api.delete(`/api/flashcards/delete_flashcard/${flashcardToDelete.uuid}/${uuid}/`);

        if (response.status === 200) {
          const newFlashcards = [...flashcards];
          newFlashcards.splice(indexToRemove, 1);
          setFlashcards(newFlashcards);
          if (showMaxFieldsMessage) {
            setDisableAddButton(false);
            setShowMaxFieldsMessage(false);
          }
        } else {
          console.error("Delete failed: ", response.data.error);
          Notify({ message: "An error occurred while deleting the flashcard. Please try again.", type: "error" });
        }

      } catch (error) {
        console.error("Error deleting flashcard: ", error);
        Notify({ message: "An error occurred while deleting the flashcard. Please try again.", type: "error" });
      } finally {
        isDeletingRef.current = false;
      }
    }
  };



  useEffect(() => {
    if (flashcards.length > 0) {
      const lastFlashcard = flashcards[flashcards.length - 1];
      const isLastFlashcardFilled = lastFlashcard.word.trim() !== "" && lastFlashcard.definition.trim() !== "";
      setDisableAddButton(!isLastFlashcardFilled);
    }
  }, [flashcards]);


  const handleAddFlashcard = () => {
    if (flashcards.length >= flashcardLimit) {
      setLimitReason("flashcards");
      setShowLimitModal(true);
      setShowMaxFieldsMessage(true);
      setDisableAddButton(true);
      return;
    }

    const newFlashcard = {
      word: "",
      definition: "",
      uuid: uuidv4(),
    };
    setFlashcards([...flashcards, newFlashcard]);
    setChanges((prevChanges) => ({
      ...prevChanges,
      added: [...prevChanges.added, newFlashcard],
    }));
    setWordFocusIndex(flashcards.length);
  };




  // const saveChanges = useCallback(async () => {

  //   if (changes.added.length === 0 && changes.updated.length === 0 && changes.titleChange === null) {
  //     return;
  //   }

  //   if (isSavingRef.current || isDeletingRef.current) {
  //     return;
  //   }

  //   const newErrors = {
  //     definitions: [],
  //     title: title.trim() === "",
  //     words: [],
  //   };


  //   const lastIndex = flashcards.length - 1;



  //   flashcards.forEach((flashcard, index) => {
  //     const { word, definition } = flashcard;

  //     const isLastEmpty =
  //       index === lastIndex &&
  //       word.trim() === "" &&
  //       definition.trim() === "";

  //     if (word.trim() === "" && !isLastEmpty) {
  //       newErrors.words.push(index + 1);
  //     }

  //     if (definition.trim() === "" && !isLastEmpty) {
  //       newErrors.definitions.push(index + 1);
  //     }
  //   });

  //   if (newErrors.title || newErrors.words.length > 0 || newErrors.definitions.length > 0) {
  //     setErrors(newErrors);

  //     if (newErrors.title) {
  //       titleInputRef.current.focus();
  //     } else if (newErrors.words.length > 0) {
  //       setWordFocusIndex(newErrors.words[0]);
  //     } else if (newErrors.definitions.length > 0) {
  //       setDefinitionFocusIndex(newErrors.definitions[0]);
  //     }
  //     return;
  //   }



  //   // FIXME: there is a problem when the user has added a field but both of them are empty when trimmed it should not save it to the databasE

  //   isSavingRef.current = true;
  //   setIsSaving(true)

    

  //   if (saveAbortController.current) {
  //     saveAbortController.current.abort();
  //   }

  //   saveAbortController.current = new AbortController();
  //   try {
  //     const config = { signal: saveAbortController.current.signal };
  //     const data = {
  //       flashcard_set_uuid: uuid,
  //       added: changes.added,
  //       updated: changes.updated,
  //       title_update: changes.titleChange,
  //     }
  //     console.log("Saving...")

  //     //FIXME: need to update the url here
  //     const response = await api.put("/api/flashcards/auto_save_changes/", data, config);

  //     if (response.status === 200) {
  //       console.log("auto save successful");
  //       setChanges({ added: [], updated: [], titleChange: null });
  //       setErrors({ definitions: [], title: false, words: [] });
  //     } else {
  //       console.error("Save failed: ", response.data.error);
  //       Notify({ message: "An error occurred while saving the flashcards. Please try again.", type: "error" });
  //     }

  //   } catch (error) {
  //     if (error.name === "AbortError") {
  //       return;
  //     }
  //     console.error("Error saving flashcards: ", error);
  //     Notify({ message: "An error occurred while saving the flashcards. Please try again.", type: "error" });
  //   } finally {
  //     isSavingRef.current = false;
  //     setIsSaving(false);
  //   }
  // }, [changes, isSavingRef, isDeletingRef, uuid]);









  
  const saveChanges = useCallback(async () => {
    if (changes.added.length === 0 && changes.updated.length === 0 && changes.titleChange === null) {
      return;
    }
  
    if (isSavingRef.current || isDeletingRef.current) {
      return;
    }
  
    const filteredAdded = changes.added.filter((flashcard) => {
      const wordTrimmed = flashcard.word.trim();
      const definitionTrimmed = flashcard.definition.trim();
      return wordTrimmed !== "" && definitionTrimmed !== "";
    });
  
    const newErrors = {
      definitions: [],
      title: title.trim() === "",
      words: [],
    };
  
    const flashcardIdToIndex = {};
    flashcards.forEach((flashcard, index) => {
      if (flashcard.uuid) {
        flashcardIdToIndex[flashcard.uuid] = index;
      }
    });
  
    changes.updated.forEach((flashcard) => {
      const { uuid, word, definition } = flashcard;
      const wordEmpty = word.trim() === "";
      const definitionEmpty = definition.trim() === "";
      if (wordEmpty || definitionEmpty) {
        const index = flashcardIdToIndex[uuid];
        if (wordEmpty) {
          newErrors.words.push(index + 1); 
        }
        if (definitionEmpty) {
          newErrors.definitions.push(index + 1);
        }
      }
    });
  
    if (
      newErrors.title ||
      newErrors.words.length > 0 ||
      newErrors.definitions.length > 0
    ) {
      setErrors(newErrors);
  
      if (newErrors.title) {
        titleInputRef.current.focus();
      } else if (newErrors.words.length > 0) {
        setWordFocusIndex(newErrors.words[0] - 1);
      } else if (newErrors.definitions.length > 0) {
        setDefinitionFocusIndex(newErrors.definitions[0] - 1);
      }
      console.log("Errors found in updated flashcards or title:", newErrors);
      return;
    }


    if (
      filteredAdded.length === 0 &&
      changes.updated.length === 0 &&
      changes.titleChange === null
    ) {
      return;
    }
  
    isSavingRef.current = true;
    setIsSaving(true);
  
    if (saveAbortController.current) {
      saveAbortController.current.abort();
    }
  
    saveAbortController.current = new AbortController();
  
    try {
      const config = { signal: saveAbortController.current.signal };
      const data = {
        flashcard_set_uuid: uuid,
        added: filteredAdded,
        updated: changes.updated,
        title_update: changes.titleChange,
      };
      console.log("data that gets sent to the backend: ", data);
  
      const response = await api.put(
        "/api/flashcards/auto_save_changes/",
        data,
        config
      );
  
      if (response.status === 200) {
        setChanges(prevChanges => ({
          added: prevChanges.added.filter(fc => !filteredAdded.some(savedFc => savedFc.uuid === fc.uuid)),
          updated: [],
          titleChange: null,
        }));
  
        setErrors({ definitions: [], title: false, words: [] });
  

      } else {
        console.error("Save failed: ", response.data.error);
        Notify({
          message:
            "An error occurred while saving the flashcards. Please try again.",
          type: "error",
        });
      }
    } catch (error) {
      if (error.name === "AbortError") {
        return;
      }
      console.error("Error saving flashcards: ", error);
      Notify({
        message:
          "An error occurred while saving the flashcards. Please try again.",
        type: "error",
      });
    } finally {
      isSavingRef.current = false;
      setIsSaving(false);
    }
  }, [
    changes,
    isSavingRef,
    isDeletingRef,
    uuid,
    title,
    flashcards,
    setErrors,
    titleInputRef,
    setWordFocusIndex,
    setDefinitionFocusIndex,
  ]);
  





  const debouncedSaveChanges = useCallback(
    debounce(saveChanges, 1000), [saveChanges]
  );
  

  useEffect(() => {
    debouncedSaveChanges();
    return () => {
      debouncedSaveChanges.cancel();
    };
  }, [changes, debouncedSaveChanges]);


  const handleBeforeUnload = useCallback((event) => {
    if (changes.added.length > 0 || changes.updated.length > 0 || changes.titleChange!== null) {
      saveChanges();
    };
    event.preventDefault();
    event.returnValue = "";
  }, [changes, saveChanges]);


  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);



  const handleSaveFlashcards = () => {
    setIsSaving(true);
    saveChanges();
    navigate(-1);
    setIsSaving(false);
  };


  





  ////////////////////////
  //
  // TODO: add autosave
  // https://chatgpt.com/c/670fb6a1-e8d4-8004-be76-362c9ec46403
  //
  //////////////////////






  useEffect(() => {

    // This is for the sticky header
    // To know if it should be sticky or not 
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const shouldBeSticky = currentScrollY > 72;
  
      // Only update state if it actually changes
      if (shouldBeSticky !== isStickyRef.current) {
        setIsSticky(shouldBeSticky);
        isStickyRef.current = shouldBeSticky;
      }
    };
  
    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);
  
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  


    



  // useEffect(() => {

  //   // This is for the sticky header
  //   // To know if it should be sticky or not 
  //   let throttleTimeout = null;

  //   const handleScroll = () => {
  //     if (throttleTimeout === null) {
  //       throttleTimeout = setTimeout(() => {
  //         const currentScrollY = window.scrollY;
  //         const shouldBeSticky = currentScrollY > 72;

  //         if (shouldBeSticky !== isStickyRef.current) {
  //           setIsSticky(shouldBeSticky);
  //         }

  //         throttleTimeout = null;
  //       }, 100);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     if (throttleTimeout) {
  //       clearTimeout(throttleTimeout);
  //     }
  //   };
  // }, []);


  const handleTitleChange = (newTitle) => {
    setTitle(newTitle)
    setChanges((prevChanges) => ({
      ...prevChanges,
      titleChange: newTitle
    }));
  };



  console.log("Page height:",document.body.scrollHeight);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <LimitModal
        showModal={showLimitModal}
        setShowModal={setShowLimitModal}
        limitReason={limitReason}
      />
      <div className="min-h-screen bg-light dark:bg-dark">

        
        <AnimatePresence>
          {isStickyRef.current && (
            <div className="flex flex-row justify-center items-center">
              <motion.div
                key="sticky-header"
                initial={{ y: 0, opacity: 1 }} 
                animate={{ y: 0, opacity: 1 }} 
                exit={{ y: 0, opacity: 0 }} 
                transition={{ duration: 0 }}
                className={`fixed top-4 transform flex flex-row justify-between items-center px-6 py-2 z-20 max-w-[900px] w-full rounded-full bg-white/60 dark:bg-gray-800/60 bg-opacity-60 shadow`}
                style={{
                  backdropFilter: "blur(15px)", 
                }}
              >
                <motion.button
                  layoutId="back-button"
                  className="px-6 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate(-1)}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>Back</span>
                </motion.button>

                <motion.button
                  layoutId="save-button"
                  className="px-6 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
                  onClick={handleSaveFlashcards}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 mr-1 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span>Saving...</span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faSave} />
                      <span>Save</span>
                    </>
                  )}
                </motion.button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <div className="pt-8 w-[900px] max-w-[900px] mx-auto">
          <AnimatePresence>
            <motion.div
              key="original-buttons"
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0 }}
              className={`w-full flex flex-row justify-between items-center mb-12 space-x-5 ${!isStickyRef.current ? "visible opacity-100" : "invisible opacity-0"}`}
            >
              <motion.button
                layoutId="back-button"
                disabled={isStickyRef.current}
                className="px-6 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
                onClick={() => navigate(-1)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>Back</span>
              </motion.button>

              <motion.button
                layoutId="save-button"
                className="px-6 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
                onClick={handleSaveFlashcards}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 mr-1 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Saving...</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faSave} />
                    <span>Save</span>
                  </>
                )}
              </motion.button>
            </motion.div>
          </AnimatePresence>



          <div className="flex flex-col justify-start w-full bg-white rounded-lg shadow-lg">
            <input
              id="title"
              type="text"
              className={`rounded-md text-xl font-medium border-b-2 ${
                errors.title ? "shadow-color-lg dark:shadow-none shadow-red-400 border-red-500" : "focus:border-gray-400 dark:border-gray-700 dark:focus:border-gray-500 "
              } py-2.5 px-4 focus:outline-none outline-none dark:bg-gray-800 dark:text-dark`}
              placeholder="Enter a title"
              onChange={(event) => handleTitleChange(event.target.value)}
              value={title}
              ref={titleInputRef}
            />
          </div>
          {errors.title && (
            <p className="text-red-500 text-sm font-semibold mt-2">
              Title is required
            </p>
          )}

          <div className="flex-row items-start justify-start mt-10">
            <div className="flex flex-row items-center ">
              <h1 className="text-3xl font-semibold dark:text-dark">
                Generate flashcards with AI
              </h1>
              <AnimatedSparkle width="48px" height="48px" className="ml-2" />
            </div>
            <p className="text-lg font-medium mt-1.5 mb-6 text-gray-600 dark:text-gray-400">
              Upload your materials to receive custom-made flashcards tailored to
              your content.
            </p>
            <div className="flex flex-row items-start justify-start gap-4">
              <UploadButton 
                onClick={(e) => { e.stopPropagation(); setShowUploadDocumentModal(true);}}
                text="Upload Document"
                icon={<FontAwesomeIcon icon={faFileLines} className="mr-2" />}
              />
              <UploadButton
                onClick={(e) => { e.stopPropagation(); setShowUploadTextModal(true);}}
                text="Upload Text"
                icon={<TextIcon className="mr-2 w-4 h-4 " />}
              />

              <UploadButton
                onClick={(e) => { e.stopPropagation(); setShowUploadImageModal(true);}}
                text="Upload Images"
                icon={<FontAwesomeIcon icon={faImage} className="mr-2" />}
              />

            </div>
          </div>

          <div className="mb-6 mt-14 flex justify-between">
            <span className="font-semibold text-lg md:text-xl lg:text-2xl dark:text-dark">
              Terms in this set ({flashcards.length})
            </span>
          </div>



          <motion.div
            layout
            className="flex flex-col gap-6 pb-32"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{
              layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
            }}
          >
            <AnimatePresence>
              {flashcards.map((flashcard, index) => (
                <motion.div
                  key={flashcard.uuid}
                  className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg px-6 pt-6 pb-6"
                  layout
                  initial={{ opacity: 0, scale: 0.95, y: 20 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.95, y: -20 }}
                  transition={{
                    layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                    duration: 0.3,
                    ease: [0.4, 0, 0.2, 1],
                  }}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12">
                    <div>
                      <label className="mb-4 font-medium text-sm text-neutral-700 dark:text-gray-300 block">
                        Term
                      </label>
                      <FlashcardInputField 
                        value={flashcard.word}
                        ref={(el) => (wordInputRefs.current[index] = el)}
                        placeholder="Enter a term"
                        error={errors.words.includes(index + 1)}
                        onChange={(event) =>
                          handleInputChange(index, "word", event.target.value)
                        }
                      />
                    </div>

                    <div>

                      <label className="mb-4 font-medium text-sm text-neutral-700 dark:text-gray-300 block">
                        Definition
                      </label>
                      <FlashcardInputField
                        value={flashcard.definition}
                        ref={(el) => (definitionInputRefs.current[index] = el)}
                        placeholder="Enter a definition"
                        error={errors.definitions.includes(index + 1)}
                        onChange={(event) =>
                          handleInputChange(index, "definition", event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <AnimatePresence>
                    {flashcards.length > 1 &&
                      flashcard.word.trim() !== "" &&
                      flashcard.definition.trim() !== "" && (
                        <motion.div
                          layout
                          className="flex justify-end"
                          initial={{ opacity: 0, height: 0, marginTop: 0 }}
                          animate={{
                            opacity: 1,
                            height: "auto",
                            marginTop: "12px",
                          }}
                          exit={{ opacity: 0, height: 0, marginTop: 0 }}
                          transition={{
                            layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                            duration: 0.3,
                            ease: [0.4, 0, 0.2, 1],
                          }}
                        >
                          <motion.button
                            className="text-red-600 font-semibold hover:bg-red-100 dark:hover:bg-red-600 dark:hover:text-white rounded-lg py-1.5 px-4 flex items-center"
                            onClick={() => handleDeleteField(index)}
                            tabIndex={-1}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ fontSize: "16px" }}
                            />
                            <span className="ml-2">Delete</span>
                          </motion.button>
                        </motion.div>
                      )}
                  </AnimatePresence>
                </motion.div>
              ))}
              {showMaxFieldsMessage && (
                <div className="text-center mt-6 flex justify-center">
                  <p className="text-lg font-semibold text-red-500 inline">
                    You've reached the maximum field limit ({flashcardLimit}).
                  </p>
                  <a href="/pricing" className="text-lg font-semibold underline text-blue-600 ml-2 hover:text-blue-800">Upgrade</a>
                </div>
              )}

            <motion.div
              key="add-button"
              layout
            >
              <motion.button
                className={`mt-8 px-6 py-3 w-full rounded-lg text-xl font-semibold transition shadow-lg dark:shadow-none ${disableAddButton ? "cursor-not-allowed dark:bg-gray-800 dark:text-gray-500 shadow-gray-300 bg-gray-200 text-gray-500" : "text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"}`} 
                onClick={handleAddFlashcard}
                whileHover={{ scale: disableAddButton ? 1 : 1.05 }}
                whileTap={{ scale: disableAddButton ? 1 : 0.95 }}
                disabled={disableAddButton}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-4" />
                Add Card
              </motion.button>
            </motion.div>
            </AnimatePresence>
          </motion.div>



          <UploadDocumentModal
            showModal={showUploadDocumentModal}
            setShowModal={setShowUploadDocumentModal}
            loading={generatingFlashcards}
            setLoading={setGeneratingFlashcards}
            setShowLimitModal={setShowLimitModal}
            setLimitReason={setLimitReason}
            subscriptionPlan={subscriptionPlan}
            saveFlashcards={true}
            uuid={uuid}
            fetchData={fetchData}
            maxNumberOfFlashcards={flashcardLimit - flashcards.length}
          />

          <UploadTextModal
            showModal={showUploadTextModal}
            setShowModal={setShowUploadTextModal}
            loading={generatingFlashcards}
            setLoading={setGeneratingFlashcards}
            setShowLimitModal={setShowLimitModal}
            setLimitReason={setLimitReason}
            subscriptionPlan={subscriptionPlan}
            saveFlashcards={true}
            uuid={uuid}
            fetchData={fetchData}
            maxNumberOfFlashcards={flashcardLimit - flashcards.length}
          />

          <UploadImageModal
            showModal={showUploadImageModal}
            setShowModal={setShowUploadImageModal}
            loading={generatingFlashcards}
            setLoading={setGeneratingFlashcards}
            setShowLimitModal={setShowLimitModal}
            setLimitReason={setLimitReason}
            subscriptionPlan={subscriptionPlan}
            saveFlashcards={true}
            uuid={uuid}
            fetchData={fetchData}
          />
        </div>
      </div>    
    </>

  );
};

export default FlashcardEdit;









// This is the old code of the sticky header:

{/* <AnimatePresence>
{isSticky && (
  <div className="flex flex-row justify-center items-center">
    <motion.div
      key="sticky-header"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className={`fixed top-4 transform flex flex-row justify-between items-center px-4 py-2 z-20 max-w-[900px] w-full rounded-full`}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        backdropFilter: "blur(10px)", 
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <motion.button
        layoutId="back-button"
        className="px-4 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>Back</span>
      </motion.button>

      <motion.button
        layoutId="save-button"
        className="px-4 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
        onClick={handleSaveFlashcards}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        disabled={isSaving}
      >
        {isSaving ? (
          <>
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-4 h-4 mr-1 text-white animate-spin" // Changed `me-3` to `mr-1` for less space
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            <span>Saving...</span>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faSave} />
            <span>Save</span>
          </>
        )}
      </motion.button>
    </motion.div>
  </div>
)}
</AnimatePresence>

<div className="pt-8 w-[900px] max-w-[900px] mx-auto">
<AnimatePresence>
  <motion.div
    key="original-buttons"
    initial={{ opacity: 1 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ type: "spring", stiffness: 300, damping: 30 }}
    className={`w-full flex flex-row justify-between items-center mb-12 space-x-5 ${!isSticky ? "visible opacity-100" : "invisible opacity-0"}`}
  >
    <motion.button
      layoutId="back-button"
      disabled={isSticky}
      className="px-4 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
      onClick={() => navigate(-1)}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
      <span>Back</span>
    </motion.button>

    <motion.button
      layoutId="save-button"
      className="px-4 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-full flex items-center space-x-2"
      onClick={handleSaveFlashcards}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      disabled={isSaving}
    >
      {isSaving ? (
        <>
          <svg
            aria-hidden="true"
            role="status"
            className="inline w-4 h-4 mr-1 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
          <span>Saving...</span>
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faSave} />
          <span>Save</span>
        </>
      )}
    </motion.button>
  </motion.div>
</AnimatePresence> */}
