

const FeatureCard = ({ title, description, image }) => {


  return (
    <div className="flex flex-col h-full p-6">
      <div className="flex-grow flex items-center justify-center h-9/12 lg:h-4/5">
        {image}
      </div>
      <div className="3/12 lg:h-1/5">
        <h1 className="text-xl lg:text-2xl">{title}</h1>
        <h2 className="text-slate-600 mt-2 lg:mt-2.5">{description}</h2>
      </div>
    </div>
  );
};
export default FeatureCard;