import AnimatedSparkleWhite from "../SVG/AnimatedSparkleWhite";
import { motion } from "framer-motion";

const GenerateButton = ({ onClick, label }) => {
  //className="bg-gradient-to-t from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 rounded-full text-white px-6 h-10 flex items-center hover:bg-blue-500 transition"

  // FIXME: finish this button
  
  // but why would i even add a disabled function to it, because i could just allow the user to press it when every he wants to, and then just show the error


  return (
    <motion.button 
      className={`flex flex-row justify-center items-center px-6 py-3 rounded-full text-xl font-medium cursor-pointer text-white bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600 transition shadow-lg shadow-blue-500/80 dark:shadow-none`}
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <AnimatedSparkleWhite width={"30px"} height={"30px"}  />
      <span className="ml-3">
        {label}
      </span>
    </motion.button>
  );
};
export default GenerateButton;