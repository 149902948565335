import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../components/General/LoadingSpinner";
import Cookies from "js-cookie";
import api from "../api";

const AppleSignInCallbackScreen = () => {
  const { code, id_token } = useParams();
  useEffect(() => {
    axios.post("/api/auth/apple/login/", { access_token: code, id_token: id_token })
      .then((response) => {
        console.log("This is the response from Google:", JSON.stringify(response.data, null, 2));
        Cookies.set("accessToken", response.data.access, {
          expires: 2,
          secure: true,
          sameSite: "strict",
        });
        Cookies.set("refreshToken", response.data.refresh, {
          expires: 90,
          secure: true,
          sameSite: "strict",
        });


        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "apple_sign_in_success",
          userEmail: response.data.user.email,
          userId: response.data.user.pk,
        });
        fetchSubscriptionData();

      })
      .catch((error) => {
        console.error(error);
      });
  }, [code, id_token]);



  const fetchSubscriptionData = () => {
    api.get("/api/payment/subscriptions/")
      .then((response) => {
        const subscriptionTier = response.data.subscription_tier;
        localStorage.setItem("subscriptionTier", subscriptionTier);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error);
      });
  };



  return <LoadingSpinner/>;
};
export default AppleSignInCallbackScreen;