import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./../api"
import BrandLogo from "../components/SVG/BrandLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Notify } from "../providers/NotificationProvider";
import PasswordInputField from "../components/Authentication/PasswordInputField";
import { motion } from "framer-motion";



const ChangePasswordPage = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [confirmNewPasswordErrorMessage, setConfirmNewPasswordErrorMessage] = useState("");
  const [nonFieldError, setNonFieldError] = useState("");
  const navigate = useNavigate();

  const handleButtonClicked = (event) => {
    event.preventDefault();
    const data = {
      old_password: oldPassword,
      new_password1: newPassword,
      new_password2: confirmNewPassword,
    };
    console.log("Data: ", data);
    api.post("/api/auth/password/change/", data)
      .then((response) => {
        console.log(response);
        Notify({ message: "Password changed successfully", type: "success" });
        navigate("/account");
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.old_password) {
          Notify({ message: error.response.data.old_password, type: "error" });
          setOldPasswordError(true);
          setOldPasswordErrorMessage(error.response.data.old_password);
        }
        if (error.response.data.new_password1) {
          Notify({ message: error.response.data.new_password1, type: "error" });
          setNewPasswordError(true);
          setNewPasswordErrorMessage(error.response.data.new_password1);
        }
        if (error.response.data.new_password2) {
          Notify({ message: error.response.data.new_password2, type: "error" });
          setConfirmNewPasswordError(true);
          setConfirmNewPasswordErrorMessage(error.response.data.new_password2);
        }
        if (error.response.data.non_field_errors) {
          Notify({ message: error.response.data.non_field_errors, type: "error" });
          setOldPasswordError(true);
          setNewPasswordError(true);
          setConfirmNewPasswordError(true);
          setNonFieldError(error.response.data.non_field_errors);
        }
      });
  }


  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  




  return (
    <motion.section 
      className="min-h-screen bg-light dark:bg-dark" 
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="absolute left-6 top-6 dark:text-white cursor-pointer"
          onClick={() => navigate("/account")}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="h-6 w-6" />
        </motion.button>

        <motion.div variants={itemVariants} className="mb-8 flex flex-row items-center">
            <BrandLogo className="w-16" />
            <span className="text-4xl font-bold ml-3 tracking-wide dark:text-dark">Study Buddy</span>
          </motion.div>

        <motion.div variants={itemVariants} className="w-full bg-white rounded-xl shadow-lg dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="font-bold text-gray-900 dark:text-white text-center" style={{ fontSize: "28px", lineHeight: "35px" }}>
                Change password
              </h1>
            <form onSubmit={event => handleButtonClicked(event)}>
              <PasswordInputField
                password={oldPassword}
                setPassword={setOldPassword}
                passwordError={oldPasswordError}
                passwordErrorMessage={oldPasswordErrorMessage}
                placeholder="Old Password"
              />
              <div className="mt-6">
                <PasswordInputField 
                  password={newPassword}
                  setPassword={setNewPassword}
                  passwordError={newPasswordError}
                  passwordErrorMessage={newPasswordErrorMessage}
                  placeholder="New Password"
                />
              </div>
              <div className="mt-6">
                <PasswordInputField 
                  password={confirmNewPassword}
                  setPassword={setConfirmNewPassword}
                  passwordError={confirmNewPasswordError}
                  passwordErrorMessage={confirmNewPasswordErrorMessage}
                  placeholder="Confirm New Password"
                />
              </div>
              {nonFieldError && <div className="text-red-500 text-sm font-bold mt-4">{nonFieldError}</div>}
              <button
                type="submit"
                className="w-full text-white transition duration-300 ease-in-out transform
                 bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                style={{ marginTop: 40 }}
              >
                Change password
              </button>
            </form>
          </div>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default ChangePasswordPage;
