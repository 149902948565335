import WarningTriangle from "./../components/SVG/WarningTriangle";
import Modal from "./Modals/Modal";

const ConfirmDeletionModal = ({ showModal, setShowModal, handleDelete, header, subHeader, text, buttonLabel }) => {
  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)} >
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg dark:shadow-none max-w-lg w-full m-6">
        <div className="p-8">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center bg-red-100 rounded-full" style={{ padding: 13 }}>
              <WarningTriangle className="w-9 h-9 text-red-600" />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center mt-4 px-8">
            <h1 className="text-2xl font-bold dark:text-dark">{header}</h1>
            {subHeader && <h2 className="text-xl font-semibold mt-2 dark:text-dark">{subHeader}</h2>}
            <p className="text-lg font-medium text-gray-600 mt-4 text-center dark:text-gray-400">{text}</p>
          </div>


          <div className="flex justify-between space-x-4 mt-8">
          <button
            onClick={() => setShowModal(false)}
            className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-600 dark:hover:border-gray-500 w-full hover:border-gray-400 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition dark:text-dark font-medium"
          >
            Cancel
          </button>

            <button
              onClick={() => {
                handleDelete();
                setShowModal(false);
              }}
              className="px-6 py-2 bg-gradient-to-t from-red-600 to-red-500 text-white rounded-lg hover:from-red-700 hover:to-red-600 transition font-medium w-full"
            >
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeletionModal;
