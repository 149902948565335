import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import ConfirmDeletionModal from '../ConfirmDeletionModal';
import { Notify } from './../../providers/NotificationProvider';
import { motion, AnimatePresence } from 'framer-motion';
import useDarkMode from '../../hooks/useDarkMode';


const RecentQuizList = ({ data, setData, questionType }) => {
  const scrollContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [itemWidth, setItemWidth] = useState(300);
  const [minItemWidth, setMinItemWidth] = useState(300);
  const [showButtons, setShowButtons] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [visibleIndexes, setVisibleIndexes] = useState({ first: 0, last: 0 });
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [deleteDraftUuid, setDeleteDraftUuid] = useState(null);
  const [deletionModalType, setDeletionModalType] = useState(null);
  const [deleteSetUuid, setDeleteSetUuid] = useState(null); 

  const isDarkMode = useDarkMode();

  const navigate = useNavigate();


  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    const item = container.children[0];
    if (!item) return;
    const itemWidth = item.offsetWidth;

    const scrollDistance = (itemWidth) * (direction === 'left' ? -1 : 1);
  
    container.scrollBy({
      left: scrollDistance,
      behavior: 'smooth',
    });
    updateVisibleIndexes();
    updateScrollButtons();
  };



  const updateScrollButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {

      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(container.scrollLeft < container.scrollWidth - container.clientWidth);
    }
  };

  const updateDimensions = () => {
    const width = window.innerWidth;
    const minItemWidth = width >= 768 ? 450 : 300;
    setMinItemWidth(minItemWidth);
    
    
    const container = scrollContainerRef.current;
    if (container) {
      const width = container.clientWidth;
      setContainerWidth(width);
      const itemsThatFit = Math.floor(width / minItemWidth);
      const dynamicItemWidth = width / itemsThatFit;
      setItemWidth(dynamicItemWidth);

      const contentOverflow = container.scrollWidth > container.clientWidth;
      setIsScrollable(contentOverflow);

      updateScrollButtons();
      updateVisibleIndexes();
    }
  };




  
  
  


  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', updateScrollButtons);
      container.addEventListener('scroll', updateVisibleIndexes);
      window.addEventListener('resize', updateDimensions);
      updateDimensions();
      updateScrollButtons(); 
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', updateScrollButtons);
        container.removeEventListener('scroll', updateVisibleIndexes);
      }
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  

  const updateVisibleIndexes = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const children = Array.from(container.children);
    const visibleWidth = container.offsetWidth;
    let firstVisibleIndex = -1;
    let lastVisibleIndex = -1;

    children.forEach((child, index) => {
      const childLeft = child.offsetLeft - container.scrollLeft;
      const childRight = childLeft + child.offsetWidth;

      if (childLeft < visibleWidth && childRight > 0) {
        lastVisibleIndex = index;
        if (firstVisibleIndex === -1) {
          firstVisibleIndex = index;
        }
      }

      if ((childLeft === 0 && childLeft < visibleWidth) || (childRight === 0 && childRight < visibleWidth) || childRight === -1 || childRight === 1 || childLeft === 1 || childLeft === -1 ) {
        firstVisibleIndex = index;
      }
    });

    setVisibleIndexes({ first: firstVisibleIndex, last: lastVisibleIndex });
  };

  useEffect(() => {
    setTimeout(() => {
      updateDimensions();
      updateScrollButtons();
      updateVisibleIndexes();
    }, 1);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updateVisibleIndexes();
    }, 1);
  }, [containerWidth])
  

  const handleBoxClicked = (index) => {
    if (questionType === "flashcards") {
      if (data[index].type === "draft") {
        navigate(`/flashcard/input/${data[index].uuid}`);
      } else {
        navigate(`/flashcard/preview/${data[index].uuid}`);
      }
    } else if (data[index].has_started === true) {
      questionType === "grammar" ? navigate(`/grammar/questions/${data[index].uuid}`) : navigate(`/exam/questions/${data[index].uuid}`);
    } else {
      setSelectedBoxIndex(index === selectedBoxIndex ? null : index);
    }
  };


  const handleRepeatButtonClicked = (index) => {
    const url = questionType === "grammar" ? "/api/delete_grammar_fields/" : "/api/delete_summary_fields/";
    const redirectUrl = questionType === "grammar" ? "/grammar/questions/" : "/exam/questions/";
    api.put(`${url}${data[index].uuid}/`,{})
      .then(() => {
        console.log("Deleted fields");
        navigate(redirectUrl + data[index].uuid);
      })
      .catch((error) => {
        console.log("Error while deleting fields:", error);
      });
  };

  const handleDeleteDraft = () => {
    api.delete(`/api/flashcards/delete_flashcard_draft/${deleteDraftUuid}/`)
    .then((response) => {
      console.log(response.data);
      const updatedData = data.filter(item => item.uuid !== deleteDraftUuid);
      setData(updatedData); 
      Notify({message: "Draft deleted successfully!", type: "success"});

    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleDeleteSet = () => {
    api.delete((questionType === "grammar" ? "/api/grammar/delete/" : "/api/summary/delete/") + deleteSetUuid + "/")
    .then((response) => {
      console.log(response.data);
      setSelectedBoxIndex(null);
      const updatedData = data.filter(item => item.uuid !== deleteSetUuid);
      setData(updatedData);
      Notify({message: "Set deleted successfully!", type: "success"});
    })
    .catch((error) => {
      console.error(error);
    });
    
    console.log((questionType === "grammar" ? "/api/grammar/delete/" : "/api/summary/delete/") + deleteSetUuid + "/");
  }




  const buttonStyle = {
    transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    opacity: showButtons ? 1 : 0,
    visibility: showButtons ? 'visible' : 'hidden',
    zIndex: 20,
  };

  const shadowStyle = {
    position: 'absolute',
    width: '10%',
    height: '100%',
    transition: 'opacity 0.3s ease-in-out',
    opacity: showButtons ? 1 : 0,
    zIndex: 10,
  };

  const scrollContainerStyle = {
    paddingBottom: '26px',
    paddingTop: '26px',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::WebkitScrollbar': {
      display: 'none',
    },
  };



  const deleteButtonVariants = {
    hidden: { x: 20, opacity: 0 },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  const progressBarVariants = {
    hidden: { width: 0 },
    show: width => ({
      width: `${width}%`,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    })
  };



  return (
    <div
      className="relative w-full flex items-center overflow-auto"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <ConfirmDeletionModal showModal={showConfirmDeletionModal} setShowModal={setShowConfirmDeletionModal} handleDelete={deletionModalType === "set" ? handleDeleteSet : handleDeleteDraft} header={deletionModalType === "set" ? "Delete Set" : "Delete Draft"} text={deletionModalType === "set" ? "Are you sure you want to delete the set? This action cannot be undone." : "Are you sure you want to delete the draft? This action cannot be undone."} buttonLabel={"Delete"} />

      {isScrollable && canScrollLeft && (
        <>
          <div
            style={{
              ...shadowStyle,
              left: 0,
              background: isDarkMode ? 'linear-gradient(to right, rgba(17, 24, 39, 1) 15%, transparent 70%)' : 'linear-gradient(to right, rgba(246, 247, 251, 1) 15%, transparent 70%)',
            }}
          ></div>
          <div className="absolute left-0 flex items-center justify-center z-20">
            <button
              onClick={() => handleScroll('left')}
              style={buttonStyle}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 shadow-md dark:shadow-none"
            >
              <FontAwesomeIcon icon={faChevronLeft} className='text-lg' />
            </button>
          </div>
        </>
      )}


      <div
        className="flex overflow-auto scroll-smooth snap-x snap-mandatory w-full"
        ref={scrollContainerRef}
        style={scrollContainerStyle}
      >
        {data.map((set, index) => (
          <div
            key={set.id || index}
            className="flex-shrink-0 snap-start"
            style={{ 
              minWidth: minItemWidth, 
              width: itemWidth, 
              paddingLeft: index === visibleIndexes.first ? '14px' : '14px', 
              paddingRight: index === visibleIndexes.last ? '14px' : '14px'
            }}
          >
            <div
              className={`transition duration-300 ease-in-out transform hover:scale-104 p-6 rounded-xl bg-white dark:bg-gray-800 cursor-pointer dark:shadow-none shadow-gray-300 shadow-color-lg ${visibleIndexes.first <= index && index <= visibleIndexes.last && ""}`} onClick={() => handleBoxClicked(index)}
            >
              {set.type === "draft" ? (
                <div className='flex justify-start space-x-2'>
                  <p className="font-semibold text-xl mb-4 dark:text-dark">
                  {set.title || "Untitled"}
                  </p>
                  <span className="text-blue-600 dark:text-blue-500 font-semibold text-xl">(Draft)</span>
                </div>
              ) : (
                <div className='flex flex-row justify-between items-center mb-4'>
                  <h3 className="font-semibold text-xl items-center dark:text-dark">{set.title}</h3>
                  <AnimatePresence>
                    {selectedBoxIndex === index  && (questionType === "exam" || questionType === "grammar") && (
                      <motion.button 
                        className="text-red-500 hover:text-red-700 text-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeletionModalType("set");
                          setDeleteSetUuid(set.uuid);
                          setShowConfirmDeletionModal(true);
                        }}
                        variants={deleteButtonVariants}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <FontAwesomeIcon icon={faTrash} /> 
                        <span className='ml-2 font-semibold'>Delete</span>
                      </motion.button>
                    )}
                  </AnimatePresence>  

                </div>
              )}
              <div className="rounded-full bg-gray-200 dark:bg-gray-700 dark:text-dark px-3 py-1 text-sm font-medium inline-block">
                {set.type !== "draft" ? (questionType === "flashcards" ? `Terms: ${set.number_of_flashcards}` : `Questions: ${set.number_of_questions}`) : `Terms: ${set.words.length}`}
              </div>
              {set.type === "draft" ? (
                <div className='w-full flex justify-end'>
                  <button 
                    className="mt-9 flex justify-end items-center font-medium text-red-500 hover:text-red-700 text-lg" 
                    onClick={(e) => {
                      console.log('Delete button clicked');
                      e.stopPropagation();
                      setDeletionModalType("draft");
                      setDeleteDraftUuid(set.uuid);
                      setShowConfirmDeletionModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} className='mr-2.5' />
                    <p>Delete Draft</p>
                  </button>
                </div>
              ) : (
                <>
                  <p className="font-medium text-sm mt-4 dark:text-dark">{set.performance}% learned</p>
                  <div className="h-5 mt-2 bg-gray-200 dark:bg-gray-700 rounded-full w-full">
                    <motion.div
                      className="bg-green-400 dark:bg-green-500 h-5 rounded-full"
                      initial="hidden"
                      animate="show"
                      variants={progressBarVariants}
                      custom={set.performance}
                    />
                  </div>
                </>
              )}
              <AnimatePresence>
                {selectedBoxIndex === index && (
                  <motion.div 
                    className="flex justify-between gap-3.5 w-full"
                    layout
                    initial={{ opacity: 0, height: 0, marginTop: 0 }}
                    animate={{
                      opacity: 1,
                      height: "auto",
                      marginTop: "24px",
                    }}
                    exit={{ opacity: 0, height: 0, marginTop: 0 }}
                    transition={{
                      layout: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
                      duration: 0.3,
                      ease: [0.4, 0, 0.2, 1],
                    }}
                  >
                    <button
                      className="flex-grow p-2.5 rounded-lg text-white bg-blue-600 hover:bg-blue-700 text-center font-medium w-1/2"
                      onClick={() => handleRepeatButtonClicked(index)}
                    >
                      Repeat {questionType === "grammar" ? "exercises" : "exam"}
                    </button>
                    <button
                      className="flex-grow p-2.5 rounded-lg text-white bg-blue-600 hover:bg-blue-700 text-center font-medium w-1/2"
                      onClick={() =>
                        navigate((questionType === "grammar" ? "/grammar/recap/" : "/exam/recap/") + set.uuid)
                      }
                    >
                      Show recap
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        ))}
      </div>



      {isScrollable && canScrollRight && (
        <>
          <div
            style={{
              ...shadowStyle,
              right: 0,
              background: isDarkMode ? 'linear-gradient(to left, rgba(17, 24, 39, 1) 15%, transparent 70%)' : 'linear-gradient(to left, rgba(246, 247, 251, 1) 15%, transparent 70%)',
            }}
          ></div>
          <div className="absolute right-0 flex items-center justify-center z-20">
            <button
              onClick={() => handleScroll('right')}
              style={buttonStyle}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 shadow-md dark:shadow-none"
            >
              <FontAwesomeIcon icon={faChevronRight} className='text-lg' />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default RecentQuizList;


