import { useEffect } from "react";

const GrammarInputComponent = ({
  title,
  value,
  setValue,
  placeholder,
  inputRef,
  error,
  setError,
}) => {

  useEffect(() => {
    if (error && value.trim() !== "") {
      setError(false);
    }
  }, [error, setError, value]);


  return (
    <div>
      <h2 className="text-2xl font-semibold mb-2 dark:text-dark">
        {title}
      </h2>
      <input
        className={`w-full border-b-2  ${error ? "border-red-500": "dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500 outline-none"} rounded-lg p-2.5 mb-1.5 shadow-lg dark:shadow-none dark:bg-gray-800 dark:text-white focus:outline-none`}
        type={"text"}
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      {error && <p className="text-red-500 font-semibold mt-1.5">Field is required</p>}
    </div>
  );
}

export default GrammarInputComponent;
