import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import SignUpButton from "../components/LandingPage/SignUpButton";

const WordPressTest = () => {
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const res = await axios.get(
          "https://studdybuddy.roistars.tech/wp-json/wp/v2/pages?slug=grammar"
        );
        // We'll assume only one item is returned
        setPageData(res.data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPosts();
  }, []);

  return (
    <div>
      {/* 
        If the fetch is still in progress, pageData might be null.
        Make sure to conditionally render once it is set.
      */}

      <motion.nav
        className="fixed w-full top-0 left-0 border-b border-gray-200 shadow-sm bg-white/65"
        style={{
          //backgroundColor: isDarkMode ? "rgba(19, 24, 57, 0.75)" : "rgba(255, 255, 255, 0.75)",
          backdropFilter: "blur(15px)",
          zIndex: 999,
        }}
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
      >
        {console.log("Returning the !authenticated")}
        <div className="container mx-auto flex items-center justify-between py-1.5 px-6 max-w-[1200px]">

          <div
            className="flex-shrink-0 flex items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            {/* <BrandLogo width="60px" height="60px" /> */}
            <span className="hidden md:inline text-2xl font-bold ml-4">
              Study Buddy
            </span>
          </div>
          <div className=" flex flex-row items-center justify-end">
            <button 
              className="hidden sm:inline mr-2.5 px-4 py-1 rounded-lg font-medium text-blue-700 hover:text-blue-800 hover:bg-blue-100 text-lg"
              onClick={() => navigate("/login")}
            >
              Sign In
            </button>
            <SignUpButton />
          </div>
        </div>
      </motion.nav>
      <iframe
    // The direct URL of the page on your WordPress site
    src="https://studdybuddy.roistars.tech/grammar"
    
    // Some basic styling so it fills the page (customize as needed)
    style={{ 
      width: "100%",
      height: "90vh",   // Full viewport height
      border: "none" 
    }}
    title="WordPress Grammar Page"
  />

    </div>
  );
};

export default WordPressTest;
