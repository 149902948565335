import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import WarningTriangle from "../components/SVG/WarningTriangle";
import { motion } from "framer-motion";

const PageNotFound = () => {
  const navigate = useNavigate();

  const arrowRotateVariants = {
    initial: { rotate: 0 },
    hover: { 
      rotate: -45,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  return (
    <div className="flex items-center justify-center flex-col bg-light dark:bg-dark" style={{ height: `${ window.innerHeight - 77 }px`}} >
      <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg w-full max-w-md text-center">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center bg-red-100 dark:bg-gray-700 rounded-full p-5">
            <WarningTriangle className="w-12 h-12 text-red-600" />
          </div>
        </div>
        <h1 className="text-4xl font-bold dark:text-dark mb-3 mt-4">404</h1>
        <p className="text-xl text-gray-700 dark:text-gray-400 mb-10">
          The page you are looking for doesn't exist.
        </p>
        <motion.button
          onClick={() => navigate("/")}
          className="flex items-center justify-center w-full gap-2 px-8 py-2 bg-gradient-to-t from-blue-600 to-blue-500 text-white text-xl rounded-lg hover:from-blue-700 hover:to-blue-600 transition font-medium"
          initial="initial"
          whileHover="hover"
        >
          Go Back Home
          <motion.div variants={arrowRotateVariants}>
            <FontAwesomeIcon icon={faArrowRight} />
          </motion.div>
        </motion.button>
      </div>
    </div>
  );
};

export default PageNotFound;


