import Lottie from "react-lottie";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import DocumentAnimationData from "../../Assets/Lottie/document_animation.json";
import OptionAnimationData from "../../Assets/Lottie/option_animation.json";
import DifficultyLevelAnimationData from "../../Assets/Lottie/difficulty_level_animation.json"
import AnimatedSparkle from "../SVG/AnimatedSparkle";


const FlowChartIcon = () => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { 
    once: true,
    margin: "0px 0px -100px 0px"
  });

  const LOTTIE_CONFIG = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  const ANIMATIONS = {
    document: DocumentAnimationData,
    option: OptionAnimationData,
    difficulty: DifficultyLevelAnimationData
  };
  
  const createLottieOptions = (animationKey) => ({
    ...LOTTIE_CONFIG,
    animationData: ANIMATIONS[animationKey]
  });
  
  const documentOptions = createLottieOptions('document');
  const optionOptions = createLottieOptions('option');
  const difficultyOptions = createLottieOptions('difficulty');

  // Animation variants for the boxes
  const boxVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: { 
      scale: 1, 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const pathVariants = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: { 
        duration: 2, // Increase duration for smoother animation
        ease: "easeInOut",
      }
    }
  };

  const pathVariantsVertical = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: { 
        duration: 1, // Increase duration for smoother animation
        ease: "easeInOut",
      }
    }
  };

  return (
    <div className="flex flex-col items-center" ref={containerRef}>

      {/* This is the desktop version */}
      <div className="mb-4 hidden lg:block">
        <motion.div 
          className="absolute left-[80.2px] w-[70px] h-[70px] rounded-2xl border-4 border-[#2196F3] bg-white flex items-center justify-center"
          variants={boxVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ delay: 0.5 }}
        >
          <Lottie options={documentOptions} height={55} width={55} />
        </motion.div>

        <motion.div 
          className="absolute left-[247px] w-[70px] h-[70px] rounded-2xl border-4 border-[#2196F3] bg-white flex items-center justify-center"
          variants={boxVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ delay: 0.7 }}
        >
          <Lottie options={optionOptions} height={55} width={55} />
        </motion.div>

        <motion.div 
          className="absolute left-[412px] w-[70px] h-[70px] rounded-2xl border-4 border-[#2196F3] bg-white flex items-center justify-center"
          variants={boxVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ delay: 0.9 }}
        >
          <Lottie options={difficultyOptions} height={55} width={55} />
        </motion.div>
      </div>




      {/* This is the mobile version */}
      <div className="mb-3 lg:hidden">
        <motion.div 
          className="absolute left-[20px] w-[55px] h-[55px] rounded-2xl border-4 border-[#2196F3] bg-white flex items-center justify-center"
          variants={boxVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ delay: 0.5 }}
        >
          <Lottie options={documentOptions} height={45} width={45} />
        </motion.div>

        <motion.div 
          className="absolute left-[144px] w-[55px] h-[55px] rounded-2xl border-4 border-[#2196F3] bg-white flex items-center justify-center"
          variants={boxVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ delay: 0.7 }}
        >
          <Lottie options={optionOptions} height={45} width={45} />
        </motion.div>

        <motion.div 
          className="absolute left-[267px] w-[60px] h-[60px] rounded-2xl border-4 border-[#2196F3] bg-white flex items-center justify-center"
          variants={boxVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ delay: 0.9 }}
        >
          <Lottie options={difficultyOptions} height={45} width={45} />
        </motion.div>
      </div>


      {/* This is the desktop version of the flowchart */}
      <div className="hidden lg:block">
        <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 14" className="w-[420px] h-[170px]">
          <defs>
            <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#4B83FF" />
              <stop offset="100%" stopColor="#E0EDFF" />
            </linearGradient>
            <linearGradient id="verticalGradient" gradientUnits="userSpaceOnUse" x1="40.746" y1="20.369" x2="40.746" y2="0.266">
              <stop offset="0%" stopColor="#0174E9" />
              <stop offset="100%" stopColor="#53AFE5" />
            </linearGradient>
          </defs>
          <motion.path
            d="m37.261 10.583c2.0297 0 3.4852 1.8878 3.4852 3.8859 0 2.0508 1.4e-4 4.1016 2.2e-4 6.1524m-10.319-10.038h6.8336m-30.381-3.4852c0 2.0426 1.882 3.4851 3.8859 3.4851h1.3406m-5.2266-10.319v6.8336m5.2266 3.4852h18.321"
            fill="none"
            stroke="#2196F3"
            strokeLinecap="round"
            strokeWidth="1.5px"
            variants={pathVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
          />
          <motion.path
            d="m44.231 10.583c-2.0297 0 -3.4852 1.8878 -3.4852 3.8859 0 2.0508 -1.4e-4 4.1016 -2.2e-4 6.1524m10.319-10.038h-6.8336m30.381-3.4852c0 2.0426 -1.882 3.4851 -3.8859 3.4851h-1.3406m5.2266-10.319v6.8336m-5.2266 3.4852h-18.321"
            fill="none"
            stroke="#2196F3"
            strokeLinecap="round"
            strokeWidth="1.5px"
            variants={pathVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            transition={{ delay: 0.3 }}
          />
          <motion.path
            d="m40.746 0.266v20.369"
            fill="none"
            stroke="#2196F3"
            strokeLinecap="round"
            strokeWidth="1.5px"
            variants={pathVariantsVertical}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            transition={{ delay: 0.6 }}
          />
        </motion.svg>
      </div>


      {/* This is the mobile version of the flowchart */}

      <div className="lg:hidden">
        <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 14" className="w-[315px] h-[127.5px]">
          <defs>
            <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#4B83FF" />
              <stop offset="100%" stopColor="#E0EDFF" />
            </linearGradient>
            <linearGradient id="verticalGradient" gradientUnits="userSpaceOnUse" x1="40.746" y1="20.369" x2="40.746" y2="0.266">
              <stop offset="0%" stopColor="#0174E9" />
              <stop offset="100%" stopColor="#53AFE5" />
            </linearGradient>
          </defs>
          <motion.path
            d="m37.261 10.583c2.0297 0 3.4852 1.8878 3.4852 3.8859 0 2.0508 1.4e-4 4.1016 2.2e-4 6.1524m-10.319-10.038h6.8336m-30.381-3.4852c0 2.0426 1.882 3.4851 3.8859 3.4851h1.3406m-5.2266-10.319v6.8336m5.2266 3.4852h18.321"
            fill="none"
            stroke="#2196F3"
            strokeLinecap="round"
            strokeWidth="1.5px"
            variants={pathVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
          />
          <motion.path
            d="m44.231 10.583c-2.0297 0 -3.4852 1.8878 -3.4852 3.8859 0 2.0508 -1.4e-4 4.1016 -2.2e-4 6.1524m10.319-10.038h-6.8336m30.381-3.4852c0 2.0426 -1.882 3.4851 -3.8859 3.4851h-1.3406m5.2266-10.319v6.8336m-5.2266 3.4852h-18.321"
            fill="none"
            stroke="#2196F3"
            strokeLinecap="round"
            strokeWidth="1.5px"
            variants={pathVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            transition={{ delay: 0.3 }}
          />
          <motion.path
            d="m40.746 0.266v20.369"
            fill="none"
            stroke="#2196F3"
            strokeLinecap="round"
            strokeWidth="1.5px"
            variants={pathVariantsVertical}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            transition={{ delay: 0.6 }}
          />
        </motion.svg>
      </div>



      {/* This is the desktop version */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: 1.2 }}
        className="mr-6 hidden lg:block"
      >
        <AnimatedSparkle />
      </motion.div>

      {/* This is the mobile version */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: 1.2 }}
        className="mr-4 lg:hidden"
      >
        <AnimatedSparkle width="58" height="58" />
      </motion.div>


    </div>
  );
};

export default FlowChartIcon;


// This is the code where there is like that animation of the flowchart i would like to get in teh future
// This is the pipe animation of the flowchart:
{/* <div
  style={{
    position: "relative",
    width: "200px",
    height: "12px", // Made thicker
    backgroundColor: "#E2E8F0",
    overflow: "hidden",
    borderRadius: "6px",
  }}
>
  <motion.div
    style={{
      position: "absolute",
      width: "40px", // Made moving part longer
      height: "12px",
      backgroundImage:
        "linear-gradient(rgb(224, 237, 255), rgb(75, 131, 255) 85%, rgb(224, 237, 255))",
      borderRadius: "6px",
    }}
    animate={{
      x: ["-40px", "200px"], // Adjusted start position for longer width
    }}
    transition={{
      duration: 1.5, // Faster movement
      repeat: Infinity,
      repeatDelay: 1, // Added 1 second delay between animations
      ease: "easeInOut",
    }}
  />
</div> */}
