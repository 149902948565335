import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Notify } from "../providers/NotificationProvider";
import PasswordInputField from "../components/Authentication/PasswordInputField";
import BrandLogo from "../components/SVG/BrandLogo";
import { motion } from "framer-motion";


const PasswordResetConfirm = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password1Error, setPassword1Error] = useState(false);
  const [password1ErrorMessage, setPassword1ErrorMessage] = useState("");
  const [password2Error, setPassword2Error] = useState(false);
  const [password2ErrorMessage, setPassword2ErrorMessage] = useState("");
  const [nonFieldError, setNonFieldError] = useState("");
  const navigate = useNavigate();
  const { uidb64, token } = useParams();

  const handleSubmitButtonClicked = (event) => {
    event.preventDefault();
    const data = {
      uid: uidb64,
      token: token,
      new_password1: password1,
      new_password2: password2,
    };

    axios.post("/api/auth/password/reset/confirm/", data)
      .then((response) => {
        console.log(response.data);
        Notify({ message: "Password reset successfully", type: "success" });
        navigate("/login")
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.new_password1) {
          Notify({ message: error.response.data.new_password1, type: "error" });
          setPassword1Error(true);
          setPassword1ErrorMessage(error.response.data.new_password1);
        }
        if (error.response.data.new_password2) {
          Notify({ message: error.response.data.new_password2, type: "error" });
          setPassword2Error(true);
          setPassword2ErrorMessage(error.response.data.new_password2);
        }
        if (error.response.data.non_field_errors) {
          Notify({ message: error.response.data.non_field_errors, type: "error" });
          setPassword1Error(true);
          setPassword2Error(true);
          setNonFieldError(error.response.data.non_field_errors);
        }
      });
  };



  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };


  return (
    <motion.section 
      className="min-h-screen bg-light dark:bg-dark" 
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

        <motion.div variants={itemVariants} className="mb-8 flex flex-row items-center">
          <BrandLogo className="w-16" />
          <span className="text-4xl font-bold ml-3 tracking-wide dark:text-dark">Study Buddy</span>
        </motion.div>

        
        <motion.div variants={itemVariants} className="w-full bg-white rounded-xl shadow-lg dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="font-bold text-gray-900 dark:text-white text-center" style={{ fontSize: "28px", lineHeight: "35px" }}>
              Reset your Password
            </h1>
            <form onSubmit={(event) => handleSubmitButtonClicked(event)}>
              <PasswordInputField 
                password={password1}
                setPassword={setPassword1}
                passwordError={password1Error}
                passwordErrorMessage={password1ErrorMessage}
                placeholder="New Password"
              />
              <div className="mt-6">
                <PasswordInputField 
                  password={password2}
                  setPassword={setPassword2}
                  passwordError={password2Error}
                  passwordErrorMessage={password2ErrorMessage}
                  placeholder="Confirm Password"
                />
              </div>
              {nonFieldError && <div className="text-red-500 text-sm font-bold mt-4">{nonFieldError}</div>}
              <button
                type="submit"
                className="w-full text-white transition duration-300 ease-in-out transform
                 bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                style={{ marginTop: 40 }}
              >
                Reset password
              </button>
              
            </form>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default PasswordResetConfirm;
