import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const TitleInput = ({ value, setValue, titleRef, error, setError }) => {
  //const [error, setError] = useState(false);

  // useEffect(() => {
  //   if (submitted && value.trim() === "") {
  //     setError(true);
  //   }
  //   else {
  //     setError(false);
  //   }
  // }, [submitted, value]);


  useEffect(() => {
    if (error && value.trim() !== "") {
      setError(false);
    }
  }, [error, setError, value]);
  


  return (
    <div className="pt-10 mb-8">
      <div className="flex flex-col justify-start w-full rounded-lg shadow-lg">
        <input
          id="title" 
          autoFocus
          type="text" 
          ref={titleRef}
          className= {`rounded-md text-xl font-medium border-b-2 ${error ? "dark:shadow-none shadow-color-lg shadow-red-400 border-red-500" : "dark:border-gray-700 dark:focus:border-gray-500 focus:border-gray-400"} py-2.5 px-4 focus:outline-none outline-none dark:bg-gray-800 dark:text-white`}
          placeholder="Enter a title"
          onChange={(event) => setValue(event.target.value)}
          value={value}
        />
        
      </div>
      <AnimatePresence>
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{ duration: 0.3 }}
            className="text-red-500 mt-4 font-semibold"
          >
            Title is required
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  )

}
export default TitleInput;