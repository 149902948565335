import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import FeatureDetails from "./FeatureDetails";  
import FeatureCard from "./FeatureCard";
import Lottie from "react-lottie";
import UploadFileAnimationData from "../../Assets/Lottie/upload_file_animation.json";
import FlowChartIcon from "./FlowChartIcon";
import FlashcardFeaturePreview from "./FlashcardFeaturePreview";
import ExamFeaturePreview from "./ExamFeaturePreview";
import FlashcardWriteFeaturePreview from "./FlashcardWriteFeaturePreview";

const FeatureSection = ({ feature }) => {
  const containerRef = useRef(null);
  const [viewportHeight, setViewportHeight] = useState(0);


  useEffect(() => {
    setViewportHeight(window.innerHeight);// Before: window.innerHeight - 138
  }, []);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const stackPositionY = 0; //FIXME: this was 80 before
                            // But that caused the issue where the cards are not at teh same point as the text started at the static content
  const cardOffset = 40;
  const cardHeight = 500; //FIXME: Why is this not 500, when the card height 500 is on the desktop version


  const vh20InPixels = (window.innerHeight * 20) / 100;


  const initialCard2Y = viewportHeight - (cardHeight * 0.3)- vh20InPixels; // 0.3 is that by default the top 30% of the card is visible
  const initialCard3Y = viewportHeight + cardHeight- vh20InPixels;

  

  const card2Y = useTransform(
    scrollYProgress,
    [0, 0.4, 0.9, 1],
    [initialCard2Y, stackPositionY + cardOffset, stackPositionY + cardOffset, stackPositionY]
  );

  const card3Y = useTransform(
    scrollYProgress,
    [0, 0.8, 0.9, 1],
    [initialCard3Y, stackPositionY + (2 * cardOffset), stackPositionY + (2 * cardOffset), stackPositionY]
  );

  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  useEffect(() => {
    return scrollYProgress.onChange((v) => {
      if (v < 0.4) {
        setCurrentCardIndex(0);
      } else if (v < 0.8) {
        setCurrentCardIndex(1);
      } else {
        setCurrentCardIndex(2);
      }
    });
  }, [scrollYProgress]);

  const contents = [
    "Content for Card 1",
    "Content for Card 2",
    "Content for Card 3",
  ];

  const transition = { 
    duration: 1.2,
    ease: [0.43, 0.13, 0.23, 0.96],
    staggerChildren: 0.1,
    delay: 0.1
  };



  // FIXME: make the card scrolling a bit better it does not feel that smooth currently$

  // FIXME: Initial animation:
  // - the static content should be moving in from the left


  // FIXME: add that when the user has finished scrolling the cards to the stack, it should also finsih the whole thing and start scrolling the componet under this one upwards, and as the compontent that is under this one is scrolling upwards the card stack should compact itself as the new content is comming and should be moving upwards.l




  const LOTTIE_CONFIG = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  // Animation data map
  const ANIMATIONS = {
    upload: UploadFileAnimationData,
  };
  
  const createLottieOptions = (animationKey) => ({
    ...LOTTIE_CONFIG,
    animationData: ANIMATIONS[animationKey]
  });
  
  const uploadOptions = createLottieOptions('upload');

  const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return width;
  };
  
  const width = useWindowWidth();
  





  const renderDesktopLayout = () => {

    const featureDetails = (
      <div className="flex-1 relative">
        <div className={`sticky top-[20vh] flex justify-start items-start ${feature === "exam" ? "pr-20" : "pl-20"}`}>
          <FeatureDetails 
            title={feature === "exam" ? "Turn Notes into Custom Exams" : "Smart Flashcards Made Easy"} 
            description={feature === "exam" ? "Overcome exam stress with a feature that turns your notes into customized questions, provides instant AI feedback, and helps you track your progress—all in one seamless experience." : "Upload your notes or create flashcards manually, and let AI help you study efficiently. Share your sets, explore different study modes, and track your progress to master your material."} 
            featureLabel={feature === "exam" ? "Exam AI" : "Flashcard AI"}
          />
        </div>
      </div>
    );

    const featureStack = (
      <div className="flex-1 relative">
        <div className="sticky top-[20vh] flex justify-center items-start">
          <div className="relative w-full h-[500px] z-50">
            <motion.div
              className="absolute w-full h-[500px] border border-gray-200 rounded-3xl shadow-xl bg-white"
              style={{ y: stackPositionY }}
            >
              {feature === "exam" ? (
                <FeatureCard 
                  title={"Upload Your Study Materials"}
                  description={"Quickly add notes, summaries, or documents."}  
                  image={<Lottie options={uploadOptions} height={450} width={450} />} 
                />  
              ) : (
                <FeatureCard 
                  title={"Instant Flashcards from Your Notes"}
                  description={"Upload text, images, or documents, and AI will generate flashcards instantly."}  
                  image={<Lottie options={uploadOptions} height={450} width={450} />} 
                />  
              )
              }
            </motion.div>
            
            <motion.div
              className="absolute w-full h-[500px] border border-gray-200 rounded-3xl shadow-xl bg-white"
              initial={{ y: initialCard2Y }}
              style={{ y: viewportHeight ? card2Y : initialCard2Y }}
              transition={transition}
            >
              {feature === "exam" ? (
                <FeatureCard 
                  title={"AI-Powered Custom Exercises"}
                  description={"Combine your materials, preferences, and difficulty for a tailored exam experience."}  
                  image={<FlowChartIcon />}
                />  
              ) : (
                <FeatureCard 
                  title={"Personalized AI Flashcards"}
                  description={"Turn your notes into interactive cards and boost your recall effortlessly."}
                  image={<FlashcardFeaturePreview />}
                />  

              )}


            </motion.div>
            
            <motion.div
              className="absolute w-full h-[500px] border border-gray-200 rounded-3xl shadow-xl bg-white"
              initial={{ y: initialCard3Y }}
              style={{ y: viewportHeight ? card3Y : initialCard3Y }}
              transition={transition}
            >
              {feature === "exam" ? (
                <FeatureCard 
                  title={"Master Your Exams with Varied Question Styles"}
                  description={"Get personalized questions and detailed AI feedback to sharpen your knowledge."}
                  image={<ExamFeaturePreview />}
                />  
              ) : (
                <FeatureCard 
                  title={"Write Flashcards and Learn with AI Feedback"}
                  description={"Test your knowledge by writing answers and get instant, personalized guidance to improve."}
                  image={<FlashcardWriteFeaturePreview />}
                />  
              )}
            </motion.div>
          </div>
        </div>
      </div>
    );

    return (
      <div ref={containerRef} className="flex h-[500vh] mt-[15vh]">
        {feature === "flashcard"
          ? [featureStack, featureDetails]
          : [featureDetails, featureStack]}
      </div>
    );
  };

  const renderMobileLayout = () => {
    const details = (
      <div className="px-6 mt-32">
        <FeatureDetails
          title={
            feature === "exam"
              ? "Turn Notes into Custom Exams"
              : "Smart Flashcards Made Easy"
          }
          description={
            feature === "exam"
              ? "Overcome exam stress with a feature that turns your notes into customized questions, provides instant AI feedback, and helps you track your progress—all in one seamless experience."
              : "Upload your notes or create flashcards manually, and let AI help you study efficiently. Share your sets, explore different study modes, and track your progress to master your material."
          }
          featureLabel={feature === "exam" ? "Exam AI" : "Flashcard AI"}
        />
      </div>
    );


    const stack = (
      <div className="flex w-full h-[400vh] mt-24 max-w-lg mx-auto" ref={containerRef}>
        <div className="flex-1 relative">
        <div className="sticky top-[20vh] flex justify-center items-start">
          <div className="relative w-full h-[450px] z-50">
            <motion.div
              className="absolute w-full h-[450px] border border-gray-200 rounded-3xl shadow-xl bg-white"
              style={{ y: stackPositionY }}
            >
              {feature === "exam" ? (
                <FeatureCard 
                  title={"Upload Your Study Materials"}
                  description={"Quickly add notes, summaries, or documents."}  
                  image={<Lottie options={uploadOptions} height={250} width={350} />} 
                />  
              ) : (
                <FeatureCard 
                  title={"Instant Flashcards from Your Notes"}
                  description={"Upload text, images, or documents, and AI will generate flashcards instantly."}  
                  image={<Lottie options={uploadOptions} height={250} width={350} />} 
                />  
              )
              }
            </motion.div>
            
            <motion.div
              className="absolute w-full h-[450px] border border-gray-200 rounded-3xl shadow-xl bg-white"
              initial={{ y: initialCard2Y }}
              style={{ y: viewportHeight ? card2Y : initialCard2Y }}
              transition={transition}
            >
              {feature === "exam" ? (
                <FeatureCard 
                  title={"AI-Powered Custom Exercises"}
                  description={"Combine your materials, preferences, and difficulty for a tailored exam experience."}  
                  image={<FlowChartIcon />}
                />  
              ) : (
                <FeatureCard 
                  title={"Personalized AI Flashcards"}
                  description={"Turn your notes into interactive cards and boost your recall effortlessly."}
                  image={<FlashcardFeaturePreview />}
                />  

              )}


            </motion.div>
            
            <motion.div
              className="absolute w-full h-[450px] border border-gray-200 rounded-3xl shadow-xl bg-white"
              initial={{ y: initialCard3Y }}
              style={{ y: viewportHeight ? card3Y : initialCard3Y }}
              transition={transition}
            >
              {feature === "exam" ? (
                <FeatureCard 
                  title={"Exams with different question types"}
                  description={"Get personalized questions and detailed AI feedback to sharpen your knowledge."}
                  image={<ExamFeaturePreview />}
                />  
              ) : (
                <FeatureCard 
                  title={"Learn with AI-Powered Flashcards"}
                  description={"Write answers, get instant AI feedback, and improve."}
                  image={<FlashcardWriteFeaturePreview />}
                />  
              )}
            </motion.div>
          </div>
        </div>
      </div>
      </div>
    );

    return (
      <>
        {details}
        {stack}
      </>
    );
  }

  if (width < 1024) {
    return (
      <>
        {renderMobileLayout()}
      </>
    );
  } else {
    return (
      <>
        {renderDesktopLayout()}
      </>
    );
  }


};
export default FeatureSection;












// Before adding the mobile version of it

// import React, { useRef, useState, useEffect } from "react";
// import { motion, useScroll, useTransform } from "framer-motion";
// import FeatureDetails from "./FeatureDetails";  
// import FeatureCard from "./FeatureCard";
// import Lottie from "react-lottie";
// import UploadFileAnimationData from "../../Assets/Lottie/upload_file_animation.json";
// import FlowChartIcon from "./FlowChartIcon";
// import FlashcardFeaturePreview from "./FlashcardFeaturePreview";
// import ExamFeaturePreview from "./ExamFeaturePreview";
// import FlashcardWriteFeaturePreview from "./FlashcardWriteFeaturePreview";

// const FeatureSection = ({ feature }) => {
//   const containerRef = useRef(null);
//   const [viewportHeight, setViewportHeight] = useState(0);


//   useEffect(() => {
//     setViewportHeight(window.innerHeight);// Before: window.innerHeight - 138
//   }, []);

//   const { scrollYProgress } = useScroll({
//     target: containerRef,
//     offset: ["start start", "end end"],
//   });

//   const stackPositionY = 0; //FIXME: this was 80 before
//                             // But that caused the issue where the cards are not at teh same point as the text started at the static content
//   const cardOffset = 40;
//   const cardHeight = 400;


//   const vh20InPixels = (window.innerHeight * 20) / 100;


//   const initialCard2Y = viewportHeight - (cardHeight * 0.3)- vh20InPixels; // 0.3 is that by default the top 30% of the card is visible
//   const initialCard3Y = viewportHeight + cardHeight- vh20InPixels;

  

//   const card2Y = useTransform(
//     scrollYProgress,
//     [0, 0.4, 0.9, 1],
//     [initialCard2Y, stackPositionY + cardOffset, stackPositionY + cardOffset, stackPositionY]
//   );

//   const card3Y = useTransform(
//     scrollYProgress,
//     [0, 0.8, 0.9, 1],
//     [initialCard3Y, stackPositionY + (2 * cardOffset), stackPositionY + (2 * cardOffset), stackPositionY]
//   );

//   const [currentCardIndex, setCurrentCardIndex] = useState(0);

//   useEffect(() => {
//     return scrollYProgress.onChange((v) => {
//       if (v < 0.4) {
//         setCurrentCardIndex(0);
//       } else if (v < 0.8) {
//         setCurrentCardIndex(1);
//       } else {
//         setCurrentCardIndex(2);
//       }
//     });
//   }, [scrollYProgress]);

//   const contents = [
//     "Content for Card 1",
//     "Content for Card 2",
//     "Content for Card 3",
//   ];

//   const transition = { 
//     duration: 1.2,
//     ease: [0.43, 0.13, 0.23, 0.96],
//     staggerChildren: 0.1,
//     delay: 0.1
//   };



//   // FIXME: make the card scrolling a bit better it does not feel that smooth currently$

//   // FIXME: Initial animation:
//   // - the static content should be moving in from the left


//   // FIXME: add that when the user has finished scrolling the cards to the stack, it should also finsih the whole thing and start scrolling the componet under this one upwards, and as the compontent that is under this one is scrolling upwards the card stack should compact itself as the new content is comming and should be moving upwards.l




//   const LOTTIE_CONFIG = {
//     loop: true,
//     autoplay: true,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice"
//     }
//   };
  
//   // Animation data map
//   const ANIMATIONS = {
//     upload: UploadFileAnimationData,
//   };
  
//   const createLottieOptions = (animationKey) => ({
//     ...LOTTIE_CONFIG,
//     animationData: ANIMATIONS[animationKey]
//   });
  
//   const uploadOptions = createLottieOptions('upload');



//   const renderContent = () => {
//     const featureDetails = (
//       <div className="flex-1 relative">
//         <div className={`sticky top-[20vh] flex justify-start items-start ${feature === "exam" ? "pr-20" : "pl-20"}`}>
//           <FeatureDetails 
//             title={feature === "exam" ? "Turn Notes into Custom Exams" : "Smart Flashcards Made Easy"} 
//             description={feature === "exam" ? "Overcome exam stress with a feature that turns your notes into customized questions, provides instant AI feedback, and helps you track your progress—all in one seamless experience." : "Upload your notes or create flashcards manually, and let AI help you study efficiently. Share your sets, explore different study modes, and track your progress to master your material."} 
//             featureLabel={feature === "exam" ? "Exam AI" : "Flashcard AI"}
//           />
//         </div>
//       </div>
//     );

//     const featureStack = (
//       <div className="flex-1 relative">
//         <div className="sticky top-[20vh] flex justify-center items-start">
//           <div className="relative w-full h-[500px] z-50">
//             <motion.div
//               className="absolute w-full h-[500px] border border-gray-200 rounded-3xl shadow-xl bg-white"
//               style={{ y: stackPositionY }}
//             >
//               {feature === "exam" ? (
//                 <FeatureCard 
//                   title={"Upload Your Study Materials"}
//                   description={"Quickly add notes, summaries, or documents."}  
//                   image={<Lottie options={uploadOptions} height={450} width={450} />} 
//                 />  
//               ) : (
//                 <FeatureCard 
//                   title={"Instant Flashcards from Your Notes"}
//                   description={"Upload text, images, or documents, and AI will generate flashcards instantly."}  
//                   image={<Lottie options={uploadOptions} height={450} width={450} />} 
//                 />  
//               )
//               }
//             </motion.div>
            
//             <motion.div
//               className="absolute w-full h-[500px] border border-gray-200 rounded-3xl shadow-xl bg-white"
//               initial={{ y: initialCard2Y }}
//               style={{ y: viewportHeight ? card2Y : initialCard2Y }}
//               transition={transition}
//             >
//               {feature === "exam" ? (
//                 <FeatureCard 
//                   title={"AI-Powered Custom Exercises"}
//                   description={"Combine your materials, preferences, and difficulty for a tailored exam experience."}  
//                   image={<FlowChartIcon />}
//                 />  
//               ) : (
//                 <FeatureCard 
//                   title={"Personalized AI Flashcards"}
//                   description={"Turn your notes into interactive cards and boost your recall effortlessly."}
//                   image={<FlashcardFeaturePreview />}
//                 />  

//               )}


//             </motion.div>
            
//             <motion.div
//               className="absolute w-full h-[500px] border border-gray-200 rounded-3xl shadow-xl bg-white"
//               initial={{ y: initialCard3Y }}
//               style={{ y: viewportHeight ? card3Y : initialCard3Y }}
//               transition={transition}
//             >
//               {feature === "exam" ? (
//                 <FeatureCard 
//                   title={"Master Your Exams with Varied Question Styles"}
//                   description={"Get personalized questions and detailed AI feedback to sharpen your knowledge."}
//                   image={<ExamFeaturePreview />}
//                 />  
//               ) : (
//                 <FeatureCard 
//                   title={"Write Flashcards and Learn with AI Feedback"}
//                   description={"Test your knowledge by writing answers and get instant, personalized guidance to improve."}
//                   image={<FlashcardWriteFeaturePreview />}
//                 />  
//               )}
//             </motion.div>
//           </div>
//         </div>
//       </div>
//     );

//     return feature === "flashcard" ? [featureStack, featureDetails] : [featureDetails, featureStack];
//   };

//   return (
//     <div ref={containerRef} className="flex h-[500vh] mt-[15vh]">
//       {renderContent()}
//     </div>
//   );
// };
// export default FeatureSection;
