const OptionsCorrectionComponent = ({ optionsList, incorrectIndex, correctIndex, isRecapPage } ) => {
  return (
    <div>
      {optionsList.map((option, index) => (
        <div key={index} className="flex flex-col items-center">
          <label
            htmlFor={`option${index}`}
            className={`cursor-pointer select-none font-medium py-4 px-6 rounded-xl transition-colors duration-300 border-2 mt-4 w-full shadow-color-lg dark:shadow-none ${index === incorrectIndex ? 'shadow-red-300 bg-correction-red border-red-500' : (index === correctIndex ? 'bg-green-300 border-green-500 shadow-green-300' : `text-gray-400 shadow-gray-300 bg-white ${isRecapPage ? 'dark:bg-gray-700 dark:border-gray-600' : 'dark:bg-gray-800 dark:border-gray-700'}`)}`}
            style={{ maxWidth: "375px" }}
          >
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};
export default OptionsCorrectionComponent;