import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const AllBlogs = () => {
  const blogs = [
    {
      title: "Top 10 Study Tips for Visual Learners: Boost Retention and Ace Your Exams",
      description: "Unlock exam success with 10 visual learning tips! Discover how mind maps, color-coded notes, and Study Buddy AI boost retention for visual learners.",
      link: "top-10-study-tips-for-visual-learners-boost-retention-and-ace-your-exams"
    },
    {
      title: "How to Stay Focused While Studying: 7 Proven Tips to Beat Distractions",
      description: "Learn 7 proven tips to stay focused while studying and overcome distractions. Discover how Study Buddy AI’s cutting-edge tools boost your concentration and productivity, helping you ace exams with ease.",
      link: "stay-focused-while-studying-7-tips"
    },
    {
      title: "Do AI-Powered Flashcards Really Work? Yes – Here’s How Study Buddy AI Can Help!",
      description: "Transform the way you study with AI-powered flashcards from Study Buddy AI. Enhance retention, save time, and ace your exams with smart features like spaced repetition and personalized quizzes.",
      link: "ai-powered-flashcards-study-buddy-revolutionize-learning"
    }
  ]

  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>All Blogs | Study Buddy AI</title>
        <meta
          name="description"
          content="Explore our collection of blog posts covering study tips, productivity hacks, and more. Boost your exam success with Study Buddy AI."
        />
      </Helmet>
      <div className="bg-light " style={{ minHeight: `${ window.innerHeight }px`}}>
        <div className="mx-auto px-4 max-w-[1200px]">
          <h1 className="text-3xl font-semibold flex items-center py-10 pt-32">Blogs</h1>
          <div className="grid gird-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {blogs.map((blog) => (
              <motion.div
                key={blog.link}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                className="bg-white rounded-xl shadow-lg border border-gray-200"
                onClick={() => navigate(`/blog/${blog.link}`)}
              >
                <div className="px-6 py-6">
                  <h1 className="text-2xl font-semibold mb-5">{blog.title}</h1>
                  <h2 className="text-xl">{blog.description}</h2>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
};
export default AllBlogs;






// this is the old version with the medium blogs:
// import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import LoadingSpinner from "../components/General/LoadingSpinner";
// import axios from "axios";

// const AllBlogs = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const username = "dswharshit";

//   const navigate = useNavigate()


//   useEffect(() => {
//     const rssUrl = `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${username}`;
//     //const rssUrl = "https://www.googleapis.com/blogger/v3/blogs/byurl?url=http://code.blogger.com/"
//     axios.get(rssUrl)
//       .then((response) => {
//         console.log(response.data);
//         setBlogs(response.data.items);
//       })
//       .catch((error) => {
//         console.error(error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return <LoadingSpinner />
//   }


//   return (
//     <div className="bg-light" style={{ padding: "0 13vw", minHeight: `${ window.innerHeight - 77 }px`}}>
//       <h1 className="text-3xl font-semibold flex items-center py-10 pt-32">Blogs</h1>
      
//       <div className="grid gird-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
//         {blogs.map((blog) => (
//           <div key={blog.guid} className="bg-white rounded-xl shadow-lg border border-gray-200">
//             {console.log("blog: ", blog)}
//             <img src={"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*lMT_gP23ynBah_DoL9KLFg.png"} alt={blog.title} className="w-full h-48 object-cover mb-4 rounded-t-lg" />
//             <div className="px-6 pb-6">
//               <h1 className="text-2xl font-semibold mb-3">{blog.title}</h1>
//               <h2 className="text-xl">This is just a random description of the blog about something that i dont really care about. it is just a place holder for the real blog descirption</h2>


//               {/* <p dangerouslySetInnerHTML={{ __html: blog.description }} /> */}
//               {/* <a href={blog.link} target="_blank" rel="noopener noreferrer">
//                 Read more
//               </a> */}
//             </div>

//           </div>
//         ))}
//       </div>



//     </div>
//   )

// };
// export default AllBlogs;