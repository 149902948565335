import SectionLabel from "./SectionLabel";
import GetStartedButton from "./GetStartedButton";
import { motion } from "framer-motion";

const FeatureDetails = ({ title, description, featureLabel }) => {
  return (
    <>
      {/* Show this on large screens and up */}
      <motion.div 
        className="hidden lg:block w-full"
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.3, margin: "100px" }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <SectionLabel label={featureLabel} />
        <h1 className="font-semibold" style={{ fontSize: "52px" }}>{title}</h1>
        <h2 className="mt-6 text-xl text-slate-600">{description}</h2>
        <div className="mt-8">
          <GetStartedButton />
        </div>
      </motion.div>

      {/* Show this below large screens */}
      <motion.div 
        className="block lg:hidden w-full max-w-lg mx-auto"
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.3, margin: "100px" }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <SectionLabel label={featureLabel} />
        <h1 className="font-semibold text-4xl md:text-5xl lg:text-[52px]">{title}</h1>
        <h2 className="mt-6 text-lg md:text-xl text-slate-600">{description}</h2>
        <div className="mt-6 md:mt-8">
          <GetStartedButton />
        </div>
      </motion.div>
    </>
  );
};

export default FeatureDetails;