import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFileLines, faImage } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../components/General/LoadingSpinner";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
import { ReactComponent as TextIcon } from "../Assets/SVG/TextIcon.svg";
import UploadDocumentModal from "../components/Modals/UploadDocumentModal";
import UploadTextModal from "../components/Modals/UploadTextModal";
import UploadImageModal from "../components/Modals/UploadImageModal";
import LimitModal from "../components/Modals/LimitModal";
import api from "../api";
import { motion, AnimatePresence } from "framer-motion"; 
import FlashcardInputField from "../components/DefinitionQuestionComponents/FlashcardInputField";
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import AnimatedSparkle from "../components/SVG/AnimatedSparkle";
import UploadButton from "../components/Buttons/UploadButton";


const FlashcardInput = () => {
  const [flashcards, setFlashcards] = useState([{ uuid: uuidv4(), word: "", definition: "" }]);
  const [title, setTitle] = useState("");
  const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wordLimit, setWordLimit] = useState(100);
  const [errors, setErrors] = useState({ definitions: [], title: false, words: [] });
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [limitReason, setLimitReason] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [showUploadTextModal, setShowUploadTextModal] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);

  const [loadingGeneratingFlashcards, setLoadingGeneratingFlashcards] = useState(false);

  const [savingDraft, setSavingDraft] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("basic");
  const [wordFocusId, setWordFocusId] = useState(null);
  const [definitionFocusId, setDefinitionFocusId] = useState(null);

  const [isSticky, setIsSticky] = useState(false);


  const saveAbortController = useRef(null);
  const originalData = useRef({ title: "", flashcards: [] });
  const navigate = useNavigate();
  const { uuid } = useParams();

  const isStickyRef = useRef(isSticky);
  const titleInputRef = useRef(null);
  const wordInputRefs = useRef({});
  const definitionInputRefs = useRef({});


  useEffect(() => {
    isStickyRef.current = isSticky;
  }, [isSticky]);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const urls = ["/api/subscription/"];
      if (uuid) {
        urls.push(`/api/flashcards/get_flashcard_draft/${uuid}/`);
      }
      setLoading(true);
      Promise.all(urls.map(url => api.get(url)))
        .then((responses) => {
          const subscriptionData = responses[0].data;
          if (subscriptionData.plan === "max") {
            setWordLimit(5000);
            setSubscriptionPlan("max");
          } else {
            setWordLimit(100);
            setSubscriptionPlan("basic");
          }

          if (uuid && responses.length > 1) {
            const draftData = responses[1].data;
            setTitle(draftData.title);

            const loadedFlashcards = draftData.words.map((word, index) => ({
              uuid: uuidv4(),
              word: word || "",
              definition: draftData.definitions[index] || "",
            }));

            if (loadedFlashcards.length === 0) {
              loadedFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
            } else {
              const lastFlashcard = loadedFlashcards[loadedFlashcards.length - 1];
              if (lastFlashcard.word.trim() !== "" || lastFlashcard.definition.trim() !== "") {
                loadedFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
              }
            }
            console.log("setting flashcards");
            setFlashcards(loadedFlashcards);

            originalData.current = {
              title: draftData.title,
              flashcards: loadedFlashcards,
            };
          } else {
            originalData.current = { title: "", flashcards: [{ uuid: uuidv4(), word: "", definition: "" }] };
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error getting subscription data: ", error);
          setLoading(false);
        });
    }
  }, [uuid]);


  useEffect(() => {
    if (wordFocusId !== null) {
      const ref = wordInputRefs.current[wordFocusId];
      if (ref && ref.focus) {
        ref.focus();
      }
      setWordFocusId(null);
    } else if (definitionFocusId !== null) {
      const ref = definitionInputRefs.current[definitionFocusId];
      if (ref && ref.focus) {
        ref.focus();
      }
      setDefinitionFocusId(null);
    }
  }, [wordFocusId, definitionFocusId]);

  const hasChanges = useCallback((currentData) => {
    const { title, flashcards } = originalData.current;
    const originalFlashcards = originalData.current.flashcards.map(({ uuid, ...rest }) => rest);
    const currentFlashcards = currentData.flashcards.map(({ uuid, ...rest }) => rest);
    console.log("original flashcards", originalFlashcards);
    console.log("current flashcards", currentFlashcards);
    return (
      title !== currentData.title ||
      JSON.stringify(currentFlashcards) !== JSON.stringify(originalFlashcards)
    );
  }, []);

  const saveDraft = useCallback(async (data) => {
    setSavingDraft(true);

    if (saveAbortController.current) {
      saveAbortController.current.abort();
    }

    saveAbortController.current = new AbortController();

    try {
      const config = { signal: saveAbortController.current.signal };
      const payload = {
        title: data.title,
        words: data.flashcards.map(flashcard => flashcard.word),
        definitions: data.flashcards.map(flashcard => flashcard.definition),
      };

      if (uuid) {
        await api.put(`/api/flashcards/check_flashcard_draft_update/${uuid}/`, payload, config);
      } else {
        const response = await api.post("/api/flashcards/save_flashcard_draft/", payload, config);
        navigate("/flashcard/input/" + response.data.new_uuid);
      }
      console.log("Draft saved successfully");

      originalData.current = { ...data };
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Save cancelled:", error.message);
      } else {
        console.error("Error saving draft:", error);
      }
    } finally {
      setSavingDraft(false);
      saveAbortController.current = null;
    }
  }, [navigate, uuid]);

  const debouncedSaveDraft = useCallback(
    debounce((data) => {
      if (hasChanges(data)) {
        saveDraft(data);
      }
    }, 1000), // 1 second debounce delay
    [hasChanges, saveDraft]
  );

  useEffect(() => {
    const data = {
      title,
      flashcards,
    };
    debouncedSaveDraft(data);

    return () => {
      debouncedSaveDraft.cancel();
    };
  }, [title, flashcards, debouncedSaveDraft]);

  const handleBeforeUnload = useCallback((event) => {
    const data = {
      title,
      flashcards,
    };

    if (hasChanges(data)) {
      saveDraft(data);
    }
    event.preventDefault();
    event.returnValue = '';
  }, [title, flashcards, hasChanges, saveDraft]);


  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);


  const handleInputChange = (uuid, field, value) => {
    console.log("changing flashcards")
    setFlashcards((prevFlashcards) => {
      const updatedFlashcards = prevFlashcards.map((flashcard) =>
        flashcard.uuid === uuid ? { ...flashcard, [field]: value } : flashcard
      );

      // Automatically add a new flashcard if the last one is filled
      const lastFlashcard = updatedFlashcards[updatedFlashcards.length - 1];
      if (
        lastFlashcard.word.trim().length > 0 &&
        lastFlashcard.definition.trim().length > 0 &&
        updatedFlashcards.length < wordLimit
      ) {
        updatedFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
      }

      return updatedFlashcards;
    });
  };

  useEffect(() => {
    const cleanFlashcards = flashcards.filter(
      (flashcard) => flashcard.word.trim() !== "" && flashcard.definition.trim() !== ""
    );

    if (cleanFlashcards.length >= wordLimit && !showMaxFieldsMessage) {
      setLimitReason("flashcards");
      setShowLimitModal(true);
      setShowMaxFieldsMessage(true);
    } else if (cleanFlashcards.length < wordLimit) {
      setShowMaxFieldsMessage(false);
    }
  }, [flashcards, showMaxFieldsMessage, wordLimit]);

  const handleDeleteField = (uuidToRemove) => {
    if (flashcards.length > 1) {
      const newFlashcards = flashcards.filter((flashcard) => flashcard.uuid !== uuidToRemove);
      setFlashcards(newFlashcards);
      if (showMaxFieldsMessage) {
        setShowMaxFieldsMessage(false);
      }
    }
  };

  const handleSave = () => {
    setLoading(true);
    const nonEmptyFlashcards = flashcards.filter(
      (flashcard) => flashcard.word.trim() !== "" && flashcard.definition.trim() !== ""
    );
    console.log("non empty flashcards", nonEmptyFlashcards);

    const newErrors = {
      title: title.trim() === "",
      words: [],
      definitions: [],
    };

    if (newErrors.title) {
      titleInputRef.current.focus();
    }
    const lastIndex = flashcards.length - 1;
    flashcards.forEach((flashcard, index) => {
      if (flashcard.word.trim() === "" && !(index === lastIndex && flashcard.word.trim() === "" && flashcard.definition.trim() === "")) {
        newErrors.words.push(index + 1);
      }
      if ((!flashcard.definition || flashcard.definition.trim() === "") && !(index === lastIndex && flashcard.word.trim() === "" && flashcard.definition.trim() === "")) {
        newErrors.definitions.push(index + 1);
      }
    });

    setErrors(newErrors);

    if (newErrors.title || newErrors.words.length > 0 || newErrors.definitions.length > 0) {
      console.log("Errors found");
      if (newErrors.title) {
        titleInputRef.current.focus();
      } else if (newErrors.words.length > 0) {
        const firstErrorUuid = flashcards[newErrors.words[0] - 1].uuid;
        setWordFocusId(firstErrorUuid);
      } else if (newErrors.definitions.length > 0) {
        const firstErrorUuid = flashcards[newErrors.definitions[0] - 1].uuid;
        setDefinitionFocusId(firstErrorUuid);
      }
      setLoading(false);
      return;
    }

    const flashcardsData = nonEmptyFlashcards.map((flashcard, index) => ({
      word: flashcard.word,
      definition: flashcard.definition,
      position: index + 1,
    }));

    const data = {
      title: title,
      flashcards: flashcardsData,
    };

    api.post("/api/new_flashcard_set/", data)
      .then((response) => {
        if (uuid) {
          api.delete("/api/flashcards/delete_flashcard_draft/" + uuid + "/")
            .then(() => {
              console.log("Draft deleted successfully");
              navigate("/flashcard/preview/" + response.data.uuid);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error deleting draft:", error);
              setLoading(false);
            });
        } else {
          navigate("/flashcard/preview/" + response.data.uuid);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };


  const handleClearAll = () => {
    setShowMaxFieldsMessage(false);
    setErrors({ definitions: [], title: false, words: [] });
    setFlashcards([{ uuid: uuidv4(), word: "", definition: "" }]);
    setTitle("");
  }

  const nonEmptyPairCount = () => {
    return flashcards.reduce((count, flashcard) => {
      if (flashcard.word.trim() !== "" && flashcard.definition.trim() !== "") {
        count++;
      }
      return count;
    }, 0);
  };

  const processGeneratedFlashcards = (data) => {
    const newFlashcards = data.map(item => ({
      uuid: uuidv4(),
      word: item.word.trim(),
      definition: item.definition.trim()
    }));

    setFlashcards(prevFlashcards => {
      let filteredFlashcards = prevFlashcards.filter(
        (flashcard) => flashcard.word.trim() !== "" || flashcard.definition.trim() !== ""
      );

      let spaceAvailable = wordLimit - filteredFlashcards.length;
      if (spaceAvailable <= 0) {
        setShowUploadDocumentModal(false);
        setShowUploadTextModal(false);
        setShowUploadImageModal(false);
        setLoadingGeneratingFlashcards(false);
        setShowLimitModal(true);
        return filteredFlashcards;
      }

      const addableFlashcards = newFlashcards.slice(0, spaceAvailable);
      filteredFlashcards = [...filteredFlashcards, ...addableFlashcards];

      if (filteredFlashcards.length >= wordLimit) {
        setShowLimitModal(true);
      }

      if (filteredFlashcards.length < wordLimit) {
        filteredFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
      }
      setLoadingGeneratingFlashcards(false);
      setShowUploadDocumentModal(false);
      setShowUploadTextModal(false);
      setShowUploadImageModal(false);

      return filteredFlashcards;
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const shouldBeSticky = currentScrollY > 72;

      if (shouldBeSticky !== isStickyRef.current) {
        setIsSticky(shouldBeSticky);
        isStickyRef.current = shouldBeSticky;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };
  
  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20 
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };
  

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) return null;


  if (loading) {
    return (
      <div style={{padding: "0 13vw"}}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <LimitModal showModal={showLimitModal} setShowModal={setShowLimitModal} limitReason={limitReason} />
      <motion.div 
        style={{ minHeight: `${ window.innerHeight - 77 }px` }} 
        className="bg-light dark:bg-dark"
        initial="hidden"
        animate="show"
        variants={containerVariants}
      >
        {/* This deletion modal is not used anymore because the user can just clear all the field if he wants to */}
        <ConfirmDeletionModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          handleDelete={handleClearAll}
          header={"Clear all fields?"}
          text={"Are you sure you want to clear all fields? This action cannot be undone."}
          buttonLabel={"Clear all"}
        />

        <UploadDocumentModal 
          showModal={showUploadDocumentModal} 
          setShowModal={setShowUploadDocumentModal} 
          loading={loadingGeneratingFlashcards} 
          setLoading={setLoadingGeneratingFlashcards} 
          processData={processGeneratedFlashcards}
          setLimitReason={setLimitReason} 
          setShowLimitModal={setShowLimitModal} 
          subscriptionPlan={subscriptionPlan} 
          saveFlashcards={false}
          maxNumberOfFlashcards={wordLimit - nonEmptyPairCount()}
        />

        <UploadTextModal 
          showModal={showUploadTextModal} 
          setShowModal={setShowUploadTextModal} 
          loading={loadingGeneratingFlashcards} 
          setLoading={setLoadingGeneratingFlashcards} 
          processData={processGeneratedFlashcards} 
          setLimitReason={setLimitReason} 
          setShowLimitModal={setShowLimitModal} 
          subscriptionPlan={subscriptionPlan} 
          saveFlashcards={false}
          maxNumberOfFlashcards={wordLimit - nonEmptyPairCount()}
        />

        <UploadImageModal 
          showModal={showUploadImageModal} 
          setShowModal={setShowUploadImageModal} 
          loading={loadingGeneratingFlashcards} 
          setLoading={setLoadingGeneratingFlashcards} 
          processData={processGeneratedFlashcards} 
          setLimitReason={setLimitReason} 
          setShowLimitModal={setShowLimitModal} 
          saveFlashcards={false}
        />


        <motion.div className="pt-10 w-[900px] max-w-[900px] mx-auto">
          

          <motion.div variants={itemVariants} className="flex flex-col justify-start w-full bg-white rounded-lg shadow-lg">
            <input
              autoFocus={true}
              id="title"
              ref={titleInputRef}
              type="text"
              className={`rounded-md text-xl font-medium border-b-2 ${
                errors.title ? "shadow-color-lg dark:shadow-none shadow-red-400 border-red-500" : "focus:border-gray-400 dark:border-gray-700 dark:focus:border-gray-500 "
              } py-2.5 px-4 focus:outline-none outline-none dark:bg-gray-800 dark:text-dark`}
              placeholder="Enter a title"
              onChange={(event) => setTitle(event.target.value)}
              value={title}
            />
          </motion.div>
          {errors.title && <p className="text-red-500 text-sm font-semibold mt-2">Title is required</p>}


          <motion.div variants={itemVariants} className="flex flex-col justify-start mt-10">
            <div className="flex flex-row items-center ">
              <h1 className="text-3xl font-semibold dark:text-dark">Generate flashcards with AI</h1>
              <AnimatedSparkle width="48px" height="48px" className="ml-2.5" />
            </div>
            <p className="text-lg font-medium mt-1.5 mb-6 text-gray-600 dark:text-gray-300">
              Upload your materials to receive custom-made flashcards tailored to your content.
            </p>

            <div className="flex flex-row items-start justify-start gap-4">
              <UploadButton 
                onClick={(e) => { e.stopPropagation(); setShowUploadDocumentModal(true); }}
                icon={<FontAwesomeIcon icon={faFileLines} className="mr-2" />} 
                text="Upload Document"
              />
              <UploadButton
                onClick={(e) => {e.stopPropagation(); setShowUploadTextModal(true);}}
                icon={<TextIcon className="mr-2 w-4 h-4" />}
                text="Upload Text"
              />
              <UploadButton
                onClick={(e) => {e.stopPropagation(); setShowUploadImageModal(true);}}
                icon={<FontAwesomeIcon icon={faImage} className="mr-2" />}
                text="Upload Images"
              />

            </div>
          </motion.div>


          <motion.div variants={itemVariants} className="mb-6 mt-14 flex justify-between">
            <span className="font-semibold text-lg md:text-xl lg:text-2xl dark:text-dark">
              Terms in this set ({nonEmptyPairCount()})
            </span>
            <button
              className="text-red-600 font-semibold hover:bg-red-100 dark:hover:bg-red-600 dark:hover:text-white rounded-lg py-2 dark:py-1.5 px-4"
              onClick={handleClearAll}
              style={{ display: flashcards.length > 0 ? '' : 'none' }}
              disabled={flashcards.length === 0}
              tabIndex={-1}
            >
              Clear all
            </button>
          </motion.div>

          <motion.div
            layout
            className="flex flex-col gap-6 pb-32"
            variants={itemVariants}

          >
            <AnimatePresence>
              {flashcards.map((flashcard, index) => (
                <motion.div
                  key={flashcard.uuid}
                  className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg px-6 pt-6 pb-6"
                  layout
                  initial={{ opacity: 0, scale: 0.95, y: 20 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.95, y: -20 }}
                  transition={{
                    layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                    duration: 0.3,
                    ease: [0.4, 0, 0.2, 1],
                  }}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12">
                    <div>
                      <label className="mb-4 font-medium text-sm text-neutral-700 dark:text-gray-300 block">Term</label>
                      <FlashcardInputField 
                        value={flashcard.word}
                        error={errors.words.includes(index + 1)}
                        placeholder="Enter a term"
                        onChange={(event) => handleInputChange(flashcard.uuid, 'word', event.target.value)}
                        ref={el => wordInputRefs.current[flashcard.uuid] = el}
                      />
                    </div>
                    <div>
                      <label className="mb-4 font-medium text-sm text-neutral-700 dark:text-gray-300 block">
                        Definition
                      </label>
                      <FlashcardInputField 
                        value={flashcard.definition}
                        error={errors.definitions.includes(index + 1)}
                        placeholder="Enter a definition"
                        onChange={(event) => handleInputChange(flashcard.uuid, 'definition', event.target.value)}
                        ref={el => definitionInputRefs.current[flashcard.uuid] = el}
                      />
                    </div>
                  </div>

                  <AnimatePresence>
                    {flashcard.word.trim() !== "" && flashcard.definition.trim() !== "" && flashcards.length > 1 && (
                      <motion.div
                        layout
                        className="flex justify-end"
                        initial={{ opacity: 0, height: 0, marginTop: 0 }}
                        animate={{
                          opacity: 1,
                          height: "auto",
                          marginTop: "12px",
                        }}
                        exit={{ opacity: 0, height: 0, marginTop: 0 }}
                        transition={{
                          layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                          duration: 0.3,
                          ease: [0.4, 0, 0.2, 1],
                        }}
                      >
                        <motion.button
                          className="text-red-600 font-semibold hover:bg-red-100 dark:hover:bg-red-600 dark:hover:text-white rounded-lg py-2 dark:py-1.5 px-4 flex items-center"
                          onClick={() => handleDeleteField(flashcard.uuid)}
                          tabIndex={-1}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ fontSize: "16px" }}
                          />
                          <span className="ml-2">Delete</span>
                        </motion.button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))}

              {showMaxFieldsMessage && (
                <div className="text-center mt-6 flex justify-center">
                  <p className="text-lg font-semibold text-red-500 inline">
                    You've reached the maximum field limit ({wordLimit}).
                  </p>
                  <a 
                    href="/pricing" 
                    className="text-lg font-medium text-blue-600 hover:text-blue-700 dark:dark:text-blue-500 dark:hover:text-blue-600 underline-offset-1 underline ml-2"
                  >
                    Upgrade
                  </a>
                </div>
              )}

              <motion.div
                key="create-button"
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.95, y: -20 }}
                layout
                transition={{
                  layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                  duration: 0.3,
                  ease: [0.4, 0, 0.2, 1],
                }}
                className="flex justify-center mt-12"
              >
                <motion.button
                  className="px-6 py-3 w-full rounded-lg text-xl font-semibold text-white bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600 transition shadow-lg shadow-blue-500/80 dark:shadow-none"  
                  onClick={handleSave}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Create Flashcard Set
                </motion.button>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </motion.div>    
    
    </>


  );
};

export default FlashcardInput;