import { v4 as uuidv4 } from 'uuid';

const getOrCreateDeviceId = () => {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
      // Generate a new UUID if none exists
      deviceId = uuidv4();
      // Store the Device ID in localStorage
      localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
};
export default getOrCreateDeviceId;