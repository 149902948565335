import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFileLines, faImage } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../components/General/LoadingSpinner";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
import { ReactComponent as TextIcon } from "../Assets/SVG/TextIcon.svg";
import UploadDocumentModal from "../components/Modals/UploadDocumentModal";
import UploadTextModal from "../components/Modals/UploadTextModal";
import UploadImageModal from "../components/Modals/UploadImageModal";
import LimitModal from "../components/Modals/LimitModal";
import api from "../api";
import { motion, AnimatePresence } from "framer-motion"; 
import FlashcardInputField from "../components/DefinitionQuestionComponents/FlashcardInputField";
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import AnimatedSparkle from "../components/SVG/AnimatedSparkle";
import UploadButton from "../components/Buttons/UploadButton";


const FlashcardInput = () => {
  const [flashcards, setFlashcards] = useState([{ uuid: uuidv4(), word: "", definition: "" }]);
  const [title, setTitle] = useState("");
  const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wordLimit, setWordLimit] = useState(100);
  const [errors, setErrors] = useState({ definitions: [], title: false, words: [] });
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [limitReason, setLimitReason] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [showUploadTextModal, setShowUploadTextModal] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);

  const [loadingGeneratingFlashcards, setLoadingGeneratingFlashcards] = useState(false);

  const [savingDraft, setSavingDraft] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("basic");
  const [wordFocusId, setWordFocusId] = useState(null);
  const [definitionFocusId, setDefinitionFocusId] = useState(null);

  const [isSticky, setIsSticky] = useState(false);


  const saveAbortController = useRef(null);
  const originalData = useRef({ title: "", flashcards: [] });
  const navigate = useNavigate();
  const { uuid } = useParams();

  const isStickyRef = useRef(isSticky);
  const titleInputRef = useRef(null);
  const wordInputRefs = useRef({});
  const definitionInputRefs = useRef({});


  useEffect(() => {
    isStickyRef.current = isSticky;
  }, [isSticky]);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const urls = ["/api/subscription/"];
      if (uuid) {
        urls.push(`/api/flashcards/get_flashcard_draft/${uuid}/`);
      }
      setLoading(true);
      Promise.all(urls.map(url => api.get(url)))
        .then((responses) => {
          const subscriptionData = responses[0].data;
          if (subscriptionData.plan === "max") {
            setWordLimit(5000);
            setSubscriptionPlan("max");
          } else {
            setWordLimit(100);
            setSubscriptionPlan("basic");
          }

          if (uuid && responses.length > 1) {
            const draftData = responses[1].data;
            setTitle(draftData.title);

            const loadedFlashcards = draftData.words.map((word, index) => ({
              uuid: uuidv4(),
              word: word || "",
              definition: draftData.definitions[index] || "",
            }));

            if (loadedFlashcards.length === 0) {
              loadedFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
            } else {
              const lastFlashcard = loadedFlashcards[loadedFlashcards.length - 1];
              if (lastFlashcard.word.trim() !== "" || lastFlashcard.definition.trim() !== "") {
                loadedFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
              }
            }
            console.log("setting flashcards");
            setFlashcards(loadedFlashcards);

            originalData.current = {
              title: draftData.title,
              flashcards: loadedFlashcards,
            };
          } else {
            originalData.current = { title: "", flashcards: [{ uuid: uuidv4(), word: "", definition: "" }] };
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error getting subscription data: ", error);
          setLoading(false);
        });
    }
  }, [uuid]);


  useEffect(() => {
    if (wordFocusId !== null) {
      const ref = wordInputRefs.current[wordFocusId];
      if (ref && ref.focus) {
        ref.focus();
      }
      setWordFocusId(null);
    } else if (definitionFocusId !== null) {
      const ref = definitionInputRefs.current[definitionFocusId];
      if (ref && ref.focus) {
        ref.focus();
      }
      setDefinitionFocusId(null);
    }
  }, [wordFocusId, definitionFocusId]);

  const hasChanges = useCallback((currentData) => {
    const { title, flashcards } = originalData.current;
    const originalFlashcards = originalData.current.flashcards.map(({ uuid, ...rest }) => rest);
    const currentFlashcards = currentData.flashcards.map(({ uuid, ...rest }) => rest);
    console.log("original flashcards", originalFlashcards);
    console.log("current flashcards", currentFlashcards);
    return (
      title !== currentData.title ||
      JSON.stringify(currentFlashcards) !== JSON.stringify(originalFlashcards)
    );
  }, []);

  const saveDraft = useCallback(async (data) => {
    setSavingDraft(true);

    if (saveAbortController.current) {
      saveAbortController.current.abort();
    }

    saveAbortController.current = new AbortController();

    try {
      const config = { signal: saveAbortController.current.signal };
      const payload = {
        title: data.title,
        words: data.flashcards.map(flashcard => flashcard.word),
        definitions: data.flashcards.map(flashcard => flashcard.definition),
      };

      if (uuid) {
        await api.put(`/api/flashcards/check_flashcard_draft_update/${uuid}/`, payload, config);
      } else {
        const response = await api.post("/api/flashcards/save_flashcard_draft/", payload, config);
        navigate("/flashcard/input/" + response.data.new_uuid);
      }
      console.log("Draft saved successfully");

      originalData.current = { ...data };
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Save cancelled:", error.message);
      } else {
        console.error("Error saving draft:", error);
      }
    } finally {
      setSavingDraft(false);
      saveAbortController.current = null;
    }
  }, [navigate, uuid]);

  const debouncedSaveDraft = useCallback(
    debounce((data) => {
      if (hasChanges(data)) {
        saveDraft(data);
      }
    }, 1000), // 1 second debounce delay
    [hasChanges, saveDraft]
  );

  useEffect(() => {
    const data = {
      title,
      flashcards,
    };
    debouncedSaveDraft(data);

    return () => {
      debouncedSaveDraft.cancel();
    };
  }, [title, flashcards, debouncedSaveDraft]);

  const handleBeforeUnload = useCallback((event) => {
    const data = {
      title,
      flashcards,
    };

    if (hasChanges(data)) {
      saveDraft(data);
    }
    event.preventDefault();
    event.returnValue = '';
  }, [title, flashcards, hasChanges, saveDraft]);


  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);


  const handleInputChange = (uuid, field, value) => {
    console.log("changing flashcards")
    setFlashcards((prevFlashcards) => {
      const updatedFlashcards = prevFlashcards.map((flashcard) =>
        flashcard.uuid === uuid ? { ...flashcard, [field]: value } : flashcard
      );

      // Automatically add a new flashcard if the last one is filled
      const lastFlashcard = updatedFlashcards[updatedFlashcards.length - 1];
      if (
        lastFlashcard.word.trim().length > 0 &&
        lastFlashcard.definition.trim().length > 0 &&
        updatedFlashcards.length < wordLimit
      ) {
        updatedFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
      }

      return updatedFlashcards;
    });
  };

  useEffect(() => {
    const cleanFlashcards = flashcards.filter(
      (flashcard) => flashcard.word.trim() !== "" && flashcard.definition.trim() !== ""
    );

    if (cleanFlashcards.length >= wordLimit && !showMaxFieldsMessage) {
      setLimitReason("flashcards");
      setShowLimitModal(true);
      setShowMaxFieldsMessage(true);
    } else if (cleanFlashcards.length < wordLimit) {
      setShowMaxFieldsMessage(false);
    }
  }, [flashcards, showMaxFieldsMessage, wordLimit]);

  const handleDeleteField = (uuidToRemove) => {
    if (flashcards.length > 1) {
      const newFlashcards = flashcards.filter((flashcard) => flashcard.uuid !== uuidToRemove);
      setFlashcards(newFlashcards);
      if (showMaxFieldsMessage) {
        setShowMaxFieldsMessage(false);
      }
    }
  };

  const handleSave = () => {
    setLoading(true);
    const nonEmptyFlashcards = flashcards.filter(
      (flashcard) => flashcard.word.trim() !== "" && flashcard.definition.trim() !== ""
    );
    console.log("non empty flashcards", nonEmptyFlashcards);

    const newErrors = {
      title: title.trim() === "",
      words: [],
      definitions: [],
    };

    if (newErrors.title) {
      titleInputRef.current.focus();
    }
    const lastIndex = flashcards.length - 1;
    flashcards.forEach((flashcard, index) => {
      if (flashcard.word.trim() === "" && !(index === lastIndex && flashcard.word.trim() === "" && flashcard.definition.trim() === "")) {
        newErrors.words.push(index + 1);
      }
      if ((!flashcard.definition || flashcard.definition.trim() === "") && !(index === lastIndex && flashcard.word.trim() === "" && flashcard.definition.trim() === "")) {
        newErrors.definitions.push(index + 1);
      }
    });

    setErrors(newErrors);

    if (newErrors.title || newErrors.words.length > 0 || newErrors.definitions.length > 0) {
      console.log("Errors found");
      if (newErrors.title) {
        titleInputRef.current.focus();
      } else if (newErrors.words.length > 0) {
        const firstErrorUuid = flashcards[newErrors.words[0] - 1].uuid;
        setWordFocusId(firstErrorUuid);
      } else if (newErrors.definitions.length > 0) {
        const firstErrorUuid = flashcards[newErrors.definitions[0] - 1].uuid;
        setDefinitionFocusId(firstErrorUuid);
      }
      setLoading(false);
      return;
    }

    const flashcardsData = nonEmptyFlashcards.map((flashcard, index) => ({
      word: flashcard.word,
      definition: flashcard.definition,
      position: index + 1,
    }));

    const data = {
      title: title,
      flashcards: flashcardsData,
    };

    api.post("/api/new_flashcard_set/", data)
      .then((response) => {
        if (uuid) {
          api.delete("/api/flashcards/delete_flashcard_draft/" + uuid + "/")
            .then(() => {
              console.log("Draft deleted successfully");
              navigate("/flashcard/preview/" + response.data.uuid);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error deleting draft:", error);
              setLoading(false);
            });
        } else {
          navigate("/flashcard/preview/" + response.data.uuid);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };


  const handleClearAll = () => {
    setShowMaxFieldsMessage(false);
    setErrors({ definitions: [], title: false, words: [] });
    setFlashcards([{ uuid: uuidv4(), word: "", definition: "" }]);
    setTitle("");
  }

  const nonEmptyPairCount = () => {
    return flashcards.reduce((count, flashcard) => {
      if (flashcard.word.trim() !== "" && flashcard.definition.trim() !== "") {
        count++;
      }
      return count;
    }, 0);
  };

  const processGeneratedFlashcards = (data) => {
    const newFlashcards = data.map(item => ({
      uuid: uuidv4(),
      word: item.word.trim(),
      definition: item.definition.trim()
    }));

    setFlashcards(prevFlashcards => {
      let filteredFlashcards = prevFlashcards.filter(
        (flashcard) => flashcard.word.trim() !== "" || flashcard.definition.trim() !== ""
      );

      let spaceAvailable = wordLimit - filteredFlashcards.length;
      if (spaceAvailable <= 0) {
        setShowUploadDocumentModal(false);
        setShowUploadTextModal(false);
        setShowUploadImageModal(false);
        setLoadingGeneratingFlashcards(false);
        setShowLimitModal(true);
        return filteredFlashcards;
      }

      const addableFlashcards = newFlashcards.slice(0, spaceAvailable);
      filteredFlashcards = [...filteredFlashcards, ...addableFlashcards];

      if (filteredFlashcards.length >= wordLimit) {
        setShowLimitModal(true);
      }

      if (filteredFlashcards.length < wordLimit) {
        filteredFlashcards.push({ uuid: uuidv4(), word: "", definition: "" });
      }
      setLoadingGeneratingFlashcards(false);
      setShowUploadDocumentModal(false);
      setShowUploadTextModal(false);
      setShowUploadImageModal(false);

      return filteredFlashcards;
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const shouldBeSticky = currentScrollY > 72;

      if (shouldBeSticky !== isStickyRef.current) {
        setIsSticky(shouldBeSticky);
        isStickyRef.current = shouldBeSticky;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };
  
  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20 
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };
  

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) return null;


  if (loading) {
    return (
      <div style={{padding: "0 13vw"}}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <LimitModal showModal={showLimitModal} setShowModal={setShowLimitModal} limitReason={limitReason} />
      <motion.div 
        style={{ minHeight: `${ window.innerHeight - 77 }px` }} 
        className="bg-light dark:bg-dark"
        initial="hidden"
        animate="show"
        variants={containerVariants}
      >
        {/* This deletion modal is not used anymore because the user can just clear all the field if he wants to */}
        <ConfirmDeletionModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          handleDelete={handleClearAll}
          header={"Clear all fields?"}
          text={"Are you sure you want to clear all fields? This action cannot be undone."}
          buttonLabel={"Clear all"}
        />

        <UploadDocumentModal 
          showModal={showUploadDocumentModal} 
          setShowModal={setShowUploadDocumentModal} 
          loading={loadingGeneratingFlashcards} 
          setLoading={setLoadingGeneratingFlashcards} 
          processData={processGeneratedFlashcards}
          setLimitReason={setLimitReason} 
          setShowLimitModal={setShowLimitModal} 
          subscriptionPlan={subscriptionPlan} 
          saveFlashcards={false}
          maxNumberOfFlashcards={wordLimit - nonEmptyPairCount()}
        />

        <UploadTextModal 
          showModal={showUploadTextModal} 
          setShowModal={setShowUploadTextModal} 
          loading={loadingGeneratingFlashcards} 
          setLoading={setLoadingGeneratingFlashcards} 
          processData={processGeneratedFlashcards} 
          setLimitReason={setLimitReason} 
          setShowLimitModal={setShowLimitModal} 
          subscriptionPlan={subscriptionPlan} 
          saveFlashcards={false}
          maxNumberOfFlashcards={wordLimit - nonEmptyPairCount()}
        />

        <UploadImageModal 
          showModal={showUploadImageModal} 
          setShowModal={setShowUploadImageModal} 
          loading={loadingGeneratingFlashcards} 
          setLoading={setLoadingGeneratingFlashcards} 
          processData={processGeneratedFlashcards} 
          setLimitReason={setLimitReason} 
          setShowLimitModal={setShowLimitModal} 
          saveFlashcards={false}
        />


        <motion.div className="pt-10 w-[900px] max-w-[900px] mx-auto">
          

          <motion.div variants={itemVariants} className="flex flex-col justify-start w-full bg-white rounded-lg shadow-lg">
            <input
              autoFocus={true}
              id="title"
              ref={titleInputRef}
              type="text"
              className={`rounded-md text-xl font-medium border-b-2 ${
                errors.title ? "shadow-color-lg dark:shadow-none shadow-red-400 border-red-500" : "focus:border-gray-400 dark:border-gray-700 dark:focus:border-gray-500 "
              } py-2.5 px-4 focus:outline-none outline-none dark:bg-gray-800 dark:text-dark`}
              placeholder="Enter a title"
              onChange={(event) => setTitle(event.target.value)}
              value={title}
            />
          </motion.div>
          {errors.title && <p className="text-red-500 text-sm font-semibold mt-2">Title is required</p>}


          <motion.div variants={itemVariants} className="flex flex-col justify-start mt-10">
            <div className="flex flex-row items-center ">
              <h1 className="text-3xl font-semibold dark:text-dark">Generate flashcards with AI</h1>
              <AnimatedSparkle width="48px" height="48px" className="ml-2.5" />
            </div>
            <p className="text-lg font-medium mt-1.5 mb-6 text-gray-600 dark:text-gray-300">
              Upload your materials to receive custom-made flashcards tailored to your content.
            </p>

            <div className="flex flex-row items-start justify-start gap-4">
              <UploadButton 
                onClick={(e) => { e.stopPropagation(); setShowUploadDocumentModal(true); }}
                icon={<FontAwesomeIcon icon={faFileLines} className="mr-2" />} 
                text="Upload Document"
              />
              <UploadButton
                onClick={(e) => {e.stopPropagation(); setShowUploadTextModal(true);}}
                icon={<TextIcon className="mr-2 w-4 h-4" />}
                text="Upload Text"
              />
              <UploadButton
                onClick={(e) => {e.stopPropagation(); setShowUploadImageModal(true);}}
                icon={<FontAwesomeIcon icon={faImage} className="mr-2" />}
                text="Upload Images"
              />

            </div>
          </motion.div>


          <motion.div variants={itemVariants} className="mb-6 mt-14 flex justify-between">
            <span className="font-semibold text-lg md:text-xl lg:text-2xl dark:text-dark">
              Terms in this set ({nonEmptyPairCount()})
            </span>
            <button
              className="text-red-600 font-semibold hover:bg-red-100 dark:hover:bg-red-600 dark:hover:text-white rounded-lg py-2 dark:py-1.5 px-4"
              onClick={handleClearAll}
              style={{ display: flashcards.length > 0 ? '' : 'none' }}
              disabled={flashcards.length === 0}
              tabIndex={-1}
            >
              Clear all
            </button>
          </motion.div>

          <motion.div
            layout
            className="flex flex-col gap-6 pb-32"
            variants={itemVariants}

          >
            <AnimatePresence>
              {flashcards.map((flashcard, index) => (
                <motion.div
                  key={flashcard.uuid}
                  className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg px-6 pt-6 pb-6"
                  layout
                  initial={{ opacity: 0, scale: 0.95, y: 20 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.95, y: -20 }}
                  transition={{
                    layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                    duration: 0.3,
                    ease: [0.4, 0, 0.2, 1],
                  }}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12">
                    <div>
                      <label className="mb-4 font-medium text-sm text-neutral-700 dark:text-gray-300 block">Term</label>
                      <FlashcardInputField 
                        value={flashcard.word}
                        error={errors.words.includes(index + 1)}
                        placeholder="Enter a term"
                        onChange={(event) => handleInputChange(flashcard.uuid, 'word', event.target.value)}
                        ref={el => wordInputRefs.current[flashcard.uuid] = el}
                      />
                    </div>
                    <div>
                      <label className="mb-4 font-medium text-sm text-neutral-700 dark:text-gray-300 block">
                        Definition
                      </label>
                      <FlashcardInputField 
                        value={flashcard.definition}
                        error={errors.definitions.includes(index + 1)}
                        placeholder="Enter a definition"
                        onChange={(event) => handleInputChange(flashcard.uuid, 'definition', event.target.value)}
                        ref={el => definitionInputRefs.current[flashcard.uuid] = el}
                      />
                    </div>
                  </div>

                  <AnimatePresence>
                    {flashcard.word.trim() !== "" && flashcard.definition.trim() !== "" && flashcards.length > 1 && (
                      <motion.div
                        layout
                        className="flex justify-end"
                        initial={{ opacity: 0, height: 0, marginTop: 0 }}
                        animate={{
                          opacity: 1,
                          height: "auto",
                          marginTop: "12px",
                        }}
                        exit={{ opacity: 0, height: 0, marginTop: 0 }}
                        transition={{
                          layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                          duration: 0.3,
                          ease: [0.4, 0, 0.2, 1],
                        }}
                      >
                        <motion.button
                          className="text-red-600 font-semibold hover:bg-red-100 dark:hover:bg-red-600 dark:hover:text-white rounded-lg py-2 dark:py-1.5 px-4 flex items-center"
                          onClick={() => handleDeleteField(flashcard.uuid)}
                          tabIndex={-1}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ fontSize: "16px" }}
                          />
                          <span className="ml-2">Delete</span>
                        </motion.button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))}

              {showMaxFieldsMessage && (
                <div className="text-center mt-6 flex justify-center">
                  <p className="text-lg font-semibold text-red-500 inline">
                    You've reached the maximum field limit ({wordLimit}).
                  </p>
                  <a 
                    href="/pricing" 
                    className="text-lg font-medium text-blue-600 hover:text-blue-700 dark:dark:text-blue-500 dark:hover:text-blue-600 underline-offset-1 underline ml-2"
                  >
                    Upgrade
                  </a>
                </div>
              )}

              <motion.div
                key="create-button"
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.95, y: -20 }}
                layout
                transition={{
                  layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
                  duration: 0.3,
                  ease: [0.4, 0, 0.2, 1],
                }}
                className="flex justify-center mt-12"
              >
                <motion.button
                  className="px-6 py-3 w-full rounded-lg text-xl font-semibold text-white bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600 transition shadow-lg shadow-blue-500/80 dark:shadow-none"  
                  onClick={handleSave}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Create Flashcard Set
                </motion.button>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </motion.div>    
    
    </>


  );
};

export default FlashcardInput;











// This is the code before adding the uuid to all the keys:

// import { useEffect, useState, useRef, useCallback } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faFileLines, faImage } from "@fortawesome/free-solid-svg-icons";
// import LoadingSpinner from "../components/General/LoadingSpinner";
// import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
// import { ReactComponent as TextIcon } from "../Assets/SVG/TextIcon.svg";
// import UploadDocumentModal from "../components/Modals/UploadDocumentModal";
// import UploadTextModal from "../components/Modals/UploadTextModal";
// import UploadImageModal from "../components/Modals/UploadImageModal";
// import LimitModal from "../components/Modals/LimitModal";
// import api from "../api";
// import { motion, AnimatePresence } from "framer-motion"; 
// import FlashcardInputField from "../components/DefinitionQuestionComponents/FlashcardInputField";
// import debounce from 'lodash/debounce';
// import { v4 as uuidv4 } from 'uuid';


// const FlashcardInput = () => {

//   const [words, setWords] = useState([""]);
//   const [definitions, setDefinitions] = useState([""]);
//   const [title, setTitle] = useState("");
//   const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [wordLimit, setWordLimit] = useState(100);
//   const [errors, setErrors] = useState({"definitions": [], "title": false, "words": []});
//   const [showLimitModal, setShowLimitModal] = useState(false);
//   const [limitReason, setLimitReason] = useState("");

//   const [showDeleteModal, setShowDeleteModal] = useState(false);

//   const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
//   const [showUploadTextModal, setShowUploadTextModal] = useState(false);
//   const [showUploadImageModal, setShowUploadImageModal] = useState(false);

//   const [loadingGeneratingFlashcards, setLoadingGeneratingFlashcards] = useState(false);

//   const [savingDraft, setSavingDraft] = useState(false);
//   const [subscriptionPlan, setSubscriptionPlan] = useState("basic");
//   const [wordFocusIndex, setWordFocusIndex] = useState(0);
//   const [definitionFocusIndex, setDefinitionFocusIndex] = useState(0);



//   const [isSticky, setIsSticky] = useState(false);

//   const saveAbortController = useRef(null);
//   const originalData = useRef({ words: [""], definitions: [""], title: "" });
//   const navigate = useNavigate();
//   const { uuid } = useParams();


//   const isStickyRef = useRef(isSticky);
//   const titleInputRef = useRef(null);
//   const wordInputRefs = useRef({});
//   const definitionInputRefs = useRef({});



//   useEffect(() => {
//     isStickyRef.current = isSticky;
//   }, [isSticky]);


//   useEffect(() => {
//     const urls = ["/api/subscription/"];
//     if (uuid) {
//       console.log("getting draft data");
//       urls.push(`/api/flashcards/get_flashcard_draft/${uuid}/`);
//     }
//     Promise.all(urls.map(url => api.get(url)))
//     .then((responses) => {
//       const subscriptionData = responses[0].data;
//       if (subscriptionData.plan === "max") {
//         setWordLimit(5000);
//         setSubscriptionPlan("max");
//       } else {
//         setWordLimit(100);
//         setSubscriptionPlan("basic");
//       }

//       if (uuid && responses.length > 1) {
//         const draftData = responses[1].data;
//         setTitle(draftData.title);

//         const loadedWords = draftData.words.length > 0 ? draftData.words : [""];
//         const loadedDefinitions = draftData.definitions.length > 0 ? draftData.definitions : [""];


//         setWords(loadedWords);
//         setDefinitions(loadedDefinitions);


//         originalData.current = {
//           words: loadedWords,
//           definitions: loadedDefinitions,
//           title: draftData.title,
//         };
//       }
//       setLoading(false);
//     })
//     .catch((error) => {
//       console.error("Error getting subscription data: ", error);
//       setLoading(false);
//     });
//   }, [uuid])





//   useEffect(() => {
//     if (wordFocusIndex !== null) {
//       const ref = wordInputRefs.current[wordFocusIndex];
//       if (ref && ref.focus) {
//         ref.focus();
//       }
//       setWordFocusIndex(null);
//     } else if (definitionFocusIndex !== null) {
//       const ref = definitionInputRefs.current[definitionFocusIndex];
//       if (ref && ref.focus) {
//         ref.focus();
//       }
//       setDefinitionFocusIndex(null);
//     }
//   }, [wordFocusIndex, definitionFocusIndex]);



//   const hasChanges = useCallback((currentData) => {
//     const { words, definitions, title } = originalData.current;
//     return (
//       JSON.stringify(currentData.words) !== JSON.stringify(words) ||
//       JSON.stringify(currentData.definitions) !== JSON.stringify(definitions) ||
//       currentData.title !== title
//     );
//   }, []);





//   const saveDraft = useCallback(async (data) => {
//     setSavingDraft(true);

//     if (saveAbortController.current) {
//       saveAbortController.current.abort();
//     }

//     saveAbortController.current = new AbortController();

//     try {
//       const config = { signal: saveAbortController.current.signal };
//       if (uuid) {
//         await api.put(`/api/flashcards/check_flashcard_draft_update/${uuid}/`, data, config);
//       } else {
//         const response = await api.post("/api/flashcards/save_flashcard_draft/", data, config);
//         navigate("/flashcard/input/" + response.data.new_uuid + "/");
//       }
//       console.log("Draft saved successfully");

//       originalData.current = { ...data };
//     } catch (error) {
//       if (error.name === "AbortError") {
//         console.log("Save cancelled:", error.message);
//       } else {
//         console.error("Error saving draft:", error);
//       }
//     } finally {
//       setSavingDraft(false);
//       saveAbortController.current = null;
//     }
//   },[navigate, uuid]);



//   const debouncedSaveDraft = useCallback(
//     debounce((data) => {
//       if (hasChanges(data)) {
//         saveDraft(data);
//       }
//     }, 1000), // 1 second debounce delay
//     [hasChanges, saveDraft]
//   );


//   useEffect(() => {
//     const data = {
//       words,
//       definitions,
//       title,
//     };
//     debouncedSaveDraft(data);

//     return () => {
//       debouncedSaveDraft.cancel();
//     };
//   }, [words, definitions, title, debouncedSaveDraft]);






//   const handleBeforeUnload = useCallback((event) => {
//     const data = {
//       words,
//       definitions,
//       title,
//     };

//     if (hasChanges(data)) {
//       saveDraft(data);
//     }
//     event.preventDefault();
//     event.returnValue = '';
//   }, [words, definitions, title, hasChanges, saveDraft]);

//   useEffect(() => {
//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [handleBeforeUnload]);







//   const handleInputChange = (index, type, value) => {
//     setWords((prevWords) => {
//       setDefinitions((prevDefinitions) => {

//         const newWords = [...prevWords];
//         const newDefinitions = [...prevDefinitions];


//         if (type === "word") {
//           newWords[index] = value;
//         } else if (type === "definition") {
//           newDefinitions[index] = value;
//         }

//         const isLastIndex = index === newWords.length - 1;
//         const isWordNotEmpty = newWords[index]?.trim().length > 0;
//         const isDefinitionNotEmpty = newDefinitions[index]?.trim().length > 0;

//         if (
//           isLastIndex &&
//           isWordNotEmpty &&
//           isDefinitionNotEmpty &&
//           newWords.length < wordLimit
//         ) {
//           newWords.push("");
//           newDefinitions.push("");
//         }

//         setWords(newWords);
//         setDefinitions(newDefinitions);
//         return newDefinitions;
//       });

//       return prevWords;
//     });
//   };


//   useEffect(() => {
//     const lastWordIndex = wordLimit - 1;
//     const wordAtLimit = words[lastWordIndex] && words[lastWordIndex].trim() !== "";
//     const definitionAtLimit = definitions[lastWordIndex] && definitions[lastWordIndex].trim() !== "";

//     if (wordAtLimit && definitionAtLimit && !showMaxFieldsMessage) {
//       setLimitReason("flashcards");
//       setShowLimitModal(true);
//       setShowMaxFieldsMessage(true);
//     }

//   }, [words, definitions, showMaxFieldsMessage, wordLimit]);



//   const handleDeleteField = (indexToRemove) => {
//     if (words.length > 1 && definitions.length > 1) {
//       const newWords = words.filter((_, index) => index !== indexToRemove);
//       const newDefinitions = definitions.filter((_, index) => index !== indexToRemove);

//       setWords(newWords);
//       setDefinitions(newDefinitions);
//       if (showMaxFieldsMessage) {
//         setShowMaxFieldsMessage(false);
//       }
//     }
//   };




//   const handleSave = () => {
//     setLoading(true);
//     const nonEmptyWords = words.filter((word) => word.trim() !== "");
//     const nonEmptyDefinitions = definitions.filter((definition) => definition.trim() !== "");

//     const newErrors = {
//       title: title.trim() === "",
//       words: [],
//       definitions: [],
//     };

//     if (newErrors.title) {
//       titleInputRef.current.focus();
//     };


//     const lastIndex = words.length - 1;
//     words.forEach((word, index) => {
//       if (word.trim() === "" && !(index === lastIndex && word.trim() === "" && definitions[lastIndex].trim() === "")) {
//         newErrors.words.push(index + 1);
//       }
//       if ((!definitions[index] || definitions[index].trim() === "") && !(index === lastIndex && word.trim() === "" && definitions[lastIndex].trim() === "")) {
//         newErrors.definitions.push(index + 1);
//       }
//     });

//     setErrors(newErrors);

//     if (newErrors.title || newErrors.words.length > 0 || newErrors.definitions.length > 0) {
//       console.log("Errors found");
//       if (newErrors.title) {
//         titleInputRef.current.focus();
//       } else if (newErrors.words.length > 0) {
//         setWordFocusIndex(newErrors.words[0] - 1);
//       } else if (newErrors.definitions.length > 0) {
//         setDefinitionFocusIndex(newErrors.definitions[0] - 1);
//       }
//       setLoading(false);
//       return;
//     }

//     const flashcards = nonEmptyWords.map((word, index) => ({
//       word,
//       definition: nonEmptyDefinitions[index] || "",
//       position: index + 1,
//     }));

//     const data = {
//       title: title,
//       flashcards: flashcards,
//     };


//     api.post("/api/new_flashcard_set/", data)
//       .then((response) => {

//         if (uuid) {
//           api.delete("/api/flashcards/delete_flashcard_draft/" + uuid + "/")
//             .then(() => {
//               console.log("Draft deleted successfully");
//               navigate("/flashcard/preview/" + response.data.uuid + "/");
//               setLoading(false);
//             })
//             .catch((error) => {
//               console.error("Error deleting draft:", error);
//             });
//         } else {
//           navigate("/flashcard/preview/" + response.data.uuid + "/");
//           setLoading(false);
//         }

//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };




//   const cleanArray = (array) => array.filter(item => item.trim() !== "");



//   const isNotEmpty = () => {
//     const cleanWords = cleanArray(words);
//     const cleanDefinitions = cleanArray(definitions);
//     return cleanWords.length > 0 && cleanDefinitions.length > 0 && title.trim() !== "";
//   };



//   const handleClearAll = () => {
//     setShowMaxFieldsMessage(false);
//     setErrors({"definitions": [], "title": false, "words": []})
//     setWords([""]);
//     setDefinitions([""]);
//     setTitle("");
//   }

//   const nonEmptyPairCount = () => {
//     return words.reduce((count, word, index) => {
//       const definition = definitions[index];
//       if (word.trim() !== "" && definition && definition.trim() !== "") {
//         count++;
//       }
//       return count;
//     }, 0);
//   };

//   const processGeneratedFlashcards = (data) => {
//     const newWords = data.map(item => item.word.trim());
//     const newDefinitions = data.map(item => item.definition.trim());

//     setWords(prevWords => {
//       const prevDefinitions = definitions;
//       let filteredWords = [];
//       let filteredDefinitions = [];

//       prevWords.forEach((word, index) => {
//           const trimmedWord = word.trim();
//           const trimmedDefinition = prevDefinitions[index] ? prevDefinitions[index].trim() : "";
//           if (trimmedWord !== "" || trimmedDefinition !== "") {
//               filteredWords.push(trimmedWord);
//               filteredDefinitions.push(trimmedDefinition);
//           }
//       });

//       let spaceAvailable = wordLimit - filteredWords.length;
//       if (spaceAvailable <= 0) {
//         setShowUploadDocumentModal(false);
//         setShowUploadTextModal(false);
//         setShowUploadImageModal(false);
//         setLoadingGeneratingFlashcards(false);
//         setShowLimitModal(true);
//         return filteredWords;
//       }

//       const addableWords = newWords.slice(0, spaceAvailable);
//       const addableDefinitions = newDefinitions.slice(0, spaceAvailable);

//       filteredWords = [...filteredWords, ...addableWords];
//       filteredDefinitions = [...filteredDefinitions, ...addableDefinitions];

//       if (filteredWords.length >= wordLimit) {
//         setShowLimitModal(true);
//       }


//       if (filteredWords.length < wordLimit) {
//         filteredWords.push("");
//         filteredDefinitions.push("");
//       }
//       setLoadingGeneratingFlashcards(false);
//       setShowUploadDocumentModal(false);
//       setShowUploadTextModal(false);
//       setShowUploadImageModal(false);
//       setDefinitions(filteredDefinitions);

//       return filteredWords;
//     });
//   };



//   useEffect(() => {

//     // This is for the sticky header
//     // To know if it should be sticky or not 
//     const handleScroll = () => {
//       const currentScrollY = window.scrollY;
//       const shouldBeSticky = currentScrollY > 72;
  
//       // Only update state if it actually changes
//       if (shouldBeSticky !== isStickyRef.current) {
//         setIsSticky(shouldBeSticky);
//         isStickyRef.current = shouldBeSticky;
//       }
//     };
  
//     // Add the scroll event listener
//     window.addEventListener("scroll", handleScroll);
  
//     // Cleanup the event listener when the component unmounts
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
  









//   if (loading) {
//     return (
//       <div style={{padding: "0 13vw"}}>
//         <LoadingSpinner />
//       </div>
//     );
//   }


//   return (
//     <div style={{ backgroundColor: "#F6F7FB"}} className="min-h-screen">

//       <ConfirmDeletionModal showModal={showDeleteModal} setShowModal={setShowDeleteModal} handleDelete={handleClearAll} header={"Clear all fields?"} text={"Are you sure you want to clear all fields? This action cannot be undone."} buttonLabel={"Clear all"} />

//       <UploadDocumentModal 
//         showModal={showUploadDocumentModal} 
//         setShowModal={setShowUploadDocumentModal} 
//         loading={loadingGeneratingFlashcards} 
//         setLoading={setLoadingGeneratingFlashcards} 
//         processData={processGeneratedFlashcards}
//         setLimitReason={setLimitReason} 
//         setShowLimitModal={setShowLimitModal} 
//         subscriptionPlan={subscriptionPlan} 
//         saveFlashcards={false}
//         maxNumberOfFlashcards={wordLimit-nonEmptyPairCount()}
//       />

//       <UploadTextModal 
//         showModal={showUploadTextModal} 
//         setShowModal={setShowUploadTextModal} 
//         loading={loadingGeneratingFlashcards} 
//         setLoading={setLoadingGeneratingFlashcards} 
//         processData={processGeneratedFlashcards} 
//         setLimitReason={setLimitReason} 
//         setShowLimitModal={setShowLimitModal} 
//         subscriptionPlan={subscriptionPlan} 
//         saveFlashcards={false}
//         maxNumberOfFlashcards={wordLimit-nonEmptyPairCount()}
//       />

//       <UploadImageModal 
//         showModal={showUploadImageModal} 
//         setShowModal={setShowUploadImageModal} 
//         loading={loadingGeneratingFlashcards} 
//         setLoading={setLoadingGeneratingFlashcards} 
//         processData={processGeneratedFlashcards} 
//         setLimitReason={setLimitReason} 
//         setShowLimitModal={setShowLimitModal} 
//         saveFlashcards={false}
//       />

//       <LimitModal showModal={showLimitModal} setShowModal={setShowLimitModal} limitReason={limitReason} />



//       <div className="pt-8 w-[900px] max-w-[900px] mx-auto">



//         <div className="flex flex-col justify-start w-full bg-white rounded-lg shadow">
//           <input
//             autoFocus
//             id="title"
//             ref={titleInputRef}
//             type="text"
//             className= {`rounded-md text-xl font-medium border-b-2 ${errors.title ? "shadow-color-lg shadow-red-400 border-red-500" : "border-transparent  focus:border-gray-400"} py-2.5 px-3 focus:outline-none outline-none `}
//             placeholder="Enter a title"
//             onChange={(event) => setTitle(event.target.value)}
//             value={title}
//           />

//         </div>
//         {errors.title && <p className="text-red-500 text-sm font-semibold mt-2">Title is required</p>}


//         <div className="flex-row items-start justify-start mt-10">
//           <div className="flex flex-row items-center ">
//             <h1 className="text-2xl font-semibold">Generate flashcards with AI</h1>

//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 24 24"
//               fill="currentColor"
//               aria-hidden="true"
//               className="inline-block w-8 h-8 text-purple-400 ml-3"
//             >
//               <path
//                 fillRule="evenodd"
//                 d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
//                 clipRule="evenodd"
//               ></path>
//             </svg>

//           </div>
//           <p className="text-lg font-medium mt-1.5 mb-4 text-gray-600">Upload your materials to receive custom-made flashcards tailored to your content.</p>

//           <div className="flex flex-row items-start justify-start gap-3">
//             <button
//               className="flex items-center px-4 py-2 rounded-full font-semibold text-white bg-blue-600 transition transform hover:scale-105 hover:shadow-color-lg hover:shadow-blue-400 hover:bg-blue-700"
//               onClick={() => setShowUploadDocumentModal(true)}
//               tabIndex={-1}
//             >
//               <FontAwesomeIcon icon={faFileLines} className="mr-2" />
//               Upload Document
//             </button>

//             <button
//               className="flex items-center px-4 py-2 rounded-full font-semibold text-white bg-blue-600 transition transform hover:scale-105 hover:shadow-color-lg hover:shadow-blue-400 hover:bg-blue-700"
//               onClick={() => setShowUploadTextModal(true)}
//               tabIndex={-1}
//             >
//               <TextIcon className="mr-2 w-4 h-4 " />
//               Upload Text
//             </button>
//             <button
//               className="flex items-center px-4 py-2 rounded-full font-semibold text-white bg-blue-600 transition transform hover:scale-105 hover:shadow-color-lg hover:shadow-blue-400 hover:bg-blue-700"
//               onClick={() => setShowUploadImageModal(true)}
//               tabIndex={-1}
//             >
//               <FontAwesomeIcon icon={faImage} className="mr-2" />
//               Upload Images
//             </button>
//           </div>
//         </div>

//         <div className="mb-4 mt-14 flex justify-between">
//           <span className="font-semibold text-lg md:text-xl lg:text-2xl">Terms in this set ({nonEmptyPairCount()})</span>
//           <button className="text-red-600 font-semibold hover:bg-red-100 rounded-lg py-2 px-3"
//             onClick={handleClearAll}
//             style={{ display: words.length > 0 && definitions.length > 0 ? '' : 'none' }}
//             disabled={words.length === 0 && definitions.length === 0}
//             tabIndex={-1}
//           >
//             Clear all
//           </button>
//         </div>


//         <motion.div
//           layout
//           className="flex flex-col gap-6 min-h-screen"
//           initial={{ opacity: 1 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 1 }}
//           transition={{
//             layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
//           }}
//         >
//           <AnimatePresence>
//             {words.map((word, index) => (
//               <motion.div
//                 key={index}
//                 className="bg-white rounded-2xl shadow px-4 pt-4 pb-3"
//                 layout
//                 initial={{ opacity: 0, scale: 0.95, y: 20 }}
//                 animate={{ opacity: 1, scale: 1, y: 0 }}
//                 exit={{ opacity: 0, scale: 0.95, y: -20 }}
//                 transition={{
//                   layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
//                   duration: 0.3,
//                   ease: [0.4, 0, 0.2, 1],
//                 }}
//               >
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5">
//                   <div>
//                     <label className="mb-3 font-medium text-sm text-neutral-700 block">Term</label>
//                     <FlashcardInputField 
//                       value={word}
//                       error={errors.words.includes(index + 1)}
//                       placeholder="Enter a term"
//                       onChange={(event) => handleInputChange(index, 'word', event.target.value)}
//                       ref={el => wordInputRefs.current[index] = el}
//                     />
//                   </div>
//                   <div>
//                     <label className="mb-3 font-medium text-sm text-neutral-700 block">
//                       Definition
//                     </label>
//                     <FlashcardInputField 
//                       value={definitions[index]}
//                       error={errors.definitions.includes(index + 1)}
//                       placeholder="Enter a definition"
//                       onChange={(event) => handleInputChange(index, 'definition', event.target.value)}
//                       ref={el => definitionInputRefs.current[index] = el}
//                     />
//                   </div>
//                 </div>

//                 <AnimatePresence>
//                   {word.trim() !== "" && definitions[index].trim() !== "" && (
//                     <motion.div
//                       layout
//                       className="flex justify-end"
//                       initial={{ opacity: 0, height: 0, marginTop: 0 }}
//                       animate={{
//                         opacity: 1,
//                         height: "auto",
//                         marginTop: "12px",
//                       }}
//                       exit={{ opacity: 0, height: 0, marginTop: 0 }}
//                       transition={{
//                         layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
//                         duration: 0.3,
//                         ease: [0.4, 0, 0.2, 1],
//                       }}
//                     >
//                       <motion.button
//                         className="text-red-600 font-semibold hover:bg-red-100 rounded-lg py-2 px-3 flex items-center"
//                         onClick={() => handleDeleteField(index)}
//                         tabIndex={-1}
//                         whileHover={{ scale: 1.05 }}
//                         whileTap={{ scale: 0.95 }}
//                       >
//                         <FontAwesomeIcon
//                           icon={faTrash}
//                           style={{ fontSize: "16px" }}
//                         />
//                         <span className="ml-2">Delete</span>
//                       </motion.button>
//                     </motion.div>
//                   )}
//                 </AnimatePresence>
//               </motion.div>
//             ))}
//             {showMaxFieldsMessage && (
//               <div className="text-center mt-4 flex justify-center">
//                 <p className="text-lg font-semibold text-red-500 inline">
//                   You've reached the maximum field limit ({wordLimit}).
//                 </p>
//                 <a href="/pricing" className="text-lg font-semibold underline text-blue-600 ml-2 hover:text-blue-800">Upgrade</a>
//               </div>
//             )}
//             <motion.div
//               key="create-button"
//               initial={{ opacity: 0, scale: 0.95, y: 20 }}
//               animate={{ opacity: 1, scale: 1, y: 0 }}
//               exit={{ opacity: 0, scale: 0.95, y: -20 }}
//               layout
//               transition={{
//                 layout: { duration: 0.5, ease: [0.4, 0, 0.2, 1] },
//                 duration: 0.3,
//                 ease: [0.4, 0, 0.2, 1],
//               }}
//               className="flex justify-center mt-12"
//             >
//               <motion.button
//                 className={`text-white bg-blue-600 border-2 border-blue-600 py-2.5 px-10 rounded-full font-medium text-lg transition-colors duration-300 ease-in-out hover:bg-blue-700 hover:border-blue-600
//                 active:bg-blue-800 active:border-blue-700 active:shadow-inner
//                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
//                 shadow-color-lg shadow-blue-400
//                 disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
//                 onClick={handleSave}
//               >
//                Create flashcard set 
//               </motion.button>
//             </motion.div>
//           </AnimatePresence>
          
//         </motion.div>

// {/* 


//         <div>
//           {words.map((word, index) => (
//             <div className="bg-white rounded-2xl shadow px-4 pt-4 pb-3 mb-10">
//               <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5 ">
//                 <div>
//                   <label className="mb-3 font-medium text-sm text-neutral-700 block">Term</label>
//                   <textarea
//                     className={`p-2.5 ${errors.words.includes(index + 1) ? "border-2 border-red-500": "border border-gray-300"}  rounded-lg focus:none w-full bg-gray-50 resize-none`}
//                     rows="2"
//                     value={word}
//                     onChange={(event) => handleInputChange(index, 'word', event.target.value)}
//                   />
//                   {errors.words.includes(index + 1) && <p className="text-red-500 text-sm font-semibold mt-2">Word is required</p>}
//                 </div>
//                 <div>
//                   <label className="mb-3 font-medium text-sm text-neutral-700 block">Definition</label>
//                   <textarea
//                     className={`p-2.5 ${errors.definitions.includes(index + 1) ? "border-2 border-red-500": "border border-gray-300"}  rounded-lg focus:none w-full bg-gray-50 resize-none`}
//                     rows="2"
//                     value={definitions[index]}
//                     onChange={(event) => handleInputChange(index, 'definition', event.target.value)}
//                   />
//                   {errors.definitions.includes(index + 1) && <p className="text-red-500 text-sm font-semibold mt-2">Definition is required</p>}
//                 </div>
//               </div>
//               <div className="flex justify-end mt-3" style={{ display: word.trim() !== "" && definitions[index].trim() !== "" ? '' : 'none' }}>
//                 <button className="text-red-600 font-semibold hover:bg-red-100 rounded-lg py-2 px-3" onClick={() => handleDeleteField(index)} tabIndex={-1}>
//                   <FontAwesomeIcon icon={faTrash} style={{ fontSize: "16px" }} />
//                   <span className="ml-2">Delete</span>
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div> */}

// {/* 
//         <div className="flex justify-center">

//           <button
//             className={`${!isNotEmpty() === true ? "text-gray-500" : "text-white"} bg-blue-600 border-2 border-blue-600 py-2.5 px-10 rounded-full font-medium text-lg transition-colors duration-300 ease-in-out
//                       hover:bg-blue-700 hover:border-blue-600
//                       active:bg-blue-800 active:border-blue-700 active:shadow-inner
//                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
//                       shadow-color-lg shadow-blue-400
//                       disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
//             onClick={handleSave}
//             disabled={!isNotEmpty()}
//           >
//             Create flashcard set
//           </button>

//         </div> */}



//       </div>
//     </div>

//   );
// };

// export default FlashcardInput;