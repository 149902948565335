import * as React from "react";
const AnimatedSparkleWhite = ({ width = "72px", height = "72px", ...props }) => (
  // chat for the svg = https://chatgpt.com/c/67434801-c3b8-8004-9122-8c6fa62c2f3f?model=gpt-4o
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"

    style={{ width, height }}
    {...props}
  >
    <defs>
      <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <path
      id="largeStar"
      fill="url(#blueGradient)"
      d="M36 18a3 3 0 012.884 2.176l3.252 11.384a15 15 0 0010.304 10.304l11.384 3.252a3 3 0 010 5.768l-11.384 3.252a15 15 0 00-10.304 10.304l-3.252 11.384a3 3 0 01-5.768 0l-3.252-11.384a15 15 0 00-10.304-10.304l-11.384-3.252a3 3 0 010-5.768l11.384-3.252a15 15 0 0010.304-10.304l3.252-11.384A3 3 0 0136 18z"
    />
    <path
      id="mediumStar"
      fill="url(#blueGradient)"
      d="M72 6a3 3 0 012.912 2.272l1.032 4.144a10.5 10.5 0 007.64 7.64l4.144 1.032a3 3 0 010 5.824l-4.144 1.032a10.5 10.5 0 00-7.64 7.64l-1.032 4.144a3 3 0 01-5.824 0l-1.032-4.144a10.5 10.5 0 00-7.64-7.64l-4.144-1.032a3 3 0 010-5.824l4.144-1.032a10.5 10.5 0 007.64-7.64l1.032-4.144A3 3 0 0172 6z"
    />
    <path
      id="smallStar"
      fill="url(#blueGradient)"
      d="M66 60a3 3 0 012.848 2.052l1.576 4.732a6 6 0 003.792 3.792l4.732 1.58a3 3 0 010 5.688l-4.732 1.576a6 6 0 00-3.792 3.792l-1.576 4.732a3 3 0 01-5.688 0l-1.576-4.732a6 6 0 00-3.792-3.792l-4.732-1.576a3 3 0 010-5.688l4.732-1.576a6 6 0 003.792-3.792l1.576-4.732A3 3 0 0166 60z"
    />
    <style>
      {
        "\n    #largeStar {\n      animation: scaleLarge 1.5s infinite alternate ease-in-out;\n      transform-origin: center;\n    }\n    #mediumStar {\n      animation: scaleMedium 1.5s infinite alternate ease-in-out;\n      transform-origin: center;\n    }\n    #smallStar {\n      animation: scaleSmall 1.5s infinite alternate ease-in-out;\n      transform-origin: center;\n    }\n\n    @keyframes scaleLarge {\n      0% {\n        transform: scale(1);\n      }\n      100% {\n        transform: scale(0.8);\n      }\n    }\n    @keyframes scaleMedium {\n      0% {\n        transform: scale(1);\n      }\n      100% {\n        transform: scale(0.85);\n      }\n    }\n    @keyframes scaleSmall {\n      0% {\n        transform: scale(1);\n      }\n      100% {\n        transform: scale(0.9);\n      }\n    }\n  "
      }
    </style>
  </svg>
);
export default AnimatedSparkleWhite;