import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import getOrCreateDeviceId from "../utils/getOrCreateDeviceId";
import ProgressBarComponent from "../components/OtherQuestionComponents/ProgressBarComponent";
import QuestionBox from "../components/OtherQuestionComponents/QuestionBox";
import OptionsComponent from "../components/OtherQuestionComponents/OptionsComponent";
import OptionsCorrectionComponent from "../components/OtherQuestionComponents/OptionsCorrectionComponent";
import InputFieldComponent from "../components/OtherQuestionComponents/InputFieldComponent";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import PointsFooter from "../components/OtherQuestionComponents/PointsFooter";
import CorrectionPointsFooter from "../components/OtherQuestionComponents/CorrectionPointsFooter";
import LoadingSpinner from "../components/General/LoadingSpinner";
import TextareaAutosize from 'react-textarea-autosize';


const ExamQuestionsGuest = () => {
  const [questions, setQuestions] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);

  const [userAnswer, setUserAnswer] = useState("");
  const [correction, setCorrection] = useState("");
  const [correctIncorrect, setCorrectIncorrect] = useState(null);

  const [pointsForQuestion, setPointsForQuestion] = useState(0);
  const [points, setPoints] = useState(0);
  const [maxPointsUntilNow, setMaxPointsUntilNow] = useState(0);
  const [optionsUserAnswerIndex, setOptionsUserAnswerIndex] = useState(0);


  const [showResponse, setShowResponse] = useState(false);
  const [loadingSite, setLoadingSite] = useState(true);
  const [essayLength, setEssayLength] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [essayInputEmpty, setEssayInputEmpty] = useState(false);
  const [progressBarQuestionIndex, setProgressBarQuestionIndex] = useState(0);
  const [loadingAnswer, setLoadingAnswer] = useState(false)

  const essayTextAreaRef = useRef(null);

  const navigate = useNavigate();
  const { uuid } = useParams();
  const deviceId = getOrCreateDeviceId();

  sessionStorage.setItem("loadNavbar", "false");

  const getMaxPoints = (currentQuestionType) => {
    if (currentQuestionType === "multipleChoice") {
      return 1;
    } else if (currentQuestionType === "trueOrFalse") {
      return 1;
    } else if (currentQuestionType === "fillInTheBlank") {
      return 2;
    } else if (currentQuestionType === "shortAnswer") {
      return 5;
    } else if (currentQuestionType === "essay") {
      return 10;
    }
  };



  useEffect(() => {
    setLoadingSite(true);
    
    axios.get(`/api/summary_questions/${uuid}/`, {
      headers: {
        'Device-ID': deviceId,
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response.data);
        setQuestions(response.data.questions);
        setNumberOfQuestions(response.data.questions.length);
        if (response.data.question_index !== 0) {
          if (response.data.question_index === response.data.questions.length) {
            return handleRedirect();
          }
          return handleContinueFromSavedPoint(response.data, response.data.question_index);
        }
        setLoadingSite(false);
      })
  }, [uuid, deviceId]);



  const handleContinueFromSavedPoint = (questionData, questionIndexBefore) => {
    const totalPointsCalc = questionData.points_list.reduce((a, b) => a + b, 0);
    const totalPointsUntilNowCalc = questionData.questions.reduce((accumulator, currentValue, index) => {
      if (index < questionIndexBefore) {
        return accumulator + currentValue.points;
      }
      return accumulator;
    }, 0); 
    console.log("these are the totalpoitns until now: ", totalPointsUntilNowCalc)
    setMaxPointsUntilNow(totalPointsUntilNowCalc);
    setPoints(totalPointsCalc);
    setQuestionIndex(questionIndexBefore);

    setProgressBarQuestionIndex(questionIndexBefore);
    setLoadingSite(false)

  };



  const handleRedirect = () => {
    // FIXME: Don't know if i want to show the user the recap page
    // api.put("/api/summary_reset_question_index/", { uuid: uuid })
    // .then(() => {
    //   navigate(`/exam/recap/${uuid}`);
    // })
    // .catch((error) => {
    //   console.log("Error while resetting question index:", error);
    // });
  };


  const handleNextQuestion = () => {
    setQuestionIndex((prevIndex) => prevIndex + 1);
    setShowResponse(false);

    setUserAnswer("");
    setCorrection(null);
    setEssayLength(0);
  }

  const handleShowResponse = () => {
    setSubmitted(true);
    if (userAnswer !== "") {
      setLoadingAnswer(true);

      if (questions[questionIndex].type === "multipleChoice") {
        setOptionsUserAnswerIndex(questions[questionIndex].choices.indexOf(userAnswer));
      }
  
  
      setCorrectIncorrect(true);
      setCorrection(null);
  
      const data = {
        answer: userAnswer,
        index: questionIndex,
        uuid: uuid
      }
  
      axios.post("/api/summary_correction/", data, {
        headers: {
          'Device-ID': deviceId,
        },
        withCredentials: true,
      })
        .then((response) => {
          const responseData = response.data;
          console.log("responseData: ", responseData)
          setCorrection(responseData.corrections.slice(-1)[0]);
          setPointsForQuestion(responseData.points_list.slice(-1)[0]);
          setPoints(
            (prevPoints) =>
              prevPoints + responseData.points_list.slice(-1)[0]
          );
          setCorrectIncorrect(responseData.correct_incorrect.slice(-1)[0]);
          setProgressBarQuestionIndex((prevIndex) => prevIndex + 1);
          setLoadingAnswer(false);
          setShowResponse(true);
          setMaxPointsUntilNow((prevPoints) => prevPoints + getMaxPoints(questions[questionIndex].type));
        })
        .catch((error) => {
          console.log("Error while getting correction data:", error);
        });
  
  
      setSubmitted(false);
    }
  }




  const handleEssayChange = (event) => {
    const { value } = event.target;
    setEssayLength(value.length);
    if (essayLength <= 40000) {
      setUserAnswer(value);
    }
  };

  useEffect(() => {
    if (submitted && userAnswer === "" && questions[questionIndex].type === "essay") {
      setEssayInputEmpty(true);
    }
    else {
      setEssayInputEmpty(false);
    }
  }, [userAnswer, submitted]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && showResponse && correction !== null) {
        if (numberOfQuestions === questionIndex + 1) {
          return handleRedirect();
        } else {
          return handleNextQuestion();
        }
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [showResponse, correction, questionIndex, numberOfQuestions]);


  const handleEssayTextAreaScroll = () => {
    const textarea = essayTextAreaRef.current;
    if (textarea) {
      if (textarea.scrollHeight > textarea.clientHeight) {
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  };

  useEffect(() => {
    handleEssayTextAreaScroll();
  }, [userAnswer]);


  if (loadingSite) {
    return <LoadingSpinner />
  }

  return (
    <div className="bg-light dark:bg-dark mx-auto px-4 max-w-[1200px]">
      <div className="pt-24">
        <ProgressBarComponent
          index={progressBarQuestionIndex}
          numberOfQuestions={numberOfQuestions}
          guest={true}
        />

        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-4/6 text-center">
            <QuestionBox
              maxPoints={getMaxPoints(questions[questionIndex].type)}
              question={questions[questionIndex].question}
            />
            {!showResponse && questions.length !== 0 && (
              <div>
                {questions[questionIndex].type === "multipleChoice" && (
                  <OptionsComponent
                    optionsList={questions[questionIndex].choices}
                    userAnswer={userAnswer}
                    setUserAnswer={setUserAnswer}
                    submitted={submitted}
                  />
                )}

                {questions[questionIndex].type === "trueOrFalse" && (
                  <OptionsComponent
                    optionsList={["True", "False"]}
                    userAnswer={userAnswer}
                    setUserAnswer={setUserAnswer}
                    submitted={submitted}
                  />
                )}

                {(questions[questionIndex].type === "fillInTheBlank" || questions[questionIndex].type === "shortAnswer") && (
                  <InputFieldComponent
                    value={userAnswer}
                    setValue={setUserAnswer}
                    maxWidth="375px"
                    placeholder={"Enter answer"}
                    submitted={submitted}
                    handleSubmit={handleShowResponse}
                  />
                )}


                {questions[questionIndex].type === "essay" && (
                  <div>
                    <TextareaAutosize 
                      ref={essayTextAreaRef}
                      autoFocus
                      minRows={15}
                      maxRows={20}
                      placeholder="Enter your essay"
                      value={userAnswer}
                      onChange={handleEssayChange}
                      className={`w-full mt-4 mb-1.5 p-2.5 border-2 border-gray-200 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500 ${essayInputEmpty && "border-red-500"} rounded-md focus:outline-none resize-none shadow-lg dark:shadow-none  dark:text-white dark:bg-gray-800`}
                      style={{
                        overflowY: 'hidden',
                        lineHeight: '1.5',
                      }}
                      onHeightChange={() => {
                        const textarea = essayTextAreaRef.current;
                        if (textarea) {
                          if (textarea.scrollHeight > textarea.clientHeight) {
                            textarea.style.overflowY = 'scroll';
                            textarea.scrollTop = textarea.scrollHeight;
                          } else {
                            textarea.style.overflowY = 'hidden';
                          }
                        }
                      }}
                    />
                    <p id="charCount" className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                      {essayLength} / 40'000 characters
                    </p>
                    {essayInputEmpty && (
                      <p className="text-red-500 text-sm">Essay is required</p>
                    )}
                  </div>
                )}
              </div>
            )}

            {showResponse && correctIncorrect === false && correction !== null && (
              <div>
                {questions[questionIndex].type === "multipleChoice" && (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={questions[questionIndex].choices}
                      incorrectIndex={optionsUserAnswerIndex}
                      correctIndex={Number(correction)}
                    />
                  </div>
                )}
                {questions[questionIndex].type === "trueOrFalse" && (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={["True", "False"]}
                      incorrectIndex={userAnswer === "True" ? 0 : 1}
                      correctIndex={userAnswer === "True" ? 0 : 1}
                    />
                  </div>
                )}
                {["fillInTheBlank", "shortAnswer", "essay"].includes(questions[questionIndex].type) && (
                  <AnswerInputFieldIncorrectCorrection
                    userAnswer={userAnswer}
                    correction={correction}
                    correctionHeader={
                      questions[questionIndex].type === "fillInTheBlank"
                        ? "Possible correct word"
                        : questions[questionIndex].type === "shortAnswer"
                        ? "Correct answer"
                        : "Feedback on your essay"
                    }
                  />
                )}

              </div>
            )}

            {showResponse && correctIncorrect === true && (
              <div>
                {questions[questionIndex].type === "multipleChoice" && (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={questions[questionIndex].choices}
                      incorrectIndex={NaN}
                      correctIndex={optionsUserAnswerIndex}
                    />
                  </div>
                )}

                {questions[questionIndex].type === "trueOrFalse" && (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={["True", "False"]}
                      incorrectIndex={NaN}
                      correctIndex={userAnswer === "True" ? 0 : 1}
                    />
                  </div>
                )}

                {["fillInTheBlank", "shortAnswer", "essay"].includes(questions[questionIndex].type) && (
                  <AnswerInputFieldCorrectCorrection
                    userAnswer={userAnswer}
                    showHeader={true}
                  />
                )}
              </div>
            )}
            {showResponse && correction !== null ? (
              <CorrectionPointsFooter
                maxPoints={maxPointsUntilNow}
                currentPoints={points}
                questionIndex={questionIndex + 1}
                numberOfQuestions={numberOfQuestions}
                handleRedirect={handleRedirect}
                handleNextQuestion={handleNextQuestion}
                isCorrect={correctIncorrect}
                pointsForQuestion={pointsForQuestion}
              />
            ) : (
              <PointsFooter 
                maxPoints={maxPointsUntilNow} 
                currentPoints={points} 
                handleShowResponse={handleShowResponse} 
                userAnswer={userAnswer} 
                loadingAnswer={loadingAnswer} 
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
};
export default ExamQuestionsGuest;