import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import getOrCreateDeviceId from "../utils/getOrCreateDeviceId";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import TextIcon from "../components/SVG/TextIcon";
import TitleInput from "../components/InputComponents/TitleInput";
import ExamUploadDocument from "../components/Exam/ExamUploadDocument";
import ExamUploadText from "../components/Exam/ExamUploadText";
import GenerateButton from "../components/Buttons/GenerateButton";
import AILoadingAnimation from "../components/AILoadingAnimation";



const tabs = [
  {
    id: "file",
    label: "Upload File",
  },
  {
    id: "text",
    label: "Upload Text",
  },
]

const ExamInputGuest = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUploadErrors, setFileUploadErrors] = useState([]);
  const [text, setText] = useState("");
  const [textError, setTextError] = useState(false);
  const [fileText, setFileText] = useState("");
  const [documentError, setDocumentError] = useState(false);
  const [numberOfQuestionsList, setNumberOfQuestionsList] = useState([0, 0, 0, 0, 0]);
  const [numberOfQuestionError, setNumberOfQuestionError] = useState(false);
  const [difficulty, setDifficulty] = useState("easy");
  const [generating, setGenerating] = useState(false);

  const titleRef = useRef(null);
  const textRef = useRef(null);

  const navigate = useNavigate();

  const maxCharacters = 10000;

  const uploadDocx = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify({
      title: title,
      number_of_multiple_choice_questions: numberOfQuestionsList[0],
      number_of_true_or_false_questions: numberOfQuestionsList[1],
      number_of_fill_in_the_blank_questions: numberOfQuestionsList[2],
      number_of_short_answer_questions: numberOfQuestionsList[3],
      number_of_essay_questions: numberOfQuestionsList[4],
      difficulty: difficulty,
    }));

    const deviceId = getOrCreateDeviceId();
    
    axios.post("/api/upload_file/exam/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Device-ID': deviceId, // Send Device ID in the headers
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log("Upload File data: ", response.data);
        navigate(`/quiz/${response.data.uuid}`);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setGenerating(false);
      });
  }




  const handleSubmit = () => {

    if (title.trim() === "") {
      setTitleError(true);
      titleRef.current.focus();
      setGenerating(false);
      return;
    };


    if (activeTab === "file" && file === null) {
      console.log("No file uploaded");
      setDocumentError(true);
      setGenerating(false);
      return;
    };

    if (activeTab === "text" && text.trim() === "") {
      console.log("Text is blank");
      setTextError(true);
      textRef.current.focus();
      setGenerating(false);
      return;
    };

    if (numberOfQuestionsList.reduce((sum, num) => sum + num, 0) === 0) {
      console.log("No questions selected");
      setNumberOfQuestionError(true);
      setGenerating(false);
      return;
    };


    setGenerating(true);

    if (file !== null) {
      console.log("Uploading DOCX file");
      return uploadDocx();
    } else if (text !== "" || fileText !== "") {
      // TODO: add here that it should maybe also add the file text if there is one
      // TODO: change that it uses instead of the summary the text?
      console.log("this is the fileText: ", fileText);
      console.log("this is the text: ", text);
      console.log("this is getting send to the backend: ");
      const data = {
        summary: text || fileText,
        title: title,
        number_of_multiple_choice_questions: numberOfQuestionsList[0],
        number_of_true_or_false_questions: numberOfQuestionsList[1],
        number_of_fill_in_the_blank_questions: numberOfQuestionsList[2],
        number_of_short_answer_questions: numberOfQuestionsList[3],
        number_of_essay_questions: numberOfQuestionsList[4],
        difficulty: difficulty,
      };
      console.log(data)

      const deviceId = getOrCreateDeviceId();
      axios.post("/api/summary_input/", data, {
        headers: {
          'Device-ID': deviceId, // Send Device ID in the headers
        },
        withCredentials: true,
      })
        .then((response) => {
          console.log("response from the backend")
          console.log(response.data);

          setGenerating(false);
          navigate(`/quiz/${response.data.uuid}`);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setGenerating(false);
        });
    } else {

      setGenerating(false);
      console.error("No text or file to upload");
    }
  };





  const updateNumberOfQuestionsField = (index, value) => {
    const newValues = [...numberOfQuestionsList];
    newValues[index] = parseInt(value);
    setNumberOfQuestionsList(newValues);
  };

  const remainingForIndex = (index) => {
    const currentTotal = numberOfQuestionsList.reduce((acc, cur, idx) => acc + (idx !== index ? cur : 0), 0);
    return 25 - currentTotal;
  };

  const createPickerItems = (max) => {
    let items = [];
    for (let i = 0; i <= max; i++) {
      items.push({ label: i.toString(), value: i });
    }
    return items;
  };



  const numberOfQuestionsTitlesList = ["Multiple Choice", "True or False", "Fill in the Blank", "Short Answer", "Essay"];

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };
  
  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20 
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };





  if (generating) {
    return (
      <div 
        className='fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-light dark:bg-dark' style={{ zIndex: 9999 }}>
        <AILoadingAnimation width={"450"} height={"450"} text={"Generating Quiz..."} />
      </div>
    );
  }


  return (
    <motion.div 
      className="bg-light dark:bg-dark mx-auto px-4 max-w-[1200px]" 
      variants={containerVariants}
      initial="hidden"
      animate="show"
    >
      {/* Need to add like a short tutorial how it works */}

      <motion.div variants={itemVariants} className="pb-5 pt-24">
        <TitleInput value={title} setValue={setTitle} titleRef={titleRef} error={titleError} setError={setTitleError} />
      </motion.div>
      <motion.div variants={itemVariants} className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-6">
        <div>
          {activeTab === "text" ? (
            <>
              <h1 className="text-2xl font-semibold dark:text-dark">Upload Text</h1>
              <h3 className="mt-1.5 text-lg dark:text-dark">
                Copy and paste your content. Your character limit is{" "}
                {maxCharacters.toLocaleString()} characters.
              </h3>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-semibold dark:text-dark">Upload File</h1>
              <h3 className="mt-1.5 text-lg dark:text-dark">
                Your character limit is{" "}{maxCharacters.toLocaleString()} characters. File size limit: 5 MB.
              </h3>
            </>
          )}
        </div>
        <div className="xl:mt-0 xl:ml-6">
          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg border-gray-300 inline-block p-1">
            <div className="inline-flex space-x-1">
              {tabs.map((tab) => {
                const isActive = activeTab === tab.id;
                const changeActiveTab = () => {
                  setFile(null);
                  setText("");  
                  setFileText("");
                  setActiveTab(tab.id);
                };
                return (
                  <button
                    key={tab.id}
                    tabIndex={-1}
                    onClick={changeActiveTab}
                    className={`
                      flex items-center relative rounded-full px-6 py-2.5 font-medium
                      outline-sky-400 transition-colors duration-300 focus-visible:outline-2
                      ${isActive ? "text-white" : "group text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-blue-600"}
                    `}
                    style={{
                      WebkitTapHighlightColor: "transparent",
                    }}
                  >
                    {isActive && (
                      <motion.span
                        layoutId="bubbleExam"
                        className="absolute inset-0 z-10 bg-blue-700 dark:bg-blue-600"
                        style={{ borderRadius: "9999px" }}
                        transition={{
                          type: "spring",
                          bounce: 0.2,
                          duration: 0.6,
                        }}
                        animate={{ opacity: 0.9 }}
                        initial={{ opacity: 0.9 }}
                      />
                    )}
                    <span className="z-20 relative flex items-center">
                      {tab.id === "text" ? (
                        <TextIcon
                          className={`
                            w-6 h-6 mr-2
                            ${isActive ? "text-white" : "text-blue-600 dark:text-blue-500 group-hover:text-blue-700 dark:group-hover:text-blue-600"}
                            transition-colors duration-300
                          `}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faFileLines}
                          className={`
                            w-6 h-6 mr-2
                            ${isActive ? "text-white" : "text-blue-600 dark:text-blue-500 group-hover:text-blue-700 dark:group-hover:text-blue-600"}
                            transition-colors duration-300
                          `}
                        />
                      )}
                      {tab.label}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div variants={itemVariants} >
        {activeTab === "text" ? (
          <ExamUploadText
            userCharacterLimit={maxCharacters}
            text={text}
            setText={setText}
            textRef={textRef}
            error={textError}
            setError={setTextError}
          />
        ) : (
          <ExamUploadDocument
            setFile={setFile}
            userCharacterLimit={maxCharacters}
            setFileText={setFileText}
            fileUploadErrors={fileUploadErrors}
            setFileUploadErrors={setFileUploadErrors}
            guestMode={true}
            fileError={documentError}
          />
        )}
      </motion.div>
      <motion.div variants={itemVariants}>

        <p className="text-2xl font-semibold mt-8 mb-6 dark:text-dark">Number of questions</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 justify-center rounded-lg p-6 bg-white dark:bg-gray-800 shadow-lg dark:shadow-none">
          {numberOfQuestionsList.map((value, index) => (
            <div className="flex flex-col" key={index}>
                <p className="mb-2 text-lg dark:text-dark">
                  {numberOfQuestionsTitlesList[index]}
                </p>
                <select
                  className={`${numberOfQuestionError ? "border-red-500" : "border-gray-200 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500"} border rounded-lg p-2.5 font-medium shadow dark:shadow-none outline-none  focus:outline-none dark:bg-gray-700 dark:text-dark`}
                  value={value}
                  onChange={(e) => updateNumberOfQuestionsField(index, e.target.value)}
                >
                  {createPickerItems(remainingForIndex(index)).map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

            </div>
          ))}

          <div className="flex flex-col">
            <label className="mb-2 text-lg dark:text-dark">Difficulty Level</label>
            <select 
              className="border border-gray-200 dark:border-gray-700 p-2.5 rounded-lg shadow dark:shadow-none focus:border-gray-400 dark:focus:border-gray-500 focus:outline-none dark:bg-gray-700 dark:text-dark"
              onChange={(e) => setDifficulty(e.target.value)}
              value={difficulty}
            >
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </div>
          {numberOfQuestionError && (
            <p className="text-red-500 mt-2 font-semibold">Select at least one question type</p>
          )}
        </div>

        </motion.div>
        <motion.div variants={itemVariants} className="flex justify-center py-12">
          <GenerateButton onClick={handleSubmit} label={"Generate Exam"} />
        </motion.div>

    </motion.div>
  )
}
export default ExamInputGuest;