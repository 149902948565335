const BoldCheckMarkGreen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={48}
    height={48}
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill="#58cc02"
          d="M116.049 194.505a67.98 67.98 0 0 0-96.14.002v.001c-26.546 26.547-26.545 69.587.001 96.134l134.296 134.301a67.977 67.977 0 0 0 96.138.002L492.089 183.2c26.546-26.545 26.548-69.583.006-96.132l-.007-.007a67.982 67.982 0 0 0-96.143-.005l-188.021 188.02a7.994 7.994 0 0 1-11.304 0z"
          opacity={1}
          data-original="#66bb6a"
          className=""
        />
        <path
          fill="#58a700"
          d="M433.014 68.041a67.977 67.977 0 0 1 37.074 19.02l.007.007c26.542 26.549 26.54 69.587-.006 96.132L228.344 424.945a67.97 67.97 0 0 1-37.068 19.014 67.981 67.981 0 0 0 59.068-19.014L492.089 183.2c26.546-26.545 26.548-69.583.006-96.132l-.007-.007a67.982 67.982 0 0 0-59.074-19.02z"
          opacity={1}
          data-original="#4caf50"
          className=""
        />
      </g>
    </g>
  </svg>
);
export default BoldCheckMarkGreen;
