import { useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import PropTypes from 'prop-types';

const AnswerInputFieldCorrectCorrection = ({ userAnswer, showHeader, isRecapPage }) => {
  const textareaRef = useRef(null);


  return (
    <div className="flex flex-col items-center w-full">
      {showHeader && <p className="font-medium text-2xl mb-4 dark:text-dark">Your answer</p>}

      <TextareaAutosize
        ref={textareaRef}
        minRows={1}
        maxRows={10}
        value={userAnswer}
        readOnly
        className={`mb-4 p-2 w-full border-b-2 border-green-400 dark:border-green-500 bg-green-50 rounded-lg shadow-color-lg shadow-green-300 dark:shadow-none outline-none text-lg resize-none dark:text-white ${isRecapPage ? 'dark:bg-gray-700' : 'dark:bg-gray-800'}`}
        style={{
          lineHeight: '1.5',
          overflowY: 'hidden',
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // IE 10+
        }}
        onClick={(e) => e.stopPropagation()}
        onHeightChange={() => {
          const textarea = textareaRef.current;
          if (textarea) {
            if (textarea.scrollHeight > textarea.clientHeight) {
              textarea.style.overflowY = 'auto';
            } else {
              textarea.style.overflowY = 'hidden';
            }
            // Ensure the scroll position is at the top
            textarea.scrollTop = 0;
          }
        }}
      />
      <style>
        {`
          textarea::WebkitScrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  );
};

AnswerInputFieldCorrectCorrection.propTypes = {
  userAnswer: PropTypes.string.isRequired,
  showHeader: PropTypes.bool.isRequired,
};

export default AnswerInputFieldCorrectCorrection;


// This was it before adding the TextArea autoresize to it:
// import { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';

// const AnswerInputFieldCorrectCorrection = ({ userAnswer, showHeader }) => {
//   const userAnswerRef = useRef(null);
  
//   const [isUserAnswerScrollable, setIsUserAnswerScrollable] = useState(false);

//   useEffect(() => {
//     checkIfScrollable(userAnswerRef.current, setIsUserAnswerScrollable);
//   }, [userAnswer]);

//   /**
//    * Checks if the content exceeds 5 lines and updates the scrollable state.
//    * @param {HTMLElement} element - The DOM element to check.
//    * @param {Function} setScrollable - State setter function.
//    */
//   const checkIfScrollable = (element, setScrollable) => {
//     if (element) {
//       const computedStyle = window.getComputedStyle(element);
//       const lineHeight = parseFloat(computedStyle.lineHeight);

//       // Calculate the maximum allowed height for 5 lines
//       const maxAllowedHeight = lineHeight * 11;

//       // Temporarily remove maxHeight to get the actual scrollHeight
//       element.style.maxHeight = 'none';
//       const totalHeight = element.scrollHeight;

//       if (totalHeight > maxAllowedHeight) {
//         setScrollable(true);
//         element.style.maxHeight = `${maxAllowedHeight}px`;
//         element.style.overflowY = 'auto';
//       } else {
//         setScrollable(false);
//         element.style.maxHeight = 'none';
//         element.style.overflowY = 'hidden';
//       }
//     }
//   };

//   return (
//     <div className="flex flex-col items-center">
//       {showHeader && <p className="font-medium text-2xl mb-5">Your answer</p>}
      
//       <div
//         ref={userAnswerRef}
//         className="mb-3 p-2 w-full border-2 border-green-400 bg-green-50 rounded-lg shadow-color-lg shadow-green-300 outline-none text-lg font-medium"
//         style={{
//           whiteSpace: 'pre-wrap',
//           wordWrap: 'break-word',
//           lineHeight: '1.5', // Ensure consistent line height (1.5em)
//           scrollbarWidth: 'none', // Firefox
//           msOverflowStyle: 'none', // Internet Explorer 10+
//           overflowY: isUserAnswerScrollable ? 'auto' : 'hidden',
//           maxHeight: isUserAnswerScrollable ? 'calc(1.5em * 5)' : 'none',
//         }}
//         aria-readonly="true"
//         role="textbox"
//         tabIndex={0}
//       >
//         {userAnswer}
//         <style>
//           {`
//             div[role="textbox"]::-webkit-scrollbar {
//               display: none;
//             }
//           `}
//         </style>
//       </div>
//     </div>
//   );
// };

// AnswerInputFieldCorrectCorrection.propTypes = {
//   userAnswer: PropTypes.string.isRequired,
//   showHeader: PropTypes.bool.isRequired,
// };

// export default AnswerInputFieldCorrectCorrection;

