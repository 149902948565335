import { useState } from 'react';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPaste } from "@fortawesome/free-solid-svg-icons";
import { Notify } from '../../providers/NotificationProvider';
import Modal from './Modal';
import { motion } from 'framer-motion';


const GetEditAccessModal = ({ showModal, setShowModal, flashcardSetId }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const handleSubmitPassword = () => {
    const data = {
      password: password,
      uuid: flashcardSetId
    };

    api.post("/api/flashcards/check_flashcard_editor_password/", data)
      .then((response) => {
        console.log(response.data);
        Notify({message: "Edit Access Unlocked", type: "success"});
        setShowModal(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.data.password) {
          setPasswordErrorMessage(error.response.data.password.join(" "));
        } else {
          setPasswordErrorMessage("An unexpected error occurred.");
        }
        setPasswordError(true);
      });
  };

  const handlePinChange = (index, value) => {
    const re = /^[0-9\b]+$/;
    if (!re.test(value) && value !== '') return;
    let newPin = password.split('');
    newPin[index] = value;
    newPin = newPin.join('').slice(0, 6);
    setPassword(newPin);
  
    // Move focus to the next input if user has entered a digit
    if (value && index < 5) {
      const nextInput = document.getElementById(`pin-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  

  const handlePastePin = async () => {
    try {
      const clipText = await navigator.clipboard.readText();
      const digits = clipText.replace(/[^0-9]/g, '').slice(0, 6);
      setPassword(digits);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)} >
      <div className="bg-white dark:bg-dark rounded-lg shadow-lg max-w-2xl w-full">
        <div className="p-8">
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl font-bold dark:text-dark'>Enter Pin for Edit Access</h2>
            <motion.button
              onClick={() => setShowModal(false)}
              className="hover:text-gray-400 text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
              style={{ fontSize: "28px"}}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </motion.button>
          </div>
          <h3 className="text-lg text-gray-600 dark:text-gray-400 pt-4 mb-8">To access editable features of the Flashcard Set, you’ll need a password. A user with edit permissions can share this with you. Find the password in the "More Options" (···) tab</h3>

          <div className="flex mb-2 space-x-2 rtl:space-x-reverse">
            {Array.from({ length: 6 }).map((_, i) => (
              <input
                key={i}
                id={`pin-input-${i}`}
                type="text"
                maxLength="1"
                value={password[i] || ''}
                onChange={(e) => handlePinChange(i, e.target.value)}
                className="block w-12 h-12 text-xl font-extrabold text-center text-gray-900 dark:text-white bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg focus:outline-none focus:border-gray-400 dark:focus:border-gray-500"
              />
            ))}
            <button 
              className="pl-3 text-2xl text-blue-600 hover:text-blue-700"
              onClick={handlePastePin}
            >
              <FontAwesomeIcon icon={faPaste} />
            </button>
          </div>
          {passwordError && <p className="text-red-500 font-medium mt-2 text-lg">{passwordErrorMessage}</p>}

          <div className="flex justify-between mt-16">
            <button 
              className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-700 dark:hover:border-gray-600 w-full max-w-60 hover:border-gray-400 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition dark:text-dark font-medium"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button 
              className="px-6 py-2 bg-gradient-to-t from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition font-medium w-full max-w-60" 
              onClick={handleSubmitPassword}
            >
              Submit
            </button>
          </div>
          
        </div>

      </div>
    </Modal>
  );
};

export default GetEditAccessModal;
