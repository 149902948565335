import { useState, useEffect } from "react";
import "reactjs-popup/dist/index.css";
import { useParams, useNavigate } from "react-router-dom";
import QuestionBox from "../components/OtherQuestionComponents/QuestionBox";
import InputFieldComponent from "../components/OtherQuestionComponents/InputFieldComponent";
import AnswerSubmitButton from "../components/OtherQuestionComponents/AnswerSubmitButton";
import NextQuestionButton from "../components/OtherQuestionComponents/NextQuestionButton";
import FlashcardProgressBar from "../components/DefinitionQuestionComponents/FlashcardProgressBar";
import LoadingSpinner from "../components/General/LoadingSpinner";
import api from "../api";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import LimitModal from "../components/Modals/LimitModal";

const FlashcardWriteMode = () => {
  const [userAnswer, setUserAnswer] = useState("");
  const [showQuestion, setShowQuestion] = useState(true);
  const [correction, setCorrection] = useState("");
  const [correctIncorrect, setCorrectIncorrect] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [flashcards, setFlashcards] = useState([]);
  const [flashcardIndex, setFlashcardIndex] = useState(0);
  const [numberOfFlashcards, setNumberOfFlashcards] = useState(0);

  const [askDefinitions, setAskDefinitions] = useState(false);
  const [loadingCorrection, setLoadingCorrection] = useState(false);
  const [progressBarIndex, setProgressBarIndex] = useState(0);
  const [originalFlashcards, setOriginalFlashcards] = useState([]);
  const [showLimitModal, setShowLimitModal] = useState(false);


  const { uuid, type } = useParams();
  const navigate = useNavigate();




  const shuffleFlashcards = (flashcardsInput) => {
    for (let i = flashcardsInput.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [flashcardsInput[i], flashcardsInput[j]] = [flashcardsInput[j], flashcardsInput[i]];
    }
    return flashcardsInput;
  };



  const askTermsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.definition,
    }));
  }
  const askDefinitionsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.word,
    }));
  }

  const updateFlashcardsThatAreAsked = (flashcardData) => {
    const newUuidList = flashcardData.map(flashcard => flashcard.uuid);
    const data = {
      flashcard_uuids: newUuidList,
      flashcard_set_uuid: uuid
    }
    api.put("/api/flashcards/update_write_mode_uuid_getting_asked/", data)
    .then(() => {
      console.log("Updated flashcards that are asked");
    })
    .catch((error) => {
      console.error("Failed to update flashcards that are asked:", error);
    });  
  };




  const processFlashcards = (responseData, askRandomlyLocal, askDefinitionsLocal) => {
    let flashcardData = responseData;
    if (askRandomlyLocal) {
      flashcardData = shuffleFlashcards(flashcardData);
    }
    
    if (!askDefinitionsLocal) {
      flashcardData = askTermsFormatter(flashcardData);
    }
    else {
      flashcardData = askDefinitionsFormatter(flashcardData);
    }

    setFlashcards(flashcardData);

    setNumberOfFlashcards(responseData.length);
    return updateFlashcardsThatAreAsked(flashcardData);
  };

  const getAllFlashcards = (askRandomlyLocal, askDefinitionsLocal) => {
    api.get("/api/flashcards/all/"+uuid+"/")
    .then((response) => {
      setOriginalFlashcards(response.data);
      processFlashcards(response.data, askRandomlyLocal, askDefinitionsLocal);
    })
    .catch((error) => {
      console.error("Failed to fetch flashcards from set:", error);
    });
  };



  const getStarredFlashcards = (askRandomlyLocal, askDefinitionsLocal) => {
    api.get("/api/flashcards/starred/"+uuid+"/")
      .then((response) => {
        setOriginalFlashcards(response.data);
        processFlashcards(response.data, askRandomlyLocal, askDefinitionsLocal);
      })
      .catch((error) => {
        console.error("Failed to fetch flashcards from set:", error);
      });
  };

  const getIncorrectFlashcards = (askRandomlyLocal, askDefinitionsLocal) => {
    api.get("/api/flashcards/incorrect/write_mode/"+uuid+"/")
      .then((response) => {
        setOriginalFlashcards(response.data);
        processFlashcards(response.data, askRandomlyLocal, askDefinitionsLocal);
      })
      .catch((error) => {
        console.error("Failed to fetch flashcards from set:", error);
      });
  };

  useEffect(() => {
    const urls = [`/api/flashcards/write_mode_settings/${uuid}/`, `/api/flashcards/question_index/${uuid}/`]

    Promise.all(urls.map(url => api.get(url)))
      .then((responses) => {
        const settingsResponse = responses[0].data;
        const questionIndex = responses[1].data.question_index;
        setAskDefinitions(settingsResponse.ask_definitions);

        if (questionIndex !== 0 && questionIndex !== undefined) {
          setFlashcardIndex(questionIndex);
          setProgressBarIndex(questionIndex);
          handleSavedFlashcards(settingsResponse.ask_definition, questionIndex);
        } else {
          setFlashcardIndex(0);
          setProgressBarIndex(0);
          switch (type) {
            case "all":
              getAllFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
              break;
            case "starred":
              getStarredFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
              break;
            case "incorrect":
              console.log("fetching incorrect flashcards")
              getIncorrectFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
              break;
            case "starred-incorrect":
              getIncorrectFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
              break;
            default:
              console.log("Invalid type");
              break;
          }
        }

      })
      .catch((error) => {
        console.error("Failed to fetch settings:", error);
      });
  }, [type, uuid]);



  const handleSavedFlashcards = (askDefinitionsLocal, currentCardIndex) => {
    api.get("/api/flashcards/saved_write_mode_flashcards/" + uuid + "/")
    .then((response) => {
      console.log("response data", response.data);
      if (response.data.length === currentCardIndex) {
        return handleRedirect();
      }
      setOriginalFlashcards(response.data);
      let flashcardData = response.data;
      if (!askDefinitionsLocal) {
        flashcardData = askTermsFormatter(flashcardData);
      }
      else {
        flashcardData = askDefinitionsFormatter(flashcardData);
      }
      setFlashcards(flashcardData);
      setNumberOfFlashcards(response.data.length);
    })
    .catch((error) => {
      console.error("Failed to fetch saved flashcards:", error);
    });
  }


  useEffect(() => {
    const handleSettingsUpdate = () => {
      console.log("Getting updated settings")
      api.get("/api/flashcards/write_mode_settings/" + uuid + "/")
      .then((settingsResponseData) => {
        const settingsResponse = settingsResponseData.data;
        const newUuidList = flashcards.map(flashcard => flashcard.uuid);
        api.post("/api/flashcards/get_flashcards_in_order/", {uuids: newUuidList})
        .then((response) => {
          setAskDefinitions(settingsResponse.ask_definition);
          console.log("ask randomly", settingsResponse.ask_randomly);
          let updatedFlashcards = response.data;
          if (settingsResponse.ask_randomly && !settingsResponse.have_been_shuffled) {
            updatedFlashcards = shuffleFlashcards(updatedFlashcards);
          } else if (!settingsResponse.ask_randomly && settingsResponse.have_been_shuffled) {
            updatedFlashcards = response.data;
          } else {
            updatedFlashcards = originalFlashcards;
          }

          setOriginalFlashcards(updatedFlashcards);
          if (settingsResponse.ask_definition) {
            updatedFlashcards = askDefinitionsFormatter(updatedFlashcards);
          } else {
            updatedFlashcards = askTermsFormatter(updatedFlashcards);
          }
          updateFlashcardsThatAreAsked(updatedFlashcards);

          setFlashcards(updatedFlashcards);
          setNumberOfFlashcards(updatedFlashcards.length);
        })
        .catch((error) => {
          console.error("Failed to fetch flashcards from set:", error);
        });
      })
      .catch((error) => {
        console.error("Failed to fetch flashcards from set:", error);
      });
    };
    window.addEventListener("modalClosedAndSettingsUpdated", handleSettingsUpdate);
  
    return () => {
      window.removeEventListener("modalClosedAndSettingsUpdated", handleSettingsUpdate);
    };
  }, [flashcards]);






  const handleNext = () => {
    if (flashcardIndex + 1 === flashcards.length) {
      handleRedirect();
    } else {
      handleNextWord();
    }
  }


  const handleCorrection = () => {
    setSubmitted(true);
    setLoadingCorrection(true);
    if (flashcardIndex < flashcards.length + 1 && userAnswer !== "") {
      const postData = {
        user_answer : userAnswer,
        flashcard_uuid : flashcards[flashcardIndex].uuid,
        flashcard_set_uuid : uuid,
        question_type: type,
      };



      api.post("/api/flashcards/user_answer/", postData)
        .then((response) => {
          api.get("/api/flashcards/correction/" + flashcards[flashcardIndex].uuid + "/" + (askDefinitions ? "definition" : "term") +  "/")
            .then((response) => {
              const responseData = response.data;
              console.log("response data", responseData);
              const enough_tokens = response.data.enough_tokens;
              if (enough_tokens === false && enough_tokens !== undefined) {
                setShowLimitModal(true);
                return null;
              } else {
                setShowLimitModal(false);
                setCorrection(responseData.correction);
                setCorrectIncorrect(responseData.correct_incorrect);
              }
              setProgressBarIndex((prevIndex) => prevIndex + 1);
              setShowQuestion(false);
              setLoadingCorrection(false);
            })
            .catch((error) => {
              console.error("Failed to fetch correction data:", error);
            });
        })
        .catch((error) => {
          console.error("Failed to save user answer:", error);
        });

      setSubmitted(false);
    } else {
      setLoadingCorrection(false);
    }
  };





  const handleNextWord = () => {
    setFlashcardIndex((prevIndex) => prevIndex + 1);
    setCorrectIncorrect(null);
    setShowQuestion(true);
    setUserAnswer("");
  };


  const handleRedirect = () => {
    api.put("/api/flashcards/write_mode/update_question_index/", { uuid : uuid, question_type: "", index: 0 })
    .then(() => {
      console.log("Deleted fields");

      return navigate(`/flashcard/recap/${uuid}/${type}`);
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
    });

  };
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && !showQuestion && correction !== null) {
        if (numberOfFlashcards === flashcardIndex + 1) {
          return handleRedirect();
        } else {
          return handleNext();
        }
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [showQuestion, correction, flashcardIndex, numberOfFlashcards]);



  if (flashcards.length === 0 || flashcards === undefined) {
    return (
      <LoadingSpinner />
    );
  }
  return (
    <div className="min-h-screen bg-light dark:bg-dark">
      <LimitModal showModal={showLimitModal} setShowModal={setShowLimitModal} limitReason={"tokens"} />
      <FlashcardProgressBar index={progressBarIndex} numberOfQuestions={numberOfFlashcards} flashcards={flashcards} uuid={uuid} />

      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-1/2">
          <QuestionBox maxPoints={0} question={flashcards[flashcardIndex].question}/>
          {showQuestion &&(
            <>
              <InputFieldComponent 
                value={userAnswer}
                setValue={setUserAnswer}
                placeholder={!askDefinitions ? "Enter term" : "Enter definition"}
                submitted={submitted}
                handleSubmit={handleCorrection}
              />
              <AnswerSubmitButton
                loadingState={loadingCorrection}
                onClick={handleCorrection}
                userAnswer={userAnswer}
              />
            </>
          )}
          {!showQuestion && correctIncorrect === true &&(
            <div>
              <AnswerInputFieldCorrectCorrection 
                userAnswer={userAnswer}
                showHeader={false}
              />
              <NextQuestionButton
                questionIndex={flashcardIndex + 1}
                numberOfQuestions={flashcards.length}
                handleRedirect={handleRedirect}
                handleNextQuestion={handleNext}
              />
            </div>
          )}

          {!showQuestion && correctIncorrect === false && (
            <div>

              <AnswerInputFieldIncorrectCorrection 
                userAnswer={userAnswer}
                correctionHeader={!askDefinitions ? "Correct term" : "Correct definition"}
                correction={correction}
              />
              <NextQuestionButton
                questionIndex={flashcardIndex + 1}
                numberOfQuestions={flashcards.length}
                handleRedirect={handleRedirect}
                handleNextQuestion={handleNext}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlashcardWriteMode;
