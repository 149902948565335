const NextQuestionButton = ({questionIndex, numberOfQuestions, handleRedirect, handleNextQuestion}) => {
  console.log("this is the question index: ", questionIndex)
  console.log("this is the number of questions: ", numberOfQuestions)


  return (
    <div className="flex justify-center mt-24">

      <button
        className={`max-w-80 w-full px-6 py-3 rounded-lg text-xl font-semibold transition shadow-lg dark:shadow-none text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600`} 
        onClick={questionIndex === numberOfQuestions ? handleRedirect : handleNextQuestion}
      >
        {questionIndex === numberOfQuestions ? "Show results" : "Next question"}
      </button>
     
    </div>
  )
}
export default NextQuestionButton;