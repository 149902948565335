import { useEffect, useState } from "react";
import api from "./../api";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faBug, faCheckCircle, faSignOut, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ConfirmDeletionModal from "./../components/ConfirmDeletionModal";
import Cookies from "js-cookie";
import { Notify } from "../providers/NotificationProvider";
import { motion } from "framer-motion";
import useTheme from "../hooks/useTheme";

const Account = () => {
  const [googleAccount, setGoogleAccount] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [hasStripeAccount, setHasStripeAccount] = useState(false);
  const [customerPlan, setCustomerPlan] = useState("");
  const [userLanguage, setUserLanguage] = useState("")
  const [oldUserLanguage, setOldUserLanguage] = useState("")
  const [showSaveLanguage, setShowSaveLanguage] = useState(false);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [confirmDeletionModalText, setConfirmDeletionModalText] = useState("");
  const [confirmDeletionModalHeader, setConfirmDeletionModalHeader] = useState("");
  const [confirmDeletionButtonLabel, setConfirmDeletionButtonLabel] = useState("");
  const [confirmDeletionType, setConfirmDeletionType] = useState(""); 


  const [theme, setTheme] = useTheme();
  const navigate = useNavigate();


  const features = [
    "Unlimited Tokens",
    "Unlimited Image Scans",
    "Unlimited Document Uploads",
    "Unlimited Flashcards In Each Set",
    "50'000 Characters Text Upload Limit",
  ]



  useEffect(() => {
    const urls = [
      "/api/auth/account-type/",
      "/api/auth/user/",
      "/api/payment/subscriptions/",
      "/api/userinfo/"
    ]

    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      setGoogleAccount(responses[0].data.social_account);
      if (!responses[0].data.social_account) {
        setPasswordEdit(true);
      }
      setEmail(responses[1].data.email);
      if (responses[2].data.customer_id !== "") {
        setHasStripeAccount(true);
        setCustomerPlan(responses[2].data.subscription_tier);
      }
      setUserLanguage(responses[3].data.language);
      setOldUserLanguage(responses[3].data.language);
    })
    .catch((errors) => {
      console.error(errors);
    });


  }, []);





  const onLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setUserLanguage(newLanguage);
    if (newLanguage !== oldUserLanguage) {
      saveLanguageToDatabase(newLanguage);
    }
  };

  const saveLanguageToDatabase = (language) => {
    api.put("/api/userinfo/", { language: language })
      .then((response) => {
        setOldUserLanguage(language);
      })
      .catch((error) => {
        console.error("Failed to save language:", error);
      });
  };

  const onLogout = () => {
    setConfirmDeletionButtonLabel("Sign Out");
    setConfirmDeletionModalHeader("Sign Out");
    setConfirmDeletionModalText("Are you sure you want to sign out from your account?");
    setConfirmDeletionType("logout");
    setShowConfirmDeletionModal(true);
  };

  const onDeleteAccount = () => {
    setConfirmDeletionButtonLabel("DELETE ACCOUNT");
    setConfirmDeletionModalHeader("Delete Account");
    setConfirmDeletionModalText("Are you sure you want to delete your account? This action cannot be undone.");
    setConfirmDeletionType("delete");
    setShowConfirmDeletionModal(true);
  };

  const handleDeleteAccount = () => {
    api.delete('/api/auth/delete_user/')
    .then((response) => {
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      Notify({message: "Your account has been deleted!", type: "success"});
      window.location.href = '/';
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleLogout = () => {
    api.post("/api/auth/logout/")
      .then((response) => {
        Notify({message: "You have been signed out", type: "success"});
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        window.location.href = '/';
      })
    .catch((error) => {
      console.error(error);
    });
  };



  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };
  
  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20 
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };



  return (
    <div className="min-h-screen bg-light dark:bg-dark">
      <ConfirmDeletionModal
        showModal={showConfirmDeletionModal}
        setShowModal={setShowConfirmDeletionModal}
        text={confirmDeletionModalText}
        header={confirmDeletionModalHeader}
        buttonLabel={confirmDeletionButtonLabel}
        handleDelete={
          confirmDeletionType === "logout" ? handleLogout : handleDeleteAccount
        }
      />
        <motion.div
          className="max-w-5xl flex justify-center items-center flex-col"
          style={{ margin: "0px auto", padding: "60px 20px" }}
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <motion.div 
            className="w-full flex justify-between items-center pb-7"
            initial="hidden"
            animate="show"
            variants={{
              hidden: { y: -20, opacity: 0 },
              show: {
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.6,
                  ease: "easeOut",
                  when: "beforeChildren"
                }
              }
            }}
          >
            <h1 className="text-4xl font-semibold dark:text-dark">Your account</h1>
          </motion.div>

          <motion.div
            className="p-8 w-full rounded-3xl mb-12 shadow-lg bg-white dark:bg-gray-800"
            variants={itemVariants}
          >
            {/* Your details content */}
            <p className="text-2xl font-semibold dark:text-dark">Your details</p>
            <div className="py-6">
              <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-1">
                Your email address
              </p>
              <p className="font-semibold dark:text-dark">{email}</p>
            </div>
            {passwordEdit && (
              <div className="pb-6 flex justify-between items-center">
                <div>
                  <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-1">
                    Your password
                  </p>
                  <p className="font-semibold text-lg dark:text-dark">•••••••••••••</p>
                </div>
                <motion.button
                  className="text-blue-600 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-400 focus:outline-none"
                  onClick={() => navigate("/changepassword")}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FontAwesomeIcon icon={faPen} className="text-lg" />
                </motion.button>
              </div>
            )}
          </motion.div>


          <motion.div
            className="p-8 w-full rounded-3xl mb-12 shadow-lg bg-white dark:bg-gray-800"
            variants={itemVariants}
          >
            {/* Support content */}
            <p className="text-2xl font-semibold mb-4 dark:text-dark">
              Settings
            </p>
            <div className="pb-6 flex-row">
              <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-2.5">
                Language for the <span className="font-bold">feedback</span> and{" "}
                <span className="font-bold">exams</span>
              </p>

              <select
                className="font-semibold p-2.5 pr-20 shadow-lg dark:shadow-none border border-gray-200 focus:border-gray-400 rounded-lg focus:outline-none dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-500 dark:text-dark"
                onChange={(event) => onLanguageChange(event)}
                value={userLanguage}
              >
                <option value={"en"}>English 🇺🇸</option>
                <option value={"de"}>Deutsch 🇩🇪</option>
              </select>
            </div>
            <div className="border-b border-gray-200 dark:border-gray-600 mt-2" />
            <div className="pt-6 flex-row">
              <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-2.5">
                Choose Your Theme
              </p>

              <select
                className="font-semibold text-md p-2.5 pr-20 shadow-lg dark:shadow-none border border-gray-200 focus:border-gray-400 rounded-lg focus:outline-none dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-500 dark:text-dark"
                onChange={(event) => setTheme(event.target.value)}
                value={theme}
              >
                <option value={"system"}>System</option>
                <option value={"light"}>Light</option>
                <option value={"dark"}>Dark </option>
              </select>
            </div>
          </motion.div>

          <motion.div
            className="p-8 w-full rounded-3xl mb-12 shadow-lg bg-white dark:bg-gray-800"
            variants={itemVariants}
          >
            {/* Your subscription content */}
            <p className="text-2xl font-semibold mb-4 dark:text-dark">Your subscription</p>
            {hasStripeAccount ? (
              <div className="py-2 flex flex-col">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-1">
                      Your plan
                    </p>
                    <p className="font-semibold text-lg dark:text-dark">
                      {customerPlan === "max" ? "Study Buddy MAX" : "None"}
                    </p>
                  </div>
                  <Link
                    to="https://billing.stripe.com/p/login/bIYg1haUscJZ6wE4gg"
                    className="hover:bg-blue-500 transition font-semibold leading-6 text-white bg-blue-600 rounded-full px-6 py-1.5"
                  >
                    Manage
                  </Link>
                </div>
                <div className="mt-4">
                  <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-2">
                    Your benefits
                  </p>
                  <ul className="list-none pl-6">
                    {features.map((feature, index) => (
                      <motion.li
                        key={index}
                        className="flex items-center mt-2.5"
                        variants={itemVariants}
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-blue-600 text-xl mr-2.5"
                        />
                        <span className="text-lg font-medium text-gray-800 dark:text-gray-200">
                          {feature}
                        </span>
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <p className="text-xl font-medium dark:text-dark">
                  You're not subscribed to Study Buddy.
                </p>
                <p className="text-lg mt-2.5 dark:text-dark">
                  Upgrade to get ∞ tokens & other max feature
                </p>
                <motion.button
                  className="bg-gradient-to-t from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 text-lg text-white rounded-lg text-center py-2 w-1/2 mt-6 font-semibold"
                  onClick={() => navigate("/pricing")}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Upgrade
                </motion.button>
              </div>
            )}
          </motion.div>

          <motion.div
            className="p-8 w-full rounded-3xl mb-12 shadow-lg bg-white dark:bg-gray-800"
            variants={itemVariants}
          >
            {/* Support content */}
            <p className="text-2xl font-semibold mb-4 dark:text-dark">Support</p>
            <p className="text-lg font-semibold text-neutral-500 mb-4 dark:text-gray-300">
              Did you encounter any unusual behavior or issues? Let us know by
              reporting a bug below.
            </p>
            <motion.button
              className="flex flex-row items-center py-2 px-6 bg-blue-600 hover rounded-lg hover:bg-blue-500"
              onClick={() =>(window.location.href = "mailto:support@studybuddyai.app")}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <FontAwesomeIcon
                icon={faBug}
                className="text-white text-lg mr-2.5"
              />
              <span className="text-start font-semibold text-white">
                Report a bug
              </span>
            </motion.button>

            <div className="border-b border-gray-200 dark:border-gray-600 mt-6" />

            <p className="mt-6 mb-4 text-2xl font-semibold dark:text-dark">Privacy Policy</p>
            <a
              className="text-lg text-blue-600 dark:text-blue-500 font-medium hover:text-blue-700 dark:hover:text-blue-600 hover:underline hover:underline-offset-2"
              href="https://studybuddyai.app/privacy-policy"
            >
              Read our Privacy Policy
            </a>

            <div className="border-b border-gray-200 dark:border-gray-600 mt-6" />

            <p className="mt-6 mb-4 text-2xl font-semibold dark:text-dark">Terms Of Service</p>
            <a
              className="text-lg text-blue-600 dark:text-blue-500 font-medium hover:text-blue-700 dark:hover:text-blue-600 hover:underline hover:underline-offset-2"
              href="https://studybuddyai.app/terms-of-service"
            >
              Terms Of Service
            </a>
          </motion.div>

          <motion.div
            className="p-8 w-full rounded-3xl mb-12 bg-white dark:bg-gray-800 dark:shadow-none shadow-lg shadow-red-400"
            variants={itemVariants}
          >
            {/* Logout and Delete Account content */}
            <p className="text-2xl font-semibold mb-4 dark:text-dark">Sign out</p>
            <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-4">
              Do you want to sign out from your account?
            </p>
            <motion.button
              onClick={(e) => {e.stopPropagation(); onLogout();}}
              className="px-6 py-2 bg-gradient-to-t from-red-600 to-red-500 text-white rounded-lg hover:from-red-700 hover:to-red-600 transition font-medium"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FontAwesomeIcon icon={faSignOut} className="mr-2.5" />
              Sign Out
            </motion.button>

            <div className="border-b border-gray-200 dark:border-gray-600 mt-6" />

            <p className="text-2xl font-semibold mb-4 mt-6 dark:text-dark">Delete Account</p>
            <p className="text-lg font-semibold text-neutral-500 dark:text-gray-300 mb-4">
              Are you sure you want to delete your account? This action cannot be
              undone.
            </p>
            <motion.button
              onClick={(e) => {e.stopPropagation(); onDeleteAccount();}}
              className="px-6 py-2 bg-gradient-to-t from-red-600 to-red-500 text-white rounded-lg hover:from-red-700 hover:to-red-600 transition font-medium"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2.5" />
              Delete Account
            </motion.button>
          </motion.div>
        </motion.div>
      
    </div>
  );
};

export default Account;