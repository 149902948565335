import { Toaster, toast } from 'react-hot-toast';
import useDarkMode from '../hooks/useDarkMode';

const NotificationProvider = ({ children }) => {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontWeight: '500',
            fontSize: '16px',
            padding: '16px 20px',
            width: 'auto',
            maxWidth: '100%'
          }
        }}
      />
      {children}
    </>
  );
};

export const Notify = ({ message, type = 'success', duration = 4000 }) => {

  const options = {
    duration,
    position: 'top-center',
    style: {
      fontWeight: '500', 
      fontSize: '16px',
      padding: '16px 20px',
      width: 'auto',
      maxWidth: '100%',
      color: type === 'success' ? '#22c55e' : '#ef4444',
    }
  };

  if (type === 'success') {
    toast.success(message, options);
  } else if (type === 'error') {
    toast.error(message, options);
  } else {
    toast(message, options);
  }
};

export default NotificationProvider;