import { Helmet } from "react-helmet-async";
const TermsOfServicePage = () => {
  return (
    <>
      <Helmet>
        <title>Study Buddy AI | Terms of Service</title>
        <link rel="canonical" href="https://studybuddyai.app/terms-of-service" />
      </Helmet>

      <div className="flex flex-col min-h-screen bg-light dark:bg-dark">
        <div className="mx-auto px-4 w-full max-w-4xl space-y-8 mt-28 mb-16">
          <section className="space-y-4">
            <h1 className="text-4xl font-bold dark:text-dark">Study Buddy Terms of use</h1>
            <p className="text-sm text-gray-600 dark:text-gray-300">Last updated July 13, 2024</p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold dark:text-dark">AGREEMENT TO OUR LEGAL TERMS</h2>
            <p className="text-base dark:text-dark">
              We are __________ ("Company," "we," "us," "our").
            </p>
            <p className="text-base dark:text-dark">
              We operate the mobile application Study Buddy (the "App"), as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").
            </p>
            <p className="text-base dark:text-dark">
              You can contact us by email at __________ or by mail to __________, __________, __________.
            </p>
            <p className="text-base dark:text-dark">
              These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and __________, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </p>
            <p className="text-base dark:text-dark">
              Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.
            </p>
            <p className="text-base dark:text-dark">
              All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or guardian read and agree to these Legal Terms prior to you using the Services.
            </p>
            <p className="text-base dark:text-dark">
              We recommend that you print a copy of these Legal Terms for your records.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">TABLE OF CONTENTS</h2>
            <ul className="list-disc list-inside space-y-2 text-base">
              <li>1. OUR SERVICES</li>
              <li>2. INTELLECTUAL PROPERTY RIGHTS</li>
              <li>3. USER REPRESENTATIONS</li>
              <li>4. USER REGISTRATION</li>
              <li>5. PURCHASES AND PAYMENT</li>
              <li>6. SUBSCRIPTIONS</li>
              <li>7. SOFTWARE</li>
              <li>8. PROHIBITED ACTIVITIES</li>
              <li>9. USER GENERATED CONTRIBUTIONS</li>
              <li>10. CONTRIBUTION LICENSE</li>
              <li>11. MOBILE APPLICATION LICENSE</li>
              <li>12. SOCIAL MEDIA</li>
              <li>13. THIRD-PARTY WEBSITES AND CONTENT</li>
              <li>14. SERVICES MANAGEMENT</li>
              <li>15. PRIVACY POLICY</li>
              <li>16. TERM AND TERMINATION</li>
              <li>17. MODIFICATIONS AND INTERRUPTIONS</li>
              <li>18. GOVERNING LAW</li>
              <li>19. DISPUTE RESOLUTION</li>
              <li>20. CORRECTIONS</li>
              <li>21. DISCLAIMER</li>
              <li>22. LIMITATIONS OF LIABILITY</li>
              <li>23. INDEMNIFICATION</li>
              <li>24. USER DATA</li>
              <li>25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
              <li>26. CALIFORNIA USERS AND RESIDENTS</li>
              <li>27. MISCELLANEOUS</li>
              <li>28. CONTACT US</li>
            </ul>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">1. OUR SERVICES</h2>
            <p className="text-base">
              The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
            </p>
            <p className="text-base">
              The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">2. INTELLECTUAL PROPERTY RIGHTS</h2>
            <h3 className="text-xl font-semibold">Our intellectual property</h3>
            <p className="text-base">
              We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").
            </p>
            <p className="text-base">
              Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.
            </p>
            <p className="text-base">
              The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use only.
            </p>

            <h3 className="text-xl font-semibold">Your use of our Services</h3>
            <p className="text-base">
              Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to:
            </p>
            <ul className="list-disc list-inside space-y-2 text-base">
              <li>access the Services; and</li>
              <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>
            </ul>
            <p className="text-base">
              solely for your personal, non-commercial use.
            </p>
            <p className="text-base">
              Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
            </p>
            <p className="text-base">
              If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: __________. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
            </p>
            <p className="text-base">
              We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
            </p>
            <p className="text-base">
              Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.
            </p>

            <h3 className="text-xl font-semibold">Your submissions and contributions</h3>
            <p className="text-base">
              Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
            </p>
            <p className="text-base">
              Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
            </p>
            <p className="text-base">
              Contributions: The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality during which you may create, submit, post, display, transmit, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other material ("Contributions"). Any Submission that is publicly posted shall also be treated as a Contribution.
            </p>
            <p className="text-base">
              You understand that Contributions may be viewable by other users of the Services and possibly through third-party websites.
            </p>
            <p className="text-base">
              When you post Contributions, you grant us a license (including use of your name, trademarks, and logos): By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other works, your Contributions, and to sublicense the licenses granted in this section. Our use and distribution may occur in any media formats and through any media channels.
            </p>
            <p className="text-base">
              This license includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.
            </p>
            <p className="text-base">
              You are responsible for what you post or upload: By sending us Submissions and/or posting Contributions through any part of the Services or making Contributions accessible through the Services by linking your account through the Services to any of your social networking accounts, you:
            </p>
            <ul className="list-disc list-inside space-y-2 text-base">
              <li>confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</li>
              <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or Contribution;</li>
              <li>warrant that any such Submission and/or Contributions are original to you or that you have the necessary rights and licenses to submit such Submissions and/or Contributions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions and/or Contributions; and</li>
              <li>warrant and represent that your Submissions and/or Contributions do not constitute confidential information.</li>
            </ul>
            <p className="text-base">
              You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.
            </p>
            <p className="text-base">
              We may remove or edit your Content: Although we have no obligation to monitor any Contributions, we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such Contributions, we may also suspend or disable your account and report you to the authorities.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">3. USER REPRESENTATIONS</h2>
            <p className="text-base">
              By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services; (5) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise; (6) you will not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any applicable law or regulation.
            </p>
            <p className="text-base">
              If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">4. USER REGISTRATION</h2>
            <p className="text-base">
              You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">5. PURCHASES AND PAYMENT</h2>
            <p className="text-base">We accept the following forms of payment:</p>
            <ul className="list-disc list-inside space-y-2 text-base">
              <li>Visa</li>
              <li>Mastercard</li>
              <li>American Express</li>
              <li>Discover</li>
              <li>PayPal</li>
            </ul>
            <p className="text-base">
              You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be in US dollars.
            </p>
            <p className="text-base">
              You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
            </p>
            <p className="text-base">
              We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">6. SUBSCRIPTIONS</h2>
            <h3 className="text-xl font-semibold">Billing and Renewal</h3>
            <p className="text-base">
              Your subscription will continue and automatically renew unless canceled. You consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable order. The length of your billing cycle is monthly.
            </p>
            <h3 className="text-xl font-semibold">Cancellation</h3>
            <p className="text-base">
              All purchases are non-refundable. You can cancel your subscription at any time by logging into your account. Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services, please email us at __________.
            </p>
            <h3 className="text-xl font-semibold">Fee Changes</h3>
            <p className="text-base">
              We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">7. SOFTWARE</h2>
            <p className="text-base">
              We may include software for use in connection with our Services. If such software is accompanied by an end user license agreement ("EULA"), the terms of the EULA will govern your use of the software. If such software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal, and non-transferable license to use such software solely in connection with our services and in accordance with these Legal Terms. Any software and any related documentation is provided "AS IS" without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except in accordance with the EULA or these Legal Terms.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">8. PROHIBITED ACTIVITIES</h2>
            <p className="text-base">
              You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
            </p>
            <p className="text-base">As a user of the Services, you agree not to:</p>
            <ul className="list-disc list-inside space-y-2 text-base">
              <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
              <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
              <li>Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.</li>
              <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
              <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
              <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
              <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
              <li>Engage in unauthorized framing of or linking to the Services.</li>
              <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</li>
              <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
              <li>Delete the copyright or other proprietary rights notice from any Content.</li>
              <li>Attempt to impersonate another user or person or use the username of another user.</li>
              <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices.</li>
              <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.</li>
              <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</li>
              <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</li>
              <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
              <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</li>
              <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services.</li>
              <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
              <li>Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means.</li>
              <li>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
              <li>Use the Services to advertise or offer to sell goods and services.</li>
              <li>Sell or otherwise transfer your profile.</li>
            </ul>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">9. USER GENERATED CONTRIBUTIONS</h2>
            <p className="text-base">
              The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary.
            </p>
            <p className="text-base">
              When you create or make available any Contributions, you thereby represent and warrant that:
            </p>
            <ul className="list-disc list-inside space-y-2 text-base">
              <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights of any third party.</li>
              <li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us to use your Contributions as contemplated by the Services and these Legal Terms.</li>
              <li>You have the written consent, release, and/or permission of each identifiable individual person in your Contributions to use their name or likeness as contemplated by the Services and these Legal Terms.</li>
              <li>Your Contributions are not false, inaccurate, or misleading.</li>
              <li>Your Contributions are not unauthorized advertising, promotional materials, spam, or other forms of solicitation.</li>
              <li>Your Contributions are not obscene, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</li>
              <li>Your Contributions do not violate any applicable law or regulation.</li>
              <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
              <li>Your Contributions do not include any offensive comments connected to race, national origin, gender, sexual preference, or physical handicap.</li>
              <li>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms or applicable law.</li>
            </ul>
            <p className="text-base">
              Any use of the Services in violation of the foregoing may result in termination or suspension of your rights to use the Services.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">10. CONTRIBUTION LICENSE</h2>
            <p className="text-base">
              By posting your Contributions to any part of the Services, you automatically grant (and warrant that you have the right to grant) to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, reformat, translate, transmit, and distribute such Contributions for any purpose, and to prepare derivative works or incorporate into other works your Contributions. You waive all moral rights in your Contributions.
            </p>
            <p className="text-base">
              You retain full ownership of all of your Contributions and any intellectual property rights associated with them. We are not liable for any statements or representations in your Contributions. You are solely responsible for your Contributions.
            </p>
            <p className="text-base">
              We may remove or re-categorize any Contributions at any time for any reason, and we have no obligation to monitor your Contributions.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">11. MOBILE APPLICATION LICENSE</h2>
            <h3 className="text-xl font-semibold">Use License</h3>
            <p className="text-base">
              If you access the Services via the App, we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on your device strictly in accordance with these Legal Terms.
            </p>

            <h3 className="text-xl font-semibold">Apple and Android Devices</h3>
            <p className="text-base">
              If you obtain the App from the Apple Store or Google Play, certain terms of use apply as outlined in these Legal Terms and by the respective App Distributor. You agree to comply with all applicable third-party terms of agreement when using the App.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">12. SOCIAL MEDIA</h2>
            <p className="text-base">
              You may link your account with online accounts you have with third-party service providers. By doing so, you grant us access to certain information you have provided to those accounts, and you agree that we may use, store, and distribute such information in accordance with our Privacy Policy and these Legal Terms.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">13. THIRD-PARTY WEBSITES AND CONTENT</h2>
            <p className="text-base">
              The Services may contain links to third-party websites and content. We do not endorse or assume responsibility for any third-party websites or content. You access these at your own risk and should review their terms and policies.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">14. SERVICES MANAGEMENT</h2>
            <p className="text-base">
              We reserve the right to monitor and manage the Services in ways that protect our rights and property and ensure proper functioning.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">15. PRIVACY POLICY</h2>
            <p className="text-base">
              We care about your privacy and data security. Please review our Privacy Policy:{" "}
              <a href="https://www.privacypolicies.com/live/9de5d83b-002e-4a23-a437-cc02c5e46e22" className="text-blue-500 underline">
                https://www.privacypolicies.com/live/9de5d83b-002e-4a23-a437-cc02c5e46e22
              </a>.
              By using the Services, you agree to be bound by our Privacy Policy.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">16. TERM AND TERMINATION</h2>
            <p className="text-base">
              These Legal Terms remain in effect while you use the Services. We may terminate or suspend your account at any time for any reason without notice.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">17. MODIFICATIONS AND INTERRUPTIONS</h2>
            <p className="text-base">
              We reserve the right to change, modify, or discontinue the Services at any time without notice. We are not liable for any downtime or interruptions.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">18. GOVERNING LAW</h2>
            <p className="text-base">
              These Legal Terms are governed by the laws of Switzerland. You agree to the jurisdiction of the courts in Meilen, Zurich, unless otherwise required by EU consumer laws.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">19. DISPUTE RESOLUTION</h2>
            <h3 className="text-xl font-semibold">Binding Arbitration</h3>
            <p className="text-base">
              Any dispute shall be resolved by arbitration in Zurich, Switzerland, under the rules of the European Court of Arbitration.
            </p>

            <h3 className="text-xl font-semibold">Restrictions</h3>
            <p className="text-base">
              Arbitration shall be limited to disputes between the parties. No className actions are permitted.
            </p>

            <h3 className="text-xl font-semibold">Exceptions to Arbitration</h3>
            <p className="text-base">
              Claims related to intellectual property rights, theft, privacy, or unauthorized use are not subject to arbitration and must be heard by a court of competent jurisdiction.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">20. CORRECTIONS</h2>
            <p className="text-base">
              We reserve the right to correct any errors or inaccuracies on the Services without prior notice.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">21. DISCLAIMER</h2>
            <p className="text-base">
              The Services are provided "AS IS" without warranties of any kind. We are not liable for any damages arising from your use of the Services.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">22. LIMITATIONS OF LIABILITY</h2>
            <p className="text-base">
              Our liability is limited to the amount paid by you in the six (6) months prior to any claim. Certain laws may grant additional rights.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">23. INDEMNIFICATION</h2>
            <p className="text-base">
              You agree to indemnify and hold us harmless for any losses arising from your use of the Services, violation of these Legal Terms, or infringement of third-party rights.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">24. USER DATA</h2>
            <p className="text-base">
              We are not liable for any loss or corruption of user data. You agree to maintain backups of your own data.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
            <p className="text-base">
              You consent to receive electronic communications and agree that they satisfy any legal requirements that such communications be in writing.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">26. CALIFORNIA USERS AND RESIDENTS</h2>
            <p className="text-base">
              If not resolved with us, you may contact the California Department of Consumer Affairs for further assistance.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">27. MISCELLANEOUS</h2>
            <p className="text-base">
              These Legal Terms constitute the entire agreement. If any provision is deemed unlawful or unenforceable, it does not affect the validity of the remaining provisions.
            </p>
          </section>

          <section className="space-y-4 dark:text-dark">
            <h2 className="text-2xl font-semibold">28. CONTACT US</h2>
            <p className="text-base">
              In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:
            </p>
            <p className="text-base">__________</p>
          </section>

        </div>
      </div>
    
    </>
  );
}

export default TermsOfServicePage;
