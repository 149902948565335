import { useParams } from "react-router-dom";

const ShareFlashcardSetPage = () => {
  const { uuid } = useParams();
  console.log("this is the uuid that got shared: ", uuid)

  console.log("on the share Flashcard set page")
  return (
    <div className="w-full h-full flex justify-center items-center">
      <p>Loading new flashcard set...</p>
    </div>
  )
};

export default ShareFlashcardSetPage;