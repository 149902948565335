import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import QuestionBox from "../components/OtherQuestionComponents/QuestionBox";
import InputFieldComponent from "../components/OtherQuestionComponents/InputFieldComponent";
import ProgressBarComponent from "../components/OtherQuestionComponents/ProgressBarComponent";
import PointsFooter from "../components/OtherQuestionComponents/PointsFooter";
import api from "./../api";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import LoadingSpinner from "../components/General/LoadingSpinner";
import CorrectionPointsFooter from "../components/OtherQuestionComponents/CorrectionPointsFooter";



const GrammarQuestions = () => {
  const [question, setQuestion] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [correction, setCorrection] = useState("");

  const [loading, setLoading] = useState(true);

  const [pointsForQuestion, setPointsForQuestion] = useState(0);
  const [points, setPoints] = useState(0);

  const [correctIncorrect, setCorrectIncorrect] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [showResponse, setShowResponse] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [progressBarQuestionIndex,  setProgressBarQuestionIndex] = useState(0)
  const [maxPointsUntilNow, setMaxPointsUntilNow] = useState(0)
  const [loadingAnswer, setLoadingAnswer] = useState(false);

  const { uuid } = useParams();
  const navigate = useNavigate();





  useEffect(() => {
    setLoading(true)
    api.get(`/api/grammar_questions/${uuid}/`)
    .then((response) => {
      const questionData = response.data.questions;
      console.log("\n user answer \n");
      setQuestion(questionData);
      setNumberOfQuestions(questionData.length);

      if (response.data.question_index !== 0) {
        if (response.data.question_index === questionData.length) {
          return handleRedirectToFinish();
        }
        return handleContinueFromSavedPoint(response.data, response.data.question_index);
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
    });
  }, [])


  const handleRedirectToFinish = () => {
    api.put("/api/grammar_reset_question_index/", {uuid: uuid})
      .then((response) => {
        navigate(`/grammar/recap/${uuid}`);
      })
      .catch((error) => {
        console.error(error)
      })
  };

  const handleContinueFromSavedPoint = (questionData, questionIndexBefore) => {
    let sumPoints = 0
    for (let i = 0; i < questionIndexBefore; i++) {
      sumPoints += questionData.points_list[i];
    }
    setPoints(sumPoints);
    setMaxPointsUntilNow((questionIndexBefore * 3));
    setProgressBarQuestionIndex(questionIndexBefore);
    setQuestionIndex(questionIndexBefore);
    setLoading(false);
  }


  const handleShowCorrection = () => {
    setSubmitted(true);    
    if (userAnswer.trim() !== "") {
      setLoadingAnswer(true);
      console.log("handle show correction is getting called");
      const correction_data = {
        user_answer: userAnswer,
      };



      api.post("/api/grammar_answers/" + uuid + "/", correction_data)
        .then((response) => {
          api.get("/api/grammar_corrections/" + uuid + "/" + questionIndex + "/")
            .then((response) => {
              const responseData = response.data;
              console.log("this is the response data", response.data);
              setCorrection(responseData.corrections.slice(-1)[0]);
              setPointsForQuestion(responseData.points_list.slice(-1)[0]);
              setPoints((prevPoints) => prevPoints + responseData.points_list.slice(-1)[0]);
              setCorrectIncorrect(responseData.correct_incorrect.slice(-1)[0]);
              setMaxPointsUntilNow((prevMaxPoints) => prevMaxPoints + 3);
              setProgressBarQuestionIndex((prevIndex) => prevIndex + 1);
              setLoadingAnswer(false);
              setShowResponse(true);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
      setSubmitted(false);
    }
  };

  const handleNextQuestion = () => {
    setQuestionIndex((prevIndex) => prevIndex + 1);

    setShowResponse(false);
    setCorrection("");
    setUserAnswer("");
  };


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && showResponse && correction !== null) {
        if (numberOfQuestions === questionIndex + 1) {
          return handleRedirectToFinish();
        } else {
          return handleNextQuestion();
        }
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [showResponse, correction, questionIndex, numberOfQuestions]);

  console.log("question: ", question);

 
  if (loading) {
    return <LoadingSpinner />;
  };
  return (
    <div className="min-h-screen bg-light dark:bg-dark">
      <ProgressBarComponent
        index={progressBarQuestionIndex}
        numberOfQuestions={question.length}
      />

      <div className="w-full flex flex-col justify-center items-center">
        <div className="w-1/2">
          <QuestionBox maxPoints={3} question={question[questionIndex]} />
          {!showResponse && question.length > 0 && (
            <div>
              <InputFieldComponent
                value={userAnswer}
                setValue={setUserAnswer}
                maxWidth="525px"
                placeholder={"Enter answer"}
                submitted={submitted}
                handleSubmit={handleShowCorrection}
              />
            </div>
          )}

          {showResponse && correctIncorrect === false && (
            <div>
              <AnswerInputFieldIncorrectCorrection 
                userAnswer={userAnswer}
                correction={correction}
                correctionHeader={"Feedback"}
              />
            </div>
          )}

          {showResponse && correctIncorrect === true && (
            <div>
              <AnswerInputFieldCorrectCorrection 
                userAnswer={userAnswer}
                showHeader={true}
              />
            </div>
          )}

          {showResponse && correction !== null ? (
            <CorrectionPointsFooter
              maxPoints={maxPointsUntilNow}
              currentPoints={points}
              questionIndex={questionIndex + 1}
              numberOfQuestions={numberOfQuestions}
              handleRedirect={handleRedirectToFinish}
              handleNextQuestion={handleNextQuestion}
              isCorrect={correctIncorrect}
              pointsForQuestion={pointsForQuestion}
            />
          ) : (
            <PointsFooter 
              maxPoints={maxPointsUntilNow}
              currentPoints={points}
              handleShowResponse={handleShowCorrection}
              userAnswer={userAnswer}
              loadingAnswer={loadingAnswer}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GrammarQuestions;
