import { useState, useEffect } from "react";
const OptionsComponent = ({ optionsList, setUserAnswer, userAnswer, submitted }) => {
  const [error, setError] = useState(false);
  
  const handleOptionClick = (option) => {
    setUserAnswer(option);
  };

  useEffect(() => {
    if (submitted && userAnswer.trim() === "") {
      setError(true);
    }
    else {
      setError(false);
    }
  }, [submitted, userAnswer]);
  console.log("this is the error", error);
  console.log("this is the userAnswer", userAnswer);
  return (
    <div>
      {optionsList.map((option, index) => (
        <div key={index} className="flex flex-col items-center">
          <input type="radio" id={`option${index}`} name="options" className="hidden" value={userAnswer}/>
          <label
            htmlFor={`option${index}`}
            className={`${userAnswer !== option ? "bg-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:border-gray-500 hover:border-gray-400" : "bg-blue-100 dark:bg-blue-600 border-blue-600 dark:border-blue-700"} shadow-lg dark:shadow-none cursor-pointer select-none font-medium py-4 px-6 rounded-xl transition-colors duration-200 ${error && "border-red-500"} border-2 mt-4 w-full dark:text-dark`}
            style={{ maxWidth: "375px"}} // Before: style={{ maxWidth: "325px" }}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </label>
        </div>
      ))}
      <div className="flex flex-col items-center mt-6">
        {error && <p className="text-red-500 text-sm">You need to choose one of the options</p>}
      </div>
    </div>
  );
};

export default OptionsComponent;
