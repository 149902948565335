import { useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import PropTypes from 'prop-types';

const AnswerInputFieldIncorrectCorrection = ({ userAnswer, correction, correctionHeader, isRecapPage }) => {
  const userAnswerRef = useRef(null);
  const correctionRef = useRef(null);



  return (
    <div className="flex flex-col items-center w-full">
      <p className="font-medium text-xl mb-4 dark:text-dark">Your answer</p>
      <TextareaAutosize
        ref={userAnswerRef}
        minRows={1}
        maxRows={7}
        value={userAnswer}
        readOnly
        className={`mb-8 p-2.5 w-full border-b-2 border-red-400 dark:border-red-500 bg-red-50 rounded-lg shadow-color-lg shadow-red-300 dark:shadow-none outline-none resize-none dark:text-white text-lg ${isRecapPage ? 'dark:bg-gray-700' : 'dark:bg-gray-800'}`}
        style={{
          lineHeight: '1.5',
          overflowY: 'hidden',
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // IE 10+
        }}
        onClick={(e) => e.stopPropagation()}
        onHeightChange={() => {
          const textarea = userAnswerRef.current;
          if (textarea) {
            if (textarea.scrollHeight > textarea.clientHeight) {
              textarea.style.overflowY = 'auto';
            } else {
              textarea.style.overflowY = 'hidden';
            }
            // Ensure the scroll position is at the top
            textarea.scrollTop = 0;
          }
        }}
      />
      <style>
        {`
          textarea::WebkitScrollbar {
            display: none;
          }
        `}
      </style>

      <p className="font-medium text-xl mb-4 dark:text-dark">{correctionHeader}</p>
      <TextareaAutosize
        ref={correctionRef}
        minRows={1}
        maxRows={7}
        value={correction}
        readOnly
        className={`p-2.5 w-full border-b-2 border-green-400 dark:border-green-500 bg-green-50 rounded-lg shadow-color-lg shadow-green-300 dark:shadow-none outline-none resize-none dark:text-white text-lg ${isRecapPage ? 'dark:bg-gray-700' : 'dark:bg-gray-800'}`}
        style={{
          lineHeight: '1.5',
          overflowY: 'hidden',
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // IE 10+
        }}
        onClick={(e) => e.stopPropagation()}
        onHeightChange={() => {
          const textarea = correctionRef.current;
          if (textarea) {
            if (textarea.scrollHeight > textarea.clientHeight) {
              textarea.style.overflowY = 'auto';
            } else {
              textarea.style.overflowY = 'hidden';
            }
          }
        }}
      />
      <style>
        {`
          textarea::WebkitScrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  );
};

AnswerInputFieldIncorrectCorrection.propTypes = {
  userAnswer: PropTypes.string.isRequired,
  correction: PropTypes.string.isRequired,
  correctionHeader: PropTypes.string.isRequired,
};

export default AnswerInputFieldIncorrectCorrection;




// This is the code before adding the TextareaAutosize:

// import { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';

// const AnswerInputFieldIncorrectCorrection = ({
//   userAnswer,
//   correction,
//   correctionHeader,
// }) => {
//   const userAnswerRef = useRef(null);
//   const correctionRef = useRef(null);

//   const [isUserAnswerScrollable, setIsUserAnswerScrollable] = useState(false);
//   const [isCorrectionScrollable, setIsCorrectionScrollable] = useState(false);

//   useEffect(() => {
//     checkIfScrollable(userAnswerRef.current, setIsUserAnswerScrollable);
//     checkIfScrollable(correctionRef.current, setIsCorrectionScrollable);
//   }, [userAnswer, correction]);

//   const checkIfScrollable = (element, setScrollable) => {
//     if (element) {
//       const computedStyle = window.getComputedStyle(element);
//       const lineHeight = parseFloat(computedStyle.lineHeight);
//       const maxAllowedHeight = lineHeight * 7;

//       element.style.maxHeight = 'none';
//       const totalHeight = element.scrollHeight;

//       if (totalHeight > maxAllowedHeight) {
//         setScrollable(true);
//         element.style.maxHeight = `${maxAllowedHeight}px`;
//         element.style.overflowY = 'auto';
//       } else {
//         setScrollable(false);
//         element.style.maxHeight = 'none';
//         element.style.overflowY = 'hidden';
//       }
//     }
//   };

//   return (
//     <div className="flex flex-col items-center">
//       <p className="font-medium text-xl mb-3">Your answer</p>
//       <div
//         ref={userAnswerRef}
//         className="mb-8 p-2.5 w-full border-2 border-red-400 bg-red-50 rounded-lg shadow-color-lg shadow-red-300 outline-none font-medium"
//         style={{
//           whiteSpace: 'pre-wrap',
//           wordWrap: 'break-word',
//           maxHeight: isUserAnswerScrollable ? 'calc(1.5em * 5)' : 'none',
//           overflowY: isUserAnswerScrollable ? 'auto' : 'hidden',
//           msOverflowStyle: 'none',
//           scrollbarWidth: 'none',
//         }}
//         aria-readonly="true"
//         role="textbox"
//         tabIndex={0}
//       >
//         {userAnswer}
//         <style>
//           {`div::-webkit-scrollbar {display: none;}`}
//         </style>
//       </div>

//       <p className="font-medium text-xl mb-3">{correctionHeader}</p>
//       <div
//         ref={correctionRef}
//         className="p-2.5 w-full border-2 border-green-400 bg-green-50 rounded-lg shadow-color-lg shadow-green-300 outline-none"
//         style={{
//           whiteSpace: 'pre-wrap',
//           wordWrap: 'break-word',
//           maxHeight: isCorrectionScrollable ? 'calc(1.5em * 5)' : 'none',
//           overflowY: isCorrectionScrollable ? 'auto' : 'hidden',
//           msOverflowStyle: 'none',
//           scrollbarWidth: 'none',
//         }}
//         aria-readonly="true"
//         role="textbox"
//         tabIndex={0}
//       >
//         {correction}
//         <style>
//           {`div::-webkit-scrollbar {display: none;}`}
//         </style>
//       </div>
//     </div>
//   );
// };

// AnswerInputFieldIncorrectCorrection.propTypes = {
//   userAnswer: PropTypes.string.isRequired,
//   correction: PropTypes.string.isRequired,
//   correctionHeader: PropTypes.string.isRequired,
// };

// export default AnswerInputFieldIncorrectCorrection;
