import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BrandLogo from "../components/SVG/BrandLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import EmailInputField from "../components/Authentication/EmailInputField";
import { motion, AnimatePresence } from "framer-motion";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate()

    
  const handlePasswordReset = (event) => {
    event.preventDefault()
    console.log("Button Clicked");
    console.log("Email: ", email);
    if (!email) {
      return setEmailError("Email is required");
    }

    const data = { email };
    axios.post("/api/auth/password/reset/", data)
    .then((response) => {
      console.log(response.data);
      setEmailSent(true);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };


  const formVariants = {
    enter: { 
      opacity: 0,
      y: 20
    },
    center: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: [0.4, 0, 0.2, 1]
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
        ease: [0.4, 0, 1, 1]
      }
    }
  };
  
  const successVariants = {
    enter: { 
      opacity: 0,
      y: 20
    },
    center: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: [0.4, 0, 0.2, 1]
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
        ease: [0.4, 0, 1, 1]
      }
    }
  };
  
  

  





  return (
    <motion.section 
      className="min-h-screen bg-light dark:bg-dark" 
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="absolute left-6 top-6 dark:text-white cursor-pointer"
          onClick={() => navigate("/login")}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="h-6 w-6" />
        </motion.button>

        
        <motion.div variants={itemVariants} className="mb-8 flex flex-row items-center">
          <BrandLogo className="w-16" />
          <span className="text-4xl font-bold ml-3 tracking-wide dark:text-dark">Study Buddy</span>
        </motion.div>

        <motion.div variants={itemVariants} className="w-full bg-white rounded-xl shadow-lg dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-center font-bold text-gray-900 dark:text-white" style={{ fontSize: "28px", lineHeight: "35px" }}>
              {emailSent ? "Email sent" : "Reset your Password"}
            </h1>
            <AnimatePresence mode="wait">
              {emailSent ? (
                <motion.div 
                  key="success"
                  className="flex flex-col items-center justify-center text-green-500"
                  initial="enter"
                  animate="center"
                  exit="exit"
                  variants={successVariants}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-16 w-16"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <motion.p 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="mt-6 text-center text-gray-600 dark:text-gray-200"
                  >
                    Check your email for the password reset link
                  </motion.p>
                </motion.div>
              ) : (
                <motion.form 
                  key="form"
                  onSubmit={event => handlePasswordReset(event)}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  variants={formVariants}
                >
                  <EmailInputField 
                    email={email}
                    setEmail={setEmail}
                    emailError={emailError}
                    emailErrorMessage={emailError}
                  />
                  <button
                  type="submit"
                  className="w-full text-white transition duration-300 ease-in-out transform
                  bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  style={{ marginTop: 40 }}
                  >
                    Reset password
                  </button>
                </motion.form>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
}
export default PasswordReset;
