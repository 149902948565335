import { useState, useEffect, useRef } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import "../../styles/FlashcardComponent.css";
import ProgressBarCircle from "../DefinitionQuestionComponents/ProgressbarCircle";



// These flashcards should only be used for testing
// const cards = [
//   { front: "Front 1", back: "Back 1" },
//   { front: "Front 2", back: "Back 2" },
//   // { front: "Front 3", back: "Back 3" },
//   // { front: "Front 4", back: "Back 4" },
//   // { front: "Front 5", back: "Back 5" },
// ];

// This flashcards should be used for the deployed version:
const cards = [
  { front: "Cell", back: "Basic unit of life" },
  { front: "DNA", back: "Genetic material of organisms" },
  { front: "Enzyme", back: "Speeds up chemical reactions" },
  // { front: "Osmosis", back: "Movement of water across a membrane" },
  // { front: "Mitochondria", back: "Powerhouse of the cell" },
];



const FlashcardFeaturePreview = () => {
  const controls = useAnimation();
  const containerRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  const [cardIndex, setCardIndex] = useState(0);
  const [localCardIndex, setLocalCardIndex] = useState(cardIndex || 0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [animationDirection, setAnimationDirection] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);

  const [currentCardData, setCurrentCardData] = useState(null);

  const [previousCardData, setPreviousCardData] = useState(null);


  const [showEndScreen, setShowEndScreen] = useState(false);
  const [animationSequence] = useState(['false', 'true', 'true', 'false', 'true']);
  const [sequenceIndex, setSequenceIndex] = useState(0);

  // This code just checks if it is currently visible on the screen or not
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.intersectionRatio === 1);
      },
      { threshold: 1.0 }
    );

    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  useEffect(() => {

    // Update current card data when localCardIndex changes
    if (localCardIndex < cards.length) {
      setCurrentCardData(cards[localCardIndex]);
    }
  }, [localCardIndex]);




  const handleCardSwipe = async (direction) => {
    if (isAnimating) return;
    const nextIndex = (localCardIndex + 1) % cards.length;

    setIsAnimating(true);
    setIsFlipped(false);
    setAnimationDirection(direction);
    setPreviousCardData(currentCardData);

    // If we've reached the last card and are about to loop back to the start
    // Instead of instantly showing end screen, we'll still animate out
    // the last card but set currentCardData to null, so no "next" card is displayed.
    if (nextIndex === 0) {
      // No next card
      setCurrentCardData(null);

      // Let the animation run
      setTimeout(() => {
        // Animation finished

        setIsAnimating(false);
        setAnimationDirection("");
        // Now show the end screen since we completed all cards
        setShowEndScreen(true);
        // After showing the end screen, reset everything after a few seconds
        setTimeout(() => {
          // Reset sequence and cards for a fresh start
          setShowEndScreen(false);
          setLocalCardIndex(0);
          setCardIndex(0);
          setSequenceIndex(0);
          setCurrentCardData(cards[0]);
          setPreviousCardData(null);
          setIsFlipped(false);
          setIsAnimating(false);
          setAnimationDirection("");
        }, 3000); // wait 3s before restarting

      }, 750);
    } else {
      // Normal case, we still have cards left
      setCurrentCardData(cards[nextIndex]);

      setTimeout(() => {
        // Animation finished
        setIsAnimating(false);
        setLocalCardIndex(nextIndex);
        setAnimationDirection("");
        setCardIndex(nextIndex);
      }, 800);
    }
  };

  //TODO: 
  // - Add more flashcards it should have 5 flashcards
  // - make the flashcard more realistic
  // - fix the ANIMATIONS
  //   - Add that it first goes wrong then correct correct wrong correct
  //   - Add that there are also always flipp animations between the swipes
  // - When the user has finished all of the flashcards there should be the end screen where there is like the summary of the flashca





  //FIXME: This is the current animation problem:
  // - The reason is because it breaks out of the animation every time, which it should not do 
  // - This is why it runs only the first animation all the time so only the false animation


  const handleFlipAnimation = async () => {
    setIsFlipped(true);
    await new Promise((res) => setTimeout(res, 1000));
    setIsFlipped(false);
  };


  useEffect(() => {
    const runSingleAnimationStep = async () => {
      // If not in view, sequence done, or end screen is shown, do nothing
      console.log("\n\n\n")
      if (!isInView || sequenceIndex >= animationSequence.length || showEndScreen) {
        console.log("!isInView", !isInView);
        console.log("sequenceIndex >= animationSequence.length", sequenceIndex >= animationSequence.length);
        console.log("showEndScreen", showEndScreen);
        return ;
      };

      try {
        const direction = animationSequence[sequenceIndex];

        // Flip the card before swiping
        await handleFlipAnimation();

        // Button highlight animation
        await new Promise((res) => setTimeout(res, 600));
        if (direction === "false") {
          await controls.start("hoverRed");
        } else {
          await controls.start("hoverGreen");
        }

        await new Promise((res) => setTimeout(res, 300));
        await controls.start("default");
        await new Promise((res) => setTimeout(res, 200));

        // Swipe the card
        handleCardSwipe(direction);

        // Wait for card to finish swiping
        await new Promise((res) => setTimeout(res, 1500));

        // Move to the next sequence step if not at the end
        setSequenceIndex((prev) => prev + 1);
      } catch (error) {
        console.error("Animation sequence error:", error);
      }
    };
    let delayTimeout;

    if (isInView && sequenceIndex === 0 && !showEndScreen) {
      delayTimeout = setTimeout(() => {
        runSingleAnimationStep();
      }, 800);
    } else {
      runSingleAnimationStep();
    }
  
    return () => {
      if (delayTimeout) clearTimeout(delayTimeout);
    };
  }, [isInView, controls, sequenceIndex, showEndScreen]);


    
  const progressVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: {
        type: "spring",
        duration: 0.8
      }
    }
  };

  const flashcardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -50, transition: { duration: 0.5 } },
  };
  
  const endScreenVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } },
  };

  // TODO: currently working on the smooth animation from the flashcard mode to the recap part
  // fix that the show end screen to false by default in the use state again





  return (
    <div className="flex flex-col items-center" ref={containerRef}>
      <AnimatePresence mode="wait">
        {showEndScreen ? (
          <motion.div 
            key="end-screen"
            variants={endScreenVariants}  
            initial="hidden"
            animate="visible"
            exit="exit"
            className="flex flex-col items-center"
          >
            <h2 className="text-2xl font-bold">You're doing great!</h2>

            <motion.div variants={progressVariants} className="hidden lg:flex flex-col justify-center items-center mt-6 mb-8">
              <ProgressBarCircle
                percent={60}
                size={"preview"}
              />
            </motion.div>
            
            <motion.div variants={progressVariants} className="lg:hidden flex-col justify-center items-center mt-6">
              <ProgressBarCircle
                percent={60}
                size={"previewSmall"}
              />
            </motion.div>
            {/* Add more summary information here */}
          </motion.div>
        ) : (
          <motion.div
            key="flashcards"
            variants={flashcardVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="flex flex-col items-center gap-8 lg:gap-6"
          >
            <div className="w-[244px] h-[180px] lg:w-[325px] lg:h-[240px] relative" >

              <div className="card-stack relative w-full h-full perspective-container">
                <div
                  className={`card bg-white current-card absolute w-full h-full cursor-pointer rounded-2xl ${
                    isFlipped ? "flipped shadow-flipped" : "shadow-normal"
                  } ${animationDirection}`}
                  onAnimationEnd={() => {
                    setIsAnimating(false);
                    setAnimationDirection("");
                  }}
                  //onClick={!isAnimating ? () => setIsFlipped(!isFlipped) : undefined}
                >
                  <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl lg:text-3xl text-black select-none">
                    {isAnimating ? previousCardData?.front : currentCardData?.front}
                  </div>
                  <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-2xl lg:text-3xl text-black select-none">
                    {isAnimating ? previousCardData?.back : currentCardData?.back}
                  </div>
                </div>
                {isAnimating && localCardIndex !== cards.length - 1 && (
                  <div
                    className={`card next-card absolute w-full h-full rounded-2xl ${animationDirection} shadow-normal`}
                  >
                    <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl lg:text-3xl text-black select-none">
                      {currentCardData?.front}
                    </div>
                    <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-2xl lg:text-3xl text-black select-none">
                      {currentCardData?.back}
                    </div>
                  </div>
                )}
              </div>
              </div>

              {/* Buttons code; not really that important */}
              <div className="flex justify-center items-center gap-16 lg:gap-20">
              <motion.div
                className="flex justify-center items-center rounded-full"
                initial="default"
                animate={controls}
                variants={{
                  default: {
                    backgroundColor: "#FFFFFF",
                  },
                  hoverRed: {
                    backgroundColor: "#FEE2E2",
                  },
                  hoverGreen: {
                    backgroundColor: "#FFFFFF",
                  },
                }}
                transition={{ duration: 0.3 }}
              >
                <button
                  className="p-1.5 lg:p-2 rounded-full border-2 border-red-500 focus:outline-none transition duration-150 ease-in-out"
                  disabled
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 lg:h-6 lg:w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#EF4444"
                    strokeWidth="2.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 6l12 12M6 18L18 6"
                    />
                  </svg>
                </button>
              </motion.div>

              {/* Green Button */}
              <motion.div
                className="flex justify-center items-center rounded-full"
                initial="default"
                animate={controls}
                variants={{
                  default: {
                    backgroundColor: "#FFFFFF",
                  },
                  hoverRed: {
                    backgroundColor: "#FFFFFF",
                  },
                  hoverGreen: {
                    backgroundColor: "#D1FAE5",
                  },
                }}
                transition={{ duration: 0.3 }}
              >
                <button
                  className="p-1.5 lg:p-2 rounded-full border-2 border-green-500 focus:outline-none transition duration-150 ease-in-out"
                  disabled
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 lg:h-6 lg:w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#10B981"
                    strokeWidth="2.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </button>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FlashcardFeaturePreview;
