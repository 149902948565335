import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.75,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    scale: 0.75,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
};

const Modal = ({ isOpen, handleClose, children }) => {
  const modalRef = useRef(null);

  // useEffect(() => {
  //   const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

  //   document.body.style.overflow = 'hidden';
  //   if (scrollbarWidth > 0) {
  //     document.body.style.paddingRight = `${scrollbarWidth}px`;
  //   }

  //   // if (modalRef.current) {
  //   //   modalRef.current.focus();
  //   // }

  //   const handleKeyDown = (e) => {
  //     if (e.key === 'Escape') {
  //       handleClose();
  //     }
  //   };
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.body.style.overflow = '';
  //     document.body.style.paddingRight = '';
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [handleClose]);


  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      // Clicking outside the modal content
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;


  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center w-full h-full"
        // This would be the modal with a blurred background
        //style={{ zIndex: 9999, backgroundColor: 'rgba(26, 32, 44, 0.16)', backdropFilter: "blur(6px)", }}
        style={{ zIndex: 9999 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleClose}
        role="dialog"
      >
        <motion.div
          className="focus:outline-none"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={(e) => e.stopPropagation()}
          ref={modalRef}
          tabIndex={-1}
        >
          <div className='w-full h-full flex justify-center items-center'>
            {children}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Modal;
