import { useState, useRef } from "react";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SectionTitle from "./SectionTitle";

const FAQSection = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  const faqList = [
    {
      question: "What does Study Buddy AI do?",
      answer: "Study Buddy AI is an advanced educational tool that leverages artificial intelligence to enhance students' learning experiences. It offers features such as AI-generated summaries, personalized feedback, and interactive study aids to help students grasp complex concepts more efficiently."
    },
    {
      question: "Is Study Buddy AI available for iPhone and iPad?",
      answer: "Yes, Study Buddy AI is available for both iPhone and iPad. You can download it from the App Store."
    },
    {
      question: "Is there an Android app?",
      answer: "Currently, Study Buddy AI is not available for Android devices. However, we are actively working on an Android version and plan to launch it in the near future. Stay tuned for updates!"
    },
    {
      question: "Is Study Buddy AI free?",
      answer: "Yes, Study Buddy AI is completely free to use. Start now and take your studying to the next level!"
    },
    {
      question: "Do you offer free trials?",
      answer: "Yes, Study Buddy AI offers a free trial period, allowing you to explore its features at no cost."
    }
  ];

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  return (
    <div ref={ref}>
      <SectionTitle title={"Frequently Asked Questions"} />
      <motion.div 
        className="border border-gray-200 bg-white w-full p-4 sm:p-6 md:p-8 rounded-2xl shadow-lg mt-6 md:mt-12"
        variants={containerVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        {faqList.map((faq, index) => (
          <motion.div
            key={index}
            className={`${index !== faqList.length - 1 && "pb-6 sm:pb-8"}`}
            variants={itemVariants}
            layout
          >
            <button
              className="w-full flex justify-between items-center focus:outline-none"
              onClick={() => handleExpand(index)}
              aria-expanded={expandedIndex === index}
              aria-controls={`faq-answer-${index}`}
            >
              <h2 className="text-base sm:text-lg md:text-xl text-left mr-6">
                {faq.question}
              </h2>
              <motion.div
                className="text-blue-700"
                animate={{ rotate: expandedIndex === index ? 45 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <FontAwesomeIcon 
                  icon={faPlus} 
                  className="text-lg sm:text-xl md:text-2xl transition-all duration-300" 
                />
              </motion.div>
            </button>

            <AnimatePresence>
              {expandedIndex === index && (
                <motion.div
                  id={`faq-answer-${index}`}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden"
                >
                  <h3 className="text-gray-600 text-sm sm:text-base md:text-lg mt-4 mr-6">
                    {faq.answer}
                  </h3>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default FAQSection;







// import { useState, useRef } from "react";
// import { motion, AnimatePresence, useInView } from "framer-motion";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import SectionTitle from "./SectionTitle";


// const FAQSection = () => {
//   const [expandedIndex, setExpandedIndex] = useState(null);
//   const ref = useRef(null);
//   const isInView = useInView(ref, { once: true, amount: 0.2 });

//   const faqList = [
//     {
//       question: "What does Study Buddy AI do?",
//       answer: "Study Buddy AI is an advanced educational tool that leverages artificial intelligence to enhance students' learning experiences. It offers features such as AI-generated summaries, personalized feedback, and interactive study aids to help students grasp complex concepts more efficiently."
//     },
//     {
//       question: "Is Study Buddy AI available for iPhone and iPad?",
//       answer: "Yes, Study Buddy AI is available for both iPhone and iPad. You can download it from the App Store."
//     },
//     {
//       question: "Is there an Android app?",
//       answer: "Currently, Study Buddy AI is not available for Android devices. However, we are actively working on an Android version and plan to launch it in the near future. Stay tuned for updates!"
//     },
//     {
//       question: "Is Study Buddy AI free?",
//       answer: "Yes, Study Buddy AI is completely free to use. Start now and take your studying to the next level!"
//     },
//     {
//       question: "Do you offer free trials?",
//       answer: "Yes, Study Buddy AI offers a free trial period, allowing you to explore its features at no cost. "
//     }
//   ];

//   const handleExpand = (index) => {
//     if (expandedIndex === index) {
//       setExpandedIndex(null);
//     } else {
//       setExpandedIndex(index);
//     }
//   };

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.2,
//         when: "beforeChildren"
//       }
//     }
//   };

//   const itemVariants = {
//     hidden: { 
//       opacity: 0,
//       y: -20
//     },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.4
//       }
//     }
//   };

//   return (
//     <div ref={ref}>
//       <SectionTitle title={"Frequently Asked Questions"} />
//       {/* <h1 className="text-center text-5xl font-semibold">Frequently Asked Questions</h1> */}
//       <motion.div 
//         className="border-1 border-gray-200 bg-white w-full p-8 rounded-2xl shadow mt-5"
//         variants={containerVariants}
//         initial="hidden"
//         animate={isInView ? "visible" : "hidden"}
//       >
//         {faqList.map((faq, index) => (
//           <motion.div
//             key={index}
//             className={`${index !== faqList.length - 1 && "pb-8"}`}
//             variants={itemVariants}
//             layout
//           >
//             <button
//               className="w-full flex justify-between items-center focus:outline-none"
//               onClick={() => handleExpand(index)}
//               aria-expanded={expandedIndex === index}
//               aria-controls={`faq-answer-${index}`}
//             >
//               <h2 className="text-xl">{faq.question}</h2>
//               <motion.div
//                 className="text-blue-700"
//                 animate={{ rotate: expandedIndex === index ? 45 : 0 }}
//                 transition={{ duration: 0.3 }}
//               >
//                 <FontAwesomeIcon icon={faPlus} style={{ fontSize: "22px" }} />
//               </motion.div>
//             </button>

//             <AnimatePresence>
//               {expandedIndex === index && (
//                 <motion.div
//                   id={`faq-answer-${index}`}
//                   initial={{ opacity: 0, height: 0 }}
//                   animate={{ opacity: 1, height: "auto" }}
//                   exit={{ opacity: 0, height: 0 }}
//                   transition={{ duration: 0.3 }}
//                   className="overflow-hidden"
//                 >
//                   <p className="text-gray-500 text-lg mt-3 mr-8">{faq.answer}</p>
//                 </motion.div>
//               )}
//             </AnimatePresence>
//           </motion.div>
//         ))}
//       </motion.div>
//     </div>
//   );
// };

// export default FAQSection;