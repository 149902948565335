import LoadingSpinnerIcon from "../LoadingSpinnerIcon";
import { motion } from "framer-motion";

const AnswerSubmitButton = ({ loadingState, onClick, userAnswer }) => {
  const loadingVariants = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
    },
  };
  return (
    <div className="flex justify-center mt-24">
      <button 
        className={`max-w-80 w-full px-6 py-3 rounded-lg text-xl font-semibold transition shadow-lg dark:shadow-none ${!userAnswer || Boolean(loadingState) ? "cursor-not-allowed dark:bg-gray-800 dark:text-gray-500 shadow-gray-300 bg-gray-200 text-gray-500" : "text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"}`} 
        onClick={onClick}
        disabled={Boolean(loadingState) || !userAnswer}
      > 
        {Boolean(loadingState) ? (
          <motion.div
            key="loading"
            className="flex items-center justify-center gap-2"
            variants={loadingVariants}
            initial="hidden"
            animate="show"
            exit="hidden"
          >
            <LoadingSpinnerIcon className="w-5 h-5" />
            <span>Loading...</span>
          </motion.div>
        ) : (
          "Submit"
        )}
      </button>
    </div>
  );

}
export default AnswerSubmitButton;