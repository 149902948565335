import AppleSignin from 'react-apple-signin-auth';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
const AppleLoginButton = () => {

  const handleAppleSignInSuccess = (data) => {
    const { authorization, user } = data
    console.log('Authorization', authorization) 
    const code = authorization.code
    const id_token = authorization.id_token
    console.log("code", code)
    console.log("id_token", id_token)
    axios.post("api/auth/apple/login/", { code: code })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <AppleSignin 
      authOptions={{
        clientId: String(process.env.REACT_APP_APPLE_CLIENT_ID),
        redirectURI: "https://studybuddyai.app/api/apple/callback/",
        nonce: 'nonce',
        scope: 'email name',
        usePopup: false,
      }}
      noDefaultStyle={true}
      onSuccess={handleAppleSignInSuccess}
      onError={(error) => console.error(error)}
      render={(props) => (
        <button
        type="button"
        onClick={props.onClick}
        disabled={props.disabled}
        className="flex items-center justify-center py-2.5 bg-black text-white rounded-lg hover:bg-gray-800 dark:hover:bg-black focus:outline-none w-full sm:w-1/2 transition transform hover:scale-105 hover:shadow-lg"
        aria-label="Sign in with Apple"
      >
        <FontAwesomeIcon icon={faApple} className="w-5 h-5 mr-2" />
        Sign in with Apple
      </button>
      )}
    />
  )
};
export default AppleLoginButton;