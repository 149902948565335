import { motion } from "framer-motion";
import StarIcon from "../SVG/StarIcon";

const ReviewCard = ({ review, reviewerName, reviewerImage }) => {

  const containerVariants = {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  
  const starVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <div className="h-[250px] w-[450px] border border-gray-200 bg-white shadow-lg p-6 rounded-xl mx-6 flex flex-col justify-start my-6">
      <motion.div
        className="flex flex-row space-x-1"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {[...Array(5)].map((_, index) => (
          <motion.span key={index} variants={starVariants}>
            <StarIcon
              className="text-yellow-400"
              style={{ width: '18px', height: '18px' }}
            />
          </motion.span>
        ))}
      </motion.div>
      
      <div className="mt-4 mb-2">
        <h2 className="text-lg text-gray-700">{review}</h2>
      </div>
      
      <div className="flex flex-row items-center mt-auto">
        <img
          src={reviewerImage}
          alt="Reviewer"
          className="w-10 h-10 rounded-full object-cover"
        />
        <h3 className="ml-4 text-lg font-semibold text-gray-900">{reviewerName}</h3>
      </div>
      
    </div>
  );
};

export default ReviewCard;
