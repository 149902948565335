import { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AILoadingAnimation from '../AILoadingAnimation';
import api from '../../api';
import Modal from './Modal';
import { motion } from 'framer-motion';
import ModalGenerateButton from '../Buttons/ModalGenerateButton';

const UploadTextModal = ({ showModal, setShowModal, processData, loading, setLoading, setLimitReason, setShowLimitModal, subscriptionPlan, saveFlashcards, uuid, fetchData, maxNumberOfFlashcards }) => {
  const [text, setText] = useState("");
  const [textLength, setTextLength] = useState(0);
  const [numberOfFlashcards, setNumberOfFlashcards] = useState(5);
  const [error, setError] = useState("");
  
  const textAreaRef = useRef(null);

  const userCharacterLimit = subscriptionPlan === "max" ? 50000 : 10000;

  const numberOfFlashcardOptions = Array.from(
    { length: Math.ceil(maxNumberOfFlashcards < 50 ? maxNumberOfFlashcards / 5 : 50 / 5) },
    (_, i) => {
      const value = (i + 1) * 5;
      return {
        label: `${value} Flashcard${value > 1 ? 's' : ''}`,
        value
      };
    }
  );



  const uploadText = () =>  {
    if (textLength === 0) {
      setError("Please enter your text.");
      textAreaRef.current.focus();
      return;
    }
    if (textLength > userCharacterLimit) {
      setError("Text exceeds character limit.");
      console.log("Text exceeds character limit");
      setLimitReason("text");
      setShowLimitModal(true);
      textAreaRef.current.focus();
      return;
    }
    setLoading(true);

    if (saveFlashcards) {
      const data = {
        "uuid": uuid, 
        "text": text,
        "number_of_flashcards": Number(numberOfFlashcards)
      }
      api.post("/api/flashcards/generate_flashcards_from_text_and_save/", data)
      .then((response) => {
        if (response.data.enough_tokens === false && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setShowModal(false);
          setLoading(false);
        } else {
          fetchData();
          setShowModal(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    } else {
      const data = {
        "text": text,
        "number_of_flashcards": Number(numberOfFlashcards)
      }
      api.post("/api/flashcards/generate_flashcards_from_text/", data)
      .then((response) => {
        if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setLoading(false);
          return;
        }
        setError("");
        console.log("Loaded the flashcards, now starting processing...")
        processData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };



  const onTextChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setTextLength(value.length);
    if (value.length <= userCharacterLimit) {
      setText(value);
    }
  }

  const handleClipboardPaste = () => {
    navigator.clipboard.readText().then(
      clipText => {
        setText(prev => prev +clipText);
        setTextLength(prev => prev + clipText.length);
      }
    );
  };






  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
      <div className="rounded-lg shadow-lg w-[768px] relative bg-white dark:bg-dark">
        {loading ? (
          <div className='h-[610.5px] flex flex-col items-center justify-center dark:bg-dark'>
            <AILoadingAnimation width={"300"} height={"300"} text={"Generating Flashcards..."} />
          </div>
        ) : (
          <div>
            <div className='px-8 pt-8'>
              <div className='flex flex-row justify-between items-center'>
                <h2 className="text-2xl font-bold dark:text-dark">Upload Text</h2>
                <motion.button
                  onClick={() => setShowModal(false)}
                  className="hover:text-gray-400 text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
                  style={{ fontSize: "28px"}}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </motion.button>
              </div>
              <h3 className="pt-2 text-lg text-gray-600 dark:text-gray-400 mb-6">
                Please ensure your character limit is set to {userCharacterLimit.toLocaleString()} characters.
              </h3>
              
              {/* <button className='mb-2 flex flex-row justify-end w-full pr-2' onClick={handleClipboardPaste}>
                <FontAwesomeIcon icon={faPaste} className='text-xl text-blue-600 hover:text-blue-700' /> 
              </button> */}
              <textarea 
                value={text}
                autoFocus
                type="text"
                ref={textAreaRef}
                className={`w-full p-2.5 shadow-lg dark:shadow-none rounded-lg resize-none outline-none border-2 dark:text-white dark:bg-gray-800 ${(textLength > userCharacterLimit || error !== "") ? "border-red-500" : "border-gray-200 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500"}`}
                placeholder="Paste your text here..."
                onChange={(e) => onTextChange(e)}
                rows={10}
                cols={50}
              />
              <p 
                className={`font-medium mt-2.5 text-gray-500 dark:text-gray-400 ${textLength > userCharacterLimit && "text-red-500"}`}
              >
                Character Count: {textLength.toLocaleString()}/{userCharacterLimit.toLocaleString()}
              </p>
              <p className='text-lg text-red-500 font-medium mt-2'>{error}</p>
              <div className='mt-6'>
                <h2 className='text-xl font-bold dark:text-dark'>Number of Flashcards</h2>
                <select 
                  className='border border-gray-200 dark:border-gray-700 rounded-lg p-2.5 mt-4 w-[205px] shadow-lg dark:shadow-none focus:border-gray-400 dark:focus:border-gray-500 focus:outline-none dark:bg-gray-800 dark:text-dark'
                  value={numberOfFlashcards}
                  onChange={(e) => setNumberOfFlashcards(e.target.value)}
                >
                  {numberOfFlashcardOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="flex justify-between mt-12 px-8 pb-8">
              <button 
                className="px-6 py-2 rounded-lg border border-gray-300 dark:border-gray-700 dark:hover:border-gray-600 w-full max-w-60 hover:border-gray-400 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition dark:text-dark font-medium"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <ModalGenerateButton onClick={uploadText} />
            </div>


          </div>
        )}
      </div>
    </Modal>
  );
};
export default UploadTextModal;