import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { motion } from "framer-motion";
import AppQrCode from "../SVG/AppQrCode";

const DownloadAppSection = () => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="py-8 md:py-10 px-6 md:px-20 bg-white border border-gray-200 rounded-2xl shadow-lg mt-14 md:mt-24"
    >
      <div className="flex flex-col lg:flex-row items-center lg:justify-between">
        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
          className="lg:mr-32"
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-6">
            Don't miss out - Get the app now!
          </h1>


          <h2 className="text-lg text-gray-700 mb-6 lg:hidden">
            Take Study Buddy AI with you! Tap below to download our mobile app and learn on the go.
          </h2>

          <h2 className="hidden lg:block text-lg text-gray-700 mb-6">
            Experience the best of Study Buddy AI on the go. Scan the QR code on the right or click the button below to download our mobile app.
          </h2>

          <button 
            className="inline-flex items-center px-6 py-4 transition duration-300 ease-in-out transform hover:scale-105 bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-semibold hover:shadow-color-lg hover:shadow-blue-600/70 rounded-xl text-white"
            onClick={() => window.open("https://apps.apple.com/app/study-buddy-ai/id6503170737/", "_blank")}
          >
            <FontAwesomeIcon icon={faApple} className="text-2xl mr-4" />
            Download on the App Store
          </button>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
          className="hidden lg:flex justify-center mt-8 lg:mt-0"
        >
          <div className="p-2 border border-gray-200 rounded-3xl shadow bg-white">
            <AppQrCode className="w-56 h-56" />
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default DownloadAppSection;










// This is the version before adding the mobile version of it

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faApple } from "@fortawesome/free-brands-svg-icons";
// import { motion } from "framer-motion";
// import AppQrCode from "../SVG/AppQrCode";



// const DownloadAppSection = () => {
//   return (
//     <motion.div 
//       initial={{ opacity: 0, y: 50 }}
//       whileInView={{ opacity: 1, y: 0 }}
//       viewport={{ once: true }}
//       transition={{ duration: 0.8, ease: "easeOut" }}
//       className="py-10 px-20 bg-white border border-1 border-gray-200 rounded-2xl shadow mt-16"
//     >
//       <div className="flex flex-col md:flex-row items-center md:justify-between">
//         <motion.div 
//           initial={{ opacity: 0, x: -50 }}
//           whileInView={{ opacity: 1, x: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
//           className="mr-32"
//         >
//           <h1 className="text-4xl font-semibold mb-4">Don't miss out - Get the app now!</h1>
//           <p className="text-lg text-gray-700 mb-6">
//             Experience the best of Study Buddy AI on the go. Scan the QR code below to download our mobile app and stay connected wherever you are.
//           </p>
//           <button 
//             className="inline-flex items-center px-6 py-3 transition duration-300 ease-in-out transform hover:scale-105 bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-semibold hover:shadow-color-lg hover:shadow-blue-600/70 rounded-xl text-white"
//             onClick={() => window.open("https://apps.apple.com/app/study-buddy-ai/id6503170737/", "_blank")}
//           >
//             <FontAwesomeIcon icon={faApple} className="text-2xl mr-3" />
//             Download on the App Store
//           </button>
//         </motion.div>

//         <motion.div 
//           initial={{ opacity: 0, x: 50 }}
//           whileInView={{ opacity: 1, x: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
//           className="flex justify-center"
//         >
//           <div className="p-2 border border-gray-200 rounded-3xl shadow bg-white">
//             <AppQrCode className="w-56 h-56" />
//           </div>
//         </motion.div>
//       </div>
//     </motion.div>
//   );
// };

// export default DownloadAppSection;