import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";
import Modal from "../Modals/Modal";
import { motion } from "framer-motion";


const FlashcardSettingsModal = ({ showModal, setShowModal, flashcards, askMode }) => {
  const [userTokens, setUserTokens] = useState(0)
  const [isMobile, setIsMobile] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("basic");
  const [userHasStarredCard, setUserHasStarredCard] = useState(false);
  const [userHasIncorrect, setUserHasIncorrect] = useState(false);
  const [questionFilter, setQuestionFilter] = useState("all");
  const [askRandomly, setAskRandomly] = useState(() =>{
    if (sessionStorage.getItem("askRandomly")) {
      return Boolean(sessionStorage.getItem("askRandomly"));
    }
    return false;
  });
  const [aiFeedback, setAiFeedback] = useState(() => {
    if (sessionStorage.getItem("aiFeedback")) {
      return Boolean(sessionStorage.getItem("aiFeedback"));
    }
    return false;
  })
  const [askType, setAskType] = useState(() => {
    if (sessionStorage.getItem("askType")) {
      return sessionStorage.getItem("askType");
    }
    return "definition";
  });
  
  const currentAskMode = askMode ? "write" : "flashcard";

  const { uuid, type } = useParams();
  const navigate = useNavigate();



  useEffect(()=> {
    const urls = [
      "/api/subscription/",
      `/api/flashcards/starred/${uuid}/`,
    ]
    if (askMode === "write") {
      urls.push(`/api/flashcards/write_mode_settings/${uuid}/`, `/api/flashcards/incorrect/write_mode/${uuid}/`)
    } else {
      urls.push(`/api/flashcards/flashcard_mode_settings/${uuid}/`)
    }

    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      setUserTokens(responses[0].data.tokens);
      setSubscriptionPlan(responses[0].data.plan);
      setUserHasStarredCard(responses[1].data.length > 0);
      setAskRandomly(responses[2].data.ask_randomly);
      setAskType(responses[2].data.ask_definition ? "definition" : "term");
      setQuestionFilter(responses[2].data.question_filter);

      if (askMode === "write") {
        const incorrectResponse = responses[3].data;
        console.log("Incorrect settings response", incorrectResponse);
        setUserHasIncorrect(incorrectResponse.length > 0);
      }
    })
    .catch((error) => {
      console.error("Failed to fetch data:", error);
    });
  }, [uuid, askMode]);





  //FIXME: Old code
  // useEffect(()=> {
  //   setUserHasStarredCard(flashcards.some(card => card.starred));
  //   setUserHasIncorrect(flashcards.some(card => card.correct_incorrect));
  //   api.get("/api/subscription/")
  //   .then((response) => {
  //     setUserTokens(response.data.tokens)
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   })

  //   api.get("/api/flashcards/starred/"+uuid+"/")
  //   .then((response) => {
  //     if (response.data.length > 0) {
  //       setUserHasStarredCard(true);
  //     }
  //   })

  //   if (askMode === "write") {
  //     api.get("/api/flashcards/incorrect/write_mode/"+uuid+"/")
  //     .then((response) => {
  //       if (response.data.length > 0) {
  //         setUserHasIncorrect(true);
  //       }
  //     })
  //   }
  // },[])



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); 
    };
    handleResize(); 
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, []);

  



  const handleRestartFlashcards = () => {
    console.log("restarting")
    const restartPath = askMode === "write" ? `/flashcard/mode/write/${uuid}/all` : `/flashcard/mode/flashcard/${uuid}/all`;

    api.put(askMode === "write" ? "/api/flashcards/restart_flashcard_set/" : "/api/flashcards/restart_flashcard_set/flashcard_mode/",
      { uuid: uuid, question_type: "all" })
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        setShowModal(false);

        navigate(restartPath);
        window.location.reload(); 
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };






  const handleCloseModal = () => {
    api.put((askMode === "write" ? "/api/flashcards/write_mode_settings/" + uuid + "/" : "/api/flashcards/flashcard_mode_settings/" + uuid + "/"), { ask_randomly: askRandomly, ask_definition: askType === "definition", question_type: questionFilter})
    .then((response) => {
      if (questionFilter !== type && questionFilter !== "incorrect" && questionFilter !== "starred-incorrect") {
        api.put((askMode === "write" ? "/api/flashcards/restart_flashcard_set/" : "/api/flashcards/restart_flashcard_set/flashcard_mode/"), { uuid: uuid, question_type: questionFilter })
          .then((response) => {
            console.log("\n\nrestart called")
            handleNavigate(questionFilter);
          })
          .catch((error) => {
            console.error("There was an error restarting the set!", error);
          });
      } else if (questionFilter !== type && (questionFilter === "incorrect" || questionFilter === "starred-incorrect")) {
        api.put((askMode === "write" ? "/api/flashcards/write_mode/update_question_index/" : "/api/flashcards/update_flashcard_mode_index/"), { uuid: uuid, question_type: questionFilter, index: 0 }) 
          .then((response) => {
            console.log("\n\nupdate question index called")
            handleNavigate(questionFilter);
          })
          .catch((error) => {
            console.error("There was an error updating the question index!", error);
          });
      } else {
        // If the question filter is the same as the type, simply update settings
        handleNavigate(questionFilter);
      }
    })
    .catch((error) => {
      console.error("Failed to update settings:", error);
    });
  }





  //FIXME: Old code
  // const handleCloseModal = (newMode) => {
  //   console.log("this is the randomly in the handle close", askRandomly)
  //   sessionStorage.setItem("askRandomly", askRandomly);
  //   sessionStorage.setItem("aiFeedback", aiFeedback);
  //   sessionStorage.setItem("askType", askType);
  //   setCurrentAskMode(newMode);
  
  //   let newType = type;
  //   if (questionFilter === "starred") {
  //     newType = "starred";
  //   } else if (questionFilter === "incorrect") {
  //     newType = "incorrect";
  //   } else {
  //     newType = "all";
  //   }
  
  //   if(newMode === "flashcard") {
  //     navigate(`/flashcard/mode/flashcard/${uuid}/${newType}`);
  //   } else {
  //     navigate(`/flashcard/mode/write/${uuid}/${newType}`);
  //   }
  //   window.dispatchEvent(new CustomEvent("modalClosedAndSettingsUpdated"));
  
  //   setShowModal(false);
  // };



  const handleNavigate = (questionFilter) => {
    let newType = "all";  // Default to 'all' if no cases match
    console.log("\n\nthis is the question filter in the settings\n", questionFilter);
    
    switch (questionFilter) {
      case "starred":
        newType = "starred";
        break;
      case "incorrect":
        newType = "incorrect";
        break;
      case "starred-incorrect": 
        newType = "starred-incorrect";
        break;
      default:
        newType = "all";
    }
  
    let path = "/flashcard/mode/flashcard/";
    if (askMode === "write") {
      path = "/flashcard/mode/write/";
    }
    navigate(`${path}${uuid}/${newType}`);
  
    window.dispatchEvent(new CustomEvent("modalClosedAndSettingsUpdated"));
    setShowModal(false);
  };
  

  // const handleNavigate = (questionFilter) => {
  //   let newType = type;
  //   console.log("\n\nthis is the question filter in the settings\n", questionFilter)
  //   if (questionFilter === "starred" || questionFilter === "starred-incorrect") {
  //     newType = "starred";
  //   } else if (questionFilter === "incorrect") {
  //     newType = "incorrect";
  //   } else if (questionFilter === "starred-incorrect") {
  //     newType = "starred-incorrect";
  //   } else {
  //     newType = "all";
  //   }


  //   let path = "/flashcard/mode/flashcard/";
  //   if (askMode === "write") { 
  //     path = "/flashcard/mode/write/";
  //   }
  //   navigate(`${path}${uuid}/${newType}/`);

  //   window.dispatchEvent(new CustomEvent("modalClosedAndSettingsUpdated"));
  //   setShowModal(false);
  // }


  const handleSwitchMode = () => {
    setShowModal(false);
    if (currentAskMode === "write") {
      navigate(`/flashcard/mode/write/${uuid}/${type}`);
    } else {
      navigate(`/flashcard/mode/flashcard/${uuid}/${type}`);
    }
  };


  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.5
      }
    }
  };


  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  const arrowVariants = {
    initial: { x: -15, opacity: 0 },
    hover: { x: 0, opacity: 1 },
  };
  
  const arrowRotateVariants = {
    initial: { rotate: 0 },
    hover: { 
      rotate: -45,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };
  



  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
      <motion.div
        className={`bg-white dark:bg-dark pt-6 px-10 pb-10 rounded-xl ${
          isMobile ? "w-full h-full" : "w-[750px] sm:mx-auto relative lg:w-[825px]"
        }`}
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >

        <motion.button
          onClick={() => handleCloseModal(currentAskMode)}
          className="absolute top-7 right-10 focus:outline-none"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="hover:text-gray-400 text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
            style={{ fontSize: "28px"}}
          />
        </motion.button>

        <div className="h-full flex flex-col justify-center items-center">
          <p className="text-xl font-semibold mb-10 dark:text-dark">
            Flashcard Settings
          </p>


          {/* This is the padding because of the px of the button at the bottom of the modal */}

          <motion.div variants={itemVariants} className="flex justify-between items-center w-full pl-4">
            <span className="font-medium text-gray-900 dark:text-dark">
              Shuffle terms
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={askRandomly}
                onChange={() => setAskRandomly(!askRandomly)}
              />
              <div
                className={`relative w-11 h-6 bg-gray-200 dark:bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                ${
                  askRandomly
                    ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white  dark:peer-checked:after:border-gray-300"
                    : ""
                } 
                after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white dark:after:bg-gray-300 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
              ></div>
            </label>
          </motion.div>

          <motion.div variants={itemVariants} className="flex justify-between items-center w-full mt-6 pl-4">
            <label className="block font-medium text-gray-900 dark:text-dark">
              Answer with
            </label>
            <select className="block border border-gray-200 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500 rounded-lg shadow-lg dark:shadow-none p-2.5 focus:outline-none mt-1 dark:bg-gray-700 dark:text-dark" defaultValue={askType} value={askType} onChange={e => setAskType(e.target.value)}>
              <option value="definition">Definition</option>
              <option value="term">Term</option>
            </select>
          </motion.div>







        

          <motion.div variants={itemVariants} className="flex justify-between items-center w-full mt-6 pl-4">
            <label className="block font-medium text-gray-900 dark:text-dark">
              Learn
            </label>
            {(
              (askMode === "flashcard" && !userHasStarredCard && !userHasIncorrect) ||
              (askMode === "write" && !userHasStarredCard && !userHasIncorrect)
            ) ? (
              <span className="block border border-gray-300 rounded-lg shadow-lg p-2.5 mt-1 font-medium focus:outline-none focus:ring-0">All terms</span>
            ) : (
              <select className="block border border-gray-200 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500 rounded-lg shadow-lg dark:shadow-none p-2.5 focus:outline-none mt-1 dark:bg-gray-700 dark:text-dark"  defaultValue={questionFilter} onChange={e => setQuestionFilter(e.target.value)}>
                <option value="all">All terms</option>
                {userHasStarredCard && <option value="starred">Only learn ★-terms</option>}
                {askMode === "write" && userHasIncorrect && <option value="incorrect">Only wrong terms</option>}
              </select>
            )}
          </motion.div>



{/* 
          {userHasStarredCard && (
            <div
              className={`flex justify-between items-center w-full mt-4 ml-2.5 ${
                askIncorrect ? "opacity-50" : ""
              }`}
            >
              <span className="font-medium text-gray-900 dark:text-gray-300">
                Only learn ★-terms{" "}
              </span>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={askStarred}
                  onChange={() => setAskStarred(!askStarred)}
                  disabled={askIncorrect}
                />
                <div
                  className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                  ${
                    askStarred
                      ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                      : ""
                  }
                  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                  dark:border-gray-600 peer-checked:bg-blue-600`}
                ></div>
              </label>
            </div>
          )} */}


{/* 
          {currentAskMode === "write" && (
            <div className="mt-4 ml-2.5 w-full">
              <div className="flex justify-between items-center cursor-pointer w-full">
                <span className="font-medium text-gray-900 dark:text-gray-300">AI feedback</span>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={aiFeedback}
                    onChange={() => setAiFeedback(!aiFeedback)}
                  />
                  <div
                    className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                              ${
                                aiFeedback
                                  ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                                  : ""
                              } 
                              after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-400`}
                  ></div>
                </label>
              </div>
              <div className="flex justify-start w-full mt-1">
                <div className="w-full" style={{ maxWidth: 'calc(100% - 100px)' }}>
                  <span className="text-sm text-neutral-400">
                    Ignoring spelling errors when marking, and check for understanding rather than a response that is exactly the same as the provided definition.
                  </span>
                </div>
              </div> 
            </div>
          )} */}



          <motion.div variants={itemVariants} className="flex justify-between items-center w-full mt-6 pl-4">
            <p className="font-medium text-gray-900 dark:text-dark">{currentAskMode === "write" ? "Switch to flashcard mode" : "Switch to write mode"}</p>
            <motion.button 
              className="flex items-center text-blue-600 hover:text-blue-700 dark:text-blue-500 dark:hover:text-white font-semibold rounded-lg px-3 py-1.5 hover:bg-blue-100 dark:hover:bg-blue-600 gap-2.5" 
              onClick={handleSwitchMode}
              initial="initial"
              whileHover="hover"
            >
              Start
              <motion.div variants={arrowRotateVariants}>
                <FontAwesomeIcon icon={faArrowRight} />
              </motion.div>
            </motion.button>
          </motion.div>


          
          <motion.div variants={itemVariants} className="flex justify-start w-full mt-6">
            <motion.button 
              className="px-4 py-1.5 hover:bg-green-100 dark:hover:bg-green-500 dark:hover:text-white rounded-lg font-semibold text-green-500 hover:text-green-600" 
              onClick={() => navigate("/pricing")}
              initial="initial"
              whileHover="hover"
            >

              {userTokens} Tokens
              <motion.span
                variants={arrowVariants}
                className="inline-block ml-2"
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </motion.span>

            </motion.button>
          </motion.div>
													
													

          <motion.div variants={itemVariants} className="flex justify-start w-full">
            <motion.button 
              className="mt-4 text-blue-600 dark:text-blue-500 hover:text-blue-600 dark:hover:text-white dark:hover:bg-blue-600 hover:bg-blue-100 font-semibold rounded-lg px-4 py-1.5"
              onClick={() => navigate("/flashcard/preview/"+uuid)}
              initial="initial"
              whileHover="hover"
            >
              Edit flashcard set
              <motion.span
                variants={arrowVariants}
                className="inline-block ml-2"
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </motion.span>
            </motion.button>
          </motion.div>


          <motion.div variants={itemVariants} className="flex justify-start w-full">
            <motion.button 
              className="mt-4 text-red-500 hover:text-red-600 dark:hover:text-white hover:bg-red-100 dark:hover:bg-red-600 font-semibold rounded-lg px-4 py-1.5" 
              onClick={handleRestartFlashcards}
              initial="initial"
              whileHover="hover"
            >
              Restart flashcards
              <motion.span
                variants={arrowVariants}
                className="inline-block ml-2"
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </motion.span>
            </motion.button>
          </motion.div>


        </div>
      </motion.div>
    </Modal>
  );
};

export default FlashcardSettingsModal;
