import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import BrandLogo from "../components/SVG/BrandLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import EmailInputField from "../components/Authentication/EmailInputField";
import PasswordInputField from "../components/Authentication/PasswordInputField";
import GoogleLoginButton from "../components/Authentication/GoogleLoginButton";
import AppleLoginButton from "../components/Authentication/AppleLoginButton";
import { Notify } from "../providers/NotificationProvider";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");
  const [nonFieldError, setNonFieldError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false)
  const navigate = useNavigate();

  

  const maskEmail = (email) => {
    if (!email) return '';
  
    const [username, domain] = email.split("@");
    
    // Validate the email format
    if (!username || !domain) return 'Invalid Email';
  
    // Mask the username
    const maskUsername = (username.length > 3)
      ? `${username.slice(0, 3)}${'*'.repeat(username.length - 3)}`
      : `${username[0]}${'*'.repeat(username.length - 1)}`;
  
    // Mask the domain
    const domainParts = domain.split('.');
    const maskedDomain = domainParts.map(part => {
      return part.length > 2
        ? `${part[0]}${'*'.repeat(part.length - 1)}`
        : `${part[0]}${'*'.repeat(part.length - 1)}`;
    }).join('.');
  
    return `${maskUsername}@${maskedDomain}`;
  };


  const handleButtonClicked = (e) => {
    e.preventDefault();
    if (!termsAndConditionsChecked) {
      Notify({ message: "Accept the Terms and Conditions", type: "error"})
      return setTermsAndConditionsError(true);
    }
    const data = {
      email: email,
      password1: password,
      password2: confirmPassword,
    };
    axios.post("/api/auth/register/", data)
      .then((response) => {
        console.log(response);
        const maskedEmail = maskEmail(email);
        sessionStorage.setItem("maskedEmail", maskedEmail);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "signup_success",
          userEmail: email, // Optional: Anonymized user email
        });
        navigate("/confirm-email");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.email) {
          Notify({ message: err.response.data.email, type: "error"})
          setEmailError(true);
          setEmailErrorMessage(err.response.data.email);
        }
        if (err.response.data.password1) {
          Notify({ message: err.response.data.password1, type: "error"})
          setPasswordError(true);
          setPasswordErrorMessage(err.response.data.password1);
        }
        if (err.response.data.password2) {
          Notify({ message: err.response.data.password2, type: "error"})
          setConfirmPasswordError(true);
          setConfirmPasswordErrorMessage(err.response.data.password2);
        }
        if (err.response.data.non_field_errors) {
          Notify({ message: err.response.data.non_field_errors, type: "error"})
          setEmailError(true);
          setPasswordError(true);
          setConfirmPasswordError(true);
          setNonFieldError(err.response.data.non_field_errors);
        }
      });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };




  return (
    <>
      <Helmet>
        <title>Study Buddy AI | Register</title>
        <link rel="canonical" href="https://studybuddyai.app/register" />
      </Helmet>
      <motion.section 
        className="min-h-screen bg-light dark:bg-dark" 
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="absolute left-6 top-6 dark:text-white cursor-pointer"
            onClick={() => navigate("/")}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="h-6 w-6" />
          </motion.button>


          <motion.div variants={itemVariants} className="mb-8 flex flex-row items-center">
            <BrandLogo className="w-16" />
            <span className="text-4xl font-bold ml-3 tracking-wide dark:text-dark">Study Buddy</span>
          </motion.div>

          <motion.div variants={itemVariants} className="w-full bg-white rounded-xl shadow-lg dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div className="text-center">
                <h1 className="font-bold text-gray-900 dark:text-white" style={{ fontSize: "28px", lineHeight: "35px" }}>
                  Create your Account
                </h1>
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                  Get started by creating your account.
                </p>
              </div>
              <form onSubmit={e => handleButtonClicked(e)}>
                <EmailInputField
                  email={email}
                  setEmail={setEmail}
                  emailError={emailError} 
                  emailErrorMessage={emailErrorMessage}
                />
                <div className="mt-6">
                  <PasswordInputField
                    password={password}
                    setPassword={setPassword}
                    passwordError={passwordError}
                    passwordErrorMessage={passwordErrorMessage}
                    placeholder={"Password"}
                  />
                </div>
                <div className="mt-6">
                  <PasswordInputField 
                    password={confirmPassword}
                    setPassword={setConfirmPassword}
                    passwordError={confirmPasswordError}
                    passwordErrorMessage={confirmPasswordErrorMessage}
                    placeholder={"Confirm password"}
                  />
                </div>


                <div className="flex items-start mt-6">
                  <div className="flex items-center h-5">
                    <input
                      id="terms"
                      aria-describedby="terms"
                      type="checkbox"
                      className={`w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800`}
                      required
                      onClick={() => setTermsAndConditionsChecked(!termsAndConditionsChecked)}
                    />
                  </div>
                  <div className="ml-4 text-sm">
                    <label
                      htmlFor="terms"
                      className="font-light text-gray-500 dark:text-gray-300"
                    >
                      I accept the{" "}
                      <Link
                        className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 hover:text-blue-700 dark:hover:text-blue-600"
                        to="/terms-of-service"
                      >
                        Terms and Conditions
                      </Link>
                    </label>
                  </div>

                </div>
                {termsAndConditionsError && (
                    <p className="mt-1 text-red-500">Please accept the Terms and Conditions to proceed.</p>
                )}
                {nonFieldError && <div className="text-red-500 text-sm font-bold mt-6">{nonFieldError}</div>}
                <button
                  type="submit"
                  className="w-full text-white transition duration-300 ease-in-out transform
                  bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  style={{ marginTop: 40 }}
                >
                  Create an account
                </button>
                
                <div className="flex items-center my-6">
                  <hr className="flex-grow border-gray-300 dark:border-gray-500" />
                  <span className="mx-2 text-gray-500 dark:text-gray-300">or</span>
                  <hr className="flex-grow border-gray-300 dark:border-gray-500" />
                </div>
                
                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0"> 
                  <AppleLoginButton />
                  <GoogleLoginButton />
                </div>


                <p className="text-gray-500 text-center mt-6 dark:text-gray-300">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="font-medium text-blue-600 hover:underline dark:text-blue-500 hover:text-blue-700 dark:hover:text-blue-600"
                  >
                    Login here
                  </Link>
                </p>
              </form>
            </div>
          </motion.div>
        </div>
      </motion.section>

    </>
      );
};

export default RegisterPage;
