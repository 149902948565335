import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import pdfToText from "react-pdftotext";
import CloudUploadIcon from "../SVG/CloudUploadIcon";

const ExamUploadDocument = ({
  userCharacterLimit,
  setFile,
  setFileText,
  fileUploadErrors,
  setFileUploadErrors,
  setShowLimitModal,
  setLimitReason,
  fileError,
  guestMode,
}) => {
  const [dragOver, setDragOver] = useState(false);

  const [fileSubmitted, setFileSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const [showUpgradeButton, setShowUpgradeButton] = useState(false);

  const navigate = useNavigate();

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      processFile(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };
  const processFile = (file) => {
    // Info: 5242880 = 5MB
    if (file.size > 5242880) {
      return setFileUploadErrors(
        "File size is too large. Please upload a file less than 5MB."
      );
    }
    console.log("File type: ", file[0].type);

    if (file[0].type === "application/pdf") {
      pdfToText(file[0])
        .then((text) => {
          console.log("Uploaded text", text);
          console.log("Uploaded text length", text.length);
          if (text === "") {
            setFileUploadErrors(
              "File is empty. No content could get extracted. Please upload a file with content."
            );
            return;
          } else if (text.length > userCharacterLimit) {
            setFileUploadErrors(
              `File is too large, upload a file with less than ${userCharacterLimit.toLocaleString()} characters.`
            );
            if (!guestMode) {
              setShowUpgradeButton(true);
              setLimitReason("text");
              setShowLimitModal(true);
            }
          } else {
            setFileText(text);
            setFileUploadErrors("");
            setFileSubmitted(true);
            if (!guestMode) {
              setShowLimitModal(false);
            }
          }
        })
        .catch((error) => {
          console.error("Failed to extract text from pdf", error);
          setFileUploadErrors(
            "Failed to extract text from pdf. Please upload a file with content."
          );
          setFileText("");
        });
    } else {
      setFileUploadErrors("");
      setFileSubmitted(true);
      setFile(file[0]);
    }
    setFileName(file[0].name);
  };

  // TODO: change here that the loading should be on the main page and also the uplaod should also be on the main page

  return (
    <div className="h-[440px]">
      <div
        className={`h-[390px] shadow-lg dark:shadow-none border-2 rounded-lg ${dragOver ? "bg-blue-200 dark:bg-gray-700" : "bg-white dark:bg-gray-800 border-dashed"} ${fileError ? "border-red-500" : "border-blue-600"} relative`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!fileSubmitted && (
          <input
            type="file"
            id="file-upload"
            className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
            onChange={(e) => processFile(e.target.files)}
            accept=".pdf,.docx"
            onClick={(event) => {
              event.target.value = null;
            }}
            multiple={false}
          />
        )}
        <h2
          htmlFor="file-upload"
          className={`text-center flex flex-col items-center justify-center h-full `}
        >
          {fileSubmitted ? (
            <div>
              <FontAwesomeIcon
                icon={faFileLines}
                className="text-blue-500 text-9xl"
              />
              <p className="text-xl mt-12 mb-8  text-blue-600 dark:text-blue-500 font-medium">
                {fileName}
              </p>
              <button
                className="text-lg bg-blue-200 dark:bg-blue-600 text-blue-600 dark:text-white rounded-full px-4 py-1.5 font-semibold hover:bg-blue-300 dark:hover:bg-blue-500 hover:text-blue-700 dark:hover:text-white"
                onClick={() => setFileSubmitted(false)}
              >
                Change File
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <CloudUploadIcon className="w-20 h-20 transition-colors duration-300 text-blue-600 dark:text-blue-500" />
              <p className="text-xl mt-8 dark:text-dark">
                Drag and drop your file here.
              </p>
              <div className="flex items-center w-full my-6">
                <hr className="flex-grow border border-gray-300 dark:border-gray-500" />
                <span className="mx-2 text-gray-500 dark:text-gray-300">
                  or
                </span>
                <hr className="flex-grow border border-gray-300 dark:border-gray-500" />
              </div>
              <div className="bg-white dark:bg-gray-700 rounded-lg border-2 py-1 px-4 border-blue-600 dark:border-gray-600 text-blue-600 dark:text-white text-xl font-medium">
                Browse files
              </div>
            </div>
          )}
        </h2>
      </div>
      <p className="font-medium mt-4 text-lg text-gray-500 dark:text-gray-400">
        Support only .pdf and .docs files.
      </p>
      {fileError && (
        <p className="text-lg font-medium text-red-500 mt-2.5">
          File is required
        </p>
      )}
      {fileUploadErrors !== "" && (
        <div className="flex flex-row items-center mt-4">
          <p className="text-lg font-medium text-red-500 mr-1">
            {fileUploadErrors}
          </p>
          {showUpgradeButton && (
            <button
              className="text-lg font-medium text-blue-600 hover:text-blue-700 dark:dark:text-blue-500 dark:hover:text-blue-600 underline-offset-1 underline"
              onClick={() => navigate("/pricing")}
            >
              Upgrade to Study Buddy MAX!
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ExamUploadDocument;
