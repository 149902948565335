import { useNavigate } from "react-router-dom";

const SignUpButton = () => {
  const navigate = useNavigate();

  return (
    <button 
      className="transition duration-300 ease-in-out transform hover:scale-105 bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 py-1.5 px-6 hover:shadow-color-md shadow-blue-500" 
      style={{ borderRadius: "50px" }}
      onClick={() => navigate("/register")}
    >
      <p className="text-lg font-medium text-white">Sign Up</p>
    </button>
  );
};
export default SignUpButton;