import { useEffect, useState } from "react";

const useTheme = () => {
  // Start with 'light' or 'dark' based on localStorage,
  // but note that the initial HTML is already correct
  // thanks to the <script> in index.html.
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'system'; 
    // 'system' means we haven't chosen a manual override yet.
  });

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      document.body.style.backgroundColor = '#101828';
      localStorage.setItem('theme', 'dark');
    } else if (theme === 'light') {
      document.documentElement.classList.remove('dark');
      document.body.style.backgroundColor = '#F6F7FB';
      localStorage.setItem('theme', 'light');
    } else {
      // 'system' means remove manual override and let the script 
      // in index.html + media query do their thing.
      localStorage.removeItem('theme');
      //localStorage.removeItem('theme');

      // Immediately re-check the system preference
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      document.body.style.backgroundColor = prefersDark ? '#101828' : '#F6F7FB';
      if (prefersDark) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, [theme]);

  return [theme, setTheme];
};

export default useTheme;