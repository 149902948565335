import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTiktok, faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import DownloadOnAppStoreIcon from "../SVG/DownloadOnAppStoreIcon";
import BrandLogo from "../SVG/BrandLogo";
import { motion } from "framer-motion";

const FooterSection = () => {
  const navigate = useNavigate();

  const socialIcons = [
    { icon: faXTwitter, link: 'https://x.com/Study_Buddy_AI' },
    { icon: faTiktok, link: 'https://www.tiktok.com/@study_buddy_ai_' },
    { icon: faInstagram, link: 'https://www.instagram.com/study_buddy_ai_/' },
  ];

  const arrowVariants = {
    initial: { x: -10, opacity: 0 },
    hover: { x: 0, opacity: 1 },
  };

  const currentYear = new Date().getFullYear();


  return (
    <div
      className="bg-white w-full mt-20 md:mt-32 border-t border-gray-200 shadow-t-md rounded-t-5xl md:rounded-t-7xl"

      // style={{ borderTopLeftRadius: "64px", borderTopRightRadius: "64px" }}
    >
      <div className="flex flex-col justify-center">
        <div className="container mx-auto px-4 max-w-[1200px] mt-12 md:mt-16 m-8 md:mb-6">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="w-full md:w-4/12 mb-6 md:mb-0">
              <div className="flex flex-row items-center">
                <BrandLogo className="w-12 md:w-14" />
                <h2 className="text-2xl md:text-3xl font-bold ml-2 md:ml-3 tracking-wide">Study Buddy</h2>
              </div>
              <h2 className="mt-6 text-gray-500 font-medium">
                Start studying faster and better with your personalized AI Study Buddy.
              </h2>
              <div className="flex space-x-3 mt-6">
                {socialIcons.map((social, index) => (
                  <button
                    onClick={() => window.open(social.link, "_blank")}
                    key={index}
                    className="flex items-center justify-center p-2.5 bg-white text-gray-700 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none transition transform hover:scale-105 hover:shadow-color-lg hover:shadow-blue-600/70 hover:text-blue-700"
                  >
                    <FontAwesomeIcon
                      icon={social.icon}
                      className="text-2xl"
                      style={{ color: 'currentColor' }}
                    />
                  </button>
                ))}
              </div>
            </div>

            <div className="w-full md:w-3/12 lg:2/12">
              <h1 className="text-xl md:text-2xl font-semibold">Resources</h1>
              <motion.button 
                className="md:text-lg text-gray-700 hover:text-gray-800 flex flex-row items-center focus:outline-none hover:underline hover:underline-offset-1 mt-2 md:mt-3" 
                onClick={() => navigate("/blogs")}
                initial="initial"
                whileHover="hover"
              >
                Blogs
                <motion.span
                  variants={arrowVariants}
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </motion.span>
              </motion.button>

              <motion.button 
                className="md:text-lg text-gray-700 hover:text-gray-800 flex flex-row items-center focus:outline-none hover:underline hover:underline-offset-1 mt-2 md:mt-3" 
                onClick={() => window.open("https://studybuddyai.app/privacy-policy", "_blank")}
                initial="initial"
                whileHover="hover"
              >
                Privacy Policy
                <motion.span
                  variants={arrowVariants}
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </motion.span>
              </motion.button>

              <motion.button 
                className="md:text-lg text-gray-700 hover:text-gray-800 flex flex-row items-center focus:outline-none hover:underline hover:underline-offset-1 mt-2 md:mt-3" 
                onClick={() => window.open("https://studybuddyai.app/terms-of-service", "_blank")}
                initial="initial"
                whileHover="hover"
              >
                Terms of Use
                <motion.span
                  variants={arrowVariants}
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </motion.span>
              </motion.button>

              <motion.button
                className="md:text-lg text-gray-700 hover:text-gray-800 flex flex-row items-center focus:outline-none hover:underline hover:underline-offset-1 mt-2 md:mt-3"
                onClick={() => window.location.href = "mailto:support@studybuddyai.app"}
                initial="initial"
                whileHover="hover"
              >
                Contact Us
                <motion.span
                  variants={arrowVariants}
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </motion.span>
              </motion.button>
            </div>
          </div>

          <div className="my-6" style={{ borderTop: "1px solid #E5E7EB" }} />
          
          <div className="flex flex-col md:flex-row w-full justify-between items-center">
            <p className="text-center md:text-left">Study Buddy AI © {currentYear}. All rights Reserved.</p>
            <button 
              onClick={() => window.open("https://apps.apple.com/app/study-buddy-ai/id6503170737/", "_blank")}
              className="hover:scale-105 transition-transform duration-300 ease-in-out"
            >
              <DownloadOnAppStoreIcon className="w-40 md:w-48" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;