import { useEffect, useState } from 'react';
import StarIcon from '../SVG/StarIcon';
import person1 from '../../Assets/images/headshots/person2.webp';
import person2 from '../../Assets/images/headshots/person4.webp';
import person3 from '../../Assets/images/headshots/person1.webp';
import Student from "../../Assets/images/hero_section_student.webp"
import AnimatedSparkle from '../SVG/AnimatedSparkle';
import { motion } from 'framer-motion';
import GetStartedButton from './GetStartedButton';


const HeroSection = () => {
  //FIXME: For the main title could also use: Start studying with your personal AI Study Buddy
  const containerVariants = {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Adjust the delay between stars here
      },
    },
  };
  
  const starVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 },
  };

  // TODO: 
  // - Add a nice animated ai sparkle in blue with a gradient on the right side of ai
  // - Add to the subheader the thing about the personalized study buddy 
  // - change that the width of the part where the text is should be the same as the one where the start for free button is
  // - Change the image
  //   - image should be same height as the text and so on the left side


  // TODO: need to add a nice circle around the image


  const textVariants = {
    hidden: { 
      opacity: 0, 
      y: 20 
    },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return width;
  };
  
  const width = useWindowWidth();
  
  const getIconSize = () => {
    if (width >= 1024) return "72px";      // lg
    if (width >= 768) return "62px";       // md
    if (width >= 640) return "62px";       // sm
    return "52px";                         // default
  };



  
  return (
    <div className="flex items-center">
      <motion.div 
        className="lg:w-1/2"
        initial="hidden"
        animate="visible"
      >
        <motion.h1 variants={textVariants} className='font-semibold leading-none text-4xl md:text-5xl lg:text-[64px]'>
          Study faster with
        </motion.h1>
        <motion.h1 
          variants={textVariants} 
          className='font-semibold leading-snug mt-1 text-4xl md:text-5xl lg:text-[64px]' 

        >
          the Power of <span style={{ display: 'inline-flex', alignItems: 'center' }}>AI
            <AnimatedSparkle className="ml-4" 
              width={getIconSize()} 
              height={getIconSize()} 
            />
          </span>
        </motion.h1>

        {/* This is the text when the screen width is desktop */}
        <motion.h2 
          variants={textVariants}
          className='hidden lg:block mt-6 mb-16 text-xl text-slate-600' 
          style={{ fontWeight: "450" }}
        >
          Start studying smarter with your personalized AI-powered study buddy. Boost your grades, stay organized, and make learning easier than ever. Your path to success starts here
        </motion.h2>

        {/* This is the text for the mobile version */}
        <motion.h2 
          variants={textVariants}
          className='lg:hidden mt-6 mb-16 text-xl text-slate-600' 
          style={{ fontWeight: "450" }}
        >
          Study smarter with your AI-powered study buddy. Boost grades, stay organized, and make learning easy. Success starts here!
        </motion.h2>


        {/* This is for the desktop sized screens */}

        <div className='hidden lg:flex mx-auto'>
          <GetStartedButton />
          <div className="flex items-center ml-8">
            <div className="flex">
              <div className="w-12 h-12 rounded-full overflow-hidden border-2 border-white flex-shrink-0">
                <img
                  src={person1}
                  alt="Person 1"
                  className="w-full h-full object-cover"
                  srcSet={`${person1} 1x, ${person1} 2x`}
                />
              </div>
              <div className="-ml-3 w-12 h-12 rounded-full overflow-hidden border-2 border-white flex-shrink-0">
                <img
                  src={person2}
                  alt="Person 2"
                  className="w-full h-full object-cover"
                  srcSet={`${person2} 1x, ${person2} 2x`}
                />
              </div>
              <div className="-ml-3 w-12 h-12 rounded-full overflow-hidden border-2 border-white flex-shrink-0">
                <img
                  src={person3}
                  alt="Person 3"
                  className="w-full h-full object-cover"
                  srcSet={`${person3} 1x, ${person3} 2x`}
                />
              </div>
              <div className="-ml-3 w-12 h-12 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 border-2 border-white flex items-center justify-center text-sm font-medium text-white">
                2k+
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start ml-6">
            <motion.div
              className="flex flex-row space-x-1"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              {[...Array(5)].map((_, index) => (
                <motion.span key={index} variants={starVariants}>
                  <StarIcon
                    className="text-yellow-400"
                    style={{ width: '18px', height: '18px' }}
                  />
                </motion.span>
              ))}
            </motion.div>
            <span className="mt-1.5 text font-medium text-slate-600">
              Join the 2000+ Students
            </span>
          </div>
        </div>


        {/* This is for the mobile sized screens */}
        <div className='lg:hidden mx-auto'>
          <div className="flex items-center justify-start space-x-4 mb-8">
            <div className="flex">
              <div className="w-11 h-11 rounded-full overflow-hidden border-2 border-white flex-shrink-0">
                <img
                  src={person1}
                  alt="Person 1"
                  className="w-full h-full object-cover"
                  srcSet={`${person1} 1x, ${person1} 2x`}
                />
              </div>
              <div className="-ml-3 w-11 h-11 rounded-full overflow-hidden border-2 border-white flex-shrink-0">
                <img
                  src={person2}
                  alt="Person 2"
                  className="w-full h-full object-cover"
                  srcSet={`${person2} 1x, ${person2} 2x`}
                />
              </div>
              <div className="-ml-3 w-11 h-11 rounded-full overflow-hidden border-2 border-white flex-shrink-0">
                <img
                  src={person3}
                  alt="Person 3"
                  className="w-full h-full object-cover"
                  srcSet={`${person3} 1x, ${person3} 2x`}
                />
              </div>
              <div className="-ml-3 w-11 h-11 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 border-2 border-white flex items-center justify-center text-sm font-medium text-white">
                2k+
              </div>
            </div>
            
            <div className="flex flex-col">
              <motion.div
                className="flex flex-row space-x-1"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                {[...Array(5)].map((_, index) => (
                  <motion.span key={index} variants={starVariants}>
                    <StarIcon
                      className="text-yellow-400"
                      style={{ width: '16px', height: '16px' }}
                    />
                  </motion.span>
                ))}
              </motion.div>
              <span className="mt-1 font-medium text-slate-600">
                Join the 2000+ Students
              </span>
            </div>
          </div>
          
          <GetStartedButton />
        </div>



      </motion.div>

      <div className="lg:w-1/2 lg:flex lg:justify-end hidden">
        <div className="relative">

          <div
            className="absolute shadow-color-xl shadow-blue-400"
            style={{
              width: '375px',
              height: '375px',
              background: 'linear-gradient(to right, #53AFE5, #0073E9)',
              borderRadius: '50%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          ></div>
          <div
            className="relative"
            style={{
              width: '375px',
              height: '375px',
              zIndex: 2,
              position: 'relative',
            }}
          >
            <div
              style={{
                width: '375px',
                height: '500px',
                backgroundColor: 'transparent',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
                borderBottomLeftRadius: '9999px',
                borderBottomRightRadius: '9999px',
                overflow: 'hidden',
                position: 'absolute',
                bottom: 0,
              }}
            >

              <motion.img
                initial={{ scale: 1.1 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1, ease: 'easeOut' }}
                src={Student}
                alt="Student"
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  position: 'absolute',
                  bottom: 0,

                  transform: 'translateX(-50%)',
                  transformOrigin: 'bottom center',
                }}
              />


            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default HeroSection;