import React, { useState, useEffect } from 'react';
import '../../styles/FlashcardComponent.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar, faUndo } from "@fortawesome/free-solid-svg-icons";
import api from '../../api';
import { motion } from 'framer-motion';

const FlashcardComponent = ({
  cards,
  cardIndex,
  setCardIndex,
  setNumberOfCorrect,
  setNumberOfIncorrect,
  setShowRecap,
  flashcardSetUuid,
  questionType,
}) => {

  const [localCardIndex, setLocalCardIndex] = useState(cardIndex || 0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('');
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const [goBackButtonDisabled, setGoBackButtonDisabled] = useState(true);
  const [numberOfGoBacks, setNumberOfGoBacks] = useState(0);

  const [currentCardData, setCurrentCardData] = useState(null);

  const [isGoBack, setIsGoBack] = useState(false);
  const [previousCardData, setPreviousCardData] = useState(null);


  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const iconSize = windowSize < 768 ? 'xs' : 'sm';

  const toggleStar = () => {
    const newStarredStatus = !currentCardData.starred;
    const updatedCardData = { ...currentCardData, starred: newStarredStatus };
    setCurrentCardData(updatedCardData);
  
    api.put('/api/flashcards/update_starred/', {
      uuid: currentCardData.uuid,
      starred: newStarredStatus,
    }).catch((error) => {
      console.error('Error updating starred status:', error);

      setCurrentCardData(currentCardData);
    });
  
  };
  

  useEffect(() => {
    setCurrentCardData(cards[localCardIndex]);
  }, [localCardIndex, cards]);
  
  




  const handleNavigationCorrection = (direction) => {
    if (isAnimating) return;
  


    const nextIndex = (localCardIndex + 1) % cards.length;
    const nextCard = cards[nextIndex];
  
    setPreviousCardData(currentCardData); 
    setCurrentCardData(nextCard);
    
    setIsAnimating(true);
    setIsFlipped(false);
    setIsRequestPending(true);
    setAnimationDirection(direction);

    console.log("this is the current card data: ", currentCardData);
    console.log("this is previous card data: ", previousCardData);
  


    const data = {
      uuid: currentCardData.uuid,
      correct_incorrect: direction,
      flashcard_set_uuid: flashcardSetUuid,
      question_type: questionType,
    };
  
    api.put('/api/flashcards/flashcard/correct_incorrect/', data)
      .then(() => {

        if (direction === 'true') {
          setNumberOfCorrect((prev) => prev + 1);
        } else {
          setNumberOfIncorrect((prev) => prev + 1);
        }

        setTimeout(() => {
          if (localCardIndex === cards.length - 1) {
            return setShowRecap(true);
          }
        }, 650);
  
        setTimeout(() => {
          setLocalCardIndex(nextIndex);
          setAnimationDirection('');
          setIsRequestPending(false);
          setCardIndex(nextIndex);
          setIsAnimating(false);
          setGoBackButtonDisabled(false);
          setNumberOfGoBacks(prev => (prev < 3 ? prev + 1 : prev));
        }, 750);
      })
      .catch((error) => {
        console.error('Error updating flashcard status:', error);
        setIsAnimating(false);
        setIsRequestPending(false);
        setAnimationDirection('');
      });
  };


  const handleGoBack = () => {
    if (isAnimating) return;

    const prevIndex = localCardIndex - 1;
    if (prevIndex < 0) return;
    setIsGoBack(true);
    setGoBackButtonDisabled(true);
    setIsAnimating(true);
    const data = {
      uuid: cards[prevIndex].uuid,
      flashcard_set_uuid: flashcardSetUuid,
    };

    api.put("/api/flashcards/flashcard_mode_go_back/", data)
      .then((response) => {
        const wasCorrect = response.data.correct_incorrect === true;
        if (wasCorrect) {
          setNumberOfCorrect((prev) => prev - 1);
          setAnimationDirection("goBackTrue");
        } else {
          setNumberOfIncorrect((prev) => prev - 1);
          setAnimationDirection("goBackFalse");
        }
        setNumberOfGoBacks((prev) => prev - 1);

        setPreviousCardData(cards[prevIndex]); 

        setIsFlipped(false);

        setTimeout(() => {
          setCurrentCardData(cards[prevIndex]); // Now update to previous card
          setLocalCardIndex(prevIndex);
          setCardIndex(prevIndex);
          setIsAnimating(false);
          setAnimationDirection("");
          setGoBackButtonDisabled(false);
          setIsGoBack(false);
        }, 750);
      })
      .catch((error) => {
        console.error("Error updating flashcard status:", error);
        setIsAnimating(false);
        setAnimationDirection("");
        setGoBackButtonDisabled(false);
      });
  };

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };



  

  return (
    <div
      className={`w-[325px] h-[440px] sm:w-[585px] sm:h-[375px] md:w-[732px] md:h-[500px] lg:w-[975px] lg:h-[550px] relative`}
    >
      <div className="card-stack relative w-full h-full perspective-container">
        <div 
          className={`card bg-white dark:bg-slate-700 current-card absolute w-full h-full cursor-pointer rounded-2xl ${isFlipped ? 'flipped shadow-flipped dark:shadow-none' : 'shadow-normal dark:shadow-none'} ${animationDirection}`}
          onAnimationEnd={() => {setIsAnimating(false); setAnimationDirection('');}}
          onClick={!isAnimating ? toggleFlip : undefined}
        >
          <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl dark:text-dark select-none">
            {isAnimating ? (isGoBack ? currentCardData?.front : previousCardData?.front) : currentCardData?.front}

            {!isFlipped && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleStar();
                }}
                className="absolute top-0 left-0 m-6 outline-none"
              >
              {(isAnimating ? (isGoBack ? currentCardData?.starred : previousCardData?.starred) : currentCardData?.starred) ? (
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" size={iconSize} />
                ) : (
                  <FontAwesomeIcon icon={farStar} className="text-gray-600 dark:text-gray-300" size={iconSize} />
                )}
              </button>
            )}
          </div>
          <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-3xl dark:text-dark select-none">
            {isAnimating ? (isGoBack ? currentCardData?.back : previousCardData?.back) : currentCardData?.back}
          </div>
        </div>
        {isAnimating && (localCardIndex !== (cards.length-1) || isGoBack) && (
          <div
            className={`card next-card absolute w-full h-full rounded-2xl ${animationDirection} shadow-normal dark:shadow-none`}
          >
            <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl dark:text-dark select-none">
            {isGoBack ? previousCardData?.front : currentCardData?.front}

              {!isFlipped && (
                <div
                  className="absolute top-0 left-0 m-6 outline-none"
                >
                  {(isGoBack ? previousCardData?.starred : currentCardData?.starred) ? (
                    <FontAwesomeIcon icon={faStar} className="text-yellow-400" size={iconSize} />
                  ) : (
                    <FontAwesomeIcon icon={farStar} className="text-gray-600 dark:text-gray-300" size={iconSize} />
                  )}
                </div>
              )}
            </div>
            <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-3xl dark:text-dark select-none">
            {isGoBack ? previousCardData?.back : currentCardData?.back}

            </div>
          </div>
        )}    
      </div>

      <div className="flex justify-between items-center mt-12 w-full">
        <button 
          disabled={goBackButtonDisabled || numberOfGoBacks <= 0} 
          className={`${(goBackButtonDisabled || numberOfGoBacks <= 0) && "opacity-50"}`}
          onClick={handleGoBack}
        >
          <FontAwesomeIcon icon={faUndo} className={`text-2xl ${(goBackButtonDisabled || numberOfGoBacks > 0) && "hover:text-gray-500 dark:hover:text-gray-400"} text-gray-700 dark:text-gray-200`} />
        </button>

        <div className="flex justify-center items-center gap-20">
          <div className="bg-white dark:bg-slate-700 flex justify-center items-center rounded-full">
            <motion.button
              className="hover:bg-red-200 dark:hover:bg-slate-600 p-2 rounded-full border-2 border-red-500 hover:border-red-600 focus:outline-none transition duration-150 ease-in-out"
              onClick={() => handleNavigationCorrection('false')}
              disabled={isRequestPending}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#EF4444"
                strokeWidth="2.5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 6l12 12M6 18L18 6" />
              </svg>
            </motion.button>
          </div>

          <div className="bg-white dark:bg-slate-700 flex justify-center items-center rounded-full">
            <motion.button
              className="hover:bg-green-200 dark:hover:bg-slate-600 p-2 rounded-full border-2 border-green-500 hover:border-green-600 focus:outline-none transition duration-150 ease-in-out"
              onClick={() => handleNavigationCorrection('true')}
              disabled={isRequestPending}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#10B981"
                strokeWidth="2.5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            </motion.button>
          </div>
        </div>


        <div style={{width: "24px"}} />
      </div>
    </div>
  );
};

export default FlashcardComponent;