import { useState, useEffect } from "react";
import axios from "axios";
import BrandLogo from "../components/SVG/BrandLogo";
import EmailInputField from "../components/Authentication/EmailInputField";
import { Notify } from "../providers/NotificationProvider";
import { motion, AnimatePresence } from "framer-motion";


const ConfirmEmail = () => {
  const [email, setEmail] = useState("");
  const [maskedEmail, setMaskedEmail] = useState("");
  const [showEmailField, setShowEmailField] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    setMaskedEmail(sessionStorage.getItem("maskedEmail"));
  }, []);

  const onResendEmailButtonClicked = (e) => {
    e.preventDefault();
    const data = { email }; 
    if (!email) {
      Notify({ message: "Email is required", type: "error" });
      setShowEmailError(true);
      return setEmailError("Email is required");
    }
    setEmailError("");
    setShowEmailError(false);
    axios.post("api/auth/register/resend-email/", data)
      .then((response) => {
        console.log(response.data);

        Notify({ message: "Email sent", type: "success" });
        setEmail("");
        setShowEmailField(false);
      })
      .catch((error) => {
        console.error(error);
      });

  };




  const handleButtonClick = (e) => {
    if (showEmailField) {
      onResendEmailButtonClicked(e);
    } else {
      setShowEmailField(!showEmailField);
    }
  };



  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };


  // const formVariants = {
  //   hidden: { 
  //     opacity: 0,
  //     height: 0,
  //     y: -20
  //   },
  //   visible: {
  //     opacity: 1,
  //     height: "auto",
  //     y: 0,
  //     transition: {
  //       duration: 0.3,
  //       ease: "easeOut"
  //     }
  //   },
  //   exit: {
  //     opacity: 0,
  //     height: 0,
  //     y: -20,
  //     transition: {
  //       duration: 0.3,
  //       ease: "easeIn"
  //     }
  //   }
  // };

  const formVariants = {
    hidden: { 
      opacity: 0,
      height: 48, // Initial height of just the button
      scale: 0.95
    },
    visible: {
      opacity: 1,
      height: "auto",
      scale: 1,
      transition: {
        duration: 0.4,
        ease: [0.04, 0.62, 0.23, 0.98]
      }
    },
    exit: {
      opacity: 0,
      height: 48,
      scale: 0.95,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98]
      }
    }
  };
  



  const emailFieldVariants = {
    hidden: {
      opacity: 0,
      height: 0,
      marginBottom: 0
    },
    visible: {
      opacity: 1,
      height: "auto",
      marginBottom: 16,
      transition: {
        duration: 0.4,
        ease: [0.04, 0.62, 0.23, 0.98]
      }
    },
    exit: {
      opacity: 0,
      height: 0,
      marginBottom: 0,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98]
      }
    }
  };
  




 
  return (
    <motion.section 
      className="min-h-screen bg-light dark:bg-dark" 
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      layout
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

        <motion.div variants={itemVariants} className="mb-8 flex flex-row items-center">
          <BrandLogo className="w-16" />
          <span className="text-4xl font-bold ml-3 tracking-wide dark:text-dark">Study Buddy</span>
        </motion.div>


        <motion.div variants={itemVariants} className="w-full bg-white rounded-xl shadow-lg dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="font-bold text-gray-900 dark:text-dark text-center" style={{ fontSize: "28px", lineHeight: "35px" }}>
              Check your Inbox
            </h1>
            <p className="text-gray-500 dark:text-gray-300 mb-6">
              We have sent an email to {" "}
              <span className="text-black font-semibold dark:text-gray-100">
                {maskedEmail}
              </span>
              . Please click the link in the email to confirm your email address.{" "}
              <span className="block mt-1.5">
                This may take a few minutes!
              </span>
            </p>


            <AnimatePresence mode="wait">
              <motion.div
                key="form"
                variants={formVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <form onSubmit={(e) => e.preventDefault()}>
                  {showEmailField && (
                    <motion.div
                    variants={emailFieldVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    >
                      <EmailInputField 
                        placeholder={"Confirm your email"}
                        email={email}
                        setEmail={setEmail}
                        emailError={showEmailError}
                        emailErrorMessage={emailError}
                      />
                    </motion.div>
                  )}
                </form>
                <motion.button
                  className="w-full text-white transition duration-300 ease-in-out transform
                  bg-gradient-to-t from-blue-700 to-blue-500 hover:from-blue-600 hover:to-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleButtonClick}
                  style={{ marginTop:  16 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ duration: 0.1, ease: "easeOut" }}
                >
                  Resend email
                </motion.button>
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default ConfirmEmail;