import AnimatedSparkleWhite from "../SVG/AnimatedSparkleWhite";

const ModalGenerateButton = ({ onClick }) => {
  return (
    <button 
    className="flex flex-row justify-center items-center px-6 py-2 bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600 transition font-medium w-full max-w-60 text-white rounded-lg " 
    onClick={onClick}
    >
      <AnimatedSparkleWhite width={"28px"} height={"28px"}  />
      <span className="ml-3">
        Generate 
      </span>
    </button>
  )
};
export default ModalGenerateButton;