import { useEffect } from "react";
import { useAuth } from "../router/isAuthenticated";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  
  useEffect(() => {
    if (!loading) {
      if (!isAuthenticated) {
        window.location.href = "/login";
      }
    
    }
  }, [isAuthenticated, loading]); 

  if (loading) {
    return null;
  }

  return (
    <>
      {isAuthenticated && children ? children : null}
    </>
  );
};

export default AuthGuard;
