import BoldCheckMarkGreen from "../SVG/BoldCheckMarkGreen";
import BoldTimesRed from "../SVG/BoldTimesRed";


const CorrectionPointsFooter = ({
  maxPoints,
  currentPoints,
  questionIndex,
  numberOfQuestions,
  handleRedirect,
  handleNextQuestion,
  isCorrect,
  pointsForQuestion
}) => {

  const onButtonClick = () => {
    if (questionIndex === numberOfQuestions) {
      handleRedirect();
    } else {
      handleNextQuestion();
    }
  }


  return (
    <div
      className={`${
        isCorrect ? "bg-[#D7FFB8] border-[#D7FFB8] dark:bg-gray-800 dark:border-gray-800" : "bg-[#FEDFE0] border-[#FEDFE0] dark:bg-gray-800 dark:border-gray-800"
      } fixed left-0 right-0 bottom-0 flex justify-center items-center h-36 min-w-full border-t-2 w-full`}
    >
      <div className="w-1/2 flex justify-between items-center">
        <div className="w-1/3 flex justify-start items-center">
          {isCorrect ? (
            <div className="flex flex-row items-center justify-between gap-6">
              <div className="bg-white dark:bg-gray-700 rounded-full" style={{padding: "18px"}}>
                <BoldCheckMarkGreen className="text-lg" />
              </div>
              <span className="text-3xl font-bold text-[#58A700]">Great!</span>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between gap-6">
              <div className="bg-white dark:bg-gray-700 rounded-full" style={{padding: "26px"}}>
                <BoldTimesRed />
              </div>
              <span className="text-3xl font-bold text-[#EA2B2B]">Oops!</span>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center w-1/3">
          <div
            className={`${
              isCorrect ? "text-[#58A700]" : "text-[#EA2B2B]"
            } text-2xl font-semibold`}
          >
            Points: {currentPoints}/{maxPoints}{isCorrect && ` + ${pointsForQuestion}`}
          </div>
        </div>
        <div className="w-1/3 flex justify-end items-center">
          <button
            className={`${
              isCorrect
                ? "bg-[#58CC02] hover:bg-[#61DE01] border-[#58A700] hover:border-[#4CAF00]"
                : "bg-[#FF4B4B] dark:bg-red-500 dark:hover:bg-red-500 hover:bg-[#FC5154] border-[#EA2B2B] dark:border-red-600 dark:hover:border-red-600 hover:border-[#FC2F31]"
            } text-white px-12 py-2 text-lg font-bold rounded-xl border-b-4 flex items-center justify-center
            active:translate-y-1 active:border-b-0 transition-all duration-75`}
            onClick={onButtonClick}
          >
            {questionIndex === numberOfQuestions ? "Show results" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CorrectionPointsFooter;
