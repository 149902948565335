const UploadButton = ({ onClick, icon, text }) => {
  return (
    <button
      className="flex items-center px-6 py-2 rounded-full text-white transition duration-300 ease-in-out transform hover:scale-105 bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600 font-semibold hover:shadow-color-lg hover:shadow-blue-600/70 dark:shadow-none"
      onClick={onClick}
      tabIndex={-1}
    >
      {icon}
      {text}
    </button>
  );
};

export default UploadButton;