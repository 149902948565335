import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import ExamInput from '../pages/ExamInput';
import ExamQuestions from '../pages/ExamQuestions';
import FlashcardInput from '../pages/FlashcardInput';
import FlashcardWriteMode from '../pages/FlashcardWriteMode';
import FlashcardRecap from '../pages/FlashcardRecap';
import GrammarInput from '../pages/GrammarInput';
import GrammarQuestion from '../pages/GrammarQuestion';
import GrammarRecap from '../pages/GrammarRecap';

import LoginPage from '../pages/LoginPage';
import ConfirmEmail from '../pages/ConfirmEmailPage';
import PasswordReset from '../pages/PasswordResetPage';
import PasswordResetConfirm from '../pages/PasswordResetConfirmPage';

import GoogleCallback from '../pages/GoogleCallback';

import Account from '../pages/Account';

import ExamRecap from '../pages/ExamRecap';

import AllPage from '../pages/AllPage';

import PaymentSuccess from '../pages/PaymentSucess';

import PaymentCancel from '../pages/PaymentCancel';
import AuthGuard from '../components/AuthGuard';
import NavbarComponent from '../components/Navbar';

import AllFlashcards from '../pages/AllFlashcards';
import FlashcardPreview from '../pages/FlashcardPreview';

import RegisterPage from '../pages/RegisterPage';
import PricingTable from '../pages/PricingTable';
import ChangePasswordPage from '../pages/ChangePasswordPage';

import FlashcardModePage from '../pages/FlashcardModePage';

import ShareFlashcardSetPage from '../pages/ShareFlashcardSetPage'
import ConfirmEmailRedirect from '../pages/ConfirmEmailRedirect';
import TermsOfServicePage from '../pages/TermsOfServicePage';

import AppleSignInCallbackScreen from '../pages/AppleSignInCallbackScreen';

import FlashcardEdit from '../pages/FlashcardEdit';

import HomePageAuth from '../pages/HomePageAuth';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import PageNotFound from "../pages/PageNotFound"
import Blogs from '../pages/AllBlogs';
import Blog from '../pages/Blog';
import WordPressTest from '../pages/WordPressTest';

import ExamInputGuest from '../pages/ExamInputGuest';

import { useAuth } from './isAuthenticated';
import { HelmetProvider } from 'react-helmet-async';



const generateRoute = (path, Component) => {
  return (
    <Route path={path} element={
      <AuthGuard>
         <Component />
      </AuthGuard>
    } />
  );
};



const AppRouter = () => {
  const { isAuthenticated } = useAuth();
  return (
    <HelmetProvider>
      <Router>
        <NavbarComponent />
          <Routes>
            <Route path="/" element={isAuthenticated ? <HomePageAuth /> : <LandingPage />} />

            <Route path='/blogs' element={<Blogs />} />
            <Route path='/blog/:slug' element={<Blog />} />
            <Route path='/wordpress' element={<WordPressTest />} />

            <Route path='/exam-generator' element={<ExamInputGuest />} />

            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/password/reset/confirm/user/:uidb64/:token" element={<PasswordResetConfirm />} />
            <Route path="/share-flashcard-set/:uuid" element={<ShareFlashcardSetPage/>} />
            <Route path="/apple/callback/:code/:id_token" element={<AppleSignInCallbackScreen />} />

            <Route path="/password/reset" element={<PasswordReset />} />

            <Route path="/callback" element={<GoogleCallback />} />
            <Route path="/subscription/success" element={<PaymentSuccess />} />
            <Route path="/subscription/cancel" element={<PaymentCancel />} />
            <Route path="/email-redirect/confirm/:key" element={<ConfirmEmailRedirect />} />


            {generateRoute("/all/:type", AllPage)}

            {generateRoute("/pricing", PricingTable)}

            {generateRoute("/exam/input", ExamInput)}
            {generateRoute("/exam/questions/:uuid", ExamQuestions)}
            {generateRoute("/exam/recap/:uuid", ExamRecap)}

            {generateRoute("/account", Account)}
            {generateRoute("/changepassword", ChangePasswordPage)}

            {generateRoute("/flashcard/input", FlashcardInput)}
            {generateRoute("/flashcard/input/:uuid", FlashcardInput)}
            {generateRoute("/flashcard/edit/:uuid", FlashcardEdit)}

            {generateRoute("/flashcard/mode/write/:uuid/:type", FlashcardWriteMode)}
            {generateRoute("/flashcard/recap/:uuid/:type", FlashcardRecap)}
            {generateRoute("/flashcard/all", AllFlashcards)}
            {generateRoute("/flashcard/preview/:uuid", FlashcardPreview)}
            {generateRoute("/flashcard/mode/flashcard/:uuid/:type", FlashcardModePage)}


            {generateRoute("/grammar/input", GrammarInput)}
            {generateRoute("/grammar/questions/:uuid", GrammarQuestion)}
            {generateRoute("/grammar/recap/:uuid", GrammarRecap)}

            <Route path='*' element={<PageNotFound />} />

          </Routes>
      </Router>
    </HelmetProvider>

  );
};

export default AppRouter;