import { ReactComponent as PartyPopper } from "../../Assets/party_popper.svg";
import ProgressBarCircle from "./../DefinitionQuestionComponents/ProgressbarCircle";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";


const FlashcardRecapComponent = ({
  numberOfCorrect,
  numberOfIncorrect,
  handleContinue,
  handleReview,
}) => {

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.2
      }
    }
  };
  
  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        duration: 0.8
      }
    }
  };
  
  const progressVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    show: { 
      opacity: 1, 
      scale: 1,
      transition: {
        type: "spring",
        duration: 0.8
      }
    }
  };
  
  const scoreCardVariants = {
    hidden: { opacity: 0, x: -30 },
    show: { 
      opacity: 1, 
      x: 0,
      transition: {
        type: "spring",
        duration: 0.8
      }
    }
  };
  
  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        duration: 0.8
      }
    }
  };

  const arrowVariants = {
    initial: { x: -15, opacity: 0 },
    hover: { x: 0, opacity: 1 },
  };

  const scrollContainerStyle = {
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::WebkitScrollbar': {
      display: 'none',
    },
  };


  return (
    <motion.div 
      className="mt-8"
      variants={containerVariants}
      initial="hidden"
      animate="show"
      style={scrollContainerStyle}
    >
      <motion.div variants={titleVariants} className="flex flex-row justify-center items-center">
        <div className="w-7/12 md:w-4/12">
          <h1 className="sm:text-lg md:text-xl lg:text-3xl font-semibold dark:text-dark">
            You're doing great! Keep focusing on your tough terms.
          </h1>
        </div>
        <div className="w-3/12 md:w-2/12 flex flex-col items-end">
          <PartyPopper className="w-20 h-20 md:w-28 md:h-28 lg:w-32 lg:h-32" />
        </div>
      </motion.div>

      <motion.div variants={progressVariants} className="flex flex-col justify-center items-center mt-6 mb-8">
        <ProgressBarCircle
          percent={Math.round(
            (numberOfCorrect / (numberOfCorrect + numberOfIncorrect)) * 100
          )}
        />
      </motion.div>
      <motion.div variants={scoreCardVariants} className="flex flex-row justify-center items-center">
        <div className="w-10/12 md:w-6/12">
          <div className="bg-green-100 dark:bg-gray-800 border border-green-400 dark:border-green-500 rounded-full shadow-flashcard-recap-green dark:shadow-none">
            <div className="flex flex-row justify-between px-6 py-2">
              <p className="justify-start font-semibold text-green-500 dark:text-green-500 text-sm sm:text-md md:text-lg">
                Know
              </p>
              <p className="justify-end font-semibold text-green-500 dark:text-green-500text-sm sm:text-md md:text-lg">
                {numberOfCorrect}
              </p>
            </div>
          </div>
        </div>
      </motion.div>


      <motion.div variants={scoreCardVariants} className="flex flex-row justify-center items-center mt-10">
        <div className="w-10/12 md:w-6/12">
          <div className="border border-[#e68400] bg-[#e68400] dark:bg-gray-800 dark:border-amber-600 bg-opacity-20 rounded-full shadow-flashcard-recap-orange dark:shadow-none">
            <div className="flex flex-row justify-between px-6 py-2">
              <p className="justify-start font-semibold text-amber-600 dark:text-amber-500 text-sm sm:text-md md:text-lg">
                Still learning
              </p>
              <p className="justify-end font-semibold text-amber-600 dark:text-amber-500 text-sm sm:text-md md:text-lg">
                {numberOfIncorrect}
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      
      {/* <motion.button
        className={`mt-8 px-6 py-3 w-full rounded-lg text-xl font-semibold transition shadow-lg dark:shadow-none ${disableAddButton ? "cursor-not-allowed dark:bg-gray-800 dark:text-gray-500 shadow-gray-300 bg-gray-200 text-gray-500" : "text-white shadow-blue-500/80 cursor-pointer  bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"}`} 
        onClick={handleAddFlashcard}
        whileHover={{ scale: disableAddButton ? 1 : 1.05 }}
        whileTap={{ scale: disableAddButton ? 1 : 0.95 }}
        disabled={disableAddButton}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-4" />
        Add Card
      </motion.button> */}


      {numberOfIncorrect === 0 ? (
        <motion.div variants={buttonVariants} className="mt-12 pb-8 flex flex-col items-center">
          <motion.button
            className="rounded-lg transition w-10/12 md:w-6/12 py-3 px-6 text-xl font-semibold shadow-lg dark:shadow-none text-white shadow-blue-500/80 cursor-pointer bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"
            onClick={handleContinue}
            initial="initial"
            whileHover="hover"
          >
            Continue
            <motion.span
              variants={arrowVariants}
              className="inline-block ml-2.5"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </motion.span>
          </motion.button>
        </motion.div>
      ) : (
        <motion.div variants={buttonVariants} className="mt-12 pb-8 flex flex-col items-center">
          <motion.button
            className="rounded-lg transition w-10/12 md:w-6/12 py-3 px-6 text-xl font-semibold shadow-lg dark:shadow-none text-white shadow-blue-500/80 cursor-pointer bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"
            onClick={handleReview}
            initial="initial"
            whileHover="hover"
          >
            Review {numberOfIncorrect} {numberOfIncorrect === 1 ? 'term' : 'terms'}
            <motion.span
              variants={arrowVariants}
              className="inline-block ml-2.5"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </motion.span>
          </motion.button>
        </motion.div>
      )}
    </motion.div>
  );
};
export default FlashcardRecapComponent;
