import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste } from '@fortawesome/free-solid-svg-icons';


const ExamUploadText = ({ userCharacterLimit, text, setText, textRef, error, setError }) => {
  const [textLength, setTextLength] = useState(0);

  const onTextChange = (e) => {
    const { value } = e.target;
    setTextLength(value.length);
    if (textLength <= userCharacterLimit) {
      setText(value);
    }
  };


  // useEffect(() => {
  //   if (submitted && text.trim() === "") {
  //     console.log("Text is required");
  //     setError(true);
  //     textRef.current.focus();
  //   }
  //   else {
  //     setError(false);
  //   }
  // }, [submitted, text]);

  useEffect(() => {
    if (error && text.trim() !== "") {
      setError(false);
    }
  }, [text, error, setError]);



  const handleClipboardPaste = () => {
    navigator.clipboard.readText().then(
      clipText => {
        setText(prev => prev +clipText);
        setTextLength(prev => prev + clipText.length);
      }
    );
  };



  return (
    <div className='h-[440px]'>


      {/* <button className='mb-2 flex flex-row justify-end w-full pr-2' onClick={handleClipboardPaste}>
        <FontAwesomeIcon icon={faPaste} className='text-xl text-blue-600 hover:text-blue-700' /> 
      </button> */}

      <textarea 
        value={text}
        type="text" 
        autoFocus
        ref={textRef}
        className={`h-[390px] w-full p-2.5 shadow-lg dark:shadow-none rounded-lg resize-none outline-none border-2 dark:text-white dark:bg-gray-800 ${error ? "border-red-500" : "border-gray-200 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-500"}`} placeholder="Paste your text here..." 
        onChange={(e) => onTextChange(e)}
        rows={15}
        cols={50}
      />
      <p 
        className={`font-medium mt-2.5 text-lg text-gray-500 dark:text-gray-400 ${textLength > userCharacterLimit && "text-red-500"}`}
      >
        Character Count: {textLength.toLocaleString()}/{userCharacterLimit.toLocaleString()}
      </p>
      {error && (
        <p className='text-lg text-red-500 font-medium mt-2'>Text is required</p>
      )}
      
    </div>
  );
};

export default ExamUploadText;