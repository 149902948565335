import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NoSetField = ({ buttonText, header, description, type }) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(300);
  const navigate = useNavigate();

  const updateDimensions = () => {
    const width = window.innerWidth;
    const minWidth = width >= 768 ? 450 : 300;

    if (containerRef.current) {
      const container = containerRef.current;
      const width = container.clientWidth > minWidth ? container.clientWidth : minWidth; 
      setContainerWidth(width);
    }
  };

  useEffect(() => {

    const timer = setTimeout(updateDimensions, 10);

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions); 
      clearTimeout(timer); 
    };
  }, []);

  const handleButtonClicked = () => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "exam":
        navigate("/exam/input");
        break;
      case "flashcard":
        navigate("/flashcard/input");
        break;
      case "grammar":
        navigate("/grammar/input");
        break;
    }
  };


  return (
    <div ref={containerRef} className="bg-white dark:bg-gray-800 h-[188px] rounded-lg my-6 shadow-bs" style={{ width: `${containerWidth}px`, marginLeft: "15px" }}>
      <div className="p-6 w-full h-full flex flex-col">
        <div className="flex flex-col items-center">
          <p className="text-xl font-medium dark:text-dark">{header}</p>
          <p className="text-center mt-2 text-gray-400 dark:text-gray-300">{description}</p>
        </div>
        <button className="bg-blue-600 hover:bg-blue-500 py-2 rounded-lg w-full mt-auto text-white font-semibold" onClick={handleButtonClicked}>{buttonText}</button>
      </div>
    </div>
  );
};

export default NoSetField;
