import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from './../api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import LoadingSpinnerIcon from '../components/LoadingSpinnerIcon';


const PricingTable = () => {
  const [stripe, setStripe] = useState(null);
  const [hasHadTrial, setHasHadTrial] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const tabs = [
    { id: 0, label: "Monthly" },
    { id: 1, label: "Yearly" },
  ];
  const features = [
    "Unlimited Tokens",
    "Unlimited Image Scans",
    "Unlimited Document Uploads",
    "Unlimited Flashcards In Each Set",
    "50'000 Characters Text Upload Limit",
  ]




  useEffect(() => {
    api.get("/api/payment/subscriptions/")
      .then(response => {
        console.log("This is the response data: ", response.data);
        setHasHadTrial(response.data.has_had_trial);
        setHasActiveSubscription(response.data.subscription_tier === "max");
      })
      .catch(error => {
        console.error("Error fetching subscription data:", error);
      });
  }, []);

  console.log("this is the has had trial: ", hasHadTrial)
  console.log("this si teh has active subscription: ", hasActiveSubscription)


  useEffect(() => {
    const initializeStripe = async () => {
      try {
        console.log("This is the stripe Key: ", process.env.REACT_APP_STRIPE_SESSION_KEY)
        const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_SESSION_KEY);
        setStripe(stripeInstance);
      } catch (error) {
        console.error('Error initializing Stripe:', error);
      }
    };
    initializeStripe();
  }, []);


  const handleSubmit = async () => {
    try {
      setLoadingPayment(true);
      const priceId = activeTab === 0 ? process.env.REACT_APP_PRICE_ID_1 : process.env.REACT_APP_PRICE_ID_2;

      const response = await api.post("/api/payment/stripesubscription/", { price_id: priceId },);
        console.log("This is the response: ", response)
        console.log("this is the session id from stripe", response.data.session_id)
      if (stripe && response.data.session_id) {
        const { error } = await stripe.redirectToCheckout({ sessionId: response.data.session_id });
        if (error) {
          console.error("Error redirecting to Stripe Checkout:", error);
          setLoadingPayment(false);
        }
      } else {
        console.error("Stripe or Session ID not found");
        setLoadingPayment(false);
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setLoadingPayment(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.1,
      }
    }
  };
  
  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1],
      }
    }
  };
  

  const priceCardVariants = {
    hidden: { 
      opacity: 0,
      y: 30,
      scale: 0.95
    },
    show: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.6,
        ease: [0.4, 0, 0.2, 1],
      }
    }
  };


  const discountBadgeVariants = {
    hidden: { opacity: 0, scale: 0.8, x: 20 },
    show: { 
      opacity: 1, 
      scale: 1, 
      x: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 15
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      x: 20
    }
  };



  const benefitVariants = {
    hidden: { opacity: 0, x: -20 },
    show: (index) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: index * 0.2, // Increased delay between items
        duration: 0.5,
        ease: "easeOut"
      }
    })
  };
  
  
  const loadingVariants = {
    hidden: { opacity: 0 },
    show: { 
      opacity: 1,
      transition: {
        duration: 0.2
      }
    }
  };
  


  return (
    <motion.div 
      style={{ padding: "0 16vw", height: `${ window.innerHeight - 77 }px`} } 
      initial="hidden"
      animate="show"
      variants={containerVariants}
      className='bg-light dark:bg-dark'
    >
      {hasActiveSubscription ? (
        <motion.div className="h-full py-12 w-full flex justify-center items-center pb-96">
          <div className="p-6 border-2 border-red-500 rounded-lg bg-red-100 dark:bg-gray-800 dark:shadow-none shadow-color-lg shadow-red-300">
            <FontAwesomeIcon icon={faInfoCircle} className="text-red-500 text-3xl" />
            <h2 className="mt-2.5 text-2xl font-semibold text-gray-900 dark:text-dark">You already have an active subscription!</h2>
            <p className="mt-2 text-lg text-gray-800 dark:text-gray-400">You cannot upgrade your subscription at this time.</p>
          </div>
        </motion.div>
      ) : (
        <div className='pt-10 flex flex-row justify-between space-x-6'>
          <motion.div variants={itemVariants} className='w-1/2'>
            <motion.div variants={itemVariants} className='flex flex-row justify-start items-center' style={{marginBottom: 20}}>
              <span className='text-3xl font-semibold dark:text-dark'>Study Buddy</span>
              <motion.div 
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.3 }}
                className={`ml-4 justify-center items-center  rounded-lg border-blue-600 dark:border-blue-500`} 
                style={{borderWidth: 2.5, paddingLeft: 11, paddingRight: 11, paddingTop: 3, paddingBottom: 3}}
              >
                <span className='text-xl font-semibold text-blue-600 dark:text-blue-500'>MAX</span>
              </motion.div>
            </motion.div>
            <motion.h2 variants={itemVariants} className='text-2xl font-medium dark:text-dark' style={{marginBottom: 32}}>
              Boost Your Grades to New Heights!
            </motion.h2>

            {features.map((feature, index) => (
              <motion.li 
                key={index} 
                className='flex items-center mt-4'
                custom={index}
                variants={benefitVariants}
                initial="hidden"
                animate="show"
              >
                <FontAwesomeIcon icon={faCheckCircle} className='text-blue-600 dark:text-blue-500 text-2xl mr-3.5' />
                <span className='text-xl font-medium text-gray-800 dark:text-gray-200'>{feature}</span>
              </motion.li>
            ))}
          </motion.div>
          
          
          <motion.div 
            className='w-1/2 flex flex-col justify-start items-center'
            variants={priceCardVariants}
            animate={{
              y: [0, -10, 0],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
            }}
          >

            <div className="xl:mt-0 xl:ml-6 h-[123px] flex flex-row items-center">
              <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg dark:shadow-none inline-block p-1">
                <div className="inline-flex space-x-1">
                  {tabs.map((tab) => {
                    const isActive = activeTab === tab.id;
                    const changeActiveTab = () => {
                      setActiveTab(tab.id);
                    };
                    return (
                      <button
                        key={tab.id}
                        tabIndex={-1}
                        onClick={changeActiveTab}
                        className={`
                          flex items-center relative rounded-full px-4 py-2.5 font-semibold
                          outline-sky-400 transition-colors duration-300 focus-visible:outline-2
                          ${isActive ? "text-white" : "text-blue-600 dark:text-blue-500 hover:text-blue-700 dark:hover:text-blue-600"}
                        `}
                        style={{
                          WebkitTapHighlightColor: "transparent",
                        }}
                      >
                        {isActive && (
                          <motion.span
                            layoutId="bubblePricing"
                            className="absolute inset-0 z-10 bg-blue-600"
                            style={{ borderRadius: "9999px" }}
                            transition={{
                              type: "spring",
                              bounce: 0.2,
                              duration: 0.5,
                            }}
                          />
                        )}

                        <span className="z-20 relative flex items-center">
                          {tab.label}{tab.id === 1 && ""}
                        </span>
                        {tab.id === 1 && (
                          <span className="ml-1.5 z-20 relative flex items-center">
                            - 30% OFF
                          </span>
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className='bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-xl shadow-blue-300 dark:shadow-none w-full'>
              <div className='flex flex-row items-center justify-between'>
                <p 
                  layout
                  className='text-2xl font-medium dark:text-dark'
                  animate={{ opacity: 1 }}
                  transition={{
                    layout: { type: "spring", duration: 0.6 },
                  }}
                >
                  {activeTab === 0 ? "Monthly" : "Yearly"}
                </p>

                <AnimatePresence mode="popLayout">
                  {activeTab === 1 && (
                    <motion.div 
                      className='bg-green-200  text-green-600 font-semibold rounded-full px-4 py-1'
                      variants={discountBadgeVariants}
                      initial="hidden"
                      animate="show"
                      exit="exit"
                    >
                      Save 30%
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <motion.div 
                layout
                className="flex-row items-center justify-start mt-6"
              >
                <motion.span
                  layout
                  className="text-4xl font-medium dark:text-dark"
                  animate={{ 
                    opacity: 1,
                    scale: [0.95, 1],
                  }}
                  transition={{
                    duration: 0.3,
                    type: "spring",
                    stiffness: 200,
                    damping: 20
                  }}
                >
                  ${activeTab === 0 ? "6.99" : "59.99"}
                </motion.span>
                <motion.span
                  layout
                  className='text-gray-500 dark:text-gray-300 font-normal text-lg ml-1'
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  / {activeTab === 0 ? "month" : "year"}
                </motion.span>
              </motion.div>

              <motion.button 
                className='w-full bg-gradient-to-t from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 text-white font-medium text-xl py-2 rounded-lg mt-12' 
                onClick={handleSubmit}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={loadingPayment}
              >
                <AnimatePresence mode="wait">
                  {loadingPayment ? (
                    <motion.div
                      key="loading"
                      className="flex items-center justify-center gap-2"
                      variants={loadingVariants}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      <LoadingSpinnerIcon className="w-5 h-5" />
                      <span>Loading...</span>
                    </motion.div>
                  ) : (
                    <motion.span
                      key="text"
                      variants={loadingVariants}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      {hasHadTrial ? "Start Now" : "Try 7 Days Free"}
                    </motion.span>
                  )}
                </AnimatePresence>
              </motion.button>
            </div>
          </motion.div>
        </div>
      )}
      
    </motion.div>
  );
};
export default PricingTable;
