import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import api from "../api";
import moment from "moment";
import { motion, AnimatePresence } from "framer-motion";

const ProfileDropDown = ({
  setShowConfirmDeletionModal,
  showProfileDropDown,
  setShowProfileDropDown,
}) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("")
  const [tokens, setTokens] = useState(0)
  const [maxTokens, setMaxTokens] = useState(0)
  const [subscriptionPlan, setSubscriptionPlan] = useState(null)
  const [lastTokenReset, setLastTokenReset] = useState(null)
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });


  const dropdownRef = useRef(null);


  useEffect(() => {
    const urls = [
      "/api/auth/user/",
      "/api/payment/subscriptions/",
      "/api/userinfo/",
      "/api/user-tokens/",
    ]

    Promise.all(urls.map(url => api.get(url)))
      .then((responses) => {
        setEmail(responses[0].data.email)
        
        if (responses[0].data.first_name !== "" || responses[0].data.last_name !== "") {
          setUserName(`${responses[0].data.first_name} ${responses[0].data.last_name}`)
        } else {
          setUserName(responses[0].data.username)
        }

        setLastTokenReset(responses[3].data.last_reset)
        if (responses[1].data.subscription_tier === "max") {
          setMaxTokens(1)
          setTokens(1)
          setSubscriptionPlan("max")
        } else {
          setMaxTokens(responses[3].data.max_tokens)
          setTokens(responses[3].data.tokens)
          setSubscriptionPlan("basic")
        }

      })
      .catch((errors) => {
        console.error("There was an error! ", errors)
      })
  }, [])






  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowProfileDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);



  useEffect(() => {
    const targetDate = moment(lastTokenReset).add(1, 'month');

    const updateCountdown = () => {
      const now = moment();
      const diff = targetDate.diff(now);

      if (diff > 0) {
        const duration = moment.duration(diff);
        setTimeLeft({
          days: duration.days(),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        clearInterval(interval);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [lastTokenReset]);

  const { days, hours, minutes, seconds } = timeLeft;

  const formatTimeRemaining = () => {
    if (days > 0) {
      return (
        <>
          <span>{days}</span>{' '}
          <span className="text-gray-500 dark:text-gray-300">Day{days > 1 ? 's' : ''}</span>
        </>
      );
    } else if (hours > 0) {
      return (
        <>
          <span>{hours}</span>{' '}
          <span className="text-gray-500 dark:text-gray-300">Hour{hours > 1 ? 's' : ''}</span>
        </>
      );
    } else if (minutes > 0) {
      return (
        <>
          <span>{minutes}</span>{' '}
          <span className="text-gray-500 dark:text-gray-300">Minute{minutes > 1 ? 's' : ''}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{seconds}</span>{' '}
          <span className="text-gray-500 dark:text-gray-300">Second{seconds > 1 ? 's' : ''}</span>
        </>
      );
    }
  };


  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        when: "beforeChildren",  // start staggering after container is visible
        staggerChildren: 0.08,   // time between children animating
        duration: 0.3,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    },
  };
  
  const childVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
  };


  return (
    <AnimatePresence>
      {showProfileDropDown && ( 
        <motion.div
          ref={dropdownRef}
          className="absolute top-full right-0 z-50 mt-8 max-w-md overflow-hidden rounded-2xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-xl opacity-100 translate-y-0"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
        <div className="w-[300px] p-5 flex flex-col gap-4">

          <motion.div variants={childVariants} className="flex items-center gap-4 mb-2">
            <FontAwesomeIcon icon={faUserCircle} className="text-5xl text-gray-500 dark:text-gray-300" />
            <div className="flex flex-col">
              <p className="text-lg font-semibold dark:text-white">{userName}</p>
              <p className="text-sm font-medium text-gray-400">{email}</p>
            </div>
          </motion.div>
          <motion.div variants={childVariants} className="w-full mb-1">

            <p className="font-medium text-gray-500 dark:text-gray-300 text-sm mb-2">Credits</p>

            
            <div className="rounded-xl overflow-hidden bg-gray-100 dark:bg-gray-700 flex flex-col w-full ">
              <div className="px-5 py-4 font-medium text-sm transition flex items-center justify-between gap-4 border-b border-gray-200 dark:border-gray-600 dark:text-white">
                <p>Tokens</p>
                <p className="font-semibold">
                  {subscriptionPlan === "max" ? "Unlimited" : `${tokens} / ${maxTokens}`}
                  
                </p>
              </div>
              <div className="px-5 py-4 font-medium text-sm transition flex items-center justify-between gap-4 dark:text-white">
                <p>Next Reset</p>
                <p className="font-semibold">
                  {formatTimeRemaining()}
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div variants={childVariants} className="w-full mb-1 mt-4">
            <p className="mb-2 font-medium text-gray-500 dark:text-gray-300 text-sm">Account</p>
            <div className="rounded-xl overflow-hidden bg-gray-100 dark:bg-gray-700 flex flex-col w-full">
              <button 
                onClick={() => {setShowProfileDropDown(false); window.location.href = '/account';}}
                className="group px-5 py-4 font-medium text-sm transition flex items-center justify-between gap-4 border-b border-gray-200 dark:border-gray-600 dark:text-white hover:bg-gray-200 dark:hover:bg-slate-600"
              >
                <p>Your Account</p>
                <p className="font-semibold text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-200 group-hover:text-black">
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </button>
              <button
                className="px-6 py-4 font-medium text-sm hover:text-red-500 hover:bg-gray-200 dark:hover:bg-slate-600 dark:hover:text-red-500 transition flex items-center gap-4 border-b border-gray-200 last:border-b-0 dark:text-white"
                onClick={(e) => {e.stopPropagation();setShowProfileDropDown(false); setShowConfirmDeletionModal(true);}}
              >
                Sign out
              </button>
            </div>
          </motion.div>

        </div>
        </motion.div>    


      )}
    </AnimatePresence>
   
  );
};

export default ProfileDropDown;
