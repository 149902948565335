import { useEffect, useState } from "react";
import useTheme from "./useTheme";

const useDarkMode = () => {
  const [theme] = useTheme();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // If the user has manually chosen a 'dark' or 'light' theme,
    // just set isDarkMode accordingly and do not listen for system changes.
    if (theme === "dark") {
      document.body.style.backgroundColor = '#101828';
      setIsDarkMode(true);
      return;
    }
    if (theme === "light") {
      document.body.style.backgroundColor = '#F6F7FB';
      setIsDarkMode(false);
      return;
    }

    // If the theme is 'system', we need to watch the system preference.
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Handler to update isDarkMode when system preference changes
    const handleChange = (e) => {
      setIsDarkMode(e.matches);
      if (e.matches) {
        document.body.style.backgroundColor = '#101828';
      } else {
        document.body.style.backgroundColor = '#F6F7FB';
      }
    };

    // Set initial value and add listener
    setIsDarkMode(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleChange);

    // Cleanup the event listener when the component unmounts or theme changes
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [theme]);

  return isDarkMode;
};

export default useDarkMode;
