import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FlashcardEditField from "./FlashcardTermField";
import { ReactComponent as FlashcardIcon } from "../Assets/flashcard_icon.svg";
import { ReactComponent as WriteModeIcon } from "../Assets/write_mode_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faPen,
  faArrowUpFromBracket,
  faKey,
  faUnlockAlt,
  faClone,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import api from "./../api";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
import GetEditAccessModal from "../components/Modals/GetEditAccessModal";
import { Notify } from "../providers/NotificationProvider";
import LimitModal from "../components/Modals/LimitModal";
import FlashcardCarousel from "../components/DefinitionQuestionComponents/FlashcardCarousel";
import LoadingSpinner from "../components/General/LoadingSpinner";
import { motion, AnimatePresence } from "framer-motion";
import useDarkMode from "../hooks/useDarkMode";


const FlashcardPreview = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [performance, setPerformance] = useState(0);
  const [title, setTitle] = useState("");

  const [flashcards, setFlashcards] = useState([]);

  const [flashcardModeQuestionFilter, setFlashcardModeQuestionFilter] = useState("");
  const [writeModeQuestionFilter, setWriteModeQuestionFilter] = useState("");

  const [titleEditMode, setTitleEditMode] = useState(false);
  const [userEditAccess, setUserEditAccess] = useState(false);
  const [numberOfFlashcardsLimit, setNumberOfFlashcardsLimit] = useState(0);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showGetEditAccessModal, setShowGetEditAccessModal] = useState(false);

  const [loading, setLoading] = useState(true);



  const titleInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const isDarkMode = useDarkMode();



  useEffect(() => {
    if (titleEditMode) {
      titleInputRef.current.style.width = `${title.length + 1}ch`;
    }
  }, [title, titleEditMode]);

  useEffect(() => {
    setLoading(true);
    const urls = [
      `/api/flashcards/all/${uuid}/`,
      `/api/flashcards/flashcard_set/${uuid}/`,
      `/api/flashcards/user_flashcard_set/${uuid}/`,
      `/api/subscription/`,
      `/api/flashcards/learned_progress/${uuid}/`,
      `/api/flashcards/user_edit_access/${uuid}/`,
    ];

    Promise.all(urls.map((url) => api.get(url)))
      .then((responses) => {
        const flashcardResponse = Array.from(responses[0].data);
        const flashcardSetResponse = responses[1];
        const userFlashcardSetResponse = responses[2];
        console.log("this is the user flashcard set response: ", userFlashcardSetResponse.data);
        const subscriptionResponse = responses[3];
        const learnedProgressResponse = responses[4];
        const userEditAccessResponse = responses[5];

        setFlashcards(flashcardResponse);
        setTitle(flashcardSetResponse.data.title);
        setWriteModeQuestionFilter(
          userFlashcardSetResponse.data.write_mode_question_type
        );
        setFlashcardModeQuestionFilter(
          userFlashcardSetResponse.data.flashcard_mode_question_type
        );
        setPerformance(learnedProgressResponse.data.performance);
        setUserEditAccess(Boolean(userEditAccessResponse.data.edit_access));

        let cardLimit = 100;
        if (subscriptionResponse.data.plan === "max") {
          cardLimit = 5000;
        } else {
          cardLimit = 100;
        }
        setNumberOfFlashcardsLimit(cardLimit);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [uuid]);

  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 0) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleWriteMode = () => {
    navigate(`/flashcard/mode/write/${uuid}/${writeModeQuestionFilter}`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFlashcardMode = () => {
    navigate(`/flashcard/mode/flashcard/${uuid}/${flashcardModeQuestionFilter}`);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      alert("Failed to copy text: ", err);
    }
  };

  const handleDeleteAll = () => {
    api.delete("/api/flashcards/delete_flashcard_set/" + uuid + "/")
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        Notify({ message: "Flashcard set deleted!", type: "error" });
        navigate("/flashcard/all");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleCopyFlashcardSet = () => {
    api.post("/api/flashcards/copy_flashcard_set/", { uuid: uuid })
      .then((response) => {
        setShowDropdown(false);
        navigate(`/flashcard/preview/${response.data.new_set_uuid}`);
        Notify({ message: "Flashcard set copy created", type: "success" });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleShareEditPassword = () => {
    api.get("/api/flashcards/flashcard_edit_password/" + uuid + "/")
      .then((response) => {
        console.log("Response: ", response.data);
        copyToClipboard(response.data.password);
        Notify({ message: "Password saved to clipboard", type: "success" });
        setShowDropdown(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleShareFlashcardSet = () => {
    copyToClipboard(`https://studybuddyai.app/share-flashcard-set/${uuid}`);
    Notify({ message: "Link to the set copied.", type: "success" });
    setShowDropdown(false);
  };

  const handleSaveEditTitle = () => {
    api.put("/api/flashcards/edit_flashcard_set_title/", {
        uuid: uuid,
        title: title,
      })
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        setTitleEditMode(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDropdown((prev) => !prev); // Toggle the dropdown state
  };



  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,

        when: "beforeChildren"
      }
    }
  };

  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };


  const cardVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.9
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeOut",
        duration: 0.6
      }
    }
  };


  const dropdownVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95,
      y: -20,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.3,
        bounce: 0.3
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      y: -20,
      transition: {
        duration: 0.2,
        ease: "easeOut"
      }
    }
  };




  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <LimitModal
        showModal={showLimitModal}
        setShowModal={setShowLimitModal}
        limitReason={"flashcards"}
      />
      
      <ConfirmDeletionModal
        showModal={showConfirmDeletionModal}
        setShowModal={setShowConfirmDeletionModal}
        handleDelete={handleDeleteAll}
        header={"DELETE FLASHCARD SET?"}
        text={
          "Are you sure you want to DELETE this flashcard set? This action cannot be undone."
        }
        buttonLabel={"DELETE SET"}
      />

      <GetEditAccessModal
        showModal={showGetEditAccessModal}
        setShowModal={setShowGetEditAccessModal}
        flashcardSetId={uuid}
      />
      
      <motion.div 
        className="bg-light dark:bg-dark"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >

        <div className="max-w-[800px] flex flex-col justify-center mx-auto">
          <motion.div 
            className="flex justify-between items-center text-2xl md:text-3xl lg:text-4xl font-semibold mt-10 mb-2"
            variants={headerVariants}
            initial="hidden"
            animate="visible"
          >
            {titleEditMode ? (
              <input
                ref={titleInputRef}
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  titleInputRef.current.style.width = `${e.target.value.length + 1}ch`;
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    titleInputRef.current.blur();
                  }
                }}
                onBlur={handleSaveEditTitle}
                className="text-2xl md:text-3xl lg:text-4xl font-semibold outline-none border-b-2 dark:border-gray-700 dark:focus:border-gray-500 focus:border-gray-300 px-2.5 py-2 rounded-xl shadow-lg dark:bg-gray-800 dark:text-white"
                autoFocus
              />
            ) : (
              <span
                onClick={() => setTitleEditMode(true)}
                className="hover:bg-gray-200 dark:hover:bg-gray-800 dark:text-white px-2.5 py-2 rounded-xl"
              >
                {title}
              </span>
            )}
            <div className="flex flex-row justify-center items-center space-x-2">
                <button
                  ref={toggleButtonRef}
                  className="flex items-center"
                  onClick={toggleDropdown}
                >
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    className="text-3xl text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-500"
                  />
                </button>
            </div>
          </motion.div>

          
          <div className="relative">
            <AnimatePresence>
              {showDropdown && (
                <motion.div
                  ref={dropdownRef}
                  className="absolute top-full right-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl w-60 z-50"
                  variants={dropdownVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <ul
                    className="text-gray-700 dark:text-gray-200 text-base"
                    aria-labelledby="dropdownMenuIconHorizontalButton"
                  >
                    <li>
                      <button
                        className="font-medium flex items-center w-full text-left px-6 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none rounded-t-lg"
                        onClick={handleCopyFlashcardSet}
                      >
                        <FontAwesomeIcon icon={faClone} className="mr-6" />
                        Create a Copy
                      </button>
                    </li>
                    <li>
                      <button
                        className="font-medium flex items-center w-full text-left px-6 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none"
                        onClick={handleShareEditPassword}
                      >
                        <FontAwesomeIcon icon={faArrowUpFromBracket} className="mr-6" />
                        Share
                      </button>
                    </li>
                    {!userEditAccess && (
                      <li>
                        <button
                          type="button"
                          className="font-medium flex items-center w-full text-left px-6 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none  rounded-b-lg"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDropdown(false);
                            setShowGetEditAccessModal(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUnlockAlt}
                            className="mr-6"
                          />
                          Get Access to Edit
                        </button>
                      </li>
                    )}
                    {userEditAccess && (
                      <>
                        <li>
                          <button
                            type="button"
                            className="font-medium flex items-center w-full text-left px-6 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none text-base"
                            onClick={handleShareEditPassword}
                          >
                            <FontAwesomeIcon icon={faKey} className="mr-6" />
                            Share Edit Password
                          </button>
                        </li>
                        <div className="border-t dark:border-gray-600 border-gray-200" />
                        <button
                          type="button"
                          className="font-medium flex items-center w-full text-left px-6 py-2 text-base text-red-600 hover:text-white hover:bg-red-600 focus:outline-none rounded-b-lg"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDropdown(false);
                            setShowConfirmDeletionModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} className="mr-6" />
                          Delete Set
                        </button>
                      </>
                    )}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </div>


          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-75px" }}
            variants={cardVariants}
          >
            <FlashcardCarousel flashcards={flashcards} />
          </motion.div>

          

          <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-75px" }}
            variants={cardVariants}
            className="flex flex-col sm:flex-row space-x-4 justify-between w-full"
          >
            <button
              className="shadow-lg dark:shadow-none w-full flex flex-row justify-center items-center px-6 py-3.5 rounded-xl font-semibold text-blue-600 dark:text-gray-300 transition transform hover:scale-105 bg-white dark:bg-gray-800 gap-4"
              onClick={handleFlashcardMode}
            >
              <FlashcardIcon
                fill={isDarkMode ? "#d1d5db" : "#2563eb"}
                style={{ height: "30px", width: "auto" }}
              />
              <span className="text-lg font-semibold">Flashcard Mode</span>
            </button>
            <button
              className="shadow-lg w-full flex flex-row justify-center items-center px-6 py-3.5 rounded-xl font-semibold text-blue-600 dark:text-gray-300 bg-white dark:bg-gray-800 transition transform hover:scale-105 gap-4"
              onClick={handleWriteMode}
            >
              <WriteModeIcon
                fill={isDarkMode ? "#d1d5db" : "#2563eb"}
                style={{ height: "30px", width: "auto" }}
              />
              <span className="text-lg font-semibold">Write Mode</span>
            </button>
          </motion.div>

          <motion.div
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg dark:shadow-none p-6 mt-12"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-75px" }}
            variants={cardVariants}
          >
            <div>
              <h1 className="text-2xl font-semibold mb-4 dark:text-dark">Performance</h1>
              <div className="text-xl font-medium mb-4 dark:text-gray-300">
                You have learned {Math.round(performance)}% of all the terms
              </div>
              <div className="w-full h-5 bg-gray-200 dark:bg-gray-700 rounded-full">
                <div
                  className="bg-green-400 dark:bg-green-500 h-5 rounded-full"
                  style={{ width: `${performance}%` }}
                ></div>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="mt-10 flex justify-between items-center pb-2 mb-6 border-b-2 border-gray-200 dark:border-gray-700"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-250px" }}
            variants={cardVariants}
          >
            <h2 className="text-2xl font-semibold dark:text-dark">
              Terms in this set ({flashcards.length})
            </h2>
            {userEditAccess && (
              <motion.button
                onClick={() => navigate(`/flashcard/edit/${uuid}`)}
                className="flex items-center px-4 py-1 rounded-full font-semibold text-white transition bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 dark:hover:from-blue-600 dark:hover:to-blue-500 hover:from-blue-700 hover:to-blue-600"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FontAwesomeIcon icon={faPen} size="1x" className="text-lg mr-2" />
                <span className="text-lg font-semibold">Edit set</span>
              </motion.button>
            )}

          </motion.div>

          <FlashcardEditField
            setFlashcards={setFlashcards}
            flashcards={flashcards}
            flashcardSetUuid={uuid}
            flashcardLimit={numberOfFlashcardsLimit}
            setShowLimitModal={setShowLimitModal}
          />
        </div>
      </motion.div>    
    </>

  );
};

export default FlashcardPreview;
