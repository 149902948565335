import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faTimes, faTriangleExclamation, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import { motion } from 'framer-motion';

const LimitModal = ({ showModal, setShowModal, limitReason }) => {
  const [header, setHeader] = useState("");
  const [subheader, setSubheader] = useState("");
  const navigate = useNavigate();


  const features = [
    "Unlimited Tokens",
    "Unlimited Image Scans",
    "Unlimited Document Uploads",
    "Unlimited Flashcards In Each Set",
    "50'000 Characters Text Upload Limit",
  ]


  useEffect(() => {
    if (limitReason === "tokens") {
      setHeader("You've Hit Your Monthly Token Limit!");
      setSubheader("You've used up all your tokens for this month. Upgrade your account now to continue.");
    } else if (limitReason === "flashcards") {
      setHeader("You've Reached Your Flashcard Limit!");
      setSubheader("Upgrade your plan to add unlimited flashcards and enhance your learning experience.");
    } else if (limitReason === "text") {
      setHeader("You've Reached Your Text Character Limit!");
      setSubheader("Upgrade your account to expand your character limit and continue adding more content.");
    }    
  }, [limitReason]);







  return (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)} >
      <div className="bg-white dark:bg-dark rounded-xl shadow-lg w-full relative max-w-2xl p-10">
        <div className='w-full flex flex-col items-center justify-center mt-2.5'>
          <FontAwesomeIcon icon={faTriangleExclamation} className='text-6xl text-red-500' />
          <h1 className='mt-4 font-semibold text-3xl dark:text-dark'>{header}</h1>
          <p className='text-lg mt-6 text-center dark:text-dark'>{subheader}</p>
          <h2 className='mt-6 text-xl text-start w-full font-semibold dark:text-dark'>What you get</h2>
        </div>

        <ul className='list-none pl-6'>
          {features.map((feature, index) => (
            <li key={index} className='flex items-center mt-2.5'>
              <FontAwesomeIcon icon={faCheckCircle} className='text-blue-600 text-xl mr-2.5' />
              <span className='text-lg font-medium text-gray-800 dark:text-gray-200'>{feature}</span>
            </li>
          ))}
        </ul>
        <button 
          className='bg-gradient-to-t from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 px-2.5 rounded-full items-center mt-10 text-white font-semibold w-full py-2.5 transition duration-300 ease-in-out transform hover:scale-105 text-lg' 
          onClick={() => navigate("/pricing")}
        >
          Try 7 Days Free
        </button>

        <motion.button
          onClick={() => setShowModal(false)}
          className="absolute top-5 right-5 hover:text-gray-400 text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
          style={{ fontSize: "28px"}}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </motion.button>
      </div>
    </Modal>
  );
};
export default LimitModal;