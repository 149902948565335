import { useState, useEffect } from 'react';
import { Root, Content, Portal } from 'vaul';
import StudyBuddyAppPreviewLogo from "../../Assets/images/StudyBuddyAppPreviewLogo.webp";
import { isMobile } from "react-device-detect";

const MobileAppFooter = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setIsOpen(true);
    }
  }, []);

  const preventClose = (open) => {

    if (!open) return;
    setIsOpen(open);
  };


  return (
    <Root open={isOpen} onOpenChange={preventClose}>
      {isOpen && (
        <Portal>

          <Content
            style={{
              boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.1), 0px -2px 5px rgba(0, 0, 0, 0.06)",
              borderRadius: "24px 24px 0 0",
            }}
            className="fixed bottom-0 left-0 right-0 bg-white p-4 border-t border-gray-200"
          >
            <div className="absolute top-2 left-1/2 transform -translate-x-1/2 cursor-pointer">
              <div className="w-10 h-1 rounded-full bg-gray-300" />
            </div>

            <div className="mt-2 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={StudyBuddyAppPreviewLogo}
                  alt="Study Buddy AI"
                  className="w-16 h-16"
                />
                <div className="ml-3">
                  <p className="text-lg font-medium">Study Buddy AI</p>
                  <p className="text-sm text-slate-400">Your AI Study Assistant</p>
                </div>
              </div>
              <button
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/app/study-buddy-ai/id6503170737/",
                    "_blank"
                  )
                }
                className="rounded-full py-1 px-5 bg-blue-600 text-white font-semibold"
              >
                GET
              </button>
            </div>
          </Content>
        </Portal>
      )}
    </Root>
  );
};

export default MobileAppFooter;




// import { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import StudyBuddyAppPreviewLogo from "../../Assets/images/StudyBuddyAppPreviewLogo.webp";
// import { isMobile } from "react-device-detect";
// const MobileAppFooter = () => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     if (isMobile) {
//       setIsVisible(true);
//     }
//   }, []);

//   return (
//     <AnimatePresence>
//       {isVisible && (
//         <motion.div
//           initial={{ y: 300 }}
//           animate={{ y: 0 }}
//           exit={{ y: 300 }}
//           transition={{ type: 'spring', stiffness: 100, damping: 15 }}
//           drag="y"
//           dragConstraints={{ top: 1, bottom: 300 }}
//           dragElastic={0}
//           onDragEnd={(e, info) => {
//             if (info.offset.y > 100) {
//               setIsVisible(false);
//             }
//           }}
//           className="fixed bottom-0 inset-x-0 bg-white rounded-t-3xl border border-gray-200 z-50"
//         >
//           <div className="flex justify-center py-1 cursor-pointer">
//             <div className="w-12 h-1 rounded-full bg-gray-300"></div>
//           </div>
//           <div className="p-4 flex items-center justify-between">
//             <div className="flex items-center">
//               <img
//                 src={StudyBuddyAppPreviewLogo}
//                 alt="Study Buddy AI"
//                 className="w-16 h-16"
//               />
//               <div className="ml-3">
//                 <p className="text-lg font-medium">Study Buddy AI</p>
//                 <p className="text-sm text-slate-400">Your AI Study Assistant</p>
//               </div>
//             </div>
//             <button
//               onClick={() =>
//                 window.open(
//                   "https://apps.apple.com/app/study-buddy-ai/id6503170737/",
//                   "_blank"
//                 )
//               }
//               className="rounded-full py-1 px-5 bg-blue-600 text-white font-semibold"
//             >
//               GET
//             </button>
//           </div>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// };

// export default MobileAppFooter;


// FIXME: This is the code where i am using the new library but there are some problems with the css

// TODO: Reasons for that problem: 1. It did not import the css file from the library correctly 2. Problems with teh peer dependencies
