import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBolt } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import ProfileDropDown from "./ProfileDropDown";
import api from "../api";
import { useAuth } from "../router/isAuthenticated";
import BrandLogo from "./SVG/BrandLogo";
import BrandLogoDarkMode from "./SVG/BrandLogoDarkMode";
import { motion } from 'framer-motion';
import SignUpButton from "./LandingPage/SignUpButton"
import ConfirmDeletionModal from "./ConfirmDeletionModal";
import { Notify } from "../providers/NotificationProvider";
import useDarkMode from "../hooks/useDarkMode";

const tabs = [
  {
    id: "home",
    label: "Home",
  },
  {
    id: "exam",
    label: "Exam",
  },
  {
    id: "flashcard",
    label: "Flashcard",
  },
  {
    id: "grammar",
    label: "Grammar",
  },
]


export default function NavbarComponent() {
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [activeTab, setActiveTab] = useState(null);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);

  const isDarkMode = useDarkMode();
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  const hideNavbarPaths = [
    /\/exam\/questions\/[0-9a-fA-F-]+/,
    /\/exam\/recap\/[0-9a-fA-F-]+/,
  
    /\/grammar\/questions\/[0-9a-fA-F-]+/,
    /\/grammar\/recap\/[0-9a-fA-F-]+/,
  
    /\/flashcard\/mode\/write\/[0-9a-fA-F-]+\/\w+/,
    /\/flashcard\/mode\/flashcard\/[0-9a-fA-F-]+\/\w+/,
    /\/flashcard\/edit\/[0-9a-fA-F-]+/,
    /\/flashcard\/recap\/[0-9a-fA-F-]+\/\w+/,
  
    /register/,
    /confirm-email/,
    /login/,
    /\/password\/reset/,
    /callback/,
    /changepassword/,
    /set/,
    /wordpress/,
  ];
  

  const showNavbarSessionStorageValue = sessionStorage.getItem("showNavbar");

  const hideNavbar = hideNavbarPaths.some((pattern) =>
    pattern.test(location.pathname)
  )|| showNavbarSessionStorageValue === "false";


  const onProductRedirect = (productName) => {
    if (productName === "exam") {
      CheckIfProductEmpty("/api/summary_title_uuid/", "exam");
    } else if (productName === "flashcard") {
      CheckIfProductEmpty(
        "/api/flashcards/flashcard_set_title_uuid/",
        "flashcard"
      );
    } else if (productName === "grammar") {
      CheckIfProductEmpty("/api/grammar_title_uuid/", "grammar");
    }
  };


  const CheckIfProductEmpty = (link, productName) => {
    api.get(link)
      .then((response) => {
        const data = response.data;
        console.log("this is the data", data);
        if (data.length === 0) {
          console.log("the length is 0");
          if (productName === "exam") {
            navigate("/exam/input");
          } else if (productName === "flashcard") {
            navigate("/flashcard/input");
          } else if (productName === "grammar") {
            navigate("/grammar/input");
          }
        } else {
          if (productName === "exam") {
            navigate("/all/exam");
          } else if (productName === "flashcard") {
            navigate("/flashcard/all");
          } else if (productName === "grammar") {
            navigate("/all/grammar");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const onLogout = () => {
    api.post("/api/auth/logout/")
      .then((response) => {
        Notify({message: "You have been signed out", type: "success"});
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error fetching logout data:", error);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      api.get("/api/subscription/")
        .then((response) => {
          if (response.data.plan === "basic") {
            setShowUpgrade(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);


  useEffect(() => {
    if (location.pathname === "/exam/input" || location.pathname === "/all/exam") {
      setActiveTab("exam");
    } else if (location.pathname === "/flashcard/input" || location.pathname === "/flashcard/all" || /^\/flashcard\/input\/[0-9a-fA-F-]+\/?$/.test(location.pathname) || /^\/flashcard\/preview\/[0-9a-fA-F-]+\/?$/.test(location.pathname)) {
      setActiveTab("flashcard")
    } else if (location.pathname === "/grammar/input" || location.pathname === "/all/grammar") {
      setActiveTab("grammar")
    } else if (location.pathname === "/") {
      setActiveTab("home")
    } else {
      setActiveTab(null)
    }
  }, [location.pathname]);



  const publicPaths = [
    "/",
    "/terms-of-service",
    "/privacy-policy",
    "/blogs",
    "/blog",
    "/wordpress",
    "/exam-generator"
  ]

  const isPublicPath = (pathname, publicPaths) => {
    return publicPaths.some(path =>
      path.endsWith('/') ? pathname.startsWith(path) : pathname === path
    );
  };
  



  if (hideNavbar) {
    return null;
  } else if (!isAuthenticated && isPublicPath(location.pathname, publicPaths)) {
    return (
      <motion.nav
        className="fixed w-full top-0 left-0 border-b border-gray-200 shadow-sm bg-white/65"
        style={{
          //backgroundColor: isDarkMode ? "rgba(19, 24, 57, 0.75)" : "rgba(255, 255, 255, 0.75)",
          backdropFilter: "blur(15px)",
          zIndex: 999,
        }}
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
      >
        <div className="container mx-auto flex items-center justify-between py-1.5 px-6 max-w-[1200px]">

          <div
            className="flex-shrink-0 flex items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            <BrandLogo width="60px" height="60px" />
            <span className="hidden md:inline text-2xl font-bold ml-4">
              Study Buddy
            </span>
          </div>
          <div className=" flex flex-row items-center justify-end">
            <button 
              className="hidden sm:inline mr-2.5 px-4 py-1 rounded-lg font-medium text-blue-700 hover:text-blue-800 hover:bg-blue-100 text-lg"
              onClick={() => navigate("/login")}
            >
              Sign In
            </button>
            <SignUpButton />
          </div>
        </div>
      </motion.nav>
    );
  }

  return (
    <>
      <ConfirmDeletionModal 
        showModal={showConfirmDeletionModal}
        setShowModal={setShowConfirmDeletionModal}
        text={"Are you sure you want to sign out from your account?"}
        header={"Sign Out"}
        buttonLabel={"Sign Out"}
        handleDelete={onLogout}
      />

      <nav 
        className="sticky w-full top-0 left-0 border-b border-gray-200 dark:border-slate-900 shadow-sm py-2 bg-white/65 dark:bg-slate-800/65"
        // new navbar color: 30, 41, 59
        // dark blue color: 19, 24, 57
        style={{
          //backgroundColor: isDarkMode ? "rgba(30, 41, 59, 0.75)" : "rgba(255, 255, 255, 0.75)",
          backdropFilter: "blur(15px)",
          zIndex: 999,
        }}
      >
        <div className="relative flex items-center justify-between" style={{ padding: "0 13vw" }}>
          <div
            className="flex-shrink-0 flex items-center"
            onClick={() => navigate("/")}
          >
            {isDarkMode ? (
              <BrandLogoDarkMode width="60px" height="60px" />
            ) : (
              <BrandLogo width="60px" height="60px" />
            )}
          </div>


          <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 p-1">
            <div className="inline-flex">
              {tabs.map((tab) => {
                const isActive = activeTab === tab.id;
                const changeTab = (newId) => {
                  console.log("newId", newId);
                  if (newId === "home") {
                    setActiveTab(newId);
                    navigate("/")
                  } else {
                    setActiveTab(newId);
                    onProductRedirect(newId);
                  }
                }
                return (
                  <button
                    key={tab.id}
                    tabIndex={-1}
                    onClick={() => changeTab(tab.id)}

                    className={`
                      flex items-center relative rounded-full px-6 py-1.5 transition-colors duration-200 focus-visible:outline-2
                      ${isActive ? "dark:text-white text-black" : "dark:text-gray-400 text-gray-500 hover:text-black dark:hover:text-gray-200"}
                    `}
                    style={{
                      WebkitTapHighlightColor: "transparent",
                    }}
                  >
                    {isActive && (
                      <motion.span
                        //layoutId="bubbleNavbar" FIXME: this would add the nice animation to it where it moves to the focused tab
                        className="absolute inset-0 z-10 bg-white dark:bg-gray-700 border border-slate-300 dark:border-gray-600 dark:text-white shadow-b-sm"
                        style={{ borderRadius: "9999px" }}
                        transition={{
                          type: "spring",
                          bounce: 0.2,
                          duration: 0.6,
                        }}
                        animate={{ opacity: 0.9 }}
                        initial={false}
                      />
                    )}
                    <span className={`z-20 relative flex items-center ${isActive ? "dark:text-white text-black" : "dark:text-gray-400 dark:hover:text-gray-200 text-gray-500 hover:text-black"}`}>
                      {tab.label}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>


          <div 
            className="relative flex items-center gap-5"
          >
            {showUpgrade && (
              <button
                onClick={() => {navigate("/pricing");}}
                className="bg-gradient-to-t from-blue-600 to-blue-500 dark:from-blue-700 dark:to-blue-600 hover:from-blue-700 hover:to-blue-600 dark:hover:from-blue-600 hover:dark:to-blue-500 rounded-full text-white px-6 h-10 flex items-center hover:bg-blue-500 transition"
                style={{ fontSize: "15px", fontWeight: "500" }}
              >
                <FontAwesomeIcon icon={faBolt} className="mr-2" />
                Upgrade
              </button>
            )}

            <button
              onClick={(e) => { e.stopPropagation(); setShowProfileDropDown(!showProfileDropDown); }}
            >
              <FontAwesomeIcon
                icon={faUser}
                style={{ fontSize: "24px" }}
                className="text-gray-900 hover:text-gray-700 dark:text-white hover:dark:text-gray-300"
              />
            </button>    

            <ProfileDropDown
              setShowConfirmDeletionModal={setShowConfirmDeletionModal}
              showProfileDropDown={showProfileDropDown}
              setShowProfileDropDown={setShowProfileDropDown}
            />
          </div>


        </div>
      </nav>
    </>
  );
}
